import { useEffect, useState } from 'react'
import config from '@config/config'
import ls from '@utils/localStorage'

const Request = ({ path, children, method = 'GET', body = null }) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  useEffect(() => {
    let mounted = true
    const asyncCaller = async () => {
      setLoading(true)
      setError(null)

      try {
        const resp = await fetch(`${config.journeyLogsApiUrl}${path}`, {
          method,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${ls.get('jwt')}`,
          },
        })

        const json = await resp.json()
        if (mounted) {
          setData(json)
        }
      } catch (e) {
        if (mounted) {
          console.error(e)
          setError(e)
        }
      } finally {
        if (mounted) {
          setLoading(false)
        }
      }
    }
    asyncCaller()
    return () => {
      mounted = false
    }
  }, [path, body])
  return children({ data, loading, error })
}

export default Request
