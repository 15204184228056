import gql from 'graphql-tag'

const GET_WEBHOOK_CONFIG = gql`
  query getWebhookConfig($id: ID!) {
    webhooks(where: { fleet: { id: $id } }) {
      id
      hookId
      url
      status
      name
      event
      fleet {
        key
      }
    }
  }
`

export default GET_WEBHOOK_CONFIG
