import React from 'react'
import injectSheet from 'react-jss'
import { object, string, func } from 'prop-types'
import ReactSVG from 'react-svg'

import { Tooltip, Position } from '@blueprintjs/core'

const style = theme => ({
  navContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    padding: '12px 0px',
    cursor: 'pointer',
    width: '46px',
    display: 'flex',
    justifyContent: 'center',
  },
})

export const NavIcon = ({
  classes,
  imagePath,
  clickAction,
  tooltipContent,
}) => {
  const { iconContainer, navContainer } = classes
  return (
    <Tooltip content={tooltipContent} position={Position.RIGHT}>
      <div className={navContainer}>
        <div className={iconContainer} onClick={() => clickAction()}>
          <ReactSVG src={imagePath} />
        </div>
      </div>
    </Tooltip>
  )
}

NavIcon.propTypes = {
  classes: object,
  imagePath: string,
  clickAction: func,
  pathNameToMatch: string,
  tooltipContent: string,
}

NavIcon.defaultProps = {
  clickAction: () => {},
}

export default injectSheet(style)(NavIcon)
