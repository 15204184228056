import React from 'react'
import {
  Button,
  Popover,
  Position,
  Checkbox,
  Spinner,
  Tag,
} from '@blueprintjs/core'
import { string, bool, func } from 'prop-types'
import { GET_FLEETS } from './queries/getFleets.query'
import { Query } from 'react-apollo'

export class FleetFilter extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Popover position={Position.BOTTOM_LEFT} boundary="window">
        <Button
          text="Fleet"
          icon="drive-time"
          disabled={this.props.disabled}
          rightIcon={
            this.props.fleetId ? (
              <Tag intent="primary">1</Tag>
            ) : (
              'double-caret-vertical'
            )
          }
        />
        <div className="bp3-popover-height">
          <Query query={GET_FLEETS}>
            {({ loading, error, data }) => {
              if (error) {
                return null
              } else if (loading) {
                return <Spinner size="15" />
              } else {
                return data.fleets.sort().map(fleet => (
                  <Checkbox
                    id={fleet.id}
                    key={fleet.id}
                    disabled={
                      this.props.fleetId !== null &&
                      this.props.fleetId !== fleet.id
                    }
                    label={fleet.name}
                    checked={this.props.fleetId === fleet.id}
                    alignIndicator="left"
                    onChange={e => {
                      this.props.onChange(
                        e.currentTarget.checked ? fleet.id : null
                      )
                    }}
                  />
                ))
              }
            }}
          </Query>
        </div>
      </Popover>
    )
  }
}

FleetFilter.propTypes = {
  fleetId: string,
  onChange: func,
  disabled: bool,
}
