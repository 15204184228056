import gql from 'graphql-tag'

const GET_JOURNEYS = gql`
  query getJourneys(
    $id: ID!
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    journeysConnection(
      where: { driver: { id: $id } }
      orderBy: createdAt_DESC
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      edges {
        cursor
        node {
          id
          customer {
            id
            name
          }
          transactions {
            id
            payment {
              id
              isPaid
            }
          }
          icabbiTripId
          dispatcherTripCost
          status
          passengerOnboardWebhookBody
          journeyCompletedWebhookBody
          createdAt
          updatedAt
        }
      }
    }
  }
`

export default GET_JOURNEYS
