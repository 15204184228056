import gql from 'graphql-tag'

const EDIT_FLEET = gql`
  mutation fleetUpdateBasicDetails(
    $id: ID!
    $name: String!
    $stripePublicAPIKey: String
    $stripeSecretAPIKey: String
    $icabbiAccountId: String
    $icabbiAPIUrl: String
    $defaultJourneyCharge: Int!
    $defaultJourneyChargeUplift: Int!
    $icabbiAPIKey: String
    $icabbiAPISecret: String
    $key: String!
    $sendSMS: Boolean
    $SMSTemplate: String
    $sendUpdateDispatchTrigger: String
    $sendSMSTrigger: String
    $paymentGatewayCharge: Int
    $paymentGatewayPercentage: Float
    $fleetCharge: Int
    $SMSProvider: String!
    $SMSNumberFormat: String!
    $useMarketplaceSplit: Boolean
    $absorbPlatformCharge: Boolean
    $excludePlatformCharge: Boolean
    $overrideDefaultPlatformCharge: Boolean
    $overrideDefaultPlatformChargeValue: Int
    $prepayAttribute: String
    $sendTransactionToICabbi: Boolean
    $enableSynchronousAuth: Boolean
    $payoutSchedule: String!
    $prepayUseHighestCostPrice: Boolean
    $failedSmartAuthCancels: Boolean
    $refundAfterFailedIcabbiUpdate: Boolean
    $useSeparateAccountForPrepay: Boolean
    $icabbiPrepayAccountId: String
    $maxFarePerJourney: Int
    $prepayUseBrandedSMS: Boolean
    $autoRefundStandardNoShow: Boolean
    $autoRefundStandardNoShowCapturePreauthedValue: Boolean
    $autoRefundStandardNoShowCaptureCustomValue: Int
    $autoRefundPrepayNoShow: Boolean
    $autoRefundPrepayNoShowCapturePreauthedValue: Boolean
    $autoRefundPrepayNoShowCaptureCustomValue: Int
    $autoRefundStandardCancelled: Boolean
    $autoRefundStandardCancelledCapturePreauthedValue: Boolean
    $autoRefundStandardCancelledCaptureCustomValue: Int
    $autoRefundPrepayCancelled: Boolean
    $autoRefundPrepayCancelledCapturePreauthedValue: Boolean
    $autoRefundPrepayCancelledCaptureCustomValue: Int
  ) {
    fleetUpdateBasicDetails(
      where: { id: $id }
      data: {
        name: $name
        stripePublicAPIKey: $stripePublicAPIKey
        stripeSecretAPIKey: $stripeSecretAPIKey
        icabbiAccountId: $icabbiAccountId
        icabbiAPIUrl: $icabbiAPIUrl
        defaultJourneyCharge: $defaultJourneyCharge
        defaultJourneyChargeUplift: $defaultJourneyChargeUplift
        icabbiAPIKey: $icabbiAPIKey
        icabbiAPISecret: $icabbiAPISecret
        key: $key
        sendSMS: $sendSMS
        SMSTemplate: $SMSTemplate
        SMSProvider: $SMSProvider
        SMSNumberFormat: $SMSNumberFormat
        sendUpdateDispatchTrigger: $sendUpdateDispatchTrigger
        sendSMSTrigger: $sendSMSTrigger
        paymentGatewayCharge: $paymentGatewayCharge
        paymentGatewayPercentage: $paymentGatewayPercentage
        fleetCharge: $fleetCharge
        useMarketplaceSplit: $useMarketplaceSplit
        absorbPlatformCharge: $absorbPlatformCharge
        excludePlatformCharge: $excludePlatformCharge
        overrideDefaultPlatformCharge: $overrideDefaultPlatformCharge
        overrideDefaultPlatformChargeValue: $overrideDefaultPlatformChargeValue
        prepayAttribute: $prepayAttribute
        sendTransactionToICabbi: $sendTransactionToICabbi
        enableSynchronousAuth: $enableSynchronousAuth
        payoutSchedule: $payoutSchedule
        prepayUseHighestCostPrice: $prepayUseHighestCostPrice
        failedSmartAuthCancels: $failedSmartAuthCancels
        refundAfterFailedIcabbiUpdate: $refundAfterFailedIcabbiUpdate
        useSeparateAccountForPrepay: $useSeparateAccountForPrepay
        icabbiPrepayAccountId: $icabbiPrepayAccountId
        maxFarePerJourney: $maxFarePerJourney
        prepayUseBrandedSMS: $prepayUseBrandedSMS
        autoRefundStandardNoShow: $autoRefundStandardNoShow
        autoRefundStandardNoShowCapturePreauthedValue: $autoRefundStandardNoShowCapturePreauthedValue
        autoRefundStandardNoShowCaptureCustomValue: $autoRefundStandardNoShowCaptureCustomValue
        autoRefundPrepayNoShow: $autoRefundPrepayNoShow
        autoRefundPrepayNoShowCapturePreauthedValue: $autoRefundPrepayNoShowCapturePreauthedValue
        autoRefundPrepayNoShowCaptureCustomValue: $autoRefundPrepayNoShowCaptureCustomValue
        autoRefundStandardCancelled: $autoRefundStandardCancelled
        autoRefundStandardCancelledCapturePreauthedValue: $autoRefundStandardCancelledCapturePreauthedValue
        autoRefundStandardCancelledCaptureCustomValue: $autoRefundStandardCancelledCaptureCustomValue
        autoRefundPrepayCancelled: $autoRefundPrepayCancelled
        autoRefundPrepayCancelledCapturePreauthedValue: $autoRefundPrepayCancelledCapturePreauthedValue
        autoRefundPrepayCancelledCaptureCustomValue: $autoRefundPrepayCancelledCaptureCustomValue
      }
    ) {
      id
      name
      stripePublicAPIKey
      stripeSecretAPIKey
      icabbiAccountId
      icabbiAPIUrl
      key
      defaultJourneyCharge
      defaultJourneyChargeUplift
      sendSMS
      SMSTemplate
      SMSProvider
      sendUpdateDispatchTrigger
      sendSMSTrigger
      SMSNumberFormat
      paymentGatewayCharge
      paymentGatewayPercentage
      fleetCharge
      useMarketplaceSplit
      absorbPlatformCharge
      excludePlatformCharge
      overrideDefaultPlatformCharge
      overrideDefaultPlatformChargeValue
      sendTransactionToICabbi
      prepayAttribute
      enableSynchronousAuth
      payoutSchedule
      prepayUseHighestCostPrice
      failedSmartAuthCancels
      refundAfterFailedIcabbiUpdate
      useSeparateAccountForPrepay
      icabbiPrepayAccountId
      maxFarePerJourney
      prepayUseBrandedSMS

      autoRefundStandardNoShow
      autoRefundStandardNoShowCapturePreauthedValue
      autoRefundStandardNoShowCaptureCustomValue

      autoRefundPrepayNoShow
      autoRefundPrepayNoShowCapturePreauthedValue
      autoRefundPrepayNoShowCaptureCustomValue

      autoRefundStandardCancelled
      autoRefundStandardCancelledCapturePreauthedValue
      autoRefundStandardCancelledCaptureCustomValue

      autoRefundPrepayCancelled
      autoRefundPrepayCancelledCapturePreauthedValue
      autoRefundPrepayCancelledCaptureCustomValue
    }
  }
`

export default EDIT_FLEET
