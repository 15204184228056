import React from 'react'
import injectSheet from 'react-jss'
import cx from 'classnames'
import { InputGroup } from '@blueprintjs/core'

import Currency from '@components/Currency/Currency'

const styles = theme => ({
  currencyContainer: {
    display: 'flex',
    position: 'relative',
  },
  currency: {
    position: 'absolute',
    zIndex: 3,
    display: 'flex',
    bottom: 6,
    left: 10,
  },
  fullWidth: {
    width: '100%',
  },
  disabled: {
    color: theme.colors.disabledText,
  },
  tableInput: {
    bottom: 7,
  },
})

const CurrencyInput = props => {
  const { classes, value, fullWidth, disabled, tableinput } = props
  return (
    <div className={classes.currencyContainer}>
      <span
        className={cx(classes.currency, {
          [classes.disabled]: disabled,
          [classes.tableInput]: tableinput,
        })}
      >
        <Currency />
      </span>
      <InputGroup
        value={value}
        type="string"
        style={{ paddingLeft: 20 }} // inputGroups styles must be inline to take affect
        className={cx({ [classes.fullWidth]: fullWidth })}
        {...props}
      />
    </div>
  )
}

export default injectSheet(styles)(CurrencyInput)
