import {
  Classes,
  Button,
  Intent,
  FormGroup,
  InputGroup,
  Switch,
  HTMLSelect,
  Divider,
  Card,
  Collapse,
  Popover,
  Menu,
  Spinner,
  NonIdealState,
} from '@blueprintjs/core'
import React, { Component, Fragment } from 'react'
import { Row, Col } from 'react-simple-flex-grid'
import 'react-simple-flex-grid/lib/main.css'
import injectSheet from 'react-jss'
import { func, object } from 'prop-types'
import * as Sqrl from 'squirrelly'
import axios from 'axios'
import ls from '@utils/localStorage'
import { get } from 'lodash'
import { permissionsAccess, authorizedRole } from '@stores/userStore'
import CurrencyInput from '@components/CurrencyInput/CurrencyInput'
import PercentageInput from '@components/PercentageInput/PercentageInput'
import config from '@config/config'

const styles = theme => ({
  example: {
    color: theme.colors.disabledText,
  },
  variables: {
    marginTop: '5px',
  },
})
class Form extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pickerOpen: false,
      attributeOptions: [],
      attributesLoading: false,
    }
  }

  closePicker = () => {
    this.setState({
      pickerOpen: false,
    })
  }

  openPicker = () => {
    this.setState({
      pickerOpen: true,
      attributesLoading: true,
    })
    const { values } = this.props
    const token = ls.get('jwt')
    axios({
      method: 'post',
      url: `${config.apiUrl}/api/icabbi/get-attribute-extras`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        fleetKey: values.key,
      },
    }).then(res => {
      const attributeOptions = get(res, 'data', null)
      if (!attributeOptions.length) {
        this.setState({
          attributesLoading: false,
        })
        return
      }
      this.setState({
        attributeOptions,
        attributesLoading: false,
      })
    })
  }

  handlePopoverMenuClick = (e, opt) => {
    e.preventDefault()
    this.props.setFieldValue('prepayAttribute', opt.alias)
    this.setState({
      pickerOpen: false,
    })
  }

  editableField = () => {
    return !permissionsAccess('fleetUpdateBasicDetails')
  }

  authorizedRole = () => {
    return authorizedRole('SUPERADMIN_ROLE')
  }

  render() {
    const {
      handleChange,
      handleSubmit,
      errors,
      values,
      setFieldValue,
      classes,
    } = this.props

    const { attributesLoading, attributeOptions, pickerOpen } = this.state

    const validation =
      values.SMSTemplate === '' || values.SMSTemplate === null
        ? 'undefined'
        : Sqrl.Render(values.SMSTemplate, {
            customerNumber: 'customerNumber',
            messageTemplate: 'messageTemplate',
          })

    const countUndefineds = str => (str.match(/undefined/g) || []).length

    const failedValidation =
      countUndefineds(validation) >
      countUndefineds(
        values.SMSTemplate === null ? 'undefined' : values.SMSTemplate
      )

    return (
      <form onSubmit={handleSubmit}>
        <Row gutter={24}>
          <Col xs={12} md={8}>
            <Card>
              <FormGroup
                label="Fleet Name"
                labelInfo="(required)"
                labelFor="name"
                helperText={errors.name || ''}
                intent={Intent.DANGER}
              >
                <InputGroup
                  name="name"
                  id="name"
                  type="text"
                  large={true}
                  onChange={handleChange}
                  intent={errors.name ? Intent.DANGER : Intent.NONE}
                  value={values.name}
                  disabled={this.editableField()}
                />
              </FormGroup>
              <br />
              <h5 className="bp3-heading">SMS Settings</h5>
              <Switch
                checked={values.sendSMS}
                name="sendSMS"
                label="Enabled (Send SMS)"
                onChange={handleChange}
                disabled={this.editableField()}
              />
              <br />
              <Row gutter={24}>
                <Col xs={12} md={4}>
                  <FormGroup
                    label="SMS Provider"
                    labelFor="SMSProvider"
                    helperText={errors.SMSProvider || ''}
                    intent={Intent.DANGER}
                  >
                    <HTMLSelect
                      name="SMSProvider"
                      id="SMSProvider"
                      type="select"
                      onChange={handleChange}
                      intent={errors.SMSProvider ? Intent.DANGER : Intent.NONE}
                      value={values.SMSProvider}
                      options={[
                        { label: 'Fleet Gateway', value: 'FLEET_GATEWAY' },
                        { label: 'iCabbi', value: 'ICABBI' },
                        { label: 'Amazon (Charges Apply!)', value: 'AMAZON' },
                      ]}
                      disabled={this.editableField()}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={4}>
                  <FormGroup
                    label="SMS Number Format"
                    labelFor="SMSNumberFormat"
                    helperText={errors.SMSNumberFormat || ''}
                    intent={Intent.DANGER}
                  >
                    <HTMLSelect
                      name="SMSNumberFormat"
                      id="SMSNumberFormat"
                      type="select"
                      onChange={handleChange}
                      intent={
                        errors.SMSNumberFormat ? Intent.DANGER : Intent.NONE
                      }
                      value={values.SMSNumberFormat}
                      options={[
                        {
                          label: 'International (+44)',
                          value: 'INTERNATIONAL',
                        },
                        { label: 'UK Only (0044)', value: 'UK' },
                      ]}
                      disabled={this.editableField()}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Collapse isOpen={values.SMSProvider === 'FLEET_GATEWAY'}>
                <FormGroup
                  label="SMS Template URL"
                  labelFor="SMSTemplate"
                  helperText={
                    failedValidation || errors.SMSTemplate
                      ? errors.SMSTemplate
                      : ''
                  }
                  intent={Intent.DANGER}
                >
                  <ul className={classes.variables}>
                    <li>customerNumber</li>
                    <li>messageTemplate</li>
                  </ul>
                  <p
                    className={classes.example}
                  >{`Example: https://www.smsgateway.com/{{customerNumber}}/{{messageTemplate}}`}</p>
                  <InputGroup
                    name="SMSTemplate"
                    id="SMSTemplate"
                    type="text"
                    onChange={handleChange}
                    intent={
                      failedValidation || errors.SMSTemplate
                        ? Intent.DANGER
                        : Intent.NONE
                    }
                    value={values.SMSTemplate}
                    disabled={this.editableField()}
                  />
                </FormGroup>
              </Collapse>
              <br />
              <Row gutter={24}>
                <Col xs={12} md={4}>
                  <FormGroup
                    label="Send SMS when..."
                    labelFor="sendSMSTrigger"
                    helperText={errors.sendSMSTrigger || ''}
                    intent={Intent.DANGER}
                  >
                    <HTMLSelect
                      name="sendSMSTrigger"
                      id="sendSMSTrigger"
                      type="select"
                      onChange={handleChange}
                      intent={
                        errors.sendSMSTrigger ? Intent.DANGER : Intent.NONE
                      }
                      value={values.sendSMSTrigger}
                      disabled={this.editableField()}
                      options={[
                        { label: 'Driver Enroute', value: 'ENROUTE' },
                        { label: 'Passenger Onboard', value: 'POB' },
                      ]}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={4}>
                  <FormGroup
                    label="Update driver on... "
                    labelFor="sendUpdateDispatchTrigger"
                    helperText={errors.sendUpdateDispatchTrigger || ''}
                    intent={Intent.DANGER}
                  >
                    <HTMLSelect
                      name="sendUpdateDispatchTrigger"
                      id="sendUpdateDispatchTrigger"
                      type="select"
                      onChange={handleChange}
                      intent={
                        errors.sendUpdateDispatchTrigger
                          ? Intent.DANGER
                          : Intent.NONE
                      }
                      value={values.sendUpdateDispatchTrigger}
                      disabled={this.editableField()}
                      options={[
                        { label: 'On Card Payment', value: 'ON_PREAUTH' },
                        { label: 'Passenger Onboard', value: 'POB' },
                      ]}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <br />
              <Divider />
              <br />

              <h5 className="bp3-heading">iCabbi</h5>

              <FormGroup
                label="API URL"
                labelFor="icabbiAPIUrl"
                helperText={errors.icabbiAPIUrl || ''}
                intent={Intent.DANGER}
              >
                <InputGroup
                  name="icabbiAPIUrl"
                  id="icabbiAPIUrl"
                  type="text"
                  onChange={handleChange}
                  intent={errors.icabbiAPIUrl ? Intent.DANGER : Intent.NONE}
                  value={values.icabbiAPIUrl || ''}
                  disabled={this.editableField()}
                />
              </FormGroup>

              <FormGroup
                label="API Key"
                labelFor="icabbiAPIKey"
                helperText={errors.icabbiAPIKey || ''}
                intent={Intent.DANGER}
              >
                <InputGroup
                  name="icabbiAPIKey"
                  id="icabbiAPIKey"
                  type="text"
                  onChange={handleChange}
                  intent={errors.icabbiAPIKey ? Intent.DANGER : Intent.NONE}
                  value={values.icabbiAPIKey || ''}
                  disabled={this.editableField()}
                />
              </FormGroup>

              <FormGroup
                label="API Key Secret"
                labelFor="icabbiAPISecret"
                helperText={errors.icabbiAPISecret || ''}
                intent={Intent.DANGER}
              >
                <InputGroup
                  name="icabbiAPISecret"
                  id="icabbiAPISecret"
                  type="text"
                  onChange={handleChange}
                  intent={errors.icabbiAPISecret ? Intent.DANGER : Intent.NONE}
                  value={values.icabbiAPISecret || ''}
                  disabled={this.editableField()}
                />
              </FormGroup>
              <Row gutter={20}>
                <Col xs={12} md={6}>
                  <FormGroup
                    label="Airpay Account ID"
                    labelFor="icabbiAccountId"
                    helperText={errors.icabbiAccountId || ''}
                    intent={Intent.DANGER}
                  >
                    <InputGroup
                      name="icabbiAccountId"
                      id="icabbiAccountId"
                      type="text"
                      onChange={handleChange}
                      intent={
                        errors.icabbiAccountId ? Intent.DANGER : Intent.NONE
                      }
                      value={values.icabbiAccountId}
                      disabled={this.editableField()}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    label="Payment Required Attribute Short-Key (Prepay)"
                    labelFor="prepayAttribute"
                    helperText={errors.prepayAttribute || ''}
                    intent={Intent.DANGER}
                  >
                    <Popover
                      enforceFocus={false}
                      isOpen={pickerOpen}
                      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                      content={
                        attributesLoading ? (
                          <Spinner intent={Intent.PRIMARY} size={40} />
                        ) : attributeOptions.length ? (
                          <Menu>
                            {attributeOptions.map((opt, key) => (
                              <Menu.Item
                                key={key}
                                onClick={e =>
                                  this.handlePopoverMenuClick(e, opt)
                                }
                                text={`${opt.attribute.title} (${opt.alias})`}
                              />
                            ))}
                          </Menu>
                        ) : (
                          <div className="bp3-popover-height">
                            <NonIdealState
                              title="No Attributes Found"
                              description="Please make sure your iCabbi API details are correct and try again."
                            />
                          </div>
                        )
                      }
                    >
                      <InputGroup
                        name="prepayAttribute"
                        id="prepayAttribute"
                        type="text"
                        value={values.prepayAttribute}
                        onChange={handleChange}
                        onFocus={this.openPicker}
                        onBlur={() => {
                          setTimeout(() => {
                            this.closePicker()
                          }, 100)
                        }}
                        intent={
                          errors.prepayAttribute ? Intent.DANGER : Intent.NONE
                        }
                        disabled={this.editableField()}
                        fill={true}
                      />
                    </Popover>
                  </FormGroup>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col xs={12} md={6}>
                  <Switch
                    checked={values.useSeparateAccountForPrepay}
                    name="useSeparateAccountForPrepay"
                    label="Use separate iCabbi account for PrePay jobs"
                    disabled={this.editableField()}
                    onChange={handleChange}
                  />
                  {values.useSeparateAccountForPrepay ? (
                    <FormGroup
                      label="Prepay Account ID"
                      labelFor="icabbiPrepayAccountId"
                      helperText={errors.icabbiPrepayAccountId || ''}
                      intent={Intent.DANGER}
                    >
                      <InputGroup
                        name="icabbiPrepayAccountId"
                        id="icabbiPrepayAccountId "
                        type="text"
                        onChange={handleChange}
                        intent={
                          errors.icabbiPrepayAccountId
                            ? Intent.DANGER
                            : Intent.NONE
                        }
                        value={values.icabbiPrepayAccountId}
                        disabled={this.editableField()}
                      />
                    </FormGroup>
                  ) : null}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card>
              {this.authorizedRole() && (
                <Fragment>
                  <h5 className="bp3-heading">Payout Schedule</h5>
                  <FormGroup
                    labelFor="payoutSchedule"
                    helperText={
                      'How often should we move collected funds into your account?'
                    }
                    intent={Intent.NONE}
                  >
                    <HTMLSelect
                      name="payoutSchedule"
                      id="payoutSchedule"
                      type="select"
                      style={{ width: '170px' }}
                      onChange={handleChange}
                      intent={
                        errors.payoutSchedule ? Intent.DANGER : Intent.NONE
                      }
                      value={values.payoutSchedule}
                      disabled={this.editableField()}
                      options={[
                        { label: 'Daily (Default)', value: 'DEFAULT' },
                        { label: 'Weekly', value: 'WEEKLY' },
                        { label: 'Monthly', value: 'MONTHLY' },
                      ]}
                    />
                  </FormGroup>
                  <br />
                </Fragment>
              )}
              <h5 className="bp3-heading">Customer Charges</h5>
              <FormGroup
                label="Default Journey Charge"
                labelInfo="(required)"
                labelFor="defaultJourneyCharge"
                helperText={errors.defaultJourneyCharge || ''}
                intent={Intent.DANGER}
              >
                <CurrencyInput
                  name="defaultJourneyCharge"
                  id="defaultJourneyCharge"
                  value={values.defaultJourneyCharge}
                  onChange={handleChange}
                  intent={
                    errors.defaultJourneyCharge ? Intent.DANGER : Intent.NONE
                  }
                  onBlur={event => {
                    const price = Number(event.currentTarget.value).toFixed(2)
                    setFieldValue('defaultJourneyCharge', price)
                  }}
                  disabled={this.editableField()}
                />
              </FormGroup>

              <FormGroup
                label="Journey Charge Uplift"
                labelInfo="(required)"
                labelFor="defaultJourneyChargeUplift"
                helperText={
                  errors.defaultJourneyChargeUplift ||
                  'The amount over the estimated fare to increase the pre-authorization charge.'
                }
                intent={errors.defaultJourneyChargeUplift && Intent.DANGER}
              >
                <PercentageInput
                  value={values.defaultJourneyChargeUplift}
                  handleChange={values =>
                    setFieldValue(
                      'defaultJourneyChargeUplift',
                      values.floatValue
                    )
                  }
                  disabled={this.editableField()}
                />
              </FormGroup>
              <FormGroup
                label="Fleet Charge"
                labelInfo="(required)"
                labelFor="fleetCharge"
                helperText={
                  errors.fleetCharge ||
                  'Additional charge made to the customer, paid to the merchant.'
                }
                intent={errors.fleetCharge ? Intent.DANGER : Intent.NONE}
              >
                <CurrencyInput
                  name="fleetCharge"
                  id="fleetCharge"
                  value={values.fleetCharge}
                  onChange={handleChange}
                  intent={errors.fleetCharge ? Intent.DANGER : Intent.NONE}
                  onBlur={event => {
                    const price = Number(event.currentTarget.value).toFixed(2)
                    setFieldValue('fleetCharge', price)
                  }}
                  disabled={this.editableField()}
                />
              </FormGroup>
              <FormGroup
                label="Max fare per journey"
                labelInfo="(required)"
                labelFor="maxFarePerJourney"
                helperText={
                  errors.maxFarPerJourney ||
                  'Journeys with a fare greater than this amount will not be processed and any pending transactions will be canceled.'
                }
                intent={errors.maxFarPerJourney ? Intent.DANGER : Intent.NONE}
              >
                <CurrencyInput
                  name="maxFarePerJourney"
                  id="maxFarePerJourney"
                  value={values.maxFarePerJourney}
                  onChange={handleChange}
                  intent={
                    errors.maxFarePerJourney ? Intent.DANGER : Intent.NONE
                  }
                  onBlur={event => {
                    const price = Number(event.currentTarget.value).toFixed(2)
                    setFieldValue('maxFarePerJourney', price)
                  }}
                  disabled={this.editableField()}
                />
              </FormGroup>
              <br />
              <h5 className="bp3-heading">Merchant Charges</h5>
              <FormGroup
                label="Payment Gateway Percentage (%)"
                labelInfo="(required)"
                labelFor="paymentGatewayPercentage"
                helperText={
                  errors.paymentGatewayPercentage ||
                  'The expected fee of the gateway, billed to the merchant as a percentage of transaction.'
                }
                intent={errors.paymentGatewayPercentage ? Intent.DANGER : null}
              >
                <PercentageInput
                  value={values.paymentGatewayPercentage}
                  handleChange={values =>
                    setFieldValue('paymentGatewayPercentage', values.floatValue)
                  }
                  disabled={this.editableField() || !this.authorizedRole()}
                />
              </FormGroup>
              <FormGroup
                label="Payment Gateway Charge"
                labelInfo="(required)"
                labelFor="paymentGatewayCharge"
                helperText={
                  errors.paymentGatewayCharge ||
                  'The expected charge of the gateway, billed to the merchant as a fixed amount.'
                }
                intent={errors.paymentGatewayCharge ? Intent.DANGER : null}
              >
                <CurrencyInput
                  name="paymentGatewayCharge"
                  id="paymentGatewayCharge"
                  value={values.paymentGatewayCharge}
                  onChange={handleChange}
                  intent={
                    errors.paymentGatewayCharge ? Intent.DANGER : Intent.NONE
                  }
                  onBlur={event => {
                    const price = Number(event.currentTarget.value).toFixed(2)
                    setFieldValue('paymentGatewayCharge', price)
                  }}
                  disabled={this.editableField() || !this.authorizedRole()}
                />
              </FormGroup>
              {this.authorizedRole() && (
                <Fragment>
                  <br />
                  <h5 className="bp3-heading">Platform Charges</h5>
                  <Switch
                    checked={values.excludePlatformCharge}
                    name="excludePlatformCharge"
                    label="Exclude platform charge"
                    onChange={e => {
                      values.overrideDefaultPlatformCharge = false
                      values.absorbPlatformCharge = false
                      handleChange(e)
                    }}
                  />
                  <Switch
                    checked={values.overrideDefaultPlatformCharge}
                    name="overrideDefaultPlatformCharge"
                    label="Override platform charge"
                    onChange={handleChange}
                    disabled={values.excludePlatformCharge}
                  />
                  {values.overrideDefaultPlatformCharge && (
                    <div>
                      <CurrencyInput
                        name="overrideDefaultPlatformChargeValue"
                        id="overrideDefaultPlatformChargeValue"
                        value={values.overrideDefaultPlatformChargeValue}
                        onChange={handleChange}
                        intent={
                          errors.overrideDefaultPlatformChargeValue
                            ? Intent.DANGER
                            : Intent.NONE
                        }
                        onBlur={event => {
                          const price = Number(
                            event.currentTarget.value
                          ).toFixed(2)
                          setFieldValue(
                            'overrideDefaultPlatformChargeValue',
                            price
                          )
                        }}
                        disabled={this.editableField()}
                      />
                      <br />
                    </div>
                  )}
                  <Switch
                    checked={values.absorbPlatformCharge}
                    name="absorbPlatformCharge"
                    label="Fleet pays platform charge"
                    onChange={handleChange}
                    disabled={values.excludePlatformCharge}
                  />
                  <br />
                </Fragment>
              )}
              <h5 className="bp3-heading">iCabbi Updates</h5>
              <FormGroup>
                <Switch
                  checked={values.sendTransactionToICabbi}
                  name="sendTransactionToICabbi"
                  label="Send transaction to iCabbi"
                  onChange={handleChange}
                  disabled={this.editableField()}
                />
                <Switch
                  checked={values.enableSynchronousAuth}
                  name="enableSynchronousAuth"
                  label="Notify driver on failed Smart Auth"
                  onChange={handleChange}
                  disabled={this.editableField()}
                />
                <Switch
                  checked={
                    values.enableSynchronousAuth &&
                    values.failedSmartAuthCancels
                  }
                  name="failedSmartAuthCancels"
                  label="Failed SmartAuth cancels transaction"
                  disabled={
                    !values.enableSynchronousAuth || this.editableField()
                  }
                  onChange={handleChange}
                />
                <Switch
                  checked={values.refundAfterFailedIcabbiUpdate}
                  name="refundAfterFailedIcabbiUpdate"
                  label="Refund after failed iCabbi update"
                  onChange={handleChange}
                />
              </FormGroup>
              <h5 className="bp3-heading">Prepay options</h5>
              <FormGroup>
                <Switch
                  checked={values.prepayUseHighestCostPrice}
                  name="prepayUseHighestCostPrice"
                  label="Use highest of cost/price on fixed cost job"
                  onChange={handleChange}
                  disabled={this.editableField()}
                />
                <Switch
                  checked={values.prepayUseBrandedSMS}
                  name="prepayUseBrandedSMS"
                  label="Use Branded SMS"
                  onChange={handleChange}
                  disabled={this.editableField()}
                />
              </FormGroup>
              <h5 className="bp3-heading">No Show Refunds</h5>
              <FormGroup
                helperText={
                  !values.autoRefundStandardNoShowCapturePreauthedValue &&
                  !values.autoRefundStandardNoShow &&
                  'The amount to capture when no-show occurs for standard jobs'
                }
              >
                <Switch
                  checked={values.autoRefundStandardNoShow}
                  name="autoRefundStandardNoShow"
                  label="Auto refund no-show for standard jobs"
                  onChange={handleChange}
                  disabled={this.editableField()}
                />
                {!values.autoRefundStandardNoShow && (
                  <Fragment>
                    <Switch
                      checked={
                        values.autoRefundStandardNoShowCapturePreauthedValue
                      }
                      name="autoRefundStandardNoShowCapturePreauthedValue"
                      label="Capture preauth value for no-show for standard jobs"
                      onChange={handleChange}
                      disabled={this.editableField()}
                    />
                    {!values.autoRefundStandardNoShowCapturePreauthedValue && (
                      <CurrencyInput
                        name="autoRefundStandardNoShowCaptureCustomValue"
                        id="autoRefundStandardNoShowCaptureCustomValue"
                        value={
                          values.autoRefundStandardNoShowCaptureCustomValue
                        }
                        onChange={handleChange}
                        intent={
                          errors.autoRefundStandardNoShowCaptureCustomValue
                            ? Intent.DANGER
                            : Intent.NONE
                        }
                        onBlur={event => {
                          const price = Number(
                            event.currentTarget.value
                          ).toFixed(2)
                          setFieldValue(
                            'autoRefundStandardNoShowCaptureCustomValue',
                            price
                          )
                        }}
                        disabled={this.editableField()}
                      />
                    )}
                  </Fragment>
                )}
              </FormGroup>

              <FormGroup
                helperText={
                  !values.autoRefundPrepayNoShowCapturePreauthedValue &&
                  !values.autoRefundPrepayNoShow
                    ? 'The amount to capture when no-show occurs for prepay jobs'
                    : ''
                }
              >
                <Switch
                  checked={values.autoRefundPrepayNoShow}
                  name="autoRefundPrepayNoShow"
                  label="Auto refund no-show for prepay jobs"
                  onChange={handleChange}
                  disabled={this.editableField()}
                />

                {!values.autoRefundPrepayNoShow && (
                  <Fragment>
                    <Switch
                      checked={
                        values.autoRefundPrepayNoShowCapturePreauthedValue
                      }
                      name="autoRefundPrepayNoShowCapturePreauthedValue"
                      label="Capture preauth value for no-show for prepay jobs"
                      onChange={handleChange}
                      disabled={this.editableField()}
                    />
                    {!values.autoRefundPrepayNoShowCapturePreauthedValue && (
                      <CurrencyInput
                        name="autoRefundPrepayNoShowCaptureCustomValue"
                        id="autoRefundPrepayNoShowCaptureCustomValue"
                        value={values.autoRefundPrepayNoShowCaptureCustomValue}
                        onChange={handleChange}
                        intent={
                          errors.autoRefundPrepayNoShowCaptureCustomValue
                            ? Intent.DANGER
                            : Intent.NONE
                        }
                        onBlur={event => {
                          const price = Number(
                            event.currentTarget.value
                          ).toFixed(2)
                          setFieldValue(
                            'autoRefundPrepayNoShowCaptureCustomValue',
                            price
                          )
                        }}
                        disabled={this.editableField()}
                      />
                    )}
                  </Fragment>
                )}
              </FormGroup>

              <br />
              <h5 className="bp3-heading">Cancellation Refunds</h5>

              <FormGroup
                helperText={
                  !values.autoRefundStandardCancelledCapturePreauthedValue &&
                  !values.autoRefundStandardCancelled &&
                  'The amount to capture when cancellation occurs for standard jobs'
                }
              >
                <Switch
                  checked={values.autoRefundStandardCancelled}
                  name="autoRefundStandardCancelled"
                  label="Auto refund cancellations for standard jobs"
                  onChange={handleChange}
                  disabled={this.editableField()}
                />

                {!values.autoRefundStandardCancelled && (
                  <Fragment>
                    <Switch
                      checked={
                        values.autoRefundStandardCancelledCapturePreauthedValue
                      }
                      name="autoRefundStandardCancelledCapturePreauthedValue"
                      label="Capture preauth value for cancelled standard jobs"
                      onChange={handleChange}
                      disabled={this.editableField()}
                    />
                    {!values.autoRefundStandardCancelledCapturePreauthedValue && (
                      <CurrencyInput
                        name="autoRefundStandardCancelledCaptureCustomValue"
                        id="autoRefundStandardCancelledCaptureCustomValue"
                        value={
                          values.autoRefundStandardCancelledCaptureCustomValue
                        }
                        onChange={handleChange}
                        intent={
                          errors.autoRefundStandardCancelledCaptureCustomValue
                            ? Intent.DANGER
                            : Intent.NONE
                        }
                        onBlur={event => {
                          const price = Number(
                            event.currentTarget.value
                          ).toFixed(2)
                          setFieldValue(
                            'autoRefundStandardCancelledCaptureCustomValue',
                            price
                          )
                        }}
                        disabled={this.editableField()}
                      />
                    )}
                  </Fragment>
                )}
              </FormGroup>

              <FormGroup
                helperText={
                  !values.autoRefundPrepayCancelledCapturePreauthedValue &&
                  !values.autoRefundPrepayCancelled &&
                  'The amount to capture when cancellation occurs for prepay jobs'
                }
              >
                <Switch
                  checked={values.autoRefundPrepayCancelled}
                  name="autoRefundPrepayCancelled"
                  label="Auto refund cancellations for prepay jobs"
                  onChange={handleChange}
                  disabled={this.editableField()}
                />

                {!values.autoRefundPrepayCancelled && (
                  <Fragment>
                    <Switch
                      checked={
                        values.autoRefundPrepayCancelledCapturePreauthedValue
                      }
                      name="autoRefundPrepayCancelledCapturePreauthedValue"
                      label="Capture preauth value for cancelled prepay jobs"
                      onChange={handleChange}
                      disabled={this.editableField()}
                    />
                    {!values.autoRefundPrepayCancelledCapturePreauthedValue && (
                      <CurrencyInput
                        name="autoRefundPrepayCancelledCaptureCustomValue"
                        id="autoRefundPrepayCancelledCaptureCustomValue"
                        value={
                          values.autoRefundPrepayCancelledCaptureCustomValue
                        }
                        onChange={handleChange}
                        intent={
                          errors.autoRefundPrepayCancelledCaptureCustomValue
                            ? Intent.DANGER
                            : Intent.NONE
                        }
                        onBlur={event => {
                          const price = Number(
                            event.currentTarget.value
                          ).toFixed(2)
                          setFieldValue(
                            'autoRefundPrepayCancelledCaptureCustomValue',
                            price
                          )
                        }}
                        disabled={this.editableField()}
                      />
                    )}
                  </Fragment>
                )}
              </FormGroup>
              {/* <FormGroup
                helperText={
                  !values.autoRefundStandardNoShow &&
                  'An email will be sent to the fleet contact for each no-show'
                }
              >
                <Switch
                  checked={values.autoRefundStandardCancelled}
                  name="autoRefundStandardCancelled"
                  label="Auto refund cancellations for standard jobs"
                  onChange={handleChange}
                  disabled={this.editableField()}
                />
                <Switch
                  checked={values.autoRefundPrepayCancelled}
                  name="autoRefundPrepayCancelled"
                  label="Auto refund cancellations for prepay jobs"
                  onChange={handleChange}
                  disabled={this.editableField()}
                />
              </FormGroup> */}
            </Card>
          </Col>
        </Row>
        {!this.editableField() && (
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              text="Save"
              intent={Intent.DEFAULT}
              type="submit"
              disabled={failedValidation}
            />
          </div>
        )}
      </form>
    )
  }
}

Form.propTypes = {
  handleChange: func,
  handleSubmit: func,
  initialValues: object,
  errors: object,
  values: object,
  setFieldValue: func,
  classes: object,
}

export default injectSheet(styles)(Form)
