import { Drawer, Classes } from '@blueprintjs/core'
import { object, string } from 'prop-types'
import React from 'react'
import { Mutation } from 'react-apollo'

import defaultErrorHandler from '@utils/defaultErrorHandler'
import ADD_MESSAGE_TEMPLATE from './mutations/createMessageTemplate.mutation'
import { modalType } from '@utils/types'
import { successToast } from '@utils/toast'
import MessageTemplateForm from '@components/MessageTemplateForm/MessageTemplateForm'
import GET_MESSAGE_TEMPLATES from './queries/getMessageTemplates.query'

const CreateMessageTemplate = ({ isOpen, drawerClose, message }) => {
  return (
    <Drawer
      isOpen={isOpen}
      onClose={drawerClose}
      title={
        message.messageDrawer.templateType
          ? `${message.messageDrawer.templateType} Template Create`
          : 'Message Template'
      }
      autoFocus={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
    >
      <div className={Classes.DIALOG_BODY}>
        <Mutation
          mutation={ADD_MESSAGE_TEMPLATE}
          onError={defaultErrorHandler}
          onCompleted={() => {
            successToast('Message template successfully created')
            drawerClose()
          }}
          refetchQueries={[
            {
              query: GET_MESSAGE_TEMPLATES,
              variables: { id: message.fleetId },
            },
          ]}
        >
          {editMessageTemplate => (
            <MessageTemplateForm
              initialValues={{
                ...message,
              }}
              onSubmit={values => {
                editMessageTemplate({
                  variables: {
                    fleetId: values.fleetId,
                    type: values.type,
                    text: values.text,
                  },
                })
              }}
            />
          )}
        </Mutation>
      </div>
    </Drawer>
  )
}

CreateMessageTemplate.propTypes = {
  ...modalType,
  classes: object,
  message: object,
  fleetId: string,
}

export default CreateMessageTemplate
