import React from 'react'
import { matchType } from '@utils/types'
import { object } from 'prop-types'
import { Switch, Redirect, Route } from 'react-router-dom'
import Details from './Details'
import Journeys from './Journeys'
import PageLayout from '@components/PageLayout/PageLayout'

const Customer = ({ match, location }) => (
  <PageLayout
    match={match}
    location={location}
    tabs={[
      { to: 'details', name: 'Details' },
      { to: 'journeys', name: 'Journeys' },
    ]}
  >
    <section>
      <Switch location={location}>
        <Redirect
          from="/customers/:customer"
          to="/customers/:customer/details"
          exact={true}
        />
        <Route
          path={`${match.path}/details`}
          exact={true}
          component={Details}
        />
        <Route
          path={`${match.path}/Journeys`}
          exact={true}
          component={Journeys}
        />
      </Switch>
    </section>
  </PageLayout>
)

Customer.propTypes = {
  match: matchType.isRequired,
  location: object,
  classes: object,
}
export default Customer
