import gql from 'graphql-tag'

const GET_CUSTOMERS = gql`
  query getCustomers(
    $id: ID!
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    customersConnection(
      first: $first
      last: $last
      before: $before
      after: $after
      where: { journeys_some: { driver: { id: $id } } }
      orderBy: name_ASC
    ) {
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          id
          phone
          name
          e164Phone
          journeysTotalCount
          partner {
            id
            name
          }
        }
        cursor
      }
    }
  }
`

const SEARCH_GET_CUSTOMERS = gql`
  query searchGetCustomers(
    $id: ID!
    $name: String
    $phone: String
    $e164Phone: String
    $first: Int
    $last: Int
    $before: String
    $after: String
    $orderBy: CustomerOrderByInput
  ) {
    customersConnection(
      first: $first
      last: $last
      before: $before
      after: $after
      orderBy: $orderBy
      where: {
        journeys_some: { driver: { id: $id } }
        OR: [
          { name_contains: $name }
          { phone_contains: $phone }
          { e164Phone_contains: $e164Phone }
        ]
      }
    ) {
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          id
          phone
          name
          e164Phone
          journeysTotalCount
          partner {
            id
            name
          }
        }
        cursor
      }
    }
  }
`

export { GET_CUSTOMERS, SEARCH_GET_CUSTOMERS }
