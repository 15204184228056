import React, { Fragment } from 'react'
import { DatePicker } from '@blueprintjs/datetime'
import {
  ButtonGroup,
  Button,
  Popover,
  Position,
  MenuItem,
} from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import moment from 'moment'
import { toast } from '@utils/toast'
import { object, bool, func } from 'prop-types'

const DateFiltersDictionary = {
  DAY: { key: 'DAY', shift: 1, start: 'day', name: 'Day' },
  WEEK: { key: 'WEEK', shift: 7, start: 'isoWeek', name: 'Week' },
  CUSTOM: { key: 'CUSTOM', shift: 1, start: 'day', name: 'Custom' },
}

export class DateSelect extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedDateFilter: DateFiltersDictionary.DAY,
      afterDate: this.props.afterDate,
      beforeDate: this.props.beforeDate,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.selectedDateFilter !== this.state.selectedDateFilter &&
      this.state.selectedDateFilter != DateFiltersDictionary.CUSTOM
    ) {
      this.setState({
        afterDate: moment().startOf(this.state.selectedDateFilter.start),
        beforeDate: moment().endOf(this.state.selectedDateFilter.start),
      })
      return false
    }

    if (prevState !== this.state) {
      this.props.onDateChange({
        afterDate: this.state.afterDate,
        beforeDate: this.state.beforeDate,
      })
    }
  }

  render() {
    const isDisabled =
      this.state.afterDate === null ||
      this.state.beforeDate === null ||
      this.props.disabled

    const afterDateValue = moment(this.state.afterDate).format(
      'ddd DD/MM HH:mm'
    )
    const beforeDateValue = moment(this.state.beforeDate).format(
      'ddd DD/MM HH:mm'
    )

    const disableShift =
      isDisabled ||
      this.state.selectedDateFilter === DateFiltersDictionary.CUSTOM

    const renderItem = (item, { handleClick }) => {
      return (
        <MenuItem
          key={item.key}
          onClick={handleClick}
          text={item.name}
          icon={item === this.state.selectedDateFilter ? 'tick' : 'blank'}
          shouldDismissPopover={false}
        />
      )
    }

    return (
      <ButtonGroup>
        <Select
          id="dateFilter"
          icon={'calendar'}
          filterable={false}
          items={Object.values(DateFiltersDictionary)}
          itemRenderer={renderItem}
          onItemSelect={selectedDateFilter => {
            this.setState({ selectedDateFilter })
          }}
          popoverProps={{
            minimal: false,
            position: Position.BOTTOM_LEFT,
            boundary: 'window',
          }}
        >
          <Button icon="calendar" disabled={isDisabled}>
            {this.state.selectedDateFilter.name}
          </Button>
        </Select>
        <Button
          rightIcon="caret-left"
          disabled={disableShift}
          onClick={() => {
            this.setState({
              afterDate: moment(this.state.afterDate).subtract(1, 'day'),
              beforeDate: moment(this.state.beforeDate).subtract(1, 'day'),
            })
          }}
        />
        <Popover
          position={Position.BOTTOM_LEFT}
          boundary="window"
          disabled={isDisabled}
        >
          <Button
            disabled={isDisabled}
            rightIcon="double-caret-vertical"
          >{`${afterDateValue}`}</Button>
          <DatePicker
            value={moment(this.state.afterDate).toDate()}
            maxDate={
              this.props.maxDate ||
              moment()
                .endOf('day')
                .toDate()
            }
            minimal={true}
            onChange={e => {
              if (e) {
                if (
                  this.state.selectedDateFilter ===
                    DateFiltersDictionary.CUSTOM &&
                  moment(e)
                    .subtract(1, 'minute')
                    .isAfter(this.state.beforeDate)
                ) {
                  toast({
                    message: 'Starting date is after the end date',
                    intent: 'warning',
                  })
                } else {
                  this.setState({
                    afterDate: moment(e).startOf(
                      this.state.selectedDateFilter.start
                    ),
                    beforeDate: moment(e).endOf(
                      this.state.selectedDateFilter.start
                    ),
                  })
                }
              }
            }}
          />
        </Popover>
        <Fragment>
          <Button disabled={true}>to</Button>
          <Popover
            position={Position.BOTTOM_LEFT}
            boundary="window"
            disabled={isDisabled}
          >
            <Button
              disabled={isDisabled}
              rightIcon="double-caret-vertical"
            >{`${beforeDateValue}`}</Button>
            <DatePicker
              value={moment(this.state.beforeDate).toDate()}
              minDate={moment(this.state.afterDate)
                .startOf('day')
                .toDate()}
              maxDate={
                this.props.maxDate ||
                moment()
                  .endOf('day')
                  .toDate()
              }
              disabled={
                this.state.selectedDateFilter === DateFiltersDictionary.CUSTOM
              }
              minimal={true}
              onChange={e => {
                if (e) {
                  if (
                    moment(e)
                      .add(1, 'minute')
                      .isBefore(this.state.afterDate)
                  ) {
                    toast({
                      message: 'The ending date is before the starting date',
                      intent: 'warning',
                    })
                  } else {
                    this.setState({
                      selectedDateFilter: DateFiltersDictionary.CUSTOM,
                      beforeDate: moment(e).endOf('day'),
                    })
                  }
                }
              }}
            />
          </Popover>
        </Fragment>
        <Button
          rightIcon="caret-right"
          onClick={() => {
            this.setState({
              afterDate: moment(this.state.afterDate).add(1, 'day'),
              beforeDate: moment(this.state.beforeDate).add(1, 'day'),
            })
          }}
          disabled={
            disableShift || moment().isSameOrBefore(this.state.beforeDate)
          }
        />
      </ButtonGroup>
    )
  }
}

DateSelect.propTypes = {
  afterDate: object,
  beforeDate: object,
  maxDate: object,
  onDateChange: func,
  disabled: bool,
}
