import React, { Fragment, useState } from 'react'
import { object, func } from 'prop-types'
import injectSheet from 'react-jss'
import ReactSVG from 'react-svg'
import { withFormik } from 'formik'
import { Mutation } from 'react-apollo'
import { Query as CacheQuery } from 'react-apollo'
import { Card } from '@blueprintjs/core'
import ls from '@utils/localStorage'
import get from 'lodash/get'
import { errorToast } from '@utils/toast'
import LoginForm from '@components/Login/LoginForm'
import styles from '@components/Login/Login.styles'
import LOGIN from '@components/Login/mutations/login.mutation'
import LOGIN_CACHE_QUERY from '@components/Login/queries/loginCache.query'
import { toggleModal } from '@utils/cacheHelpers'
import loginIcon from '@assets/icons/login.svg'
import ForgotPasswordModal from '@components/ForgotPassword/ForgotPassword.modal'
import ResetPassword from '@components/ResetPassword/ResetPassword'
import queryString from 'query-string'
import { client } from '@services/client'
import gql from 'graphql-tag'
import airpayLogo from '@assets/airpay-logo-white.png'

const handleLogin = async (
  { id, partialToken, canUse2FA, obfuscatedPhoneNumber, bypassToken },
  history
) => {
  if (bypassToken) {
    ls.set('jwt', bypassToken)

    const rolesAndPermissionsResponse = await client.query({
      query: gql`
        query getRoleAndPermissionCategories($userId: ID) {
          user(where: { id: $userId }) {
            role {
              key
              permissionCategories {
                key
                resolverNames
              }
            }
          }
        }
      `,
      variables: {
        userId: id,
      },
    })

    ls.set('role', get(rolesAndPermissionsResponse, 'data.user.role.key', ''))
    ls.set('id', id)
    ls.set(
      'permissionCategories',
      get(
        rolesAndPermissionsResponse,
        'data.user.role.permissionCategories',
        ''
      )
    )

    history.push('/dashboard')
    return
  }

  ls.set('partialJwt', partialToken)
  ls.set('obfuscatedPhoneNumber', obfuscatedPhoneNumber)

  if (canUse2FA) {
    history.push('/2fa')
  } else {
    history.push('/validate-phone')
  }
}
const LoginWrapper = props => {
  const { classes, history } = props
  const [forgotPassOpen, setForgotPassOpen] = useState(false)
  const {
    container,
    loginContainer,
    loginForm,
    icon,
    airPayLogo,
    airPayContainer,
    forgotPassword,
  } = classes
  const { email, token } = queryString.parse(location.search)

  const openforgotPassModal = () => {
    setForgotPassOpen(true)
  }

  const closeforgotPassModal = () => {
    setForgotPassOpen(false)
  }

  return (
    <div className={container}>
      <CacheQuery
        query={LOGIN_CACHE_QUERY}
        variables={{
          email: '',
          password: '',
        }}
      >
        {({ data }) => (
          <div className={loginContainer}>
            {console.log(data)}
            <div className={airPayContainer}>
              <img
                src={airpayLogo}
                alt="Airpay.: Cashless Journeys."
                className={airPayLogo}
              />
            </div>
            {!token && (
              <Fragment>
                <Card elevation="2" className={loginForm}>
                  <ReactSVG src={loginIcon} className={icon} />
                  <Mutation
                    mutation={LOGIN}
                    onError={error => {
                      const serverErrors = get(error, 'graphQLErrors', [])

                      // fallback for errors that weren't returned by the server
                      if (!serverErrors.length) {
                        return errorToast(error.message)
                      }

                      // loop through custom server errors displaying error toast
                      for (const serverError of serverErrors) {
                        errorToast(serverError.message)
                      }

                      props.setSubmitting(false)
                    }}
                    onCompleted={async ({ login }) => {
                      await handleLogin(login, history)
                    }}
                  >
                    {loginUser => (
                      <LoginForm
                        classes={classes}
                        data={data}
                        mutation={loginUser}
                        {...props}
                      />
                    )}
                  </Mutation>
                </Card>
                <Fragment>
                  <a
                    onClick={() => {
                      openforgotPassModal()
                    }}
                    tabIndex={0}
                    className={forgotPassword}
                  >
                    Forgotten Password?
                  </a>
                  <ForgotPasswordModal
                    closeModal={() => closeforgotPassModal()}
                    modalOpen={forgotPassOpen}
                  />
                </Fragment>
              </Fragment>
            )}
            {token && (
              <ResetPassword email={email} token={token} history={history} />
            )}
          </div>
        )}
      </CacheQuery>
    </div>
  )
}

const Login = withFormik({
  mapPropsToValues: () => ({
    email: '',
    password: '',
  }),
})(LoginWrapper)

LoginWrapper.propTypes = {
  classes: object,
  history: object,
  setSubmitting: func,
}

export default injectSheet(styles)(Login)
export { Login }
