import gql from 'graphql-tag'

const GET_FLEET = gql`
  query getFleet($id: ID!) {
    fleet(where: { id: $id }) {
      id
      name
      stripeSecretAPIKey
      stripePublicAPIKey
      defaultJourneyCharge
      defaultJourneyChargeUplift
      key
      icabbiAPIUrl
      icabbiAPIKey
      icabbiAPISecret
      icabbiAccountId
      sendSMS
      SMSTemplate
      SMSProvider
      sendUpdateDispatchTrigger
      sendTransactionToICabbi
      sendSMSTrigger
      paymentGatewayCharge
      SMSNumberFormat
      paymentGatewayPercentage
      fleetCharge
      useMarketplaceSplit
      absorbPlatformCharge
      excludePlatformCharge
      overrideDefaultPlatformCharge
      overrideDefaultPlatformChargeValue
      prepayAttribute
      payoutSchedule
      enableSynchronousAuth
      partner {
        id
        name
      }
      prepayUseHighestCostPrice
      failedSmartAuthCancels
      refundAfterFailedIcabbiUpdate
      useSeparateAccountForPrepay
      icabbiPrepayAccountId
      maxFarePerJourney
      prepayUseBrandedSMS

      autoRefundStandardNoShow
      autoRefundStandardNoShowCapturePreauthedValue
      autoRefundStandardNoShowCaptureCustomValue

      autoRefundPrepayNoShow
      autoRefundPrepayNoShowCapturePreauthedValue
      autoRefundPrepayNoShowCaptureCustomValue

      autoRefundStandardCancelled
      autoRefundStandardCancelledCapturePreauthedValue
      autoRefundStandardCancelledCaptureCustomValue

      autoRefundPrepayCancelled
      autoRefundPrepayCancelledCapturePreauthedValue
      autoRefundPrepayCancelledCaptureCustomValue
    }
  }
`

export default GET_FLEET
