import React, { useState } from 'react'
import {
  FormGroup,
  InputGroup,
  Button,
  Tooltip,
  Intent,
} from '@blueprintjs/core'
import PhoneInput from '@components/PhoneInput/PhoneInput'

const RegisterForm = ({
  handleChange,
  values,
  isSubmitting,
  mutation,
  data,
  errors,
  isValid,
  setFieldValue,
  validateForm,
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const lockButton = (
    <Tooltip content={`${showPassword ? 'Hide' : 'Show'} Password`}>
      <Button
        icon={showPassword ? 'unlock' : 'lock'}
        intent={Intent.WARNING}
        minimal={true}
        onClick={() => setShowPassword(!showPassword)}
      />
    </Tooltip>
  )
  return (
    <form
      onSubmit={async e => {
        e.preventDefault()

        mutation({
          variables: { ...values },
        })
      }}
    >
      {console.log(errors)}
      <FormGroup label="Email">
        <InputGroup
          value={data.email}
          name="email"
          type="text"
          disabled={true}
          large="true"
        />
      </FormGroup>
      <FormGroup label="First name">
        <InputGroup
          value={values.firstName}
          name="firstName"
          type="text"
          intent={errors.firstName ? Intent.DANGER : Intent.NONE}
          onChange={handleChange}
          large="true"
        />
      </FormGroup>
      <FormGroup label="Last name">
        <InputGroup
          value={values.lastName}
          name="lastName"
          type="text"
          intent={errors.lastName ? Intent.DANGER : Intent.NONE}
          onChange={handleChange}
          large="true"
        />
      </FormGroup>
      <FormGroup label="Phone Number" intent={Intent.DANGER}>
        <PhoneInput
          value={values.phoneNumber}
          inputProps={{ name: 'phoneNumber' }}
          onChange={phone => {
            setFieldValue('phoneNumber', phone)
          }}
        />
      </FormGroup>
      <FormGroup
        label="Password"
        intent={errors.password ? Intent.DANGER : Intent.NONE}
      >
        <InputGroup
          value={values.password}
          onChange={handleChange}
          type={showPassword ? 'text' : 'password'}
          rightElement={lockButton}
          name="password"
          large="true"
        />
      </FormGroup>
      <FormGroup
        label="Confirm password"
        helperText={errors.passwordConfirm ? errors.passwordConfirm : ''}
        intent={errors.passwordConfirm ? Intent.DANGER : Intent.NONE}
      >
        <InputGroup
          value={values.passwordConfirm}
          onChange={handleChange}
          type={showPassword ? 'text' : 'password'}
          name="passwordConfirm"
          large="true"
        />
      </FormGroup>
      <FormGroup>
        <Button
          type="submit"
          disabled={!isValid || isSubmitting}
          text="Register"
        />
      </FormGroup>
    </form>
  )
}

export default RegisterForm
