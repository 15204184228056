import {
  ControlGroup,
  InputGroup,
  Button,
  ButtonGroup,
  Tag,
} from '@blueprintjs/core'
import debounce from 'lodash/debounce'
import React, { Component, Fragment } from 'react'

import { permissionsAccess } from '@stores/userStore'
import { matchType } from '@utils/types'
import PageLayout from '@components/PageLayout/PageLayout'
import { DEFAULT_RECORDS } from '@stores/recordsStore'

import { GET_DRIVERS, SEARCH_GET_DRIVERS } from './queries/getDrivers.query'
import Query from '@components/Query/Query'
import DriversTable from './DriversTable'
import PageNumbers from '@components/PageNumbers/PageNumbers'
import FilterRow from '@components/FilterRow/FilterRow'

class Drivers extends Component {
  constructor() {
    super()
    this.state = {
      first: DEFAULT_RECORDS,
      last: null,
      before: null,
      after: null,
      searchDriver: '',
      driverName: null,
      driverId: null,
      currentPage: 1,
      pageInfo: null,
      totalCount: 0,
    }
  }

  goToNext = (e, nextPage, endCursor) => {
    e.preventDefault()
    nextPage &&
      this.setState(prevState => ({
        first: DEFAULT_RECORDS,
        last: null,
        before: null,
        after: endCursor,
        currentPage: prevState.currentPage + 1,
      }))
  }

  goToPrevious = (e, previousPage, startCursor) => {
    e.preventDefault()
    previousPage &&
      this.setState(prevState => ({
        first: null,
        last: DEFAULT_RECORDS,
        before: startCursor,
        after: null,
        currentPage: prevState.currentPage - 1,
      }))
  }

  searchDriver = e => {
    e.preventDefault()
    const searchValue = e.target.value
    this.setState({ searchDriver: searchValue })
    searchValue.length >= 3 && this.filterQuery(searchValue)
  }

  filterQuery = debounce(searchValue => {
    const driverName = /^[a-zA-Z]+$/.test(searchValue)
    const driverId = /^\d+$/.test(searchValue)
    driverName &&
      this.setState({
        driverName: searchValue,
        first: null,
        last: DEFAULT_RECORDS,
        before: null,
        after: null,
        currentPage: 1,
      })
    driverId &&
      this.setState({
        driverId: Number(searchValue),
        first: null,
        last: DEFAULT_RECORDS,
        before: null,
        after: null,
        currentPage: 1,
      })
  }, 300)

  clearSearch = e => {
    e.preventDefault()
    this.setState({
      searchDriver: '',
      before: null,
      after: null,
      driverName: null,
      driverId: null,
      currentPage: 1,
    })
  }

  filterBar = () => {
    const { currentPage, totalCount, pageInfo } = this.state
    const totalPages = Math.ceil(totalCount / DEFAULT_RECORDS)
    const hasNextPage = currentPage < totalPages
    const hasPreviousPage = currentPage !== 1
    return (
      <FilterRow>
        <ButtonGroup>
          <ControlGroup>
            <InputGroup
              leftIcon="search"
              id="searchDriver"
              placeholder="Search Driver..."
              value={this.state.searchDriver}
              onChange={this.searchDriver}
            />
            <Button
              icon="small-cross"
              onClick={e => {
                this.clearSearch(e)
              }}
            />
          </ControlGroup>
        </ButtonGroup>

        <Tag
          disabled={true}
          minimal={true}
          large={true}
          style={{ marginLeft: 'auto' }}
        >
          {totalCount} Drivers
        </Tag>

        <ButtonGroup id="navigationGroup">
          <Button
            disabled={!hasPreviousPage || !pageInfo}
            icon="chevron-left"
            onClick={e =>
              this.goToPrevious(e, hasPreviousPage, pageInfo.startCursor)
            }
          >
            Back
          </Button>
          <PageNumbers
            currentPage={currentPage}
            totalPages={totalPages === 0 ? 1 : totalPages}
          />
          <Button
            disabled={!hasNextPage || !pageInfo}
            rightIcon="chevron-right"
            onClick={e => this.goToNext(e, hasNextPage, pageInfo.endCursor)}
          >
            Next
          </Button>
        </ButtonGroup>
      </FilterRow>
    )
  }
  render() {
    const { match, location } = this.props
    const { driverName, driverId } = this.state

    let queryToUse = {
      query: GET_DRIVERS,
      variables: {
        first: this.state.first,
        last: this.state.last,
        before: this.state.before,
        after: this.state.after,
      },
    }
    if (driverName || driverId) {
      queryToUse = {
        query: SEARCH_GET_DRIVERS,
        variables: driverId
          ? {
              driverId: driverId,
              first: this.state.first,
              last: this.state.last,
              before: this.state.before,
              after: this.state.after,
            }
          : {
              driverName: driverName,
              first: this.state.first,
              last: this.state.last,
              before: this.state.before,
              after: this.state.after,
            },
      }
    }

    return (
      <PageLayout
        match={match}
        location={location}
        permissions={permissionsAccess('drivers')}
      >
        <div className="bp3-table-frame">
          {this.filterBar()}
          <Query
            query={queryToUse.query}
            showLoader={false}
            loaderTitle="Loading Drivers..."
            variables={queryToUse.variables}
            onCompleted={({ driversConnection }) => {
              this.setState({
                totalCount: driversConnection.totalCount,
                pageInfo: driversConnection.pageInfo,
              })
            }}
          >
            {({ driversConnection }) => {
              const edges = driversConnection.edges
              return (
                <Fragment>
                  <DriversTable edges={edges} />
                </Fragment>
              )
            }}
          </Query>
        </div>
      </PageLayout>
    )
  }
}

Drivers.propTypes = {
  match: matchType,
  location: matchType,
}

export default Drivers
