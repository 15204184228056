import React, { Fragment } from 'react'
import { Mutation } from 'react-apollo'
import { Card, HTMLTable, NonIdealState } from '@blueprintjs/core'
import moment from 'moment'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import DetailsForm from './DetailsForm'
import EDIT_DRIVER from './mutations/editDriver.mutation'
import GET_DRIVER from './queries/getDriver.query'
import GET_DRIVERS from '@components/Drivers/queries/getDrivers.query'
import { history } from '@stores/routerStore'
import { matchType } from '@utils/types'
import { successToast } from '@utils/toast'
import Query from '@components/Query/Query'

const handleMutation = id => {
  history.push(`/drivers/${id}`)
}

const Details = ({ match }) => {
  const id = match.params.driver
  return (
    <Query query={GET_DRIVER} variables={{ id }}>
      {({ driver }) => {
        if (driver) {
          return (
            <Fragment>
              <Card className="bp3-nopad">
                <HTMLTable>
                  <thead>
                    <tr>
                      <th>Created At</th>
                      <th>Updated At</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {moment(driver.createdAt).format('DD/MM/YY HH:mm:ss')}
                      </td>
                      <td>
                        {moment(driver.updatedAt).format('DD/MM/YY HH:mm:ss')}
                      </td>
                    </tr>
                  </tbody>
                </HTMLTable>
              </Card>
              <Card className="bp3-layout-col">
                <Mutation
                  mutation={EDIT_DRIVER}
                  onError={defaultErrorHandler}
                  onCompleted={({ driverUpdate }) =>
                    handleMutation(driverUpdate.id)
                  }
                  refetchQueries={[{ query: GET_DRIVERS }]}
                >
                  {editDetails => {
                    return (
                      <DetailsForm
                        values={driver}
                        onSubmit={variables => {
                          successToast('Successfully edited driver details')
                          editDetails({ variables })
                        }}
                        initialValues={driver}
                      />
                    )
                  }}
                </Mutation>
              </Card>
            </Fragment>
          )
        } else {
          return (
            <NonIdealState
              icon="unknown-vehicle"
              title="No Driver Found"
              description="Sorry, this driver cannot be found"
            />
          )
        }
      }}
    </Query>
  )
}

Details.propTypes = {
  match: matchType,
}

export default Details
