import {
  Button,
  Classes,
  FormGroup,
  Intent,
  InputGroup,
} from '@blueprintjs/core'
import { func, object } from 'prop-types'
import React, { Fragment } from 'react'

import { permissionsAccess } from '@stores/userStore'
import CurrencyInput from '@components/CurrencyInput/CurrencyInput'

const editableField = () => {
  return !permissionsAccess('partnerUpdate')
}

const Form = ({ handleChange, values, errors, setFieldValue }) => (
  <Fragment>
    <FormGroup
      label="Name"
      labelFor="name"
      intent={Intent.DANGER}
      helperText={errors.name || ''}
    >
      <InputGroup
        name="name"
        id="name"
        type="text"
        intent={errors.name ? Intent.DANGER : Intent.NONE}
        onChange={handleChange}
        value={values.name}
        disabled={editableField()}
      />
    </FormGroup>
    <FormGroup label="Platform" labelFor="platform">
      <InputGroup
        name="platform"
        id="platform"
        type="text"
        onChange={handleChange}
        value={values.platform.name}
        disabled={true}
      />
    </FormGroup>
    <FormGroup
      label="Service Charge (pence)"
      labelFor="serviceCharge"
      style={{ width: '200px' }}
      intent={Intent.DANGER}
      helperText={errors.serviceCharge || ''}
    >
      <CurrencyInput
        name="serviceCharge"
        id="serviceCharge"
        value={values.serviceCharge}
        fullWidth={true}
        onChange={handleChange}
        intent={errors.serviceCharge ? Intent.DANGER : Intent.NONE}
        onBlur={event => {
          const price = Number(event.currentTarget.value).toFixed(2)
          setFieldValue('serviceCharge', price)
        }}
        disabled={editableField()}
      />
    </FormGroup>
    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
      {!editableField() && (
        <Button text="Save" intent={Intent.DEFAULT} type="submit" />
      )}
    </div>
  </Fragment>
)

Form.propTypes = {
  handleChange: func,
  errors: object,
  values: object,
  setFieldValue: func,
}

export default Form
