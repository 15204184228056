import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Card,
  HTMLTable,
  NonIdealState,
  Tag,
  Button,
  ButtonGroup,
} from '@blueprintjs/core'
import { map } from 'lodash'

import { JourneyStatus } from '@stores/recordsStore'
import { matchType } from '@utils/types'
import Query from '@components/Query/Query'
import FilterRow from '@components/FilterRow/FilterRow'
import PageNumbers from '@components/PageNumbers/PageNumbers'
import GET_CUSTOMER_JOURNEYS from './queries/getCustomerJourneys.query'

import { DEFAULT_RECORDS } from '@stores/recordsStore'
import JourneysTable from '../Journeys/JourneysTable'
const Journeys = ({ match }) => {
  const id = match.params.customer
  const [paginationArgs, setPaginationArgs] = useState({
    first: DEFAULT_RECORDS,
    last: null,
    after: null,
    before: null,
  })

  const [currentPage, setCurrentPage] = useState(1)

  return (
    <Query query={GET_CUSTOMER_JOURNEYS} variables={{ id, ...paginationArgs }}>
      {({ journeysConnection }) => {
        const {
          pageInfo: { hasNextPage, hasPreviousPage, startCursor, endCursor },
          totalCount,
        } = journeysConnection

        const totalPages = Math.ceil(totalCount / DEFAULT_RECORDS)

        if (journeysConnection.edges.length > 0) {
          return (
            <div className="bp3-layout-row">
              <div className="bp3-table-frame">
                <FilterRow>
                  <Tag
                    disabled={true}
                    minimal={true}
                    large={true}
                    style={{
                      marginLeft: 'auto',
                    }}
                  >
                    {totalCount} Journeys
                  </Tag>

                  <ButtonGroup id="navigationGroup">
                    <Button
                      disabled={!hasPreviousPage}
                      icon="chevron-left"
                      onClick={() => {
                        setPaginationArgs({
                          last: DEFAULT_RECORDS,
                          before: startCursor,
                          after: null,
                          first: null,
                        })
                        setCurrentPage(currentPage - 1)
                      }}
                    >
                      Back
                    </Button>
                    <PageNumbers
                      currentPage={currentPage}
                      totalPages={totalPages === 0 ? 1 : totalPages}
                    />
                    <Button
                      disabled={!hasNextPage}
                      rightIcon="chevron-right"
                      onClick={() => {
                        setPaginationArgs({
                          last: null,
                          before: null,
                          after: endCursor,
                          first: DEFAULT_RECORDS,
                        })
                        setCurrentPage(currentPage + 1)
                      }}
                    >
                      Next
                    </Button>
                  </ButtonGroup>
                </FilterRow>
                <JourneysTable edges={journeysConnection.edges} />
              </div>
            </div>
          )
        } else
          return (
            <NonIdealState
              icon="taxi"
              title="No Journeys with this ID found"
              description="We were unable to find journey details for this customer. Please try again later."
            />
          )
      }}
    </Query>
  )
}

Journeys.propTypes = {
  match: matchType,
}

export default Journeys
