import React from 'react'
import Form from '@components/PartnerForm/Form'

import { Formik } from 'formik'
import { func, object } from 'prop-types'
import { validation } from './validation'

const PartnerForm = ({ onSubmit, initialValues }) => (
  <Formik
    onSubmit={onSubmit}
    initialValues={initialValues}
    validationSchema={validation}
  >
    {props => {
      const { handleSubmit } = props

      return (
        <form onSubmit={handleSubmit}>
          <Form {...props} />
        </form>
      )
    }}
  </Formik>
)

PartnerForm.propTypes = {
  onSubmit: func.isRequired,
  initialValues: object,
  handleSubmit: func,
  validationSchema: func,
}

export default PartnerForm
