import gql from 'graphql-tag'

const UNSUSPEND_ACCOUNT_HOLDER = gql`
  mutation unsuspendAccountHolder($id: ID!) {
    fleetUnsuspendAccountHolder(where: { id: $id })
  }
`

const SUSPEND_ACCOUNT_HOLDER = gql`
  mutation suspendAccountHolder($id: ID!) {
    fleetSuspendAccountHolder(where: { id: $id })
  }
`

export { UNSUSPEND_ACCOUNT_HOLDER, SUSPEND_ACCOUNT_HOLDER }
