import {
  Button,
  Callout,
  Classes,
  ControlGroup,
  FormGroup,
  HTMLSelect,
  InputGroup,
  Intent,
  Checkbox,
} from '@blueprintjs/core'
import { func, object } from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'
import isEmpty from 'lodash/isEmpty'
const styles = () => ({
  addressContainers: {
    marginBottom: '5px',
  },
  callout: {
    marginBottom: '10px',
  },
})

const countries = {
  GB: 'United Kingdom',
  IE: 'Ireland',
  IM: 'Isle Of Man',
}

function getCountryCode(object, value) {
  return Object.keys(object).find(key => object[key] === value)
}

const CreateAccountMerchantForm = ({
  classes,
  handleChange,
  handleSubmit,
  errors,
  values,
  setFieldValue,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Callout className={classes.callout}>
        <h5 className="bp3-heading">Account Holder</h5>

        <FormGroup
          label="Email"
          labelFor="email"
          helperText={errors.email || ''}
          intent={Intent.DANGER}
        >
          <InputGroup
            name="email"
            id="email"
            type="text"
            onChange={handleChange}
            intent={errors.email ? Intent.Danger : Intent.NONE}
            value={values.email}
          />
        </FormGroup>
        <ControlGroup vertical={true} className={classes.addressContainers}>
          <InputGroup
            name="houseNumberOrName"
            type="text"
            placeholder="House number or name"
            onChange={handleChange}
            value={values.houseNumberOrName}
          />
          <InputGroup
            name="street"
            type="text"
            placeholder="Street name"
            onChange={handleChange}
            value={values.street}
          />
          <InputGroup
            name="city"
            id="city"
            type="text"
            placeholder="City"
            onChange={handleChange}
            intent={errors.city ? Intent.DANGER : Intent.NONE}
            value={values.city}
          />
          <InputGroup
            name="postalCode"
            type="text"
            placeholder="Postcode"
            onChange={handleChange}
            value={values.postalCode}
          />

          <HTMLSelect
            name="country"
            id="country"
            type="select"
            onChange={event => {
              const countryCode = getCountryCode(
                countries,
                event.currentTarget.value
              )
              setFieldValue('country', countryCode)
            }}
            intent={errors.country ? Intent.DANGER : Intent.NONE}
            value={countries[values.country]}
            options={Object.values(countries)}
          />
        </ControlGroup>
      </Callout>
      <br />
      <Callout className={classes.callout}>
        <h5 className="bp3-heading">Business Details</h5>
        <FormGroup
          label="First Name"
          labelFor="firstName"
          helperText={errors.firstName || ''}
          intent={Intent.DANGER}
        >
          <InputGroup
            name="firstName"
            id="firstName"
            type="text"
            onChange={handleChange}
            intent={errors.firstName ? Intent.Danger : Intent.NONE}
            value={values.firstName}
          />
        </FormGroup>
        <FormGroup
          label="Last Name"
          labelFor="lastName"
          helperText={errors.lastName || ''}
          intent={Intent.DANGER}
        >
          <InputGroup
            name="lastName"
            id="lastName"
            type="text"
            onChange={handleChange}
            intent={errors.lastName ? Intent.Danger : Intent.NONE}
            value={values.lastName}
          />
        </FormGroup>
        <FormGroup
          label="Email"
          labelFor="businessEmail"
          helperText={errors.businessEmail || ''}
          intent={Intent.DANGER}
        >
          <InputGroup
            name="businessEmail"
            id="businessEmail"
            type="text"
            onChange={handleChange}
            intent={errors.businessEmail ? Intent.Danger : Intent.NONE}
            value={values.businessEmail}
          />
        </FormGroup>
        <FormGroup
          label="Legal Business Name"
          labelFor="legalBusinessName"
          helperText={errors.legalBusinessName || ''}
          intent={Intent.DANGER}
        >
          <InputGroup
            name="legalBusinessName"
            id="legalBusinessName"
            type="text"
            onChange={handleChange}
            intent={errors.legalBusinessName ? Intent.Danger : Intent.NONE}
            value={values.legalBusinessName}
          />
        </FormGroup>
        <FormGroup
          label="Doing Business As"
          labelFor="doingBusinessAs"
          helperText={errors.doingBusinessAs || ''}
          intent={Intent.DANGER}
        >
          <InputGroup
            name="doingBusinessAs"
            id="doingBusinessAs"
            type="text"
            onChange={handleChange}
            intent={errors.doingBusinessAs ? Intent.Danger : Intent.NONE}
            value={values.doingBusinessAs}
          />
        </FormGroup>
        <FormGroup
          label="Company Number"
          labelFor="registrationNumber"
          helperText={errors.registrationNumber || ''}
          intent={Intent.DANGER}
        >
          <InputGroup
            name="registrationNumber"
            id="registrationNumber"
            type="text"
            onChange={handleChange}
            intent={errors.registrationNumber ? Intent.Danger : Intent.NONE}
            value={values.registrationNumber}
          />
        </FormGroup>
      </Callout>
      <br />
      <Callout className={classes.callout}>
        <h5 className="bp3-heading">Shareholder Details</h5>
        <FormGroup
          label="Email"
          labelFor="shareholdersEmail"
          helperText={errors.shareholdersEmail || ''}
          intent={Intent.DANGER}
        >
          <InputGroup
            name="shareholdersEmail"
            id="shareholdersEmail"
            type="text"
            onChange={handleChange}
            intent={errors.shareholdersEmail ? Intent.Danger : Intent.NONE}
            value={values.shareholdersEmail}
          />
        </FormGroup>
        <FormGroup
          label="Phone Number"
          labelFor="phoneNumber"
          helperText={errors.phoneNumber || ''}
          intent={Intent.DANGER}
        >
          <InputGroup
            name="phoneNumber"
            id="phoneNumber"
            type="text"
            onChange={handleChange}
            intent={errors.phoneNumber ? Intent.Danger : Intent.NONE}
            value={values.phoneNumber}
          />
        </FormGroup>
        <ControlGroup vertical={true} className={classes.addressContainers}>
          <InputGroup
            name="shareholdersHouseNumberOrName"
            type="text"
            placeholder="House number or name"
            onChange={handleChange}
            value={values.shareholdersHouseNumberOrName}
          />
          <InputGroup
            name="shareholdersStreet"
            type="text"
            placeholder="Street name"
            onChange={handleChange}
            value={values.shareholdersStreet}
          />
          <InputGroup
            name="shareholdersCity"
            id="shareholdersCity"
            type="text"
            placeholder="City"
            onChange={handleChange}
            intent={errors.shareholdersCity ? Intent.DANGER : Intent.NONE}
            value={values.shareholdersCity}
          />
          <InputGroup
            name="shareholdersPostalCode"
            type="text"
            placeholder="Postcode"
            onChange={handleChange}
            value={values.shareholdersPostalCode}
          />
          <HTMLSelect
            name="shareholdersCountry"
            id="shareholdersCountry"
            type="select"
            onChange={event => {
              const countryCode = getCountryCode(
                countries,
                event.currentTarget.value
              )
              setFieldValue('shareholdersCountry', countryCode)
              setFieldValue('phoneCountryCode', countryCode)
            }}
            intent={errors.shareholdersCountry ? Intent.DANGER : Intent.NONE}
            value={countries[values.shareholdersCountry]}
            options={Object.values(countries)}
          />
        </ControlGroup>
      </Callout>

      <div style={{ padding: '20px' }}>
        <h5 className="bp3-heading">Terms and Conditions</h5>
        <Checkbox
          name="airpayConditions"
          id="airpayConditions"
          checked={values.airpayConditions}
          onChange={handleChange}
        >
          I have read and accept Airpay's{' '}
          <a
            href="#_" //need to be changed
          >
            terms and conditions
          </a>
        </Checkbox>
        <Checkbox
          name="accepttcs"
          id="accepttcs"
          checked={values.accepttcs}
          onChange={handleChange}
        >
          I have read and accept Adyen's{' '}
          <a
            href="https://www.adyen.com/legal/terms-and-conditions"
            rel="noopener noreferrer"
            target="_blank"
          >
            terms and conditions
          </a>{' '}
          and{' '}
          <a
            href={'https://www.adyen.com/legal/list-restricted-prohibited'}
            rel="noopener noreferrer"
            target="_blank"
          >
            restricted product list
          </a>
        </Checkbox>
      </div>

      <div className={Classes.DIALOG_FOOTER_ACTIONS}>
        <Button
          text="Create"
          intent={Intent.DEFAULT}
          type="submit"
          disabled={
            !isEmpty(errors) || !values.accepttcs || !values.airpayConditions
          }
        />
      </div>
    </form>
  )
}

CreateAccountMerchantForm.propTypes = {
  classes: object,
  handleChange: func,
  handleSubmit: func,
  setFieldValue: func,
  errors: object,
  values: object,
  setStatus: func,
  status: object,
}

export default injectSheet(styles)(CreateAccountMerchantForm)
