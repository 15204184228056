import React, { Component, Fragment } from 'react'
import { ButtonGroup, Tag, Button, Spinner, Popover } from '@blueprintjs/core'
import { number, func, string } from 'prop-types'

export class Pager extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Fragment>
        <ButtonGroup
          id="navigationGroup"
          style={{
            float: 'right',
          }}
        >
          <Button
            disabled={this.props.skip === 0}
            icon="chevron-left"
            onClick={e => this.props.goToPrevious(e)}
          />
          <Button
            disabled={true}
            style={{
              width: '90px',
            }}
          >
            {this.props.skip / this.props.defaultNmbRecords + 1}
            &nbsp;of&nbsp;
            {Math.ceil(this.props.total / this.props.defaultNmbRecords)}
          </Button>

          <Button
            disabled={
              this.props.skip / this.props.defaultNmbRecords + 1 <
                this.props.total / this.props.defaultNmbRecords ===
              false
            }
            rightIcon="chevron-right"
            onClick={e => this.props.goToNext(e, this.props.outcomeLength)}
          />
        </ButtonGroup>
        <Tag
          disabled={true}
          minimal={true}
          large={true}
          style={{
            float: 'right',
          }}
        >
          {this.props.totalCount || 0} {this.props.dataName || 'Records'}
        </Tag>
      </Fragment>
    )
  }
}

Pager.propTypes = {
  dataName: string,
  goToPrevious: func,
  goToNext: func,
  defaultNmbRecords: number,
  skip: number,
  total: number,
  outcomeLength: number,
  totalCount: number,
}
