const marketplace = {
  defaults: {
    DriversId: '',
    zone: {
      id: '',
      deliveryCost: '',
      deliveryZone: {
        id: '',
        name: '',
        __typename: 'deliveryZone',
      },
      __typename: 'zone',
    },
  },
}

export default marketplace
