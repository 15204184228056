import { HTMLSelect } from '@blueprintjs/core'
import map from 'lodash/map'
import { string, func, bool, object } from 'prop-types'
import React from 'react'

import GET_ROLE_IDS from './queries/getRoleIds.query'
import Query from '@components/Query/Query'

const RoleSelect = ({ roleId, disabled, onChange }) => (
  <Query query={GET_ROLE_IDS}>
    {({ roles }) => (
      <div>
        <HTMLSelect
          name="roleId"
          fill="true"
          options={[
            { value: '', label: 'Select role...' },
            ...map(roles, ({ id, name }) => ({
              value: id,
              label: name,
            })),
          ]}
          value={roleId}
          disabled={disabled}
          onChange={onChange}
        />
      </div>
    )}
  </Query>
)

RoleSelect.propTypes = {
  roleId: string,
  disabled: bool,
  onChange: func,
}

export default RoleSelect
