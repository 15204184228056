import gql from 'graphql-tag'

const GET_TRANSACTIONS = gql`
  query getTransactions(
    $id: ID!
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    transactionsConnection(
      where: { journey: { driver: { id: $id } } }
      orderBy: createdAt_DESC
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      edges {
        cursor
        node {
          id
          journey {
            id
            driver {
              id
              name
              fleet {
                id
                name
              }
            }
            customer {
              id
              name
            }
          }
          payment {
            id
            isPaid
          }
          type
          gatewayStatus
          createdAt
        }
      }
    }
  }
`

export default GET_TRANSACTIONS
