const colors = {
  mainBg: 'rgba(232, 237, 242, 0.42)',
  secondaryBg: '#fff',
  borderColor: 'rgba(115, 134, 148, 0.28)',
  globalLinkColor: '#106ba3',
  headerBgColor: '#092332',
  headerTextColor: '#ffffff',
  primaryButtonColor: '#ffffff',
  primaryButtonText: '#5C7080',
  primaryButtonShadow: '0 0 0 1px #000',
  primaryNavColor: '#738694',
  primaryNavHoverColor: '#353535',
  primaryNavActiveColor: '#092332',
  disabledText: 'rgba(92, 112, 128, 0.5)',
  disabledBackground: 'rgba(206, 217, 224, 0.5)',

  // ********************************
  // tabBar
  // ********************************
  tabBarBG: '#010d14',
  tabBarTabText: '#738694',
  tabBarTabHoverText: '#181F26',
  tabBarTabActiveText: '#fff',

  // ********************************
  // Menu
  // ********************************

  menuSelect: '#137CBD',
  menuSelectText: 'white',

  dropdownBg: '#fff',
  highlight: '#00D5E9',

  // ********************************
  // Order
  // ********************************
  orderHighlight: '#5c7080',
  orderTimeColor: '#43bf4d',
}

export default colors
