import { HTMLTable, NonIdealState, Card } from '@blueprintjs/core'
import { map, lowerCase, startCase, upperCase } from 'lodash'
import { shape, arrayOf, string } from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

const DriversTable = ({ edges }) => {
  if (edges.length > 0) {
    return (
      <div className="bp3-table-container">
        <Card className="bp3-scrollable bp3-nopad">
          <HTMLTable bordered={false} interactive={true}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Driver ID</th>
                <th>Fleet</th>
                <th>Vehicle</th>
                <th>Registration</th>
                <th>Updated</th>
              </tr>
            </thead>
            <tbody>
              {map(edges, edge => {
                const driver = edge.node
                return (
                  <tr key={driver.id}>
                    <td>
                      <Link to={`/drivers/${driver.id}`}>
                        {startCase(lowerCase(driver.name))}
                      </Link>
                    </td>
                    <td>{driver.icabbiDriverId}</td>
                    <td>
                      <Link to={`/fleets/${driver.fleet.id}`}>
                        {driver.fleet.name}
                      </Link>
                    </td>
                    <td>{`${driver.make} ${driver.model}`}</td>
                    <td>{upperCase(driver.reg)}</td>
                    <td>
                      {moment(driver.updatedAt).format('DD/MM/YYYY HH:mm:ss')}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </HTMLTable>
        </Card>
      </div>
    )
  } else {
    return (
      <NonIdealState
        icon="person"
        title="No drivers to display"
        description="There are currently no drivers, all drivers will be displayed here"
      />
    )
  }
}

DriversTable.propTypes = {
  edges: arrayOf(
    shape({
      cursor: string,
      node: shape({
        id: string,
        name: string,
        make: string,
        model: string,
        reg: string,
        icabbiDriverId: string,
        fleet: shape({
          id: string,
          name: string,
        }),
      }),
    })
  ),
}

export default DriversTable
