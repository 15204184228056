import React from 'react'
import {
  FormGroup,
  InputGroup,
  Classes,
  Button,
  Intent,
} from '@blueprintjs/core'
import find from 'lodash/find'
import { selectionUpdate } from '@utils/helpers'
import RoleSelect from '@components/RoleSelect/RoleSelect'
import FleetSelect from '@components/FleetSelect/FleetSelect'
import PhoneInput from '@components/PhoneInput/PhoneInput'

import { func, object } from 'prop-types'

const Form = ({
  values,
  handleChange,
  handleSubmit,
  errors,
  setFieldValue,
  roles,
  onDisable,
}) => {
  const fleetAdminRole = find(roles, { key: 'FLEET_ADMIN_ROLE' })
  const fleetUserRole = find(roles, { key: 'FLEET_USER_ROLE' })
  const journeyResenderRole = find(roles, { key: 'JOURNEY_ADMIN' })
  const disableUser = e => {
    e.preventDefault()
    if (
      !confirm(
        `Are you sure you want to ${
          values.disabled ? 'enable' : 'disable'
        } this user?`
      )
    )
      return
    onDisable({
      id: values.id,
    })
  }
  return (
    <form onSubmit={handleSubmit}>
      <FormGroup
        label="Email"
        labelFor="email"
        helperText={errors.email || ''}
        intent={Intent.DANGER}
      >
        <InputGroup
          value={values.email}
          name="email"
          type="email"
          disabled={true}
          onChange={handleChange}
          intent={errors.email ? Intent.DANGER : Intent.NONE}
        />
      </FormGroup>
      <FormGroup
        label="First Name"
        labelFor="firstName"
        helperText={errors.firstName || ''}
        intent={Intent.DANGER}
      >
        <InputGroup
          value={values.firstName}
          name="firstName"
          type="text"
          onChange={handleChange}
          intent={errors.firstName ? Intent.DANGER : Intent.NONE}
        />
      </FormGroup>

      <FormGroup
        label="Last Name"
        labelFor="lastName"
        helperText={errors.lastName || ''}
        intent={Intent.DANGER}
      >
        <InputGroup
          value={values.lastName}
          name="lastName"
          type="text"
          onChange={handleChange}
          intent={errors.lastName ? Intent.DANGER : Intent.NONE}
        />
      </FormGroup>
      <FormGroup
        label="Phone Number"
        helperText={errors.phoneNumber ? errors.phoneNumber : ''}
        intent={Intent.DANGER}
      >
        <PhoneInput
          value={values.phoneNumber}
          inputProps={{ name: 'phoneNumber' }}
          onChange={phone => {
            setFieldValue('phoneNumber', phone)
          }}
        />
      </FormGroup>

      <FormGroup
        label="Role"
        labelFor="roleId"
        intent={Intent.Danger}
        helperText={errors.roleId || ''}
      >
        <RoleSelect
          values={values}
          roleId={values.roleId}
          onChange={event => {
            const selection = selectionUpdate(event)
            setFieldValue('roleId', selection.value, false)
          }}
        />
      </FormGroup>
      {[fleetAdminRole.id, fleetUserRole.id, journeyResenderRole.id].includes(
        values.roleId
      ) && (
        <FormGroup label="Fleets" labelFor="fleetIds" intent={Intent.Danger}>
          <FleetSelect
            fleetIds={values.ownsFleets}
            addFleetId={fleet => {
              setFieldValue(
                'ownsFleets',
                [...values.ownsFleets, fleet.id],
                false
              )
            }}
            removeFleetId={idToRemove => {
              setFieldValue(
                'ownsFleets',
                values.ownsFleets.filter(id => id !== idToRemove),
                false
              )
            }}
          />
        </FormGroup>
      )}

      <div className={Classes.DIALOG_FOOTER_ACTIONS}>
        {[fleetUserRole.id, journeyResenderRole.id].includes(values.roleId) &&
          (values.disabled ? (
            <Button
              text="Enable User"
              intent={Intent.PRIMARY}
              type="submit"
              onClick={disableUser}
            />
          ) : (
            <Button
              text="Disable User"
              intent={Intent.WARNING}
              type="submit"
              onClick={disableUser}
            />
          ))}
        <Button text="Save" intent={Intent.DEFAULT} type="submit" />
      </div>
    </form>
  )
}
Form.propTypes = {
  values: object,
  handleChange: func,
  setFieldValue: func,
  handleSubmit: func,
  roles: object,
  errors: object,
  onDisable: func,
}
export default Form
