import gql from 'graphql-tag'

const GET_PLATFORM = gql`
  {
    platforms {
      id
      name
      adyenPercentageCharge
      adyenGatewayCharge
      adyenFallbackEstimate
      partners {
        id
        fleets {
          name
          id
        }
      }
    }
  }
`

export default GET_PLATFORM
