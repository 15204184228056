import gql from 'graphql-tag'

const GET_FLEET_KEY = gql`
  query getFleet($id: ID!) {
    fleet(where: { id: $id }) {
      key
      id
    }
  }
`

export default GET_FLEET_KEY
