import { client, clearState } from '@services/client'

export const clearStore = () => clearState()

export const toggleModal = values => {
  writeToCache({
    ...values,
  })
}

export const setValue = values => {
  writeToCache({
    ...values,
  })
}

export const togglePane = values => {
  writeToCache({
    ...values,
  })
}

const writeToCache = data => {
  client.writeData({
    data,
  })
}
