import 'setimmediate' // polyfill for generators
import { FocusStyleManager } from '@blueprintjs/core'
import React from 'react'
import { Router, Redirect } from 'react-router'
import { RouterToUrlQuery } from 'react-url-query'

import { Switch } from 'react-router-dom'
import { ThemeProvider } from 'react-jss'
import injectSheet from 'react-jss'
import 'normalize.css/normalize.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import '@blueprintjs/table/lib/css/table.css'

import { ApolloProvider } from 'react-apollo'
import { client } from '@services/client'

import Login from '@components/Login/Login'
import BlankPage from '@components/BlankPage/BlankPage'
import Dashboard from '@components/Dashboard/Dashboard'
import Users from '@components/Users/Users'
import Platform from '@components/Platform/Platform'
import Partners from '@components/Partners/Partners'

import Partner from '@components/Partner/Partner'

import Fleets from '@components/Fleets/Fleets'
import Fleet from '@components/Fleet/Fleet'
import Customers from '@components/Customers/Customers'
import Customer from '@components/Customer/Customer'
import Transactions from '@components/Transactions/Transactions'
import Drivers from '@components/Drivers/Drivers'
import Driver from '@components/Driver/Driver'
import Journeys from '@components/Journeys/Journeys'
import Journey from '@components/Journey/Journey'
import TwoFactorAuthentication from '@components/TwoFactorAuthentication/TwoFactorAuthentication'
import ValidatePhone from '@components/ValidatePhone/ValidatePhone'
import Register from '@components/Register/Register'
import theme from '@styles/theme'
import global from '@styles/global'
import { history } from '@stores/routerStore'
import { jwtLogin, permissionsAccess } from '@stores/userStore'
import Route from '@ui/Route/Route'
import productDictionary from '@utils/productDictionary'

// gets JWT from localstoreage
jwtLogin()

/*
// Embed Beacon
!(function(e, t, n) {
  function a() {
    var e = t.getElementsByTagName('script')[0],
      n = t.createElement('script')
    ;(n.type = 'text/javascript'),
      (n.async = !0),
      (n.src = 'https://beacon-v2.helpscout.net'),
      e.parentNode.insertBefore(n, e)
  }
  if (
    ((e.Beacon = n = function(t, n, a) {
      e.Beacon.readyQueue.push({ method: t, options: n, data: a })
    }),
    (n.readyQueue = []),
    'complete' === t.readyState)
  )
    return a()
  e.attachEvent ? e.attachEvent('onload', a) : e.addEventListener('load', a, !1)
})(window, document, window.Beacon || function() {})
window.Beacon('init', '73acaef6-3987-49ee-b2e5-e16dbd02869d')

*/

// focus styles are hidden for users interacting with a mouse, but will appear if they use tab
FocusStyleManager.onlyShowFocusOnTabs()

const App = () => (
  <Router history={history}>
    <RouterToUrlQuery>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <Switch>
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/login" component={Login} />
            <Route path="/2fa" component={TwoFactorAuthentication} />
            <Route path="/validate-phone" component={ValidatePhone} />
            <Route path="/register" component={Register} />
            <Switch>
              <Route path="/users" component={Users} />
              <Route
                path="/platform"
                component={Platform}
                product={productDictionary.PLATFORM}
                isPermissionAllowed={() => permissionsAccess('platforms')}
              />
              <Route
                path="/partners"
                component={Partners}
                exact={true}
                isPermissionAllowed={() => permissionsAccess('partners')}
              />
              <Route
                path="/partners/:partner"
                component={Partner}
                product={productDictionary.PARTNER}
                isPermissionAllowed={() => permissionsAccess('partner')}
              />
              <Route
                path="/fleets"
                component={Fleets}
                exact={true}
                product={productDictionary.FLEETS}
                isPermissionAllowed={() => permissionsAccess('fleets')}
              />
              <Route
                path="/fleets/:fleet"
                component={Fleet}
                product={productDictionary.FLEET}
                isPermissionAllowed={() => permissionsAccess('fleet')}
              />
              <Route
                path="/drivers"
                component={Drivers}
                exact={true}
                product={productDictionary.DRIVERS}
                isPermissionAllowed={() => permissionsAccess('drivers')}
              />
              <Route
                path="/drivers/:driver"
                component={Driver}
                product={productDictionary.DRIVER}
              />
              <Route
                path="/customers"
                component={Customers}
                exact={true}
                isPermissionAllowed={() => permissionsAccess('partners')}
              />
              <Route
                path="/customers/:customer"
                component={Customer}
                product={productDictionary.CUSTOMER}
              />
              <Route
                path="/journeys"
                component={Journeys}
                exact={true}
                product={productDictionary.JOURNEYS}
              />
              <Route
                path="/journeys/:journey"
                component={Journey}
                exact={true}
                product={productDictionary.JOURNEY}
              />
              <Route
                path="/transactions"
                component={Transactions}
                product={productDictionary.TRANSACTION}
              />
              <Route
                path="/"
                exact
                component={() => <Redirect to="/dashboard" />}
              />
              <Route component={BlankPage} />
            </Switch>
          </Switch>
        </ThemeProvider>
      </ApolloProvider>
    </RouterToUrlQuery>
  </Router>
)

export default injectSheet(global)(App)
