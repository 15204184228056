'use strict'

import * as yup from 'yup'

export const addDriverStructure = {
  name: yup.string().required('Name is required'),
  icabbiDriverId: yup
    .number()
    .positive()
    .integer()
    .required('Driver ID is required'),
  fleetId: yup.string(),
}

export const addDriverDetailsStructure = {
  name: yup.string().required('Driver name is required'),
  icabbiDriverId: yup
    .number()
    .positive()
    .integer()
    .required('Driver Id is required'),
}
