import React from 'react'

import Crumb from '@components/Header/Breadcrumbs/Crumb'
import Query from '@components/Query/Query'
import GET_PARTNER_NAME from './queries/getPartnerName.query'

const isSecondPart = i => i === 1

const PartnerCrumbs = ({ crumbs }) => {
  return crumbs.map((crumb, i) => {
    if (isSecondPart(i)) {
      return (
        <Query key={crumb} query={GET_PARTNER_NAME} variables={{ id: crumb }}>
          {({ partner }) => {
            if (!partner) {
              return null
            }

            return (
              <Crumb
                key={crumb}
                text={partner.name}
                link={`/partners/${crumb}`}
              />
            )
          }}
        </Query>
      )
    } else {
      return <Crumb key={crumb} text={crumb} />
    }
  })
}

export default PartnerCrumbs
