import {
  HTMLTable,
  Card,
  Tag,
  NonIdealState,
  Button,
  Icon,
  Text,
} from '@blueprintjs/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { Query as CacheQuery } from 'react-apollo'
import { map, upperCase } from 'lodash'
import Currency from '@components/Currency/Currency'

import { permissionsAccess } from '@stores/userStore'
import PageLayout from '@components/PageLayout/PageLayout'
import Query from '@components/Query/Query'
import OPEN_MODAL from './queries/openModal.query'
import GET_FLEETS from './queries/getFleets.query'
import FleetModal from '@components/FleetModal/FleetModal'
import moment from 'moment'
import getKycStatusTag from '@utils/kycStatusTag'

const toggleModal = (client, value) =>
  client.writeData({
    data: {
      fleetModal: value,
    },
  })

const Fleets = () => (
  <CacheQuery query={OPEN_MODAL}>
    {({ data: { fleetModal }, client }) => (
      <PageLayout
        button={{
          text: 'Add Fleet',
          onClick: () => toggleModal(client, true),
        }}
        permissions={permissionsAccess('fleetCreate')}
      >
        <Query query={GET_FLEETS}>
          {({ fleets }) => {
            if (fleets.length > 0) {
              return (
                <div className="bp3-table-frame">
                  <div className="bp3-table-container bp3-scrollable">
                    <Card className={'bp3-nopad bp3-scrollable'}>
                      <HTMLTable bordered={false} interactive={true}>
                        <thead>
                          <tr>
                            <th colspan="4" />
                            <th colspan="3">Charges</th>
                            <th colspan="5">Verification</th>
                          </tr>
                          <tr>
                            <th>Fleet</th>
                            <th>Balance</th>
                            <th>Partner</th>
                            <th>Key</th>
                            <th>Default</th>
                            <th>Fleet</th>
                            <th>Uplift</th>
                            <th width="10">
                              <span title="Bank Account">B</span>
                            </th>
                            <th width="10">
                              <span title="Password">P</span>
                            </th>
                            <th width="10">
                              <span title="Identification">ID</span>
                            </th>
                            <th width="10">
                              <span title="Company">C</span>
                            </th>
                            <th>Last Update</th>
                          </tr>
                        </thead>
                        <tbody>
                          {map(fleets, fleet => (
                            <tr key={fleet.id}>
                              <td>
                                <Icon
                                  icon="symbol-circle"
                                  color={
                                    fleet.icabbiAPIUrl &&
                                    fleet.icabbiAccountId &&
                                    fleet.sendSMS
                                      ? '#5BB70D'
                                      : '#CDD6DD'
                                  }
                                />{' '}
                                <Link to={`/fleets/${fleet.id}`}>
                                  {fleet.name}
                                </Link>
                              </td>
                              <td>
                                {Currency({
                                  amount: fleet.balance || 0,
                                })}
                              </td>
                              <td>
                                <Link to={`/partners/${fleet.partner.id}`}>
                                  {fleet.partner.name}
                                </Link>
                              </td>
                              <td>
                                <Tag minimal="true">{fleet.key}</Tag>
                              </td>
                              <td>
                                {Currency({
                                  amount: fleet.defaultJourneyCharge,
                                })}
                              </td>
                              <td>
                                {fleet.fleetCharge > 0 &&
                                  Currency({
                                    amount: fleet.fleetCharge,
                                  })}
                              </td>
                              <td>{fleet.defaultJourneyChargeUplift}%</td>
                              <td>
                                {getKycStatusTag(
                                  fleet.bankAccountVerification,
                                  null,
                                  'Bank Account Verification'
                                )}
                              </td>
                              <td>
                                {getKycStatusTag(
                                  fleet.passportVerification,
                                  null,
                                  'Passport Verification'
                                )}
                              </td>
                              <td>
                                {getKycStatusTag(
                                  fleet.identityVerification,
                                  null,
                                  'Identity Verification'
                                )}
                              </td>
                              <td>
                                {getKycStatusTag(
                                  fleet.companyVerification,
                                  null,
                                  'Company Verification'
                                )}
                              </td>
                              <td>{moment(fleet.updatedAt).fromNow()}</td>
                            </tr>
                          ))}
                        </tbody>
                      </HTMLTable>
                    </Card>
                  </div>
                </div>
              )
            } else {
              return (
                <NonIdealState
                  title="No fleets to display"
                  icon="taxi"
                  className="bp3-card-non-ideal-state"
                  description="There are currently no fleets, all your fleets will be displayed here."
                  action={
                    permissionsAccess('fleetCreate') && (
                      <Button
                        text="Add Fleet"
                        className="bp3-save-button"
                        onClick={() => toggleModal(client, true)}
                      />
                    )
                  }
                />
              )
            }
          }}
        </Query>
        <FleetModal
          modalOpen={fleetModal}
          modalClose={() => toggleModal(client, false)}
        />
      </PageLayout>
    )}
  </CacheQuery>
)

export default Fleets
