import gql from 'graphql-tag'

const GET_CUSTOMER_DETAILS = gql`
  query customer($id: ID!) {
    customer(where: { id: $id }) {
      id
      name
      phone
      e164Phone
      partner {
        id
        key
        name
      }
    }
  }
`

export default GET_CUSTOMER_DETAILS
