const modals = {
  defaults: {
    accountMerchantModal: false,
    closeAccountMerchantModal: false,
    suspendAccountMerchantModal: false,
    messageDrawer: {
      isOpen: false,
      templateType: null,
      id: null,
      __typename: 'messageDrawer',
    },
    restaurantModal: false,
    fleetModal: false,
    refundModal: false,
    recaptureModal: false,
    completeModal: false,
    cancelModal: false,
    driverModal: false,
    partnerModal: false,
    itemModal: false,
    subMenuModal: false,
    optionModal: false,
    outletModal: false,
    forgotPasswordModal: false,
    terminalModal: false,
    profileModal: false,
    inviteUserModal: false,
    reinviteUserModal: false,
    updateUserModal: false,
    outletDeliveryZoneModal: false,
    parentMenuModal: false,
    editMenuModal: false,
    resetTerminalPinModal: false,
    DriversModal: false,
    DetailsModal: false,
    addDiscountModal: false,
    editDiscountModal: false,
    __typename: 'Modals',
  },
}

export default modals
