import React from 'react'
import injectSheet from 'react-jss'
import { node } from 'prop-types'
import PerfectScrollBar from '@components/PerfectScrollBar/PerfectScrollBar'

const styles = () => ({
  filterWrapper: {
    flexShrink: 0,
    flexGrow: 0,
  },

  scrollBar: {
    width: '100%',
    height: 'auto',
  },

  filters: {
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,

    background: '#f7f6f9',
    borderBottom: '1px solid #CDD3D6',

    '& > *': {
      display: 'inline-flex',
      marginRight: '12px',
      flexShrink: '0',

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
})

const FilterRow = ({ classes, children }) => (
  <div className={classes.filterWrapper}>
    <PerfectScrollBar
      options={{
        className: classes.scrollBar,
        options: { suppressScrollY: true },
      }}
    >
      <div className={classes.filters}>{children}</div>
    </PerfectScrollBar>
  </div>
)

FilterRow.propTypes = {
  children: node,
}

export default injectSheet(styles)(FilterRow)
