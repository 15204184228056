import React from 'react'
import { Formik } from 'formik'
import { func, object } from 'prop-types'
import PlatformEditForm from './PlatformEditForm'

import validation from './validation'

const PlatformEdit = ({ onSubmit, initialValues }) => (
  <Formik
    onSubmit={onSubmit}
    initialValues={initialValues}
    validationSchema={validation}
  >
    {props => <PlatformEditForm {...props} />}
  </Formik>
)

PlatformEdit.propTypes = {
  onSubmit: func.isRequired,
  initialValues: object.isRequired,
}

export default PlatformEdit
