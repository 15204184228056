import React, { Component, Fragment } from 'react'
import { DatePicker } from '@blueprintjs/datetime'
import {
  Button,
  ButtonGroup,
  ControlGroup,
  Divider,
  Spinner,
  Tag,
  Icon,
  NonIdealState,
  Popover,
  Position,
} from '@blueprintjs/core'
import moment from 'moment'
import clone from 'lodash.clone'
import debounce from 'lodash/debounce'
import reverse from 'lodash/reverse'
import { func, string, bool } from 'prop-types'
import { Query } from 'react-apollo'
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query'
import { authorizedRole } from '@stores/userStore'

import {
  SEARCH_GET_JOURNEYS,
  FILTER_GET_JOURNEYS,
  FILTER_GET_JOURNEYS_STATUS,
} from './queries/getJourneys.query'

import { Search, FleetFilter } from '@components/Toolbar'
import PageLayout from '@components/PageLayout/PageLayout'
import PageNumbers from '@components/PageNumbers/PageNumbers'
import { DEFAULT_RECORDS } from '@stores/recordsStore'
import JourneysTable from './JourneysTable'
import { refreshToast } from '@utils/toast'
import FilterRow from '@components/FilterRow/FilterRow'

const urlPropsQueryConfig = {
  date: { type: UrlQueryParamTypes.string },
  fleetId: { type: UrlQueryParamTypes.string },
  journeySearch: { type: UrlQueryParamTypes.string },
  viewed: { type: UrlQueryParamTypes.bool },
  authorised: { type: UrlQueryParamTypes.bool },
  charged: { type: UrlQueryParamTypes.bool },
  status: { type: UrlQueryParamTypes.string },
}

const isSuperAdmin = () => {
  return authorizedRole('SUPERADMIN_ROLE')
}

class Journeys extends Component {
  static defaultProps = {
    date: moment(),
    fleetId: null,
    viewed: null,
    authorised: null,
    charged: null,
    status: null,
    journeySearch: null,
  }
  constructor(props) {
    super(props)
    this.state = {
      first: null,
      last: DEFAULT_RECORDS,
      after: null,
      before: null,
      date: moment(),
      searchValue: '',
      searchTripId: null,
      currentPage: 1,
      totalRecords: null,
      showRefreshToast: false,
      pageInfo: null,
      totalCount: 0,
    }
  }

  goToNext = (e, nextPage, startCursor) => {
    e.preventDefault()
    nextPage &&
      this.setState(prevState => ({
        first: null,
        last: DEFAULT_RECORDS,
        before: startCursor,
        after: null,
        currentPage: prevState.currentPage + 1,
        showRefreshToast: false,
      }))
  }

  goToPrevious = (e, previousPage, endCursor) => {
    e.preventDefault()
    previousPage &&
      this.setState(prevState => ({
        first: DEFAULT_RECORDS,
        last: null,
        before: null,
        after: endCursor,
        currentPage: prevState.currentPage - 1,
        showRefreshToast: prevState.currentPage - 1 === 1,
      }))
  }

  searchTripId = e => {
    const searchId = Number(e.value)
    this.props.onChangeJourneySearch(searchId)
    if (isNaN(searchId)) {
      this.setState({ searchValue: '' })
    } else {
      this.setState({ searchValue: searchId })
      searchId.toString().length >= 3 && this.filterQuery(searchId)
    }
  }

  filterQuery = debounce(searchId => {
    this.setState({
      searchTripId: searchId,
      before: null,
      after: null,
      first: null,
      last: DEFAULT_RECORDS,
      currentPage: 1,
      totalRecords: null,
      showRefreshToast: false,
    })
  }, 300)

  clearSearch = e => {
    this.props.onChangeJourneySearch(null)
    this.setState({
      searchTripId: null,
      searchValue: '',
      before: null,
      after: null,
      totalRecords: null,
      showRefreshToast: false,
    })
  }

  newRecordsRefresh = refetch => {
    this.setState({ showRefreshToast: false })
    refetch()
  }

  filterBar = () => {
    const { currentPage, totalCount, pageInfo } = this.state
    const totalPages = Math.ceil(totalCount / DEFAULT_RECORDS)
    const hasNextPage = currentPage < totalPages
    const hasPreviousPage = currentPage !== 1
    const date = moment(this.props.date, 'YYYYMMDDTHHmm')
    const maxDate = moment()
      .add(6, 'days')
      .startOf('day')

    return (
      <FilterRow>
        <ButtonGroup>
          <Button
            rightIcon="caret-left"
            disabled={this.props.journeySearch}
            onClick={() => {
              const newDate = moment(this.state.date).subtract(1, 'day')
              this.setState({ date: newDate })
              this.props.onChangeDate(newDate.format('YYYYMMDDTHHmm'))
            }}
          />
          <Popover position={Position.BOTTOM_LEFT} boundary="window">
            <Button
              disabled={this.props.journeySearch}
              rightIcon="double-caret-vertical"
            >
              {date.format('DD MMMM YYYY')}
            </Button>
            <DatePicker
              value={moment(this.state.date).toDate()}
              maxDate={maxDate.toDate()}
              minimal={true}
              onChange={newDate => {
                const momentNewDate = moment(newDate)
                this.setState({ date: momentNewDate })
                this.props.onChangeDate(momentNewDate.format('YYYYMMDDTHHmm'))
              }}
            />
          </Popover>
          <Button
            disabled={
              date.add(1, 'day').isAfter(maxDate) || this.props.journeySearch
            }
            rightIcon="caret-right"
            onClick={() => {
              const newDate = moment(this.state.date).add(1, 'day')
              this.setState({ date: newDate })
              this.props.onChangeDate(newDate.format('YYYYMMDDTHHmm'))
            }}
          />
          <Divider />
          <ControlGroup>
            <Search
              placeholder="Journey Search..."
              value={this.props.journeySearch}
              onChange={this.searchTripId}
              onReset={this.clearSearch}
            />
            {isSuperAdmin() && (
              <FleetFilter
                fleetId={this.props.fleetId}
                disabled={this.props.journeySearch !== null}
                onChange={id => {
                  this.props.onChangeFleetId(id)
                }}
              />
            )}
            <Button
              text="Viewed"
              icon={<Icon icon="eye-open" />}
              disabled={this.props.journeySearch !== null}
              active={this.props.viewed}
              onClick={() => {
                this.props.onChangeViewed(this.props.viewed ? null : true)
              }}
            />
            <Button
              text="Captured"
              icon={<Icon icon="small-tick" />}
              disabled={this.props.journeySearch !== null}
              active={this.props.charged}
              onClick={() => {
                this.props.onChangeCharged(this.props.charged ? null : true)
              }}
            />
            <Button
              text="Refund"
              icon={<Icon icon="undo" />}
              disabled={this.props.journeySearch !== null}
              active={this.props.status === 'REFUNDED'}
              onClick={() => {
                this.props.onChangeStatus(this.props.status ? null : 'REFUNDED')
              }}
            />
            <Button
              text="Failed"
              icon={<Icon icon="cross" />}
              disabled={this.props.journeySearch !== null}
              active={this.props.status === 'FAILED'}
              onClick={() => {
                this.props.onChangeStatus(this.props.status ? null : 'FAILED')
              }}
            />
          </ControlGroup>
        </ButtonGroup>

        <Tag
          disabled={true}
          minimal={true}
          large={true}
          style={{ marginLeft: 'auto' }}
        >
          {totalCount} Journeys
        </Tag>

        <ButtonGroup id="navigationGroup">
          <Button
            disabled={!hasPreviousPage || !pageInfo}
            icon="chevron-left"
            onClick={e =>
              this.goToPrevious(e, hasPreviousPage, pageInfo.endCursor)
            }
          >
            Back
          </Button>
          <PageNumbers
            currentPage={currentPage}
            totalPages={totalPages === 0 ? 1 : totalPages}
          />
          <Button
            disabled={!hasNextPage || !pageInfo}
            rightIcon="chevron-right"
            onClick={e => this.goToNext(e, hasNextPage, pageInfo.startCursor)}
          >
            Next
          </Button>
        </ButtonGroup>
      </FilterRow>
    )
  }

  render() {
    const beforeDate = moment(this.props.date, 'YYYYMMDDTHHmm').endOf('day')
    const afterDate = moment(this.props.date, 'YYYYMMDDTHHmm').startOf('day')
    const journeySearch = this.props.journeySearch

    let queryToUse = {
      query: FILTER_GET_JOURNEYS,
      variables: {
        first: this.state.first,
        last: this.state.last,
        before: this.state.before,
        after: this.state.after,
        createdAt_gte: afterDate,
        createdAt_lte: beforeDate,
        fleetId: this.props.fleetId || undefined,
        lastSeenAt_gte: this.props.viewed ? afterDate : undefined,
        dispatcherTripCost_gte: this.props.charged ? 0 : undefined,
      },
    }

    if (this.props.status !== null) {
      queryToUse = {
        query: FILTER_GET_JOURNEYS_STATUS,
        variables: {
          first: this.state.first,
          last: this.state.last,
          before: this.state.before,
          after: this.state.after,
          createdAt_gte: afterDate,
          createdAt_lte: beforeDate,
          fleetId: this.props.fleetId || undefined,
          lastSeenAt_gte: this.props.viewed ? afterDate : undefined,
          dispatcherTripCost_gte: this.props.charged ? 0 : undefined,
          gatewayStatus: this.props.status ? this.props.status : undefined,
        },
      }
    }

    if (this.state.searchTripId || journeySearch) {
      queryToUse = {
        query: SEARCH_GET_JOURNEYS,
        variables: {
          searchTripId: this.state.searchTripId || Number(journeySearch),
          first: this.state.first,
          last: this.state.last,
          before: this.state.before,
          after: this.state.after,
        },
      }
    }

    return (
      <PageLayout>
        <div className="bp3-table-frame">
          {this.filterBar()}
          <Query
            query={queryToUse.query}
            variables={queryToUse.variables}
            fetchPolicy={
              this.state.currentPage === 1 && this.state.showRefreshToast
                ? 'network-only'
                : 'cache-first'
            }
            onCompleted={({ journeysConnection }) => {
              if (
                this.state.totalRecords === null &&
                this.state.currentPage === 1
              ) {
                this.setState({
                  totalRecords: journeysConnection.totalCount,
                  showRefreshToast: false,
                })
              }
              this.setState({
                totalCount: journeysConnection.totalCount,
                pageInfo: journeysConnection.pageInfo,
              })
            }}
          >
            {({ loading, error, data, refetch }) => {
              if (error) {
                return (
                  <NonIdealState
                    icon="error"
                    title="Server Error"
                    description="Please try again."
                  />
                )
              }

              if (loading) {
                return (
                  <NonIdealState
                    icon={<Spinner size="60" value={null} />}
                    title="Loading Journeys"
                    description="Please wait..."
                  />
                )
              }
              if (data) {
                const journeysConnection = data.journeysConnection
                const edges = reverse(clone(journeysConnection.edges))
                if (
                  this.state.showRefreshToast &&
                  this.state.totalRecords !== journeysConnection.totalCount
                ) {
                  refreshToast(this.newRecordsRefresh, refetch)
                }
                return (
                  <Fragment>
                    <JourneysTable edges={edges} />
                  </Fragment>
                )
              }
            }}
          </Query>
        </div>
      </PageLayout>
    )
  }
}

Journeys.propTypes = {
  date: string,
  journeySearch: string,
  fleetId: string,
  viewed: bool,
  authorised: bool,
  charged: bool,
  status: string,
  onChangeDate: func,
  onChangeJourneySearch: func,
  onChangeFleetId: func,
  onChangeViewed: func,
  onChangeAuthorised: func,
  onChangeCharged: func,
  onChangeStatus: func,
}

export default addUrlProps({ urlPropsQueryConfig })(Journeys)
