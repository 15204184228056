import React from 'react'
import { Switch, Route, Redirect } from 'react-router'
import { object } from 'prop-types'

import { matchType } from '@utils/types'
import PageLayout from '@components/PageLayout/PageLayout'
import AllTransactions from '@components/Transactions/AllTransactions'
import FailedTransactions from '@components/FailedTransactions/FailedTransactions'

const Transactions = ({ match, location }) => (
  <PageLayout
    match={match}
    location={location}
    tabs={[
      { to: 'all', name: 'All' },
      { to: 'failed', name: 'Failed' },
    ]}
  >
    <section>
      <Switch location={location}>
        <Redirect
          from="/transactions"
          to={{ pathname: '/transactions/all', search: location.search }}
          exact={true}
        />
        <Route path={`${match.path}/all`} render={() => <AllTransactions />} />
        <Route
          path={`${match.path}/failed`}
          exact={true}
          render={() => <FailedTransactions />}
        />
      </Switch>
    </section>
  </PageLayout>
)

Transactions.propTypes = {
  match: matchType.isRequired,
  classes: object,
  location: object,
}

export default Transactions
