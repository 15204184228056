import gql from 'graphql-tag'

const GET_HOC_URL = gql`
  query getHocUrl($id: ID) {
    fleet(where: { id: $id }) {
      id
      hocUrl
    }
  }
`

export default GET_HOC_URL
