import { number } from 'prop-types'
import { penceToPounds } from '@utils/helpers'

const Currency = ({ amount }) => {
  return amount != undefined
    ? `\u00A3${penceToPounds(amount) || '0.00'}`
    : `\u00A3`
}

Currency.propTypes = {
  amount: number,
}

export default Currency
