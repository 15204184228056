import { Drawer, Classes } from '@blueprintjs/core'
import { object, string } from 'prop-types'
import React from 'react'
import { Mutation } from 'react-apollo'

import defaultErrorHandler from '@utils/defaultErrorHandler'
import EDIT_MESSAGE_TEMPLATE from './mutations/editMessageTemplate.mutation'
import { modalType } from '@utils/types'
import { successToast } from '@utils/toast'
import MessageTemplateForm from '@components/MessageTemplateForm/MessageTemplateForm'

const UpdateMessageTemplate = ({ isOpen, drawerClose, message }) => {
  return (
    <Drawer
      isOpen={isOpen}
      onClose={drawerClose}
      title={
        message.messageDrawer.templateType
          ? `${message.messageDrawer.templateType} Template`
          : 'Message Template'
      }
      autoFocus={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
    >
      <div className={Classes.DIALOG_BODY}>
        <Mutation
          mutation={EDIT_MESSAGE_TEMPLATE}
          onError={defaultErrorHandler}
          onCompleted={() => {
            successToast('Message template successfully edited')
            drawerClose()
          }}
        >
          {editMessageTemplate => (
            <MessageTemplateForm
              initialValues={{
                ...message,
              }}
              onSubmit={values => {
                editMessageTemplate({
                  variables: {
                    id: values.id,
                    text: values.text,
                  },
                })
              }}
            />
          )}
        </Mutation>
      </div>
    </Drawer>
  )
}

UpdateMessageTemplate.propTypes = {
  ...modalType,
  classes: object,
  message: object,
  fleetId: string,
}

export default UpdateMessageTemplate
