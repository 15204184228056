import gql from 'graphql-tag'

const COMPLETE_AND_CAPTURE = gql`
  mutation completeAndCapture($id: ID!, $value: Int!) {
    completeAndCapture(id: $id, value: $value) {
      id
    }
  }
`

export default COMPLETE_AND_CAPTURE
