import {
  HTMLTable,
  NonIdealState,
  Icon,
  Card,
  Tooltip,
  Position,
  ProgressBar,
  Button,
} from '@blueprintjs/core'
import { map, lowerCase, startCase } from 'lodash'
import moment from 'moment'
import { arrayOf, shape, string, number } from 'prop-types'
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import RESEND_SMS from '../Journey/queries/resendSMS.query'
import { Mutation } from 'react-apollo'
import Currency from '@components/Currency/Currency'
import defaultErrorHandler from '../../utils/defaultErrorHandler'
import { successToast } from '../../utils/toast'

const JourneysTable = ({ edges }) => {
  if (edges.length === 0) {
    return (
      <NonIdealState
        icon="taxi"
        title="No Journeys"
        description="There are no matching journeys."
        className="bp3-card-non-ideal-state"
      />
    )
  }

  const statusBar = (status, authorised, seen) => {
    const intent = authorised ? 'success' : seen ? 'primary' : 'default'

    switch (status) {
      case 'COMPLETED':
        return (
          <ProgressBar
            animate={false}
            intent={intent}
            stripes={false}
            value={authorised ? '1' : '0'}
          />
        )
      case 'TRAVELLING':
        return (
          <ProgressBar
            animate={true}
            intent={intent}
            stripes={true}
            value={0.7}
          />
        )
      case 'ENROUTE':
        return (
          <ProgressBar
            animate={true}
            intent={intent}
            stripes={true}
            value={0.4}
          />
        )
      default:
        return (
          <ProgressBar
            animate={false}
            intent={intent}
            stripes={true}
            value={0.2}
          />
        )
    }
  }

  return (
    <div className="bp3-table-container">
      <Card className="bp3-nopad bp3-scrollable">
        <HTMLTable bordered={false} interactive={true}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Created</th>
              <th>Driver</th>
              <th>Fleet</th>
              <th>Customer</th>
              <th>Estimate</th>
              <th>Update</th>
              <th>Journey</th>
              <th>Charged</th>
              <th>SMS</th>
            </tr>
          </thead>
          <tbody>
            {map(edges, edge => {
              const journey = edge.node

              const diff =
                journey.dispatcherEstimatedTripCost -
                (journey.transactions.length &&
                journey.transactions[0].grandTotal
                  ? journey.transactions[0].grandTotal
                  : journey.dispatcherTripCost)

              const dispatcherEstimatedTripCost = journey.dispatcherEstimatedTripCost
                ? Currency({
                    amount: journey.dispatcherEstimatedTripCost,
                  })
                : ''

              const diffInCosts =
                journey.dispatcherTripCost &&
                journey.dispatcherEstimatedTripCost
                  ? Currency({ amount: diff })
                  : ''

              const updatedAtDate = journey.updatedAt
                ? moment(journey.updatedAt).fromNow()
                : ''

              return (
                <tr key={journey.id}>
                  <td>
                    <Link to={`/journeys/${journey.id}`}>
                      #{journey.icabbiTripId}
                    </Link>
                  </td>
                  <td>{moment(journey.createdAt).format('HH:mm:ss')}</td>
                  <td>
                    <Link to={`drivers/${journey.driver.id}`}>
                      {startCase(lowerCase(journey.driver.name))}
                    </Link>
                  </td>
                  <td>
                    <Link to={`fleets/${journey.driver.fleet.id}`}>
                      {journey.driver.fleet.name}
                    </Link>
                  </td>
                  <td>
                    {journey.firstSeenAt ? (
                      <Tooltip
                        content={`Seen ${moment(journey.firstSeenAt).format(
                          'HH:mm:ss'
                        )}`}
                        position={Position.LEFT}
                      >
                        <Icon icon={'eye-open'} color="#30404D" />
                      </Tooltip>
                    ) : (
                      <Icon icon={'eye-off'} color="#CDD6DD" />
                    )}
                    &nbsp;
                    <Link to={`customers/${journey.customer.id}`}>
                      {journey.customer.name}
                    </Link>
                  </td>
                  <td>
                    {journey.transactions.length > 0 ? (
                      <Tooltip content={`Authorised`} position={Position.LEFT}>
                        <Icon icon={'credit-card'} color="#30404D" />
                      </Tooltip>
                    ) : (
                      <Icon icon={'credit-card'} color="#CDD6DD" />
                    )}
                    &nbsp;{dispatcherEstimatedTripCost}&nbsp;
                    {journey.fixedCost ? (
                      <Tooltip
                        content={`Fixed price`}
                        position={Position.RIGHT}
                      >
                        <Icon icon={'lock'} color="#30404D" iconSize={16} />
                      </Tooltip>
                    ) : null}
                  </td>
                  <td>
                    <Tooltip
                      content={startCase(journey.status)}
                      position={Position.LEFT}
                    >
                      <span className="bp3-text-muted">{updatedAtDate}</span>
                    </Tooltip>
                  </td>
                  <td>
                    {statusBar(
                      journey.status,
                      journey.transactions.length > 0,
                      journey.firstSeenAt
                    )}
                  </td>
                  <td>
                    {journey.transactions.length > 0 &&
                    journey.transactions[0].gatewayStatus === 'FAILED' ? (
                      <Icon icon="cross" color="red" />
                    ) : (
                      <Fragment>
                        {journey.transactions.length > 0 &&
                        journey.transactions[0].refundedTotal ? (
                          <Fragment>
                            <Tooltip
                              content={`Refund (Charged: ${Currency({
                                amount: journey.transactions[0].grandTotal,
                              })})`}
                              position={Position.LEFT}
                            >
                              <Icon icon="undo" color="orange" />
                            </Tooltip>
                            &nbsp;
                            {Currency({
                              amount: journey.transactions[0].refundedTotal,
                            })}
                          </Fragment>
                        ) : (
                          journey.transactions.length > 0 &&
                          journey.transactions[0].grandTotal !== null && (
                            <Fragment>
                              {journey.transactions[0].grandTotal >
                              journey.dispatcherEstimatedTripCost ? (
                                <Tooltip
                                  content={`Smart Auth ${diffInCosts}`}
                                  position={Position.LEFT}
                                >
                                  <Icon icon={'endorsed'} intent="success" />
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  content={`Difference ${diffInCosts}`}
                                  position={Position.LEFT}
                                >
                                  <Icon icon="small-tick" intent="success" />
                                </Tooltip>
                              )}
                              &nbsp;
                              {Currency({
                                amount: journey.transactions[0].grandTotal,
                              })}
                            </Fragment>
                          )
                        )}
                      </Fragment>
                    )}
                  </td>
                  <td>
                    <Mutation
                      mutation={RESEND_SMS}
                      variables={{ id: journey.id }}
                      onCompleted={() => {
                        successToast('SMS Resent')
                      }}
                      onError={defaultErrorHandler}
                    >
                      {resendSMS => (
                        <Button
                          small
                          minimal
                          icon="envelope"
                          text={'Resend SMS'}
                          onClick={resendSMS}
                        />
                      )}
                    </Mutation>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </HTMLTable>
      </Card>
    </div>
  )
}

JourneysTable.propTypes = {
  edges: arrayOf(
    shape({
      cursor: string,
      node: shape({
        id: string,
        transactions: shape({ id: string }),
        customer: shape({ id: string, name: string }),
        driver: shape({
          id: string,
          name: string,
          fleet: shape({ id: string, name: string }),
        }),
        icabbitTripId: number,
        estimatedCost: number,
        dispatcherEstimatedTripCost: number,
        dispatcherTripCost: number,
        status: string,
        firstSeenAt: string,
        createdAt: string,
        updatedAt: string,
      }),
    })
  ),
}

export default JourneysTable
