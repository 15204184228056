import { arrayOf, shape, string } from 'prop-types'
import React from 'react'
import { HTMLTable, NonIdealState, Card } from '@blueprintjs/core'
import { Link } from 'react-router-dom'

import map from 'lodash/map'

const CustomersTable = ({ edges }) => {
  if (edges.length === 0) {
    return (
      <NonIdealState
        icon="mugshot"
        title="Waiting For Customers"
        description="Your registered customers will be displayed here."
      />
    )
  }
  return (
    <div className="bp3-table-container">
      <Card className="bp3-scrollable bp3-noPad">
        <HTMLTable bordered={false} interactive={true}>
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>Phone Number</th>
              <th>International Phone Number</th>
              <th>Total Journeys</th>
              <th>Partner</th>
            </tr>
          </thead>
          <tbody>
            {map(edges, edge => {
              const customer = edge.node
              return (
                <tr key={customer.id}>
                  <td>
                    <Link to={`/customers/${customer.id}`}>
                      {customer.name || 'Unnamed'}
                    </Link>
                  </td>
                  <td>{customer.phone}</td>
                  <td>{customer.e164Phone}</td>
                  <td>{customer.journeysTotalCount}</td>
                  <td>{customer.partner.name}</td>
                </tr>
              )
            })}
          </tbody>
        </HTMLTable>
      </Card>
    </div>
  )
}

CustomersTable.propTypes = {
  edges: arrayOf(
    shape({
      cursor: string,
      node: shape({
        id: string,
        phone: string,
        name: string,
        e164Phone: string,
        partner: shape({
          id: string,
          name: string,
        }),
      }),
    })
  ),
}

export default CustomersTable
