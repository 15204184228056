import gql from 'graphql-tag'

const GET_ACCOUNT_MERCHANT = gql`
  query accountHolder($id: ID!) {
    fleet(where: { id: $id }) {
      id
      name
      accountMerchantStatus
      photoIdType
      bankAccountVerification
      passportVerification
      identityVerification
      companyVerification
      accountHolder {
        accountHolderCode
        accountHolderDetails {
          fullPhoneNumber
          address {
            city
            country
            houseNumberOrName
            postalCode
            street
          }
          bankAccountDetails {
            ownerCity
            ownerPostalCode
            ownerHouseNumberOrName
            ownerName
            ownerStreet
            ownerDateOfBirth
            branchCode
            accountNumber
            countryCode
            currencyCode
            bankAccountUUID
          }
          email
          businessDetails {
            doingBusinessAs
            legalBusinessName
            registrationNumber
            shareholders {
              shareholderCode
              name {
                firstName
                lastName
              }
              personalData {
                dateOfBirth
              }
              email
              address {
                city
                country
                houseNumberOrName
                postalCode
                street
              }
              phoneNumber {
                phoneCountryCode
                phoneNumber
              }
            }
          }
          individualDetails {
            name {
              firstName
              lastName
            }
            personalData {
              dateOfBirth
            }
          }
          fullPhoneNumber
        }
        accountHolderStatus {
          status
        }
        legalEntity
      }
    }
  }
`

export default GET_ACCOUNT_MERCHANT
