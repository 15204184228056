import gql from 'graphql-tag'

const UPDATE_MERCHANT_ACCOUNT_INDIVIDUAL = gql`
  mutation fleetUpdateAccountHolderIndividual(
    $id: ID!
    $email: String!
    $firstName: String!
    $lastName: String!
    $city: String!
    $country: String!
    $houseNumberOrName: String!
    $postalCode: String!
    $street: String!
    $legalEntity: String!
    $ownerCity: String
    $ownerPostalCode: String
    $ownerHouseNumberOrName: String
    $ownerName: String
    $ownerDateOfBirth: String
    $branchCode: String
    $accountNumber: String
    $countryCode: String
    $currencyCode: String
    $dateOfBirth: String
    $phoneCountryCode: String
    $phoneNumber: String
  ) {
    fleetUpdateAccountHolderIndividual(
      where: { id: $id }
      data: {
        accountHolderDetails: {
          email: $email
          individualDetails: {
            name: {
              firstName: $firstName
              lastName: $lastName
              gender: "UNKNOWN"
            }
            personalData: { dateOfBirth: $dateOfBirth }
          }
          address: {
            city: $city
            country: $country
            houseNumberOrName: $houseNumberOrName
            postalCode: $postalCode
            street: $street
          }
          phoneNumber: {
            phoneCountryCode: $phoneCountryCode
            phoneNumber: $phoneNumber
          }
          bankAccountDetails: {
            ownerCity: $ownerCity
            ownerPostalCode: $ownerPostalCode
            ownerHouseNumberOrName: $ownerHouseNumberOrName
            ownerName: $ownerName
            ownerDateOfBirth: $ownerDateOfBirth
            branchCode: $branchCode
            accountNumber: $accountNumber
            countryCode: $countryCode
            currencyCode: $currencyCode
          }
        }
        legalEntity: $legalEntity
      }
    )
  }
`

const UPDATE_MERCHANT_ACCOUNT_BUSINESS = gql`
  mutation fleetUpdateAccountHolderBusiness(
    $id: ID!
    $email: String!
    $firstName: String!
    $lastName: String!
    $city: String!
    $country: String!
    $houseNumberOrName: String!
    $postalCode: String!
    $street: String!
    $legalEntity: String!
    $doingBusinessAs: String!
    $legalBusinessName: String!
    $registrationNumber: String!
    $shareholdersEmail: String!
    $shareholdersCity: String!
    $shareholdersCountry: String!
    $shareholdersHouseNumberOrName: String!
    $shareholdersPostalCode: String!
    $shareholdersStreet: String!
    $phoneCountryCode: String
    $shareholdersPhoneNumber: String
    $fullPhoneNumber: String
    $ownerCity: String
    $ownerPostalCode: String
    $ownerHouseNumberOrName: String
    $ownerStreet: String
    $ownerName: String
    $ownerDateOfBirth: String
    $branchCode: String
    $accountNumber: String
    $countryCode: String
    $currencyCode: String
    $shareholderDOB: String!
    $bankAccountUUID: String
    $shareholderCode: String!
  ) {
    fleetUpdateAccountHolderBusiness(
      where: { id: $id }
      data: {
        accountHolderDetails: {
          email: $email
          fullPhoneNumber: $fullPhoneNumber
          address: {
            city: $city
            country: $country
            houseNumberOrName: $houseNumberOrName
            postalCode: $postalCode
            street: $street
          }
          businessDetails: {
            doingBusinessAs: $doingBusinessAs
            legalBusinessName: $legalBusinessName
            registrationNumber: $registrationNumber
            shareholders: [
              {
                shareholderCode: $shareholderCode
                name: {
                  firstName: $firstName
                  lastName: $lastName
                  gender: "UNKNOWN"
                }
                email: $shareholdersEmail
                address: {
                  city: $shareholdersCity
                  country: $shareholdersCountry
                  houseNumberOrName: $shareholdersHouseNumberOrName
                  postalCode: $shareholdersPostalCode
                  street: $shareholdersStreet
                }
                phoneNumber: {
                  phoneCountryCode: $phoneCountryCode
                  phoneNumber: $shareholdersPhoneNumber
                }
                personalData: { dateOfBirth: $shareholderDOB }
              }
            ]
          }
          bankAccountDetails: {
            ownerCity: $ownerCity
            ownerStreet: $ownerStreet
            ownerPostalCode: $ownerPostalCode
            ownerHouseNumberOrName: $ownerHouseNumberOrName
            ownerName: $ownerName
            ownerDateOfBirth: $ownerDateOfBirth
            branchCode: $branchCode
            accountNumber: $accountNumber
            countryCode: $countryCode
            currencyCode: $currencyCode
            bankAccountUUID: $bankAccountUUID
          }
        }
        legalEntity: $legalEntity
      }
    )
  }
`

export { UPDATE_MERCHANT_ACCOUNT_INDIVIDUAL, UPDATE_MERCHANT_ACCOUNT_BUSINESS }
