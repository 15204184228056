import { get } from 'lodash'

export const getVariables = (values, fleet) => {
  const { id: fleetId } = fleet

  const bankAccountUUID = get(
    fleet,
    'accountHolder.accountHolderDetails.bankAccountDetails[0].bankAccountUUID'
  )
  const shareholderCode = get(
    fleet,
    'accountHolder.accountHolderDetails.businessDetails.shareholders[0].shareholderCode'
  )
  const { accountHolderDetails, legalEntity } = values
  const variables = {
    id: fleetId,
    bankAccountUUID,
    shareholderCode,
    email: accountHolderDetails.email,
    legalEntity,
  }

  if (accountHolderDetails.address) {
    variables.city = accountHolderDetails.address.city
    variables.country = accountHolderDetails.address.country
    variables.houseNumberOrName = accountHolderDetails.address.houseNumberOrName
    variables.postalCode = accountHolderDetails.address.postalCode
    variables.street = accountHolderDetails.address.street
  }

  if (
    accountHolderDetails.bankAccountDetails &&
    accountHolderDetails.bankAccountDetails[0]
  ) {
    variables.ownerCity = accountHolderDetails.bankAccountDetails[0].ownerCity
    variables.ownerPostalCode =
      accountHolderDetails.bankAccountDetails[0].ownerPostalCode
    variables.ownerHouseNumberOrName =
      accountHolderDetails.bankAccountDetails[0].ownerHouseNumberOrName
    variables.ownerName = accountHolderDetails.bankAccountDetails[0].ownerName
    variables.ownerDateOfBirth =
      accountHolderDetails.bankAccountDetails[0].ownerDateOfBirth
    variables.branchCode = accountHolderDetails.bankAccountDetails[0].branchCode
    variables.accountNumber =
      accountHolderDetails.bankAccountDetails[0].accountNumber
    variables.countryCode =
      accountHolderDetails.bankAccountDetails[0].countryCode || 'GB'
    variables.ownerStreet =
      accountHolderDetails.bankAccountDetails[0].ownerStreet
    variables.currencyCode =
      accountHolderDetails.bankAccountDetails[0].currencyCode || 'GBP'
  }
  if (accountHolderDetails.individualDetails) {
    variables.firstName = accountHolderDetails.individualDetails.name.firstName
    variables.lastName = accountHolderDetails.individualDetails.name.lastName
    variables.dateOfBirth =
      accountHolderDetails.individualDetails.personalData.dateOfBirth
  }

  if (accountHolderDetails.businessDetails) {
    variables.doingBusinessAs =
      accountHolderDetails.businessDetails.doingBusinessAs
    variables.registrationNumber =
      accountHolderDetails.businessDetails.registrationNumber
    variables.legalBusinessName =
      accountHolderDetails.businessDetails.legalBusinessName

    if (accountHolderDetails.businessDetails.shareholders) {
      const { shareholders } = accountHolderDetails.businessDetails
      variables.shareholdersEmail = shareholders[0].email
      variables.shareholdersCity = shareholders[0].address.city
      variables.shareholdersCountry = shareholders[0].address.country
      variables.shareholderDOB = shareholders[0].personalData.dateOfBirth
      variables.shareholdersHouseNumberOrName =
        shareholders[0].address.houseNumberOrName
      variables.shareholdersPostalCode = shareholders[0].address.postalCode
      variables.shareholdersStreet = shareholders[0].address.street
      if (shareholders[0].name) {
        variables.firstName = shareholders[0].name.firstName
        variables.lastName = shareholders[0].name.lastName
      }
      if (shareholders[0].phoneNumber) {
        variables.phoneCountryCode =
          shareholders[0].phoneNumber.phoneCountryCode
        variables.shareholdersPhoneNumber =
          shareholders[0].phoneNumber.phoneNumber
      }
    }
  }

  if (accountHolderDetails.fullPhoneNumber) {
    variables.fullPhoneNumber = accountHolderDetails.fullPhoneNumber
  }
  return variables
}
