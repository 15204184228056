import {
  Button,
  ButtonGroup,
  Classes,
  ControlGroup,
  Divider,
  FormGroup,
  HTMLSelect,
  InputGroup,
  Intent,
} from '@blueprintjs/core'
import { DateInput } from '@blueprintjs/datetime'
import moment from 'moment'

import { getIn } from 'formik'
import { func, object } from 'prop-types'
import React, { Fragment } from 'react'
import { Query as CacheQuery } from 'react-apollo'
import injectSheet from 'react-jss'

import OPEN_MODAL from '../CloseMerchantAccount/queries/openAccountMerchantModalClose.query'
import CloseMerchantAccount from '../CloseMerchantAccount/CloseMerchangeAccount'
import SuspendMerchantAccount from '../SuspendMerchantAccount/SuspendMerchantAccount'
import { get } from 'lodash'
import { permissionsAccess } from '@stores/userStore'
import { toggleModal } from '@utils/cacheHelpers'
import getKycStatusTag from '@utils/kycStatusTag'

const styles = () => ({
  controlGroup: {
    marginBottom: '10px',
  },
  callout: {
    marginBottom: '10px',
  },
  buttons: {
    paddingTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  status: {
    float: 'right',
  },
  helperText: {
    color: '#db3837',
    fontSize: '11px',
    fontWeight: 500,
    marginBottom: '12px',
    display: 'block',
  },
  divider: {
    borderTop: '1px dotted #ccc',
    paddingTop: '20px',
  },
})

const accountMerchantStatuses = {
  NOT_STARTED: Intent.DANGER,
  REQUEST_SENT: Intent.WARNING,
  CREATED: Intent.SUCCESS,
  VERIFICATION_IN_PROGRESS: Intent.WARNING,
  VERIFICATION_COMPLETE: Intent.SUCCESS,
  SUSPENDED: Intent.DANGER,
}

const countries = {
  IE: { country: 'Ireland', currency: 'EUR' },
  IM: { country: 'Isle Of Man', currency: 'GBP' },
  GB: { country: 'United Kingdom', currency: 'GBP' },
}
function getCountryCode(object, value) {
  return Object.keys(object).find(key => object[key].country === value)
}

const UpdateMerchantAccountForm = props => {
  const { handleSubmit, errors, values, setFieldValue, classes } = props
  const editableField = () => {
    if (permissionsAccess('fleetUpdateBasicDetails') === false) {
      return true
    }
    return false
  }

  const shareholderDOB = get(
    values,
    'accountHolderDetails.businessDetails.shareholders[0].personalData.dateOfBirth',
    false
  )

  return (
    <form onSubmit={handleSubmit}>
      <h5 className="bp3-heading">
        Account Holder Details
        {getKycStatusTag(
          accountMerchantStatuses[values.accountMerchantStatus],
          'right'
        )}
        <span className={classes.helperText}>All fields are required</span>
      </h5>
      <FormGroup
        label="Email"
        labelFor="email"
        helperText={getIn(errors, 'accountHolderDetails.email') || ''}
        intent={Intent.DANGER}
      >
        <InputGroup
          name="accountHolderDetails.email"
          id="email"
          type="text"
          onChange={event =>
            setFieldValue(
              'accountHolderDetails.email',
              event.currentTarget.value
            )
          }
          intent={
            getIn(errors, 'accountHolderDetails.email')
              ? Intent.DANGER
              : Intent.NONE
          }
          value={values.accountHolderDetails.email}
        />
      </FormGroup>
      <FormGroup
        label="Phone Number"
        labelFor="fullPhoneNumber"
        helperText={getIn(errors, 'accountHolderDetails.fullPhoneNumber') || ''}
        intent={Intent.DANGER}
      >
        <InputGroup
          name="accountHolderDetails.fullPhoneNumber"
          id="fullPhoneNumber"
          type="text"
          onChange={event =>
            setFieldValue(
              'accountHolderDetails.fullPhoneNumber',
              event.currentTarget.value
            )
          }
          intent={
            getIn(errors, 'accountHolderDetails.fullPhoneNumber')
              ? Intent.DANGER
              : Intent.NONE
          }
          value={
            values.accountHolderDetails.fullPhoneNumber
              ? values.accountHolderDetails.fullPhoneNumber
              : ''
          }
          disabled={editableField()}
        />
      </FormGroup>

      <ControlGroup vertical={true} className={classes.controlGroup}>
        <FormGroup label="Account Holder Address">
          <InputGroup
            name="accountHolderDetails.address.houseNumberOrName"
            type="text"
            placeholder="House number or name"
            onChange={event =>
              setFieldValue(
                'accountHolderDetails.address.houseNumberOrName',
                event.currentTarget.value
              )
            }
            value={values.accountHolderDetails.address.houseNumberOrName}
            disabled={editableField()}
            intent={
              getIn(errors, 'accountHolderDetails.address.houseNumberOrName')
                ? Intent.DANGER
                : Intent.NONE
            }
          />
          <InputGroup
            name="accountHolderDetails.address.street"
            type="text"
            placeholder="Street name"
            onChange={event =>
              setFieldValue(
                'accountHolderDetails.address.street',
                event.currentTarget.value
              )
            }
            value={values.accountHolderDetails.address.street}
            disabled={editableField()}
            intent={
              getIn(errors, 'accountHolderDetails.address.street')
                ? Intent.DANGER
                : Intent.NONE
            }
          />
          <InputGroup
            name="accountHolderDetails.address.city"
            id="city"
            type="text"
            placeholder="City"
            onChange={event =>
              setFieldValue(
                'accountHolderDetails.address.city',
                event.currentTarget.value
              )
            }
            value={values.accountHolderDetails.address.city}
            disabled={editableField()}
            intent={
              getIn(errors, 'accountHolderDetails.address.city')
                ? Intent.DANGER
                : Intent.NONE
            }
          />
          <InputGroup
            name="accountHolderDetails.address.postcode"
            type="text"
            placeholder="Postcode"
            onChange={event =>
              setFieldValue(
                'accountHolderDetails.address.postalCode',
                event.currentTarget.value
              )
            }
            value={values.accountHolderDetails.address.postalCode}
            disabled={editableField()}
            intent={
              getIn(errors, 'accountHolderDetails.address.postalCode')
                ? Intent.DANGER
                : Intent.NONE
            }
          />

          <HTMLSelect
            name="accountHolderDetails.address.country"
            id="country"
            onChange={event => {
              const countryCode = getCountryCode(
                countries,
                event.currentTarget.value
              )
              setFieldValue('accountHolderDetails.address.country', countryCode)
            }}
            value={
              countries[values.accountHolderDetails.address.country]
                ? countries[values.accountHolderDetails.address.country].country
                : 'United Kingdom'
            }
            options={Object.values(countries).map(
              countryCodes => countryCodes.country
            )}
            disabled={editableField()}
          />
        </FormGroup>
      </ControlGroup>

      <div className={classes.divider} />

      {values.legalEntity === 'Business' && (
        <div>
          <h5 className="bp3-heading">
            Shareholders Details
            <span className={classes.helperText}>All fields are required</span>
          </h5>
          <FormGroup
            label="First Name"
            labelFor="firstName"
            helperText={
              getIn(
                errors,
                'accountHolderDetails.businessDetails.shareholders[0].name.firstName'
              ) || ''
            }
            intent={Intent.DANGER}
          >
            <InputGroup
              name="accountHolderDetails.businessDetails.shareholders[0].name.firstName"
              id="firstName"
              type="text"
              onChange={event =>
                setFieldValue(
                  'accountHolderDetails.businessDetails.shareholders[0].name.firstName',
                  event.currentTarget.value
                )
              }
              intent={
                getIn(
                  errors,
                  'accountHolderDetails.businessDetails.shareholders[0].name.firstName'
                )
                  ? Intent.DANGER
                  : Intent.NONE
              }
              value={
                values.accountHolderDetails.businessDetails.shareholders[0].name
                  .firstName
              }
              disabled={editableField()}
            />
          </FormGroup>
          <FormGroup
            label="Last Name"
            labelFor="lastName"
            helperText={
              getIn(
                errors,
                'accountHolderDetails.businessDetails.shareholders[0].name.lastName'
              ) || ''
            }
            intent={Intent.DANGER}
          >
            <InputGroup
              name="lastName"
              id="accountHolderDetails.businessDetails.shareholders[0].name.lastName"
              type="text"
              onChange={event =>
                setFieldValue(
                  'accountHolderDetails.businessDetails.shareholders[0].name.lastName',
                  event.currentTarget.value
                )
              }
              intent={
                getIn(
                  errors,
                  'accountHolderDetails.businessDetails.shareholders[0].name.lastName'
                )
                  ? Intent.DANGER
                  : Intent.NONE
              }
              value={
                values.accountHolderDetails.businessDetails.shareholders[0].name
                  .lastName
              }
              disabled={editableField()}
            />
          </FormGroup>
          <FormGroup
            label="Email"
            labelFor="businessEmail"
            helperText={
              getIn(
                errors,
                'accountHolderDetails.businessDetails.shareholders[0].email'
              ) || ''
            }
            intent={Intent.DANGER}
          >
            <InputGroup
              name="accountHolderDetails.businessDetails.shareholders[0].email"
              id="businessEmail"
              type="text"
              onChange={event =>
                setFieldValue(
                  'accountHolderDetails.businessDetails.shareholders[0].email',
                  event.currentTarget.value
                )
              }
              intent={
                getIn(
                  errors,
                  'accountHolderDetails.businessDetails.shareholders[0].email'
                )
                  ? Intent.DANGER
                  : Intent.NONE
              }
              value={
                values.accountHolderDetails.businessDetails.shareholders[0]
                  .email
              }
              disabled={editableField()}
            />
          </FormGroup>

          <FormGroup
            label="Shareholders Date of Birth"
            labelFor="shareholderDOB"
            helperText={
              getIn(
                errors,
                'accountHolderDetails.businessDetails.shareholders[0].personalData.dateOfBirth'
              ) || ''
            }
            intent={
              getIn(
                errors,
                'accountHolderDetails.businessDetails.shareholders[0].personalData.dateOfBirth'
              )
                ? Intent.DANGER
                : Intent.NONE
            }
          >
            <DateInput
              parseDate={str => new Date(str)}
              formatDate={date => moment(date).format('YYYY-MM-DD')}
              value={shareholderDOB === false ? null : new Date(shareholderDOB)}
              onChange={date =>
                setFieldValue(
                  'accountHolderDetails.businessDetails.shareholders[0].personalData.dateOfBirth',
                  moment(date).format('YYYY-MM-DD')
                )
              }
              maxDate={moment().toDate()}
              minDate={moment()
                .subtract(100, 'year')
                .toDate()}
            />
          </FormGroup>

          <FormGroup
            label="Doing Business As"
            labelFor="doingBusinessAs"
            helperText={
              getIn(
                errors,
                'accountHolderDetails.businessDetails.doingBusinessAs'
              ) || ''
            }
            intent={Intent.DANGER}
          >
            <InputGroup
              name="doingBusinessAs"
              id="doingBusinessAs"
              type="text"
              onChange={event =>
                setFieldValue(
                  'accountHolderDetails.businessDetails.doingBusinessAs',
                  event.currentTarget.value
                )
              }
              intent={
                getIn(
                  errors,
                  'accountHolderDetails.businessDetails.doingBusinessAs'
                )
                  ? Intent.DANGER
                  : Intent.NONE
              }
              value={
                values.accountHolderDetails.businessDetails.doingBusinessAs
              }
            />
          </FormGroup>
          <FormGroup
            label="Legal Business Name"
            labelFor="legalBusinessName"
            helperText={
              getIn(
                errors,
                'accountHolderDetails.businessDetails.legalBusinessName'
              ) || ''
            }
            intent={Intent.DANGER}
          >
            <InputGroup
              name="legalBusinessName"
              id="legalBusinessName"
              type="text"
              onChange={event =>
                setFieldValue(
                  'accountHolderDetails.businessDetails.legalBusinessName',
                  event.currentTarget.value
                )
              }
              intent={
                getIn(
                  errors,
                  'accountHolderDetails.businessDetails.legalBusinessName'
                )
                  ? Intent.DANGER
                  : Intent.NONE
              }
              value={
                values.accountHolderDetails.businessDetails.legalBusinessName
              }
            />
          </FormGroup>
          <FormGroup
            label="Company Number"
            labelFor="registrationNumber"
            helperText={
              getIn(
                errors,
                'accountHolderDetails.businessDetails.registrationNumber'
              ) || ''
            }
            intent={Intent.DANGER}
          >
            <InputGroup
              name="accountHolderDetails.businessDetails.registrationNumber"
              id="registrationNumber"
              type="text"
              onChange={event =>
                setFieldValue(
                  'accountHolderDetails.businessDetails.registrationNumber',
                  event.currentTarget.value
                )
              }
              intent={
                getIn(
                  errors,
                  'accountHolderDetails.businessDetails.registrationNumber'
                )
                  ? Intent.DANGER
                  : Intent.NONE
              }
              value={
                values.accountHolderDetails.businessDetails.registrationNumber
              }
            />
          </FormGroup>
        </div>
      )}

      <div className={(Classes.DIALOG_FOOTER_ACTIONS, classes.buttons)}>
        <CacheQuery query={OPEN_MODAL}>
          {({
            data: { closeAccountMerchantModal, suspendAccountMerchantModal },
          }) => (
            <Fragment>
              <ButtonGroup minimal={true}>
                {permissionsAccess('fleetCloseAccountHolder') && (
                  <Fragment>
                    <Button
                      icon="cross"
                      text="Close Account"
                      intent={Intent.DANGER}
                      type="button"
                      onClick={() =>
                        toggleModal({ closeAccountMerchantModal: true })
                      }
                    />
                    <Divider />
                  </Fragment>
                )}
                <Button
                  icon="disable"
                  text={
                    values.accountHolderStatus.status === 'Suspended'
                      ? 'Unsuspend Account'
                      : 'Suspend Account'
                  }
                  intent={Intent.WARNING}
                  type="button"
                  onClick={() =>
                    toggleModal({ suspendAccountMerchantModal: true })
                  }
                />
              </ButtonGroup>
              <CloseMerchantAccount
                modalOpen={closeAccountMerchantModal}
                modalClose={() =>
                  toggleModal({ closeAccountMerchantModal: false })
                }
                fleetId={values.fleetId}
              />
              <SuspendMerchantAccount
                modalOpen={suspendAccountMerchantModal}
                modalClose={() =>
                  toggleModal({ suspendAccountMerchantModal: false })
                }
                fleetId={values.fleetId}
                accountStatus={values.accountHolderStatus.status}
              />
            </Fragment>
          )}
        </CacheQuery>
        {!editableField() && (
          <Button
            text="Save"
            intent={Intent.PRIMARY}
            type="submit"
            className={classes.save}
          />
        )}
      </div>
    </form>
  )
}

UpdateMerchantAccountForm.propTypes = {
  handleChange: func,
  handleSubmit: func,
  initialValues: object,
  errors: object,
  values: object,
  setFieldValue: func,
  classes: object,
  setStatus: func,
}

export default injectSheet(styles)(UpdateMerchantAccountForm)
