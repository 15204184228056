import React, { Component } from 'react'
import { InputGroup, Button, HTMLSelect, ControlGroup } from '@blueprintjs/core'
import { func, string, bool, array } from 'prop-types'

export class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      field: null,
      value: null,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.value !== prevState.value ||
      this.state.field !== prevState.field
    ) {
      this.props.onChange(this.state)
    }
  }

  reset = e => {
    this.setState({ value: null })
    this.props.onReset(e)
  }

  render() {
    return (
      <ControlGroup>
        {this.props.options && this.props.options.length > 0 && (
          <HTMLSelect
            options={this.props.options}
            value={this.props.fieldValue}
            onChange={e => {
              this.setState({ field: e.currentTarget.value })
            }}
          />
        )}
        <InputGroup
          leftIcon="search"
          placeholder={this.props.placeholder}
          disabled={this.props.disabled && this.props.value === null}
          value={this.state.value || this.props.value || ''}
          onChange={e => {
            this.setState({ value: e.target.value })
          }}
          rightElement={
            this.props.value !== null && (
              <Button
                disabled={this.props.disabled}
                icon="small-cross"
                minimal
                onClick={this.reset}
              />
            )
          }
        />
      </ControlGroup>
    )
  }
}
