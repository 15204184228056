import gql from 'graphql-tag'

const GET_PARTNER_NAME = gql`
  query partner($id: ID!) {
    partner(where: { id: $id }) {
      id
      name
    }
  }
`

export default GET_PARTNER_NAME
