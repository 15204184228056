import { gql } from '@services/client'

const EDIT_MY_DETAILS = gql`
  mutation userUpdate(
    $firstName: String
    $lastName: String
    $id: ID!
    $email: String
  ) {
    userUpdate(
      data: { firstName: $firstName, lastName: $lastName, email: $email }
      where: { id: $id }
    ) {
      id
      email
      firstName
      lastName
    }
  }
`

export default EDIT_MY_DETAILS
