import React from 'react'
import Form from './Form'
import { Formik } from 'formik'
import { func, object } from 'prop-types'
import { validation } from './validation'

const DetailsForm = ({ onSubmit, initialValues }) => (
  <Formik
    onSubmit={onSubmit}
    initialValues={initialValues}
    validationSchema={validation}
  >
    {props => <Form {...props} />}
  </Formik>
)

DetailsForm.propTypes = {
  onSubmit: func.isRequired,
  initialValues: object,
}

export default DetailsForm
