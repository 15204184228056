import React from 'react'
import { object, func } from 'prop-types'
import injectSheet from 'react-jss'
import ReactSVG from 'react-svg'
import { withFormik } from 'formik'
import { Mutation } from 'react-apollo'
import { Card } from '@blueprintjs/core'
import ls from '@utils/localStorage'
import get from 'lodash/get'
import { errorToast } from '@utils/toast'
import { Link } from 'react-router-dom'

import RegisterForm from './RegisterForm'
import styles from './Register.styles'

import Query from '@components/Query/Query'
import REGISTER from './mutations/register.mutation'
import logo from '@assets/logo.svg'
import registerUserIcon from '@assets/icons/login.svg'
import queryString from 'query-string'
import CHECK_USER_CAN_REGISTER from './queries/checkUserCanRegister.query'
import { newUserRegisterStructure } from '../../validation/user'
import * as yup from 'yup'

const handleRegister = (data, history) => {
  ls.set('obfuscatedPhoneNumber', data.obfuscatedPhoneNumber)
  history.push('/validate-phone')
}

const RegisterWrapper = props => {
  const { classes, history } = props
  const {
    container,
    registerUserContainer,
    airPayContainer,
    registerUserForm,
    icon,
  } = classes
  const { uid, token } = queryString.parse(location.search)

  if (!uid || !token) {
    history.push('/')
  }
  ls.set('partialJwt', token)
  // remove current login token to prevent multi session errors
  ls.remove('jwt')

  return (
    <Query query={CHECK_USER_CAN_REGISTER}>
      {({ checkUserCanRegister }) => {
        return (
          <div className={container}>
            <div className={registerUserContainer}>
              <ReactSVG src={logo} alt={'logo'} className={airPayContainer} />
              <Card elevation="2" className={registerUserForm}>
                <ReactSVG src={registerUserIcon} className={icon} />
                {checkUserCanRegister.canRegister ? (
                  <Mutation
                    mutation={REGISTER}
                    onError={error => {
                      const serverErrors = get(error, 'graphQLErrors', [])

                      // fallback for errors that weren't returned by the server
                      if (!serverErrors.length) {
                        return errorToast(error.message)
                      }

                      // loop through custom server errors displaying error toast
                      for (const serverError of serverErrors) {
                        errorToast(serverError.message)
                      }

                      props.setSubmitting(false)
                    }}
                    onCompleted={({ register }) => {
                      handleRegister(register, history)
                    }}
                  >
                    {register => (
                      <RegisterForm
                        classes={classes}
                        mutation={register}
                        data={checkUserCanRegister}
                        {...props}
                      />
                    )}
                  </Mutation>
                ) : (
                  <p>
                    This account has already been registered please{' '}
                    <Link to="/login">login</Link>
                  </p>
                )}
              </Card>
            </div>
          </div>
        )
      }}
    </Query>
  )
}

const Register = withFormik({
  mapPropsToValues: () => ({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    password: '',
    passwordConfirm: '',
  }),
  validationSchema: yup.object(newUserRegisterStructure),
})(RegisterWrapper)

RegisterWrapper.propTypes = {
  classes: object,
  history: object,
  setSubmitting: func,
}

export default injectSheet(styles)(Register)
export { Register }
