import { NonIdealState } from '@blueprintjs/core'
import { string } from 'prop-types'
import React from 'react'
import { Mutation } from 'react-apollo'

import defaultErrorHandler from '@utils/defaultErrorHandler'
import EDIT_FLEET_CONTACT_DETAILS from './mutations/editFleetContactDetails.mutation'
import GET_FLEET_CONTACT_DETAILS from './queries/getFleetContactDetails.query'
import { history } from '@stores/routerStore'
import { successToast } from '@utils/toast'
import Query from '@components/Query/Query'
import ContactFormContainer from './Form/ContactFormContainer'

const handleMutation = id => {
  history.push(`/fleets/${id}/contact`)
}

const Details = ({ fleetId }) => {
  return (
    <Query query={GET_FLEET_CONTACT_DETAILS} variables={{ id: fleetId }}>
      {({ fleet }) => {
        if (!fleet) {
          return (
            <NonIdealState
              icon="unknown-vehicle"
              title="No fleet with this ID"
              description="There is currently no fleet with this ID"
            />
          )
        }

        return (
          <Mutation
            mutation={EDIT_FLEET_CONTACT_DETAILS}
            onError={defaultErrorHandler}
            onCompleted={({ fleetUpdateContactDetails }) => {
              handleMutation(fleetUpdateContactDetails.id)
              successToast('Successfully edited fleet contact details')
            }}
            refetchQueries={[
              {
                query: GET_FLEET_CONTACT_DETAILS,
                variables: {
                  id: fleetId,
                },
              },
            ]}
          >
            {editContactDetails => (
              <ContactFormContainer
                onSubmit={variables => {
                  editContactDetails({
                    variables,
                  })
                }}
                initialValues={fleet}
              />
            )}
          </Mutation>
        )
      }}
    </Query>
  )
}

Details.propTypes = {
  fleetId: string.isRequired,
}

export default Details
