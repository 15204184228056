import some from 'lodash/some'
import includes from 'lodash/includes'
import { unsubscribe } from '@services/client'
import ls from '@utils/localStorage'
import { history } from '@stores/routerStore'

export const jwtLogin = () => {
  const localToken = ls.get('jwt')

  const stopRedirectAndClearStoreWhiteList = ['/register', '/login']

  const stopRedirectWhiteList = ['/2fa', '/validate-phone']

  if (stopRedirectAndClearStoreWhiteList.includes(window.location.pathname)) {
    // user is logging in or registering, wipe out the store and prevent redirect
    clearStore()
    return
  }

  // redirect to login if no token and not on 2fa or validate phone
  if (
    !localToken &&
    !stopRedirectWhiteList.includes(window.location.pathname)
  ) {
    history.push(`/login${history.location.search}`)
  }
}

const clearStore = () => {
  ls.clear()
  unsubscribe() // clear cache and reset link state defaults
}

export const logout = () => {
  clearStore()
  return history.push(`/login`)
}

export const permissionsAccess = key => {
  const permissionCategories = ls.get('permissionCategories')
  return some(permissionCategories, category =>
    includes(category.resolverNames, key)
  )
}

export const authorizedRole = key => {
  const role = ls.get('role')
  return role === key
}

export const UserId = () => {
  const id = ls.get('id')

  return id
}
