import gql from 'graphql-tag'

const DISABLE_USER = gql`
  mutation disableUser($id: String!) {
    disableUser(id: $id) {
      id
    }
  }
`

export default DISABLE_USER
