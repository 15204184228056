import React from 'react'
import {
  Dialog,
  Classes,
  RadioGroup,
  Radio,
  Button,
  FormGroup,
} from '@blueprintjs/core'
import { Mutation } from 'react-apollo'
import { useState } from 'react'
import COMPLETE_AND_CAPTURE from './mutations/completeAndCapture.mutation'
import { Fragment } from 'react'
import COMPLETE_AND_CANCEL from './mutations/completeAndCancel.mutation'
import CurrencyInput from '../CurrencyInput/CurrencyInput'
import { poundsToPence } from '../../utils/helpers'
import { successToast } from '../../utils/toast'
import defaultErrorHandler from '../../utils/defaultErrorHandler'
import { bool, func, string } from 'prop-types'

const CompleteModal = ({ modalOpen, modalClose, journeyId }) => {
  const [completeType, setCompleteType] = useState('capture')
  const [value, setValue] = useState('')

  const closeModal = () => {
    modalClose()
    setValue('')
  }

  return (
    <Dialog isOpen={modalOpen} onClose={closeModal} title="Complete Journey">
      <div className={Classes.DIALOG_BODY}>
        <RadioGroup
          onChange={e => setCompleteType(e.currentTarget.value)}
          selectedValue={completeType}
        >
          <Radio
            label="Capture transaction and complete journey"
            value="capture"
          />
          <Radio
            label="Cancel transaction and complete journey"
            value="cancel"
          />
        </RadioGroup>

        {completeType === 'capture' ? (
          <Mutation
            mutation={COMPLETE_AND_CAPTURE}
            variables={{ id: journeyId, value: poundsToPence(value) }}
            onCompleted={() => {
              successToast('Transaction captured and journey completed')
              closeModal()
            }}
            onError={defaultErrorHandler}
          >
            {completeAndCapture => (
              <Fragment>
                <FormGroup
                  label="Final fare"
                  helperText="Additional fees and charges are calculated at capture."
                >
                  <CurrencyInput
                    value={value}
                    onChange={e => setValue(e.target.value)}
                  />
                </FormGroup>
                <Button onClick={() => completeAndCapture()}>Save</Button>
              </Fragment>
            )}
          </Mutation>
        ) : (
          <Mutation
            mutation={COMPLETE_AND_CANCEL}
            variables={{ id: journeyId }}
            onCompleted={() => {
              successToast('Transaction cancelled and journey completed')
              closeModal()
            }}
            onError={defaultErrorHandler}
          >
            {completeAndCancel => (
              <Button onClick={() => completeAndCancel()}>Save</Button>
            )}
          </Mutation>
        )}
      </div>
    </Dialog>
  )
}

CompleteModal.propTypes = {
  modalOpen: bool,
  modalClose: func,
  journeyId: string,
}

export default CompleteModal
