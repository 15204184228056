import gql from 'graphql-tag'

const GET_CUSTOMERS = gql`
  query($first: Int, $last: Int, $after: String, $before: String) {
    customersConnection(
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          id
          phone
          name
          e164Phone
          journeysTotalCount
          partner {
            id
            name
          }
        }
        cursor
      }
    }
  }
`

const SEARCH_GET_CUSTOMERS = gql`
  query($search: String!) {
    searchCustomers(search: $search) {
      id
      phone
      name
      e164Phone
      journeysTotalCount
      partner {
        id
        name
      }
    }
  }
`

export { GET_CUSTOMERS, SEARCH_GET_CUSTOMERS }
