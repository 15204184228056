import { object } from 'prop-types'
import React from 'react'
import { Switch, Route, Redirect } from 'react-router'

import Details from '@components/Fleet/Details/Details'
import MerchantAccount from './MerchantAccount/MerchantAccount'
import MessageTemplates from './MessageTemplates/MessageTemplates'
import Webhooks from './Webhooks/Webhooks'
import Payouts from './Payouts/Payouts'
import Contact from './Contact/Contact'
import { matchType } from '@utils/types'
import PageLayout from '@components/PageLayout/PageLayout'
import { permissionsAccess } from '@stores/userStore'

const Fleet = ({ match, location }) => {
  const tabs = [
    { to: 'details', name: 'Details', permission: 'fleet' },
    { to: 'merchantAccount', name: 'Merchant Account', permission: 'fleet' },
    {
      to: 'messageTemplates',
      name: 'Message Templates',
      permission: 'messageTemplates',
    },
    { to: 'webhooks', name: 'Webhooks', permission: 'webhooks' },
    { to: 'payouts', name: 'Payouts', permission: 'payouts' },
    { to: 'contact', name: 'Contact Details', permission: 'fleet' },
  ]
  return (
    <PageLayout
      match={match}
      location={location}
      tabs={tabs.filter(({ permission }) => permissionsAccess(permission))}
      permissions={permissionsAccess('fleet')}
    >
      <section>
        <Switch location={location}>
          <Redirect
            from="/fleets/:fleet"
            to="/fleets/:fleet/details"
            exact={true}
          />
          <Route
            path={`${match.path}/details`}
            exact
            render={() => <Details fleetId={match.params.fleet} />}
          />
          <Route
            path={`${match.path}/merchantAccount`}
            exact
            render={() => <MerchantAccount fleetId={match.params.fleet} />}
          />
          <Route
            path={`${match.path}/messageTemplates`}
            exact
            render={() => <MessageTemplates fleetId={match.params.fleet} />}
          />
          <Route
            path={`${match.path}/webhooks`}
            exact
            render={() => <Webhooks fleetId={match.params.fleet} />}
          />
          <Route
            path={`${match.path}/payouts`}
            exact
            render={() => <Payouts fleetId={match.params.fleet} />}
          />
          <Route
            path={`${match.path}/contact`}
            exact
            render={() => <Contact fleetId={match.params.fleet} />}
          />
        </Switch>
      </section>
    </PageLayout>
  )
}

Fleet.propTypes = {
  match: matchType.isRequired,
  location: object,
}

export default Fleet
