import React from 'react'
import { Dialog, Classes, Button } from '@blueprintjs/core'
import { Mutation } from 'react-apollo'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import REINVITE_USER from './mutations/reinviteUser.mutation'

import { modalType } from '@utils/types'
import { successToast } from '@utils/toast'

const ReinviteUserModal = ({ modalClose, modalOpen, id }) => {
  return (
    <Dialog isOpen={modalOpen} onClose={modalClose} title="Reinvite User">
      <div className={Classes.DIALOG_BODY}>
        <Mutation
          mutation={REINVITE_USER}
          onError={defaultErrorHandler}
          onCompleted={() => successToast('Invite sent.')}
        >
          {reinviteUser => (
            <div>
              <p>Are you sure you want to resend the invite?</p>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button onClick={modalClose}>No</Button>
                <Button
                  onClick={() =>
                    reinviteUser({ variables: { id } }) && modalClose()
                  }
                >
                  Yes
                </Button>
              </div>
            </div>
          )}
        </Mutation>
      </div>
    </Dialog>
  )
}

ReinviteUserModal.propTypes = {
  ...modalType,
}

export default ReinviteUserModal
