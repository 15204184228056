import gql from 'graphql-tag'

const OPEN_MODAL = gql`
  {
    inviteUserModal @client
    reinviteUserModal @client
    user @client {
      id
      firstName
      lastName
      email
      role {
        id
      }
      ownsFleets {
        id
      }
    }
  }
`
export default OPEN_MODAL
