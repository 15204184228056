import React, { useRef } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import injectSheet from 'react-jss'
import { Icon } from '@blueprintjs/core'
import get from 'lodash/get'

const styles = {
  container: {
    position: 'relative',
  },
  inputClass: {
    width: '100% !important',
  },
  searchClass: {
    width: '100% !important',
    display: 'flex',
    alignItems: 'center',
    padding: '10px !important',

    '& input': {
      flexGrow: 1,
    },
  },
  clearButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}

export default injectSheet(styles)(({ classes, onChange, ...rest }) => {
  const ref = useRef(null)

  const clear = () => {
    const countryCode = get(
      ref,
      'current.state.selectedCountry.countryCode',
      ''
    )

    onChange(countryCode)
  }

  return (
    <div className={classes.container}>
      <PhoneInput
        country={'gb'}
        countryCodeEditable={false}
        enableSearch
        copyNumbersOnly={false}
        inputClass={classes.inputClass}
        searchClass={classes.searchClass}
        onChange={onChange}
        ref={ref}
        {...rest}
      />

      <button
        onClick={clear}
        className={'bp3-button ' + classes.clearButton}
        type="button"
      >
        <Icon icon="cross" />
      </button>
    </div>
  )
})
