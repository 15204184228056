import React from 'react'
import { FormGroup, InputGroup, Button } from '@blueprintjs/core'
import { func, object, bool } from 'prop-types'

const TwoFactorForm = ({
  handleChange,
  values,
  handleSubmit,
  isValid,
  classes,
}) => (
  <form onSubmit={handleSubmit}>
    <FormGroup>
      <InputGroup
        placeholder={'Code'}
        value={values.code}
        onChange={handleChange}
        type="text"
        name="code"
        large="true"
      />
    </FormGroup>
    <div className={classes.buttonContainer}>
      <FormGroup>
        <Button type="submit" disabled={!isValid} text="Submit" />
      </FormGroup>
    </div>
  </form>
)

TwoFactorForm.propTypes = {
  handleChange: func,
  handleSubmit: func,
  values: object,
  isValid: bool,
  classes: object,
  mutation: func,
}

export default TwoFactorForm
