import {
  Classes,
  Dialog,
  Button,
  InputGroup,
  RadioGroup,
  Radio,
  Intent,
  FormGroup,
} from '@blueprintjs/core'
import { bool, func, string } from 'prop-types'
import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import REFUND_QUERY from './queries/refund.query'
import { numberToPence, penceToPounds } from '@utils/helpers'
import * as _ from 'lodash'
import { successToast, errorToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'

class RefundModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      option: 'full',
      value: '0.00',
      hasChanged: false,
    }
  }

  handleOption = e => {
    this.setState({
      option: e.target.value,
    })
  }

  handleValue = e => {
    const { value } = e.target
    const rawVal = penceToPounds(numberToPence(_.trimStart(value, '£')))
    const displayVal = isNaN(rawVal) ? 0.0 : rawVal
    this.setState({
      value: displayVal,
      hasChanged: true,
    })
  }

  checkAndSubmit = (e, sendRefund) => {
    e.preventDefault()
    let { option, value } = this.state
    if (option === 'partial' && (!value || value <= 0)) {
      return
    }
    if (option === 'full') {
      value = 0
    }
    sendRefund({
      variables: {
        ...this.state,
        value: numberToPence(value),
        journeyId: this.props.journeyId,
      },
    })
  }

  render() {
    const { modalOpen, modalClose } = this.props
    const { option, value, hasChanged } = this.state
    const valAsNum = numberToPence(value)
    const hasError = hasChanged && (valAsNum < 1 || isNaN(valAsNum))
    return (
      <Dialog isOpen={modalOpen} onClose={modalClose} title="Process Refund">
        <div className={Classes.DIALOG_BODY}>
          <Mutation
            mutation={REFUND_QUERY}
            onError={defaultErrorHandler}
            refetchQueries={[`getJourney`]}
            onCompleted={({ journeyRefund }) => {
              if (!journeyRefund)
                return errorToast('Problem Sending Refund Request')
              successToast('Refund Request Sent')
              this.props.modalClose()
            }}
          >
            {sendRefund => (
              <form>
                <RadioGroup
                  label="Select refund type"
                  onChange={this.handleOption}
                  selectedValue={this.state.option}
                >
                  <Radio label="Full Refund" value="full" />
                  <Radio label="Partial Refund" value="partial" />
                </RadioGroup>
                {this.state.option === 'partial' && (
                  <FormGroup
                    labelFor="text-input"
                    helperText={
                      hasError ? 'Value should be greater than zero' : ''
                    }
                    intent={Intent.DANGER}
                  >
                    <InputGroup
                      type="string"
                      id="text-input"
                      placeholder="£0.00"
                      value={`£${this.state.value}`}
                      onChange={this.handleValue}
                      intent={hasError ? Intent.DANGER : null}
                    />
                  </FormGroup>
                )}
                <br />
                <Button
                  text="Confirm Refund"
                  onClick={e => this.checkAndSubmit(e, sendRefund)}
                  disabled={hasError && option === 'partial'}
                  style={{
                    float: 'right',
                  }}
                />
              </form>
            )}
          </Mutation>
        </div>
      </Dialog>
    )
  }
}

RefundModal.propTypes = {
  modalOpen: bool,
  modalClose: func,
  journeyId: string,
}

export default RefundModal
