import { createBrowserHistory } from 'history'
import pull from 'lodash/pull'
import split from 'lodash/split'
import takeRight from 'lodash/takeRight'

const history = createBrowserHistory()
let currentPath

const storePath = path => {
  currentPath = path
}

const returnCrumbArray = (crumbNo = 3) => {
  // Split on /, remove empty results and return.
  return pull(takeRight(split(currentPath, '/'), crumbNo), '')
}

history.listen(location => {
  storePath(location.pathname)
})

storePath(window.location.pathname) // store path on load

export { history, returnCrumbArray }
