import { Classes, Dialog } from '@blueprintjs/core'
import { bool, func } from 'prop-types'
import React from 'react'
import { Mutation } from 'react-apollo'

import { ADD_FLEET, GET_ROLE_ID } from './mutations/addFleet.mutation'
import GET_ALL_USERS from '@components/Users/queries/getAllUsers.query'
import GET_FLEETS from '@components/Fleets/queries/getFleets.query'

import FleetForm from '@components/FleetForm/FleetForm'
import Query from '@components/Query/Query'

import { history } from '@stores/routerStore'
import { successToast } from '@utils/toast'
import { toggleModal } from '@utils/cacheHelpers'
import defaultErrorHandler from '@utils/defaultErrorHandler'

const handleMutation = fleetId => {
  toggleModal({
    fleetModal: false,
  })
  history.push(`/fleets/${fleetId}/details`)
}

const FleetModal = ({ modalOpen, modalClose }) => {
  return (
    <Dialog isOpen={modalOpen} onClose={modalClose} title="Add Fleet">
      <Query query={GET_ROLE_ID}>
        {({ role }) => (
          <Mutation
            mutation={ADD_FLEET}
            onError={defaultErrorHandler}
            onCompleted={({ fleetUserCreate }) => {
              successToast('Successfully added new fleet')
              handleMutation(fleetUserCreate.id)
            }}
            refetchQueries={[{ query: GET_FLEETS }, { query: GET_ALL_USERS }]}
          >
            {addFleet => (
              <div className={Classes.DIALOG_BODY}>
                <FleetForm
                  onSubmit={values => {
                    addFleet({
                      variables: {
                        name: values.name,
                        partnerId: values.partnerId,
                        email: values.email,
                        password: values.password,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        roleId: role.id,
                      },
                    })
                  }}
                  initialValues={{
                    name: '',
                    partnerId: '',
                    email: '',
                    password: '',
                    passwordConfirm: '',
                    firstName: '',
                    lastName: '',
                  }}
                />
              </div>
            )}
          </Mutation>
        )}
      </Query>
    </Dialog>
  )
}

FleetModal.propTypes = {
  modalOpen: bool,
  modalClose: func,
}

export default FleetModal
