import React from 'react'
import { Dialog, Tabs, Tab, Classes } from '@blueprintjs/core'

import { modalType } from '@utils/types'

import Details from './Details/Details'
import Password from './Password/Password'

const UserModal = ({ modalClose, modalOpen }) => {
  return (
    <Dialog isOpen={modalOpen} onClose={modalClose} title="My Profile">
      <div className={Classes.DIALOG_BODY}>
        <Tabs id="profileTabs">
          <Tab id="profile" panel={<Details />} title="Details" />
          <Tab id="password" panel={<Password />} title="Change Password" />
        </Tabs>
      </div>
    </Dialog>
  )
}

UserModal.propTypes = {
  ...modalType,
}

export default UserModal
