import gql from 'graphql-tag'

const GET_CUSTOMER_JOURNEYS = gql`
  query getCustomerJourneys(
    $id: ID!
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    journeysConnection(
      where: { customer: { id: $id } }
      orderBy: createdAt_DESC

      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      edges {
        cursor
        node {
          id
          customer {
            id
            name
          }
          driver {
            id
            name
            fleet {
              id
              name
            }
          }
          transactions {
            id
            refundedTotal
            grandTotal
            gatewayStatus
            payment {
              id
              isPaid
            }
          }
          status
          icabbiTripId
          estimatedCost
          dispatcherEstimatedTripCost
          dispatcherTripCost
          firstSeenAt
          createdAt
          fixedCost
          updatedAt
        }
      }
    }
  }
`

export default GET_CUSTOMER_JOURNEYS
