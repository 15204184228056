import gql from 'graphql-tag'

const FILTER_GET_JOURNEYS = gql`
  query(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $createdAt_lte: DateTime
    $createdAt_gte: DateTime
    $lastSeenAt_gte: DateTime
    $fleetId: ID
    $dispatcherTripCost_gte: Int
  ) {
    journeysConnection(
      first: $first
      last: $last
      after: $after
      before: $before
      where: {
        createdAt_gte: $createdAt_gte
        createdAt_lte: $createdAt_lte
        fleetId: $fleetId
        lastSeenAt_gte: $lastSeenAt_gte
        dispatcherTripCost_gte: $dispatcherTripCost_gte
      }
    ) {
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          id
          transactions {
            id
            refundedTotal
            grandTotal
            gatewayStatus
          }
          customer {
            id
            name
          }
          driver {
            id
            name
            fleet {
              id
              name
            }
          }
          icabbiTripId
          estimatedCost
          dispatcherEstimatedTripCost
          dispatcherTripCost
          status
          firstSeenAt
          createdAt
          fixedCost
          updatedAt
        }
        cursor
      }
    }
  }
`

const FILTER_GET_JOURNEYS_STATUS = gql`
  query(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $createdAt_lte: DateTime
    $createdAt_gte: DateTime
    $lastSeenAt_gte: DateTime
    $fleetId: ID
    $dispatcherTripCost_gte: Int
    $gatewayStatus: GatewayStatus
  ) {
    journeysConnection(
      first: $first
      last: $last
      after: $after
      before: $before
      where: {
        createdAt_gte: $createdAt_gte
        createdAt_lte: $createdAt_lte
        fleetId: $fleetId
        lastSeenAt_gte: $lastSeenAt_gte
        dispatcherTripCost_gte: $dispatcherTripCost_gte
        transactions_some: { gatewayStatus: $gatewayStatus }
      }
    ) {
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          id
          transactions {
            id
            refundedTotal
            grandTotal
            gatewayStatus
          }
          customer {
            id
            name
          }
          driver {
            id
            name
            fleet {
              id
              name
            }
          }
          icabbiTripId
          estimatedCost
          dispatcherEstimatedTripCost
          dispatcherTripCost
          status
          firstSeenAt
          createdAt
          fixedCost
          updatedAt
        }
        cursor
      }
    }
  }
`

const SEARCH_GET_JOURNEYS = gql`
  query(
    $searchTripId: Int
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    journeysConnection(
      first: $first
      last: $last
      after: $after
      before: $before
      where: { icabbiTripId: $searchTripId }
    ) {
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          id
          transactions {
            id
            refundedTotal
            grandTotal
            gatewayStatus
          }
          customer {
            id
            name
          }
          driver {
            id
            name
            fleet {
              id
              name
            }
          }
          icabbiTripId
          estimatedCost
          dispatcherEstimatedTripCost
          dispatcherTripCost
          status
          firstSeenAt
          createdAt
          updatedAt
          fixedCost
        }
        cursor
      }
    }
  }
`

export { SEARCH_GET_JOURNEYS, FILTER_GET_JOURNEYS, FILTER_GET_JOURNEYS_STATUS }
