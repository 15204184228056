import React from 'react'
import { Formik } from 'formik'
import { func, object, string } from 'prop-types'
import { validation } from './validation'

import UpdateMerchantAccountForm from './UpdateMerchantAccountForm'

const UpdateMerchantAccount = ({ onSubmit, initialValues }) => (
  <Formik
    onSubmit={onSubmit}
    initialValues={initialValues}
    validationSchema={validation}
  >
    {props => <UpdateMerchantAccountForm {...props} />}
  </Formik>
)

UpdateMerchantAccount.propTypes = {
  fleetId: string,
  onSubmit: func.isRequired,
  initialValues: object,
}

export default UpdateMerchantAccount
