import gql from 'graphql-tag'

const GET_FLEETS = gql`
  {
    fleets {
      id
      key
      name
      partner {
        id
        key
        name
      }
      icabbiAPIUrl
      icabbiAccountId
      sendSMS
      defaultJourneyCharge
      defaultJourneyChargeUplift
      fleetCharge
      bankAccountVerification
      passportVerification
      identityVerification
      companyVerification
      updatedAt
      balance
    }
  }
`

export default GET_FLEETS
