import gql from 'graphql-tag'

const OPEN_UPDATE_USER_MODAL = gql`
  {
    updateUserModal @client
    user @client {
      id
      firstName
      lastName
      email
      phoneNumber
      disabled
      role {
        id
      }
      ownsFleets {
        id
      }
    }
  }
`
export default OPEN_UPDATE_USER_MODAL
