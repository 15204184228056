import { gql } from '@services/client'

const GET_USER_INFO = gql`
  query($id: ID!) {
    user(where: { id: $id }) {
      key
      email
      firstName
      lastName
      role {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`

export default GET_USER_INFO
