import colors from './colors'
import theme from './theme'

const styles = {
  '@global': {
    body: {
      backgroundColor: '#FCFCFC',
    },
    table: {
      width: '100%',
    },
    form: {
      marginBottom: 0,
    },
    '.pageContainer': {
      width: '100%',
      height: '100%',
      position: 'fixed',
      transition: theme.transition,
      background: colors.mainBg,
    },
    '.mainBodyContainer': {
      overflowY: 'auto',
      position: 'relative',
      marginLeft: '48px',
      padding: '24px',
      top: 96,
    },
    '.bp3-non-ideal-state': {
      height: '50%',
    },
    '.bp3-dialog': {
      backgroundColor: '#FCFCFC',
    },
    '.bp3-nopad': {
      // Bottom padding prevents last item being cut off
      padding: '0 !important',
    },
    '.bp3-card': {
      padding: '20px 20px 20px 20px',
      marginBottom: '24px',
    },
    '.bp-popover-footer-actions': {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '12px',
      '& button': {
        marginLeft: '12px',
      },
    },
    '.bp3-dialog-header': {
      background: '#26333c',
      '& .bp3-heading': {
        color: 'white',
        margin: '10px 0px 10px',
      },
    },
    '.bp3-layout-vertical': {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      '& > div': {
        flex: '1 0 21%',
        marginBottom: '24px',
        '&:last-child': {
          marginBottom: '0px',
        },
      },
    },
    '.bp3-layout-row': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
    },
    '.bp3-layout-col': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: '1',
    },
    '.bp3-layout-col-side': {
      flex: '0 0 324px',
      marginLeft: '24px',
    },
    '.bp3-dashboard': {
      display: 'flex',
      flexWrap: 'wrap',
      '& .bp3-card': {
        flex: '1 0 21%',
        marginRight: '24px',
        height: '100px',
        '&:last-child': {
          marginRight: '0px',
        },
      },
    },
    '.bp3-limit-form-width': {
      '& .bp3-form-group': {
        maxWidth: '500px',
      },
    },
    '.bp3-form-content .bp3-popover-target': {
      width: '100%',
    },
    'input[type=text].bp3-time': {
      width: '60px !important',
    },
    'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
      ['-webkit-appearance']: 'none',
      margin: 0,
    },
    '.bp3-tab-indicator': {
      backgroundColor: `${colors.highlight} !important`,
    },
    '.bp3-html-table': {
      '& .bp3-switch': {
        marginBottom: 0,
        marginTop: '4px',
      },
      '& .bp3-control': {
        marginBottom: 0,
      },
      '&.bp3-html-table-tall td': {
        height: '54px',
        lineHeight: '20px',
      },
    },
    '.bp3-table-container': {
      paddingBottom: '48px',
    },
    '.bp3-scrollable': {
      overflow: 'scroll !important',
      '& .bp3-html-table': {
        minWidth: 'max-content',
      },
    },
    '.bp3-html-table > .bp3-control': {
      marginBotton: 0,
    },
    '.bp3-html-table td': {
      verticalAlign: 'middle !important',
      height: '38px',
      padding: '0 !important',
      paddingLeft: '11px !important',
      paddingRight: '11px !important',
    },
    '.bp3-dialog-table': {
      margin: '10px 0 15px',
    },
    '.bp3-table-frame': {
      background: '#FCFCFC',
      minWidth: 0,
      maxWidth: '100%',
      top: 96,
      bottom: 0,
      left: 46,
      right: 0,
      position: 'fixed',
      '& .bp3-table-frame-nav': {
        height: 46,
        padding: '8px 0 0 16px',
        background: '#f7f6f9',
        borderBottom: '1px solid #CDD3D6',
        '& > *': {
          marginRight: 12,
          display: 'inline-flex',
        },
        '& .bp3-icon': {
          '& svg': {
            fill: '#6a7c88',
          },
        },
      },
    },
    '.bp3-popover-height': {
      maxHeight: '500px',
      overflowY: 'auto',
      padding: 16,
    },
    '.bp3-add-button': {
      padding: '30px 0px',
      color: `${colors.highlight} !important`,
      '& .bp3-icon': {
        color: colors.highlight,
      },
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
    },
    '.bp3-save-button': {
      background: `${colors.primaryButtonColor} !important`,
      color: `${colors.primaryButtonText} !important`,
      padding: '0px 10px',
      marginRight: 5,
    },
    '.bp3-delete-cell': {
      verticalAlign: 'middle !important',
    },
    '.bp3-button-link': {
      color: `${colors.globalLinkColor} !important`,

      '&:hover': {
        textDecoration: 'underline !important',
      },
    },
    '.bp3-card-non-ideal-state': {
      height: '50vh',
      margin: 10,
    },
    '.bp3-card-panel': {
      background: '#f6f6f7',
    },
    '.bp3-popover-select': {
      '& .bp3-menu': {
        maxHeight: 400,
        width: 500,
        overflow: 'auto',
      },
    },
  },
}
export default styles
