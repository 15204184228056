import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { object } from 'prop-types'
import {
  HTMLTable,
  NonIdealState,
  Tag,
  ButtonGroup,
  Button,
  Card,
} from '@blueprintjs/core'
import FilterRow from '@components/FilterRow/FilterRow'
import PageNumbers from '@components/PageNumbers/PageNumbers'
import map from 'lodash/map'
import moment from 'moment'

import { JourneyStatus } from '@stores/recordsStore'

import Currency from '@components/Currency/Currency'
import GET_JOURNEYS from './queries/getJourneys.query'
import Query from '@components/Query/Query'

import { DEFAULT_RECORDS } from '@stores/recordsStore'

const Drivers = ({ match }) => {
  const id = match.params.driver

  const [paginationArgs, setPaginationArgs] = useState({
    first: DEFAULT_RECORDS,
    last: null,
    after: null,
    before: null,
  })

  const [currentPage, setCurrentPage] = useState(1)

  return (
    <Query query={GET_JOURNEYS} variables={{ id, ...paginationArgs }}>
      {({ journeysConnection }) => {
        const {
          pageInfo: { hasNextPage, hasPreviousPage, startCursor, endCursor },
          totalCount,
        } = journeysConnection

        const totalPages = Math.ceil(totalCount / DEFAULT_RECORDS)

        if (journeysConnection.edges.length > 0) {
          return (
            <div className="bp3-layout-row">
              <div className="bp3-table-frame">
                <FilterRow>
                  <Tag
                    disabled={true}
                    minimal={true}
                    large={true}
                    style={{
                      marginLeft: 'auto',
                    }}
                  >
                    {totalCount} Journeys
                  </Tag>

                  <ButtonGroup id="navigationGroup">
                    <Button
                      disabled={!hasPreviousPage}
                      icon="chevron-left"
                      onClick={() => {
                        setPaginationArgs({
                          last: DEFAULT_RECORDS,
                          before: startCursor,
                          after: null,
                          first: null,
                        })
                        setCurrentPage(currentPage - 1)
                      }}
                    >
                      Back
                    </Button>
                    <PageNumbers
                      currentPage={currentPage}
                      totalPages={totalPages === 0 ? 1 : totalPages}
                    />
                    <Button
                      disabled={!hasNextPage}
                      rightIcon="chevron-right"
                      onClick={() => {
                        setPaginationArgs({
                          last: null,
                          before: null,
                          after: endCursor,
                          first: DEFAULT_RECORDS,
                        })
                        setCurrentPage(currentPage + 1)
                      }}
                    >
                      Next
                    </Button>
                  </ButtonGroup>
                </FilterRow>

                <div className="bp3-table-container">
                  <Card className="bp3-scrollable bp3-noPad">
                    <HTMLTable bordered={false} interactive={true}>
                      <thead>
                        <tr>
                          <th>Journey ID</th>
                          <th>Customer</th>
                          <th>Transactions</th>
                          <th>Trip ID</th>
                          <th>Dispatcher Trip Cost</th>
                          <th>Status</th>
                          <th>Created at</th>
                        </tr>
                      </thead>
                      <tbody>
                        {map(
                          journeysConnection.edges,
                          ({
                            node: {
                              id,
                              customer,
                              transactions,
                              icabbiTripId,
                              status,
                              createdAt,
                              dispatcherTripCost,
                            },
                          }) => (
                            <tr key={id}>
                              <td>
                                <Link to={`/journeys/${id}`}>{id}</Link>
                              </td>
                              <td>
                                <Link to={`/customers/${customer.id}`}>
                                  {customer.name}
                                </Link>
                              </td>
                              <td>
                                <Link
                                  to={`/drivers/${
                                    match.params.driver
                                  }/transactions`}
                                >
                                  {transactions.length}
                                </Link>
                              </td>
                              <td>{icabbiTripId}</td>
                              <td>
                                {Currency({ amount: dispatcherTripCost }) ===
                                '£'
                                  ? '--'
                                  : Currency({ amount: dispatcherTripCost })}
                              </td>
                              <td>
                                <Tag
                                  minimal={true}
                                  intent={JourneyStatus[status] || 'DANGER'}
                                >
                                  {status}
                                </Tag>
                              </td>
                              <td>{moment(createdAt).format('HH:mm D/MM')}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </HTMLTable>
                  </Card>
                </div>
              </div>
            </div>
          )
        } else {
          return (
            <NonIdealState
              icon="path-search"
              title="No journeys for this driver"
              description="There are currently no journeys for this driver"
            />
          )
        }
      }}
    </Query>
  )
}

Drivers.propTypes = {
  match: object.isRequired,
  location: object,
}

export default Drivers
