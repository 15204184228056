import {
  HTMLTable,
  Card,
  NonIdealState,
  Tag,
  Popover,
  PopoverInteractionKind,
  Position,
  Classes,
  Button,
  Tooltip,
  Icon,
  Callout,
  Intent,
} from '@blueprintjs/core'
import moment from 'moment'

import { map, last } from 'lodash'
import { object } from 'prop-types'
import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { Query as CacheQuery, Mutation } from 'react-apollo'
import GET_JOURNEY from './queries/getJourney.query'
import PageLayout from '@components/PageLayout/PageLayout'
import Currency from '@components/Currency/Currency'
import Query from '@components/Query/Query'
import Request from '@components/Request/Request'
import config from '@config/config'
import { JourneyStatus, GatewayStatus } from '@stores/recordsStore'
import RefundModal from './RefundModal'
import RecaptureModal from './RecaptureModal'
import CompleteModal from './CompleteModal'
import CancelModal from './CancelModal'
import ls from '@utils/localStorage'
import defaultErrorHandler from '../../utils/defaultErrorHandler'
import OPEN_MODAL from './queries/openModal.query'
import { successToast } from '../../utils/toast'
import { penceToPounds } from '@utils/helpers'
import { permissionsAccess, authorizedRole } from '@stores/userStore'
import RESEND_SMS from './queries/resendSMS.query'

const toggleRefundModal = (client, value) =>
  client.writeData({
    data: {
      refundModal: value,
    },
  })

const toggleRecaptureModal = (client, value) =>
  client.writeData({
    data: {
      recaptureModal: value,
    },
  })

const toggleCompleteModal = (client, value) =>
  client.writeData({
    data: {
      completeModal: value,
    },
  })

const toggleCancelModal = (client, value) =>
  client.writeData({
    data: {
      cancelModal: value,
    },
  })

const Journey = ({ match, location, history }) => {
  let variables = {}
  if (location.shortId) {
    variables.shortId = location.shortId
  } else {
    variables.id = match.params.journey
  }

  const [
    isJourneyLogsButtonDisabled,
    setIsJourneyLogsButtonDisabled,
  ] = useState(false)
  const [journeyLogsButtonText, setJourneyLogsButtonText] = useState(
    'Request Journey Logs'
  )

  return (
    <CacheQuery query={OPEN_MODAL}>
      {({
        data: { refundModal, cancelModal, recaptureModal, completeModal },
        client,
      }) => {
        return (
          <PageLayout match={match} location={location}>
            <Query query={GET_JOURNEY} variables={variables}>
              {({ journey }, refetch) => {
                if (journey) {
                  if (location.shortId) {
                    history.push(`/journeys/${journey.id}`)
                  }

                  const diff =
                    journey.dispatcherEstimatedTripCost -
                    journey.dispatcherTripCost

                  const lastTransaction =
                    journey.transactions && journey.transactions.length
                      ? last(journey.transactions)
                      : null

                  const gatewayStatus = lastTransaction
                    ? lastTransaction.gatewayStatus
                    : 'N/A'

                  const dispatcherTripCost = lastTransaction
                    ? Currency({
                        amount: lastTransaction.grandTotal || 0,
                      })
                    : '--'

                  const diffInCosts =
                    lastTransaction && journey.dispatcherEstimatedTripCost
                      ? Currency({ amount: diff })
                      : '--'

                  const seenAtDate = journey.firstSeenAt
                    ? moment(journey.firstSeenAt).fromNow()
                    : '--'

                  const canRecaptureOrCancel =
                    lastTransaction &&
                    lastTransaction.status !== 'CAPTURED' &&
                    journey.status === 'COMPLETED'

                  const canRefund =
                    lastTransaction &&
                    permissionsAccess('journeyRefund') &&
                    gatewayStatus !== 'REFUNDED' &&
                    lastTransaction.status === 'CAPTURED'

                  const refundedAmount =
                    gatewayStatus === 'REFUNDED'
                      ? `£${penceToPounds(lastTransaction.refundedTotal)}`
                      : null

                  const showCompleteButton = journey.status !== 'COMPLETED'

                  const customerUrl = `${config.customerUrl}/${journey.shortId}`

                  const hideTransactionStatues =
                    lastTransaction && lastTransaction.adyenStatus === 'Settled'

                  return (
                    <div>
                      <h4 className="bp3-heading">
                        Journey &#160;
                        <Tag
                          minimal
                          style={{
                            float: 'right',
                            marginLeft: '10px',
                          }}
                          icon={'credit-card'}
                          intent={GatewayStatus[gatewayStatus] || 'NONE'}
                        >
                          {refundedAmount ? refundedAmount : ''} {gatewayStatus}
                        </Tag>
                        <Tag
                          minimal
                          style={{
                            float: 'right',
                          }}
                          icon={'taxi'}
                          intent={JourneyStatus[journey.status] || 'DANGER'}
                        >
                          {journey.status}
                        </Tag>
                      </h4>

                      <Card className={'bp3-nopad'}>
                        <HTMLTable interactive={false} striped={true}>
                          <tbody>
                            <tr>
                              <td>
                                <strong>Journey ID</strong>
                              </td>
                              <td>{journey.icabbiTripId}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Created</strong>
                              </td>
                              <td>
                                {moment(journey.createdAt).format('HH:mm D/MM')}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Seen</strong>
                              </td>
                              <td>{seenAtDate}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Short ID</strong>
                              </td>
                              <td>
                                <Popover
                                  interactionKind={PopoverInteractionKind.HOVER}
                                  position={Position.BOTTOM_LEFT}
                                  popoverClassName={
                                    Classes.POPOVER_CONTENT_SIZING
                                  }
                                  content={
                                    <Fragment>
                                      <strong>Link:</strong>{' '}
                                      <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={customerUrl}
                                      >
                                        {customerUrl}
                                      </a>
                                    </Fragment>
                                  }
                                  className={Classes.TOOLTIP_INDICATOR}
                                >
                                  {journey.shortId}
                                </Popover>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Site ID</strong>
                              </td>
                              <td>{journey.siteId}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Driver</strong>
                              </td>
                              <td>
                                <Link to={`/drivers/${journey.driver.id}`}>
                                  {journey.driver.name}
                                </Link>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Customer</strong>
                              </td>
                              <td>
                                <Link to={`/customers/${journey.customer.id}`}>
                                  {journey.customer.name}
                                </Link>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Estimate</strong>
                              </td>
                              <td>
                                {journey.dispatcherEstimatedTripCost
                                  ? Currency({
                                      amount:
                                        journey.dispatcherEstimatedTripCost,
                                    })
                                  : '--'}
                                &nbsp;
                                {journey.fixedCost ? (
                                  <Tooltip
                                    content={`Fixed price`}
                                    position={Position.RIGHT}
                                  >
                                    <Icon
                                      icon={'lock'}
                                      color="#30404D"
                                      iconSize={16}
                                    />
                                  </Tooltip>
                                ) : null}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Final Fare</strong>
                              </td>
                              <td
                                style={{
                                  color:
                                    journey.status === 'COMPLETED' &&
                                    !journey.webhookTripCost
                                      ? 'red'
                                      : 'black',
                                }}
                              >
                                {journey.status === 'COMPLETED'
                                  ? Currency({
                                      amount: journey.webhookTripCost || 0,
                                    })
                                  : ''}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Captured</strong>
                              </td>
                              {gatewayStatus === 'FAILED' ? (
                                <td
                                  style={{
                                    textDecoration: 'line-through',
                                  }}
                                >
                                  {dispatcherTripCost}
                                </td>
                              ) : (
                                <td>{dispatcherTripCost}</td>
                              )}
                            </tr>
                            {gatewayStatus === 'COMPLETED' && (
                              <tr>
                                <td>
                                  <strong>Difference</strong>
                                </td>
                                <td>{diffInCosts}</td>
                              </tr>
                            )}
                          </tbody>
                        </HTMLTable>
                      </Card>
                      {journey.transactions.length &&
                      journey.transactions[0].refusalReason ? (
                        <Callout intent={Intent.DANGER}>
                          This transaction has failed to capture, the payment
                          gateway responded with the following reason
                          <br />
                          {journey.transactions[0].refusalReason}
                        </Callout>
                      ) : null}

                      {journey.transactions.length > 0 && (
                        <Fragment>
                          <h4 className="bp3-heading">Transactions</h4>
                          <Card className={'bp3-nopad'}>
                            <HTMLTable interactive={false} striped={true}>
                              <thead>
                                <tr>
                                  <th colSpan="2">Transaction</th>
                                  <th width="100">Created</th>
                                  <th width="100">Status</th>
                                  <th width="100">Gateway</th>
                                  <th width="100">Processor</th>
                                  <th width="100" colSpan="2">
                                    Amount
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {map(
                                  journey.transactions,
                                  (transaction, index) => (
                                    <tr>
                                      <td>
                                        <strong>#{index}</strong>{' '}
                                      </td>
                                      <td>
                                        <Fragment>
                                          <Link
                                            to={`/transactions?searchValue=${transaction.id}`}
                                          >
                                            {transaction.id}
                                          </Link>
                                        </Fragment>
                                      </td>
                                      <td>
                                        {moment(transaction.createdAt).format(
                                          'HH:mm D/MM'
                                        )}
                                      </td>
                                      <td>
                                        {hideTransactionStatues ? null : (
                                          <Tag minimal>
                                            {transaction.status}
                                          </Tag>
                                        )}
                                      </td>
                                      <td>
                                        {hideTransactionStatues ? null : (
                                          <Tag
                                            minimal
                                            icon={'credit-card'}
                                            intent={
                                              GatewayStatus[
                                                transaction.gatewayStatus
                                              ] || 'NONE'
                                            }
                                          >
                                            {transaction.refundedTotal
                                              ? transaction.refundedTotal
                                              : ''}{' '}
                                            {transaction.gatewayStatus}
                                          </Tag>
                                        )}
                                      </td>
                                      <td>
                                        <Tag minimal>
                                          {transaction.adyenStatus ||
                                            'WAITING SYNC'}
                                          &nbsp;
                                          {transaction.notes !== null && (
                                            <Tag>{transaction.notes}</Tag>
                                          )}
                                        </Tag>
                                      </td>
                                      <td>
                                        {transaction.grandTotal &&
                                          transaction.grandTotal > 0 &&
                                          Currency({
                                            amount: transaction.grandTotal || 0,
                                          })}
                                      </td>
                                      <td>
                                        {transaction.refundedTotal > 0 &&
                                          Currency({
                                            amount:
                                              transaction.refundedTotal || 0,
                                          })}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </HTMLTable>
                          </Card>
                        </Fragment>
                      )}
                      <Mutation
                        mutation={RESEND_SMS}
                        variables={{ id: journey.id }}
                        onCompleted={() => {
                          successToast('SMS Resent')
                        }}
                        onError={defaultErrorHandler}
                      >
                        {resendSMS => (
                          <Button
                            style={{
                              float: 'right',
                              marginLeft: '15px',
                            }}
                            icon="envelope"
                            text={'Resend SMS'}
                            onClick={resendSMS}
                          />
                        )}
                      </Mutation>
                      {showCompleteButton &&
                        permissionsAccess('completeAndCancel') &&
                        permissionsAccess('completeAndCapture') && (
                          <Fragment>
                            <Button
                              style={{
                                float: 'right',
                                marginLeft: '15px',
                              }}
                              icon="known-vehicle"
                              text="Complete Journey"
                              onClick={() => toggleCompleteModal(client, true)}
                            />

                            <CompleteModal
                              journeyId={journey.id}
                              modalOpen={completeModal}
                              modalClose={() => {
                                toggleCompleteModal(client, false)
                                refetch()
                              }}
                            />
                          </Fragment>
                        )}

                      {canRecaptureOrCancel &&
                        permissionsAccess('journeyRecapture') && (
                          <Fragment>
                            <Button
                              style={{
                                float: 'right',
                                marginLeft: '15px',
                              }}
                              icon="refresh"
                              text="Recapture Payment"
                              onClick={() => toggleRecaptureModal(client, true)}
                            />

                            <RecaptureModal
                              fare={
                                journey.webhookTripCost
                                  ? journey.webhookTripCost
                                  : journey.transactions.length > 0 &&
                                    lastTransaction.grandTotal
                              }
                              fleetCharge={journey.driver.fleet.fleetCharge}
                              partnerCharge={
                                journey.driver.fleet.partner.serviceCharge
                              }
                              modalOpen={recaptureModal}
                              modalClose={() =>
                                toggleRecaptureModal(client, false)
                              }
                              journeyId={journey.id}
                            />
                          </Fragment>
                        )}
                      {canRecaptureOrCancel &&
                        permissionsAccess('cancelTransaction') && (
                          <Fragment>
                            <Button
                              style={{
                                float: 'right',
                                marginLeft: '15px',
                              }}
                              icon="cross"
                              text="Cancel Payment"
                              onClick={() => toggleCancelModal(client, true)}
                            />

                            <CancelModal
                              modalOpen={cancelModal}
                              modalClose={() =>
                                toggleCancelModal(client, false)
                              }
                              journeyId={journey.id}
                            />
                          </Fragment>
                        )}
                      {canRefund && (
                        <Button
                          style={{
                            float: 'right',
                          }}
                          icon="undo"
                          text="Refund Journey"
                          onClick={() => toggleRefundModal(client, true)}
                        />
                      )}

                      <RefundModal
                        modalOpen={refundModal}
                        modalClose={() => toggleRefundModal(client, false)}
                        journeyId={journey.id}
                      />

                      {authorizedRole('SUPERADMIN_ROLE') && (
                        <Button
                          style={{
                            float: 'right',
                          }}
                          icon="import"
                          text={journeyLogsButtonText}
                          disabled={isJourneyLogsButtonDisabled}
                          onClick={() => {
                            setJourneyLogsButtonText(
                              'Logs have been requested. Please wait 10-20 mins. Once ready, you will get an email'
                            )
                            setIsJourneyLogsButtonDisabled(true)

                            fetch(
                              `${config.journeyLogsApiUrl}/journey/${journey.id}`,
                              {
                                method: 'POST',
                                headers: {
                                  Authorization: `Bearer ${ls.get('jwt')}`,
                                },
                              }
                            ).then(() => {
                              alert(
                                "Journey logs have been requested and they'd be available in the next 10-20 minutes. \nOnce ready, you will get an email"
                              )
                            })
                          }}
                        />
                      )}
                      <br />

                      {authorizedRole('SUPERADMIN_ROLE') && (
                        <Request path={`/journey/${journey.id}`}>
                          {({ data, loading, error }) => {
                            if (loading) {
                              return (
                                <NonIdealState
                                  title="Fetching available data..."
                                  icon="cloud-download"
                                />
                              )
                            }
                            if (error) {
                              return (
                                <NonIdealState
                                  title="Failed to fetch data"
                                  icon="error"
                                />
                              )
                            }
                            if (data.length === 0)
                              return (
                                <NonIdealState
                                  title="Journey logs doesn't exist. You might need to fetch them using the button"
                                  icon="error"
                                />
                              )
                            return (
                              <Fragment>
                                <h4 className="bp3-heading">
                                  Journey Log Messages
                                </h4>
                                <Card className={'bp3-nopad'}>
                                  <HTMLTable interactive={false} striped={true}>
                                    <thead>
                                      <tr>
                                        <th width="50">ID</th>
                                        <th width="150">Timestamp</th>
                                        <th>Message</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {map(data, (log, index) => (
                                        <tr key={index}>
                                          <td>
                                            <strong>#{index}</strong>{' '}
                                          </td>
                                          <td>
                                            {moment(
                                              parseInt(log.timestamp)
                                            ).format('HH:mm D/MM')}
                                          </td>
                                          <td>
                                            <pre
                                              style={{ whiteSpace: 'pre-wrap' }}
                                            >
                                              {JSON.stringify(
                                                JSON.parse(log.message),
                                                null,
                                                2
                                              ).replace(/\\n/g, '\n')}
                                            </pre>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </HTMLTable>
                                </Card>
                              </Fragment>
                            )
                          }}
                        </Request>
                      )}
                    </div>
                  )
                } else {
                  return (
                    <NonIdealState
                      icon="path-search"
                      title="No journeys with this ID"
                      description="There are currently no journeys with this ID"
                    />
                  )
                }
              }}
            </Query>
          </PageLayout>
        )
      }}
    </CacheQuery>
  )
}

Journey.propTypes = {
  match: object.isRequired,
  location: object,
  history: object,
}

export default Journey
