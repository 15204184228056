import gql from 'graphql-tag'

const EDIT_PLATFORM = gql`
  mutation editPlatform(
    $id: ID!
    $adyenPercentageCharge: Float!
    $adyenGatewayCharge: Int!
    $adyenFallbackEstimate: Int!
  ) {
    updatePlatform(
      where: { id: $id }
      data: {
        adyenPercentageCharge: $adyenPercentageCharge
        adyenGatewayCharge: $adyenGatewayCharge
        adyenFallbackEstimate: $adyenFallbackEstimate
      }
    ) {
      id
      adyenPercentageCharge
      adyenGatewayCharge
      adyenFallbackEstimate
    }
  }
`

export default EDIT_PLATFORM
