class Ls {
  set = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data))
    return true
  }

  get = id => {
    const data = localStorage.getItem(id)
    return JSON.parse(data) || ''
  }

  remove = id => {
    localStorage.removeItem(id)
  }

  clear = () => {
    localStorage.clear()
  }
}

const ls = new Ls()

export default ls
