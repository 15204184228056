import React from 'react'

import Crumb from '@components/Header/Breadcrumbs/Crumb'
import Query from '@components/Query/Query'
import GET_CUSTOMER_NAME from './queries/getCustomerName.query'

const isSecondPart = i => i === 1

const CustomerCrumbs = ({ crumbs }) => {
  return crumbs.map((crumb, i) => {
    if (isSecondPart(i)) {
      return (
        <Query key={crumb} query={GET_CUSTOMER_NAME} variables={{ id: crumb }}>
          {({ customer }) => {
            if (!customer) {
              return null
            }

            return (
              <Crumb
                key={crumb}
                text={customer.name}
                link={`/customers/${crumb}`}
              />
            )
          }}
        </Query>
      )
    } else {
      return <Crumb key={crumb} text={crumb} />
    }
  })
}

export default CustomerCrumbs
