'use strict'

import * as yup from 'yup'

export const myDetailsGeneralStructure = {
  email: yup.string().email('The email is not in a valid format'),
  firstName: yup
    .string()
    .min(2, 'First name should be at least 2 characters long'),
  lastName: yup
    .string()
    .min(2, 'Last name should be at least 2 characters long'),
}
export const myDetailsPasswordStructure = {
  currentPassword: yup.string(),
  newPassword: yup
    .string()
    .required('A password is required')
    .min(8, 'The password should be at least 8 characters long'),
  passwordConfirm: yup
    .string()
    .required('Please confirm your password')
    .test('passwordMatch', 'Passwords should match.', function() {
      const { newPassword, passwordConfirm } = this.parent
      return newPassword === passwordConfirm
    }),
  roleId: yup.string().required('User role is required'),
}
export const newUserRegisterStructure = {
  firstName: yup
    .string()
    .min(2, 'First name should be at least 2 characters long')
    .required('First name is required'),
  lastName: yup
    .string()
    .min(2, 'Last name should be at least 2 characters long')
    .required('Last name is required'),
  password: yup
    .string()
    .required('A password is required')
    .min(8, 'The password should be at least 8 characters long'),
  passwordConfirm: yup
    .string()
    .test('passwordMatch', 'Passwords should match.', function() {
      const { password, passwordConfirm } = this.parent
      return password === passwordConfirm
    }),
  phoneNumber: yup.string().required('Phone number is required'),
}

export const editUserStructure = {
  email: yup
    .string()
    .email('The email is not in a valid format')
    .required(),
  firstName: yup
    .string()
    .min(2, 'First name should be at least 2 characters long')
    .required(),
  lastName: yup
    .string()
    .min(2, 'Last name should be at least 2 characters long')
    .required(),
}
