import {
  Cell,
  Column,
  CopyCellsMenuItem,
  SelectionModes,
  Table,
  RenderMode,
} from '@blueprintjs/table'
import { Menu } from '@blueprintjs/core'
import get from 'lodash/get'
import startCase from 'lodash/startCase'
import moment from 'moment'
import { arrayOf, bool, shape, string } from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import Currency from '@components/Currency/Currency'

const TransactionsTable = ({ edges, visibleColumns }) => {
  const cellRenderer = (column, currency, date) =>
    function cellRender(rowIndex) {
      const cellValue = get(edges[rowIndex].node, column)
      if (column === 'journey.id') {
        return (
          <Cell>
            <Link
              to={{
                pathname: `/journeys/${cellValue}`,
              }}
            >
              {cellValue}
            </Link>
          </Cell>
        )
      }
      if (cellValue === null) {
        return <Cell>{'--'}</Cell>
      }
      if (date) {
        return (
          <Cell>{`${moment(cellValue).format('DD/MM/YYYY, HH:mm:ss')}`}</Cell>
        )
      }
      if (currency) {
        return <Cell>{`${Currency({ amount: cellValue })}`}</Cell>
      }
      return <Cell>{`${cellValue}`}</Cell>
    }

  const columns = []

  Object.keys(visibleColumns).map(column => {
    if (visibleColumns[column].visible) {
      columns.push(
        <Column
          key={column}
          name={startCase(column)}
          cellRenderer={cellRenderer(
            visibleColumns[column].path,
            visibleColumns[column].currency,
            visibleColumns[column].date
          )}
        />
      )
    }
  })
  return (
    <Table
      numRows={edges.length}
      selectionModes={SelectionModes.ROWS_AND_CELLS}
      renderMode={RenderMode.BATCH_ON_UPDATE}
      numFrozenRows={0}
      enableFocusedCell={true}
      enableGhostCells={false}
      bodyContextMenuRenderer={context => {
        return (
          <Menu>
            <CopyCellsMenuItem
              context={context}
              getCellData={(rowIndex, columnIndex) => {
                const cell =
                  edges[rowIndex].node[
                    visibleColumns[columns[columnIndex].key].path
                  ]
                return cell
              }}
              text="Copy"
            />
          </Menu>
        )
      }}
    >
      {columns}
    </Table>
  )
}

TransactionsTable.propTypes = {
  edges: arrayOf(
    shape({
      cursor: string,
      node: shape({
        id: string,
        journey: shape({
          id: string,
          driver: shape({
            id: string,
            name: string,
            fleet: shape({
              id: string,
              name: string,
            }),
          }),
          customer: shape({
            id: string,
            name: string,
          }),
        }),
        createdAt: string,
        type: string,
        gatewayStatus: string,
      }),
    })
  ),
  visibleColumns: shape({
    transactionId: shape({ visible: bool, path: string }),
    createdAt: shape({ visible: bool, path: string, date: bool }),
    gatewayStatus: shape({ visible: bool, path: string }),
    type: shape({ visible: bool, path: string }),
    journeyId: shape({ visible: bool, path: string }),
    driver: shape({ visible: bool, path: string }),
    fleet: shape({ visible: bool, path: string }),
    orderNet: shape({ visible: bool, path: string, currency: bool }),
    preauthTotal: shape({ visible: bool, path: string, currency: bool }),
    orderGross: shape({ visible: bool, path: string, currency: bool }),
    partnerChargeGross: shape({ visible: bool, path: string, currency: bool }),
    partnerChargeNet: shape({ visible: bool, path: string, currency: bool }),
    grandTotal: shape({ visible: bool, path: string, currency: bool }),
    vatTotal: shape({ visible: bool, path: string, currency: bool }),
    processingFee: shape({ visible: bool, path: string, currency: bool }),
    fleetPayout: shape({ visible: bool, path: string, currency: bool }),
    fleetChargePayout: shape({ visible: bool, path: string, currency: bool }),
    platformPayout: shape({ visible: bool, path: string, currency: bool }),
    partnerVATPayout: shape({ visible: bool, path: string, currency: bool }),
    partnerPayout: shape({ visible: bool, path: string, currency: bool }),
    refundedTotal: shape({ visible: bool, path: string, currency: bool }),
    paymentRef: shape({ visible: bool, path: string }),
    journeyIdSnapshot: shape({ visible: bool, path: string }),
    tripIdSnapshot: shape({ visible: bool, path: string }),
    driverIdSnapshot: shape({ visible: bool, path: string }),
    customerIdSnapshot: shape({ visible: bool, path: string }),
    fleetNameSnapshot: shape({ visible: bool, path: string }),
  }),
}

export default TransactionsTable
