import gql from 'graphql-tag'

const TWO_FACTOR = gql`
  mutation twoFactorAuthentication($code: String!) {
    twoFactorAuthentication(code: $code) {
      token
      user {
        id
        role {
          key
          permissionCategories {
            key
            resolverNames
          }
        }
      }
    }
  }
`

export default TWO_FACTOR
