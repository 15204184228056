import React from 'react'
import { array } from 'prop-types'
import {
  HTMLTable,
  Tooltip,
  Position,
  Icon,
  Tag,
  Intent,
} from '@blueprintjs/core'
import { toggleModal, setValue } from '@utils/cacheHelpers'
import moment from 'moment'

const UsersTable = ({ edges }) => (
  <HTMLTable>
    <thead>
      <tr>
        <th>Email</th>
        <th>Name</th>
        <th>Phone number</th>
        <th>Role</th>
        <th>Created At</th>
        <th>Updated At</th>
        <th>Status</th>
      </tr>
    </thead>

    <tbody>
      {edges.map(({ node: user }) => (
        <tr key={user.id}>
          <td>
            <a
              onClick={() => {
                toggleModal({ updateUserModal: true })
                setValue({
                  user: { ...user },
                })
              }}
            >
              {user.email}{' '}
            </a>
            {user.hasRegistered ? (
              ''
            ) : (
              <Tooltip
                content={`User has not yet registered`}
                position={Position.RIGHT}
              >
                <Icon
                  onClick={() => {
                    toggleModal({ reinviteUserModal: true })
                    setValue({
                      user: { ...user },
                    })
                  }}
                  icon={'warning-sign'}
                  intent="danger"
                />
              </Tooltip>
            )}
          </td>
          <td>
            {user.firstName} {user.lastName}
          </td>
          <td>
            {user.phoneNumber}{' '}
            {user.canUse2FA ? (
              <Tooltip
                content={`Mobile number validated`}
                position={Position.RIGHT}
              >
                <Icon icon={'endorsed'} intent="success" />
              </Tooltip>
            ) : null}
          </td>
          <td>{user.role.name}</td>
          <td>{moment(user.createdAt).format('HH:mm D/MM')}</td>
          <td>{moment(user.updatedAt).format('HH:mm D/MM')}</td>
          <td>
            <Tag
              minimal={true}
              intent={user.disabled ? Intent.WARNING : Intent.SUCCESS}
            >
              {user.disabled ? 'Disabled' : 'Active'}
            </Tag>
          </td>
        </tr>
      ))}
    </tbody>
  </HTMLTable>
)

UsersTable.propTypes = {
  edges: array,
}

export default UsersTable
