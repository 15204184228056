import React from 'react'

import PageLayout from '@components/PageLayout/PageLayout'

const Dashboard = () => {
  return (
    <PageLayout pageTitle="Dashboard">
      <h1>Welcome To Airpay</h1>
    </PageLayout>
  )
}

export default Dashboard
