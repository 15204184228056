import React from 'react'
import { func, object } from 'prop-types'
import injectSheet from 'react-jss'
import {
  Callout,
  Classes,
  Button,
  Intent,
  FormGroup,
  InputGroup,
} from '@blueprintjs/core'

import PartnerSelect from '../PartnerSelect/PartnerSelect'
import { selectionUpdate } from '@utils/helpers'

const styles = () => ({
  callout: {
    marginBottom: '10px',
  },
})

const Form = ({
  classes,
  handleChange,
  handleSubmit,
  errors,
  values,
  setFieldValue,
}) => (
  <form onSubmit={handleSubmit}>
    <FormGroup
      label="Fleet Name"
      labelInfo="(required)"
      labelFor="name"
      helperText={errors.name || ''}
      intent={Intent.DANGER}
    >
      <InputGroup
        name="name"
        id="name"
        type="text"
        onChange={handleChange}
        intent={errors.name ? Intent.DANGER : Intent.NONE}
        value={values.name}
        disabled={false}
      />
    </FormGroup>
    <FormGroup
      label="Partner"
      labelInfo="(required)"
      labelFor="partnerId"
      helperText={
        values.partnerId === '' && errors.partnerId ? errors.partnerId : ''
      }
      intent={values.partnerId === '' ? Intent.DANGER : Intent.NONE}
    >
      <PartnerSelect
        partnerId={values.partnerId}
        disabled={values.partnerId === null}
        onChange={event => {
          const selection = selectionUpdate(event)
          setFieldValue('partnerId', selection.value, false)
        }}
      />
    </FormGroup>
    <Callout className={classes.callout}>
      <h3>Fleet Admin Details</h3>
      <FormGroup
        label="Email"
        helperText={
          errors.email ||
          'The user will receive and email inviting them to setup their account'
        }
        intent={errors.email ? Intent.DANGER : Intent.NONE}
      >
        <InputGroup
          value={values.email}
          name="email"
          type="email"
          onChange={handleChange}
          intent={errors.email ? Intent.DANGER : Intent.NONE}
        />
      </FormGroup>
    </Callout>

    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
      <Button text="Save" intent={Intent.DEFAULT} type="submit" />
    </div>
  </form>
)

Form.propTypes = {
  classes: object,
  handleChange: func,
  handleSubmit: func,
  setFieldValue: func,
  errors: object,
  values: object,
  setStatus: func,
  status: object,
  touched: object,
}

export default injectSheet(styles)(Form)
