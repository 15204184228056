import gql from 'graphql-tag'

const GET_ALL_USERS = gql`
  query usersConnection(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $userSearch: String
  ) {
    usersConnection(
      first: $first
      last: $last
      before: $before
      after: $after
      where: { email_contains: $userSearch }
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          id
          key
          email
          firstName
          lastName
          phoneNumber
          canUse2FA
          hasRegistered
          createdAt
          updatedAt
          disabled
          role {
            id
            key
            name
            permissionCategories {
              key
            }
          }
          ownsFleets {
            id
          }
        }
      }
    }
  }
`

export default GET_ALL_USERS
