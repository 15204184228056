import gql from 'graphql-tag'

const EDIT_DRIVER = gql`
  mutation driverDetails($id: ID!, $name: String, $icabbiDriverId: Int) {
    driverUpdate(
      where: { id: $id }
      data: { name: $name, icabbiDriverId: $icabbiDriverId }
    ) {
      id
      name
      icabbiDriverId
    }
  }
`

export default EDIT_DRIVER
