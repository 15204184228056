import { Classes, Dialog } from '@blueprintjs/core'
import { bool, func, string } from 'prop-types'
import React from 'react'
import { Mutation } from 'react-apollo'

import CreateAccountMerchant from './CreateAccountMerchant'
import {
  CREATE_ACCOUNT_HOLDER_INDIVIDUAL,
  CREATE_ACCOUNT_HOLDER_BUSINESS,
} from './queries/createAccountMerchant.mutation'

import { toggleModal } from '@utils/cacheHelpers'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast, errorToast } from '@utils/toast'

const handleMutation = data => {
  toggleModal({
    fleetModal: false,
  })
}

const AccountMerchantModel = ({ modalOpen, modalClose, fleetId }) => {
  return (
    <Dialog
      isOpen={modalOpen}
      onClose={modalClose}
      title="Add Merchant Account"
    >
      <div className={Classes.DIALOG_BODY}>
        <Mutation
          mutation={CREATE_ACCOUNT_HOLDER_INDIVIDUAL}
          onError={defaultErrorHandler}
          onCompleted={() =>
            successToast('Successfully created merchant account')
          }
        >
          {createAccountMerchantIndividual => (
            <Mutation
              mutation={CREATE_ACCOUNT_HOLDER_BUSINESS}
              onError={defaultErrorHandler}
              onCompleted={() => {
                successToast('Successfully created merchant account')
                modalClose()
                location.reload()
              }}
            >
              {createAccountMerchantBusiness => (
                <CreateAccountMerchant
                  onSubmit={values => {
                    if (!values.accepttcs || !values.airpayConditions) {
                      errorToast(
                        'Please accept the terms and conditions before proceeding'
                      )
                      return
                    }
                    if (values.legalEntity === 'Business') {
                      createAccountMerchantBusiness({
                        variables: {
                          ...values,
                        },
                      })
                    }
                    if (values.legalEntity === 'Individual') {
                      createAccountMerchantIndividual({
                        variables: {
                          ...values,
                        },
                      })
                    }
                    handleMutation(fleetId)
                  }}
                  initialValues={{
                    id: fleetId,
                    email: '',
                    firstName: '',
                    lastName: '',
                    city: '',
                    country: 'GB',
                    houseNumberOrName: '',
                    postalCode: '',
                    street: '',
                    legalEntity: 'Business',
                    doingBusinessAs: '',
                    legalBusinessName: '',
                    registrationNumber: '',
                    businessEmail: '',
                    fullPhoneNumber: '',
                    shareholdersEmail: '',
                    shareholdersCity: '',
                    shareholdersCountry: 'GB',
                    shareholdersHouseNumberOrName: '',
                    shareholdersPostalCode: '',
                    shareholdersStreet: '',
                    phoneCountryCode: 'GB',
                    phoneNumber: '',
                    accepttcs: false,
                    airpayConditions: false,
                  }}
                />
              )}
            </Mutation>
          )}
        </Mutation>
      </div>
    </Dialog>
  )
}

AccountMerchantModel.propTypes = {
  modalOpen: bool,
  modalClose: func,
  fleetId: string,
}

export default AccountMerchantModel
