import React from 'react'
import { object, string, func, bool, array, shape } from 'prop-types'
import injectSheet from 'react-jss'
import ReactSVG from 'react-svg'
import { Button, Navbar, NavbarGroup } from '@blueprintjs/core'
import airpayLogo from '@assets/airpay-logo-white.png'
import Breadcrumbs from '@components/Header/Breadcrumbs/Breadcrumbs'
import TabBar from '@components/Header/TabBar/TabBar'
import { matchType, locationType } from '@utils/types'

const styles = ({ colors }) => ({
  topHeader: {
    width: '100%',
    height: 48,
    zIndex: 2,
    paddingLeft: 64,
    paddingRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: colors.headerBgColor,
  },
  topLeftLogo: {
    top: -39,
    left: -58,
    zIndex: 3,
    position: 'fixed',
    transform: 'scale(0.2)',
    width: 170,
    height: 125,
    objectFit: 'cover',
    objectPosition: '0 0' /* try 20px 10px */,
  },
  button: {
    backgroundImage: 'none !important',
    color: `${colors.primaryButtonText} !important`,
    boxShadow: `${colors.primaryButtonShadow} !important`,
    backgroundColor: `${colors.primaryButtonColor} !important`,
  },
})

const Header = ({
  location,
  classes,
  button,
  permissions = true,
  tabs,
  match,
}) => (
  <header>
    <Navbar className={classes.topHeader} fixedToTop={true}>
      <img src={airpayLogo} className={classes.topLeftLogo} />
      <Breadcrumbs location={location} />
      <NavbarGroup>
        {button && permissions && (
          <Button icon="add" className={classes.button} {...button} />
        )}
      </NavbarGroup>
    </Navbar>
    <TabBar tabs={tabs} match={match} location={location} />
  </header>
)

Header.propTypes = {
  location: locationType,
  classes: object,
  button: shape({
    text: string,
    action: func,
  }),
  permissions: bool,
  tabs: array,
  match: matchType,
}

export default injectSheet(styles)(Header)
