import gql from 'graphql-tag'

const RECAPTURE_PAYMENT = gql`
  mutation journeyRecapture($id: ID!, $value: Int!) {
    journeyRecapture(id: $id, value: $value) {
      id
    }
  }
`
export default RECAPTURE_PAYMENT
