const styles = () => ({
  container: {
    display: 'flex',
    backgroundColor: 'rgb(46, 14, 163)',
    backgroundSize: 'cover',
    height: '100%',
    width: '100%',
    backgroundPosition: 'center',
    position: 'relative',
    overflow: 'hidden',
    overflowX: 'hidden',
    zIndex: '0',
  },
  registerUserContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
  },
  registerUserForm: {
    width: 340,
    marginBottom: 20,
    zIndex: '0',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 10,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  backgroundShapes: {
    margin: 0,
    height: '100%',
    display: 'inline-block',
    zIndex: -100,
  },
  backgroundShapesTop: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: '0',
  },
  backgroundShapeBottom: {
    position: 'absolute',
    top: '68%',
    right: '0',
  },
  airPayContainer: {
    position: 'absolute',
    top: 'calc(50% - 400px)',
    horizontalAlign: 'center',
    alignItems: 'center',
  },
  airPayLogo: {
    height: '60px',
    width: '60px',
    paddingBottom: '6px',
  },
  airPayText: {
    display: 'block',
    height: '60px',
    width: '60px',

    marginRight: 0,
  },
})

export default styles
