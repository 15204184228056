import { HTMLSelect } from '@blueprintjs/core'
import map from 'lodash/map'
import { string, func, bool, object } from 'prop-types'
import React from 'react'

import GET_PLATFORM_IDS from './queries/getPlatformIds.query'
import Query from '@components/Query/Query'

const PlatformSelect = ({ platformId, disabled, onChange }) => (
  <Query query={GET_PLATFORM_IDS}>
    {({ platforms }) => (
      <div>
        <HTMLSelect
          name="PlatformId"
          fill="{true}"
          options={[
            { value: '', label: 'Select Platform...' },
            ...map(platforms, ({ id, name }) => ({
              value: id,
              label: name,
            })),
          ]}
          value={platformId}
          disabled={disabled}
          onChange={event => onChange(event)}
        />
      </div>
    )}
  </Query>
)

PlatformSelect.propTypes = {
  platformId: string,
  disabled: bool,
  onChange: func,
  values: object,
}

export default PlatformSelect
