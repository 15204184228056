import React from 'react'
import Form from './ContactForm'
import { Formik } from 'formik'
import { func, object } from 'prop-types'
import { contactFormValidation } from './validation'

const ContactFormContainer = ({ onSubmit, initialValues }) => (
  <Formik
    onSubmit={onSubmit}
    initialValues={initialValues}
    validationSchema={contactFormValidation}
  >
    {props => <Form {...props} />}
  </Formik>
)

ContactFormContainer.propTypes = {
  onSubmit: func.isRequired,
  initialValues: object,
}

export default ContactFormContainer
