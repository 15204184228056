import React, { Fragment } from 'react'
import { object, func } from 'prop-types'
import { permissionsAccess } from '@stores/userStore'
import { Link } from 'react-router-dom'
import { Tag, Intent, Tooltip, Position, Icon, Button } from '@blueprintjs/core'
import Currency from '@components/Currency/Currency'
import { JourneyStatus } from '@stores/recordsStore'
import moment from 'moment'

const FailedTransactionRow = ({ transaction, updateModal }) => {
  const { journey } = transaction
  const { customer } = journey

  const isTransactionCaptured = transaction.status === 'CAPTURED'
  const isFailedCapture = transaction.notes === 'INCREASE_FAILED_UNFUNDED'
  const isJourneyComplete = journey.status === 'COMPLETED'

  const canRecaptureOrCancel = !isTransactionCaptured && isJourneyComplete

  const canRefund =
    permissionsAccess('journeyRefund') &&
    transaction.gatewayStatus !== 'REFUNDED' &&
    isTransactionCaptured

  const showCompleteButton = !isJourneyComplete

  return (
    <tr key={transaction.id}>
      <td>
        <Link to={`/journeys/${journey.id}`}>{journey.icabbiTripId}</Link>
      </td>
      <td>
        <Link to={`/transactions/all?searchValue=${transaction.id}`}>
          {transaction.id}
        </Link>
      </td>
      <td>
        <Link to={`/customers/${customer.id}`}>{customer.name}</Link>
      </td>
      <td>
        <Tooltip
          content={isTransactionCaptured ? `Captured` : `Authorised`}
          position={Position.LEFT}
        >
          <Icon icon={'credit-card'} color="#30404D" />
        </Tooltip>
        &nbsp;
        {Currency({
          amount: transaction.grandTotal,
        })}
        &nbsp;
        {journey.fixedCost ? (
          <Tooltip content={`Fixed price`} position={Position.RIGHT}>
            <Icon icon={'lock'} color="#30404D" iconSize={16} />
          </Tooltip>
        ) : null}
      </td>
      <td>
        <Tag minimal={true}>{transaction.status}</Tag>
      </td>
      <td>
        <Tag minimal={true} intent={JourneyStatus[journey.status] || 'DANGER'}>
          {journey.status}
        </Tag>
      </td>
      <td>
        <Tag minimal={true}>{transaction.gatewayStatus}</Tag>
      </td>
      <td>
        {transaction.notes ? (
          <Tag
            minimal={true}
            intent={isFailedCapture ? Intent.WARNING : Intent.NONE}
          >
            {transaction.notes}
          </Tag>
        ) : null}
      </td>
      <td>{transaction.captureAttempts || 1}</td>
      <td>
        {transaction.lastCaptureAttempt
          ? moment(transaction.lastCaptureAttempt).format('HH:mm D/MM')
          : 'unknown'}
      </td>
      <td>
        {showCompleteButton &&
          permissionsAccess('completeAndCancel') &&
          permissionsAccess('completeAndCapture') && (
            <Fragment>
              <Button
                intent={Intent.SUCCESS}
                minimal
                icon="known-vehicle"
                text="Complete Journey"
                onClick={() =>
                  updateModal('complete', { open: true, id: journey.id })
                }
              />
            </Fragment>
          )}

        {canRecaptureOrCancel && permissionsAccess('journeyRecapture') && (
          <Fragment>
            <Button
              intent={Intent.PRIMARY}
              icon="refresh"
              text="Recapture Payment"
              minimal
              onClick={() =>
                updateModal('recapture', {
                  id: journey.id,
                  open: true,
                  fare: journey.webhookTripCost,
                  fleetCharge: journey.driver.fleet.fleetCharge,
                  partnerCharge: journey.driver.fleet.partner.serviceCharge,
                })
              }
            />
          </Fragment>
        )}
        {canRecaptureOrCancel && permissionsAccess('cancelTransaction') && (
          <Fragment>
            <Button
              icon="cross"
              intent={Intent.WARNING}
              minimal
              text="Cancel Payment"
              onClick={() =>
                updateModal('cancel', { open: true, id: journey.id })
              }
            />
          </Fragment>
        )}
        {canRefund && (
          <Fragment>
            <Button
              icon="undo"
              minimal
              intent={Intent.DANGER}
              text="Refund Journey"
              onClick={() =>
                updateModal('refund', { open: true, id: journey.id })
              }
            />
          </Fragment>
        )}
      </td>
    </tr>
  )
}

FailedTransactionRow.propTypes = {
  transaction: object,
  updateModal: func,
}

export default FailedTransactionRow
