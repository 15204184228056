import React from 'react'
import { Intent, FormGroup, Button, InputGroup } from '@blueprintjs/core'
import { Col } from 'react-simple-flex-grid'
import { func, object, bool } from 'prop-types'
import PercentageInput from '@components/PercentageInput/PercentageInput'
import CurrencyInput from '@components/CurrencyInput/CurrencyInput'

const PlatformEditForm = ({
  values,
  handleSubmit,
  handleChange,
  errors,
  isValid,
  setFieldValue,
  dirty,
}) => (
  <form onSubmit={handleSubmit}>
    <Col xs={12} md={4}>
      <FormGroup
        label="Adyen Percentage Charge"
        labelFor="adyenPercentageCharge"
        helperText={errors.adyenPercentageCharge}
        intent={Intent.DANGER}
      >
        <PercentageInput
          value={values.adyenPercentageCharge}
          handleChange={values =>
            setFieldValue('adyenPercentageCharge', values.floatValue)
          }
        />
      </FormGroup>
      <FormGroup
        label="Adyen gateway charge"
        labelFor="adyenGatewayCharge"
        helperText={errors.adyenGatewayCharge}
        intent={Intent.DANGER}
      >
        <CurrencyInput
          name="adyenGatewayCharge"
          id="adyenGatewayCharge"
          value={values.adyenGatewayCharge}
          onChange={handleChange}
          intent={errors.adyenGatewayCharge ? Intent.DANGER : Intent.NONE}
          onBlur={event => {
            const price = Number(event.currentTarget.value).toFixed(2)
            setFieldValue('adyenGatewayCharge', price)
          }}
        />
      </FormGroup>
      <FormGroup
        label="Adyen Fallback Estimate"
        labelFor="adyenFallbackEstimate"
        helperText={errors.adyenFallbackEstimate || ''}
        intent={Intent.DANGER}
      >
        <CurrencyInput
          name="adyenFallbackEstimate"
          id="adyenFallbackEstimate"
          value={values.adyenFallbackEstimate}
          onChange={handleChange}
          intent={errors.adyenFallbackEstimate ? Intent.DANGER : Intent.NONE}
          onBlur={event => {
            const price = Number(event.currentTarget.value).toFixed(2)
            setFieldValue('adyenFallbackEstimate', price)
          }}
        />
      </FormGroup>
      <Button
        text="Save"
        intent={Intent.DEFAULT}
        type="submit"
        disabled={dirty && !isValid}
      />
    </Col>
  </form>
)

PlatformEditForm.propTypes = {
  handleChange: func,
  handleSubmit: func,
  initialValues: object,
  errors: object,
  values: object,
  setFieldValue: func,
  classes: object,
  isValid: bool,
  dirty: bool,
}

export default PlatformEditForm
