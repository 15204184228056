import gql from 'graphql-tag'

const INVITE_USER = gql`
  mutation inviteUser(
    $email: String!
    $roleId: String!
    $ownsFleets: [String!]
  ) {
    inviteUser(email: $email, roleId: $roleId, ownsFleetIds: $ownsFleets) {
      id
      key
      email
      firstName
      lastName
      createdAt
      updatedAt
      role {
        id
        name
      }
      ownsFleets {
        id
        name
      }
    }
  }
`

export default INVITE_USER
