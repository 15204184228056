import { Card, HTMLTable, Icon, Tag } from '@blueprintjs/core'
import difference from 'lodash/difference'
import React, { Fragment } from 'react'
import { Query as CacheQuery } from 'react-apollo'
import { string } from 'prop-types'

import { matchType } from '@utils/types'
import { toggleModal, setValue } from '@utils/cacheHelpers'

import CreateMessageTemplate from './CreateMessageTemplate'
import GET_MESSAGE_TEMPLATES from './queries/getMessageTemplates.query'
import GET_TEMPLATE_TYPES from './queries/getTemplateTypes.query'
import OPEN_DRAWER from './queries/getOpenDrawer.query'
import Query from '@components/Query/Query'
import UpdateMessageTemplate from './UpdateMessageTemplate'

const MessageTemplates = ({ fleetId }) => {
  return (
    <Query query={GET_TEMPLATE_TYPES} variables={{ name: 'FleetTemplateType' }}>
      {({ __type: { enumValues } }) => {
        const allTemplateTypes = enumValues.map(template => template.name)
        return (
          <Query query={GET_MESSAGE_TEMPLATES} variables={{ id: fleetId }}>
            {({ messageTemplates }) => {
              const createdTemplateTypes = messageTemplates.map(
                messageTemplate => messageTemplate.type
              )
              const availableTemplateTypes = difference(
                allTemplateTypes,
                createdTemplateTypes
              )
              return (
                <Fragment>
                  <Card className="bp3-layout-col bp3-nopad">
                    <HTMLTable bordered={false} interactive={true}>
                      <thead>
                        <tr>
                          <th key="template">Template</th>
                          <th key="messageBody">Message Body</th>
                        </tr>
                      </thead>
                      <tbody>
                        {availableTemplateTypes.map(templateType => {
                          return (
                            <tr key={templateType}>
                              <td>
                                <a
                                  onClick={() =>
                                    toggleModal({
                                      messageDrawer: {
                                        isOpen: true,
                                        templateType: templateType,
                                        id: null,
                                        __typename: 'messageDrawer',
                                      },
                                    })
                                  }
                                >
                                  {templateType}
                                </a>
                              </td>
                              <td>
                                <Tag minimal={true} intent={'warning'}>
                                  <Icon
                                    icon={'warning-sign'}
                                    minimal={true}
                                    size={12}
                                  />{' '}
                                  Template Required
                                </Tag>
                              </td>
                            </tr>
                          )
                        })}
                        {messageTemplates.map(messageTemplate => {
                          return (
                            <tr key={messageTemplate.id}>
                              <td>
                                <a
                                  onClick={() =>
                                    toggleModal({
                                      messageDrawer: {
                                        isOpen: true,
                                        templateType: messageTemplate.type,
                                        id: messageTemplate.id,
                                        __typename: 'messageDrawer',
                                      },
                                    })
                                  }
                                >
                                  {messageTemplate.type}
                                </a>
                              </td>
                              <td>{messageTemplate.text}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </HTMLTable>
                  </Card>

                  <CacheQuery query={OPEN_DRAWER}>
                    {({ data: { messageDrawer } }) => {
                      const message = {
                        messageDrawer: messageDrawer,
                        fleetId: fleetId,
                        messageId: messageDrawer.id,
                      }
                      if (messageDrawer.id !== null) {
                        return (
                          <UpdateMessageTemplate
                            isOpen={messageDrawer.isOpen}
                            drawerClose={() => {
                              toggleModal({
                                messageDrawer: {
                                  isOpen: false,
                                  templateType: null,
                                  id: null,
                                  __typename: 'messageDrawer',
                                },
                              })
                              setValue({
                                message: {
                                  fleetId: fleetId,
                                  id: messageDrawer.id,
                                  templateType: messageDrawer.templateType,
                                },
                              })
                            }}
                            message={message}
                          />
                        )
                      } else {
                        return (
                          <CreateMessageTemplate
                            isOpen={messageDrawer.isOpen}
                            drawerClose={() => {
                              toggleModal({
                                messageDrawer: {
                                  isOpen: false,
                                  templateType: null,
                                  id: null,
                                  __typename: 'messageDrawer',
                                },
                              })
                              setValue({
                                message: {
                                  fleetId: fleetId,
                                  id: messageDrawer.id,
                                  templateType: messageDrawer.templateType,
                                },
                              })
                            }}
                            message={message}
                          />
                        )
                      }
                    }}
                  </CacheQuery>
                </Fragment>
              )
            }}
          </Query>
        )
      }}
    </Query>
  )
}

MessageTemplates.propTypes = {
  fleetId: string,
  match: matchType,
}

export default MessageTemplates
