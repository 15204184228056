import gql from 'graphql-tag'

const GET_FLEET_CONTACT_DETAILS = gql`
  query getFleetContactDetails($id: ID!) {
    fleet(where: { id: $id }) {
      id
      businessContactName
      businessContactPhone
      businessContactEmail
      technicalContactName
      technicalContactPhone
      technicalContactEmail
    }
  }
`

export default GET_FLEET_CONTACT_DETAILS
