import gql from 'graphql-tag'

const REGISTER = gql`
  mutation register(
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $password: String!
  ) {
    register(
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      password: $password
    ) {
      obfuscatedPhoneNumber
    }
  }
`

export default REGISTER
