import React from 'react'

import Crumb from '@components/Header/Breadcrumbs/Crumb'
import Query from '@components/Query/Query'
import GET_ICABBI_TRIP_ID from '@components/Header/Breadcrumbs/queries/getiCabbiTripId.query'

const isSecondPart = i => i === 1

const JourneyCrumbs = ({ crumbs }) => {
  return crumbs.map((crumb, i) => {
    if (isSecondPart(i)) {
      return (
        <Query key={crumb} query={GET_ICABBI_TRIP_ID} variables={{ id: crumb }}>
          {({ journey }) => {
            if (!journey) {
              return null
            }

            return (
              <Crumb
                key={crumb}
                text={journey.icabbiTripId}
                link={`/journeys/${crumb}`}
              />
            )
          }}
        </Query>
      )
    } else {
      return <Crumb key={crumb} text={crumb} />
    }
  })
}

export default JourneyCrumbs
