import React from 'react'
import { Card, Elevation, NonIdealState } from '@blueprintjs/core'
import { Mutation } from 'react-apollo'

import { successToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import GET_PLATFORM from './queries/getPlatform.query'
import { locationType, matchType } from '@utils/types'
import Query from '@components/Query/Query'
import PageLayout from '@components/PageLayout/PageLayout'
import EDIT_PLATFORM from './mutations/editPlatform.mutation'
import PlatformEdit from './Form/PlatformEdit'
import { numberToPence, penceToPounds } from '@utils/helpers'

const Platform = ({ match, location }) => (
  <PageLayout match={match} location={location}>
    <Query query={GET_PLATFORM}>
      {({ platforms }) => {
        if (platforms.length > 0) {
          const platform = platforms[0]
          return (
            <div className="bp3-layout-col">
              <Card elevation={Elevation.ZERO}>
                <h4 className={'bp3-heading'}>Platform</h4>
                <h5 className="bp3-heading">{platform.name}</h5>
                <Mutation
                  mutation={EDIT_PLATFORM}
                  onError={defaultErrorHandler}
                  onCompleted={() => {
                    successToast('Successfully edited fleet details')
                  }}
                >
                  {editPlatform => (
                    <PlatformEdit
                      onSubmit={platform => {
                        editPlatform({
                          variables: {
                            ...platform,
                            adyenGatewayCharge: numberToPence(
                              platform.adyenGatewayCharge
                            ),

                            adyenFallbackEstimate: numberToPence(
                              platform.adyenFallbackEstimate
                            ),
                          },
                        })
                      }}
                      initialValues={{
                        ...platform,
                        adyenGatewayCharge: penceToPounds(
                          platform.adyenGatewayCharge
                        ),

                        adyenFallbackEstimate: penceToPounds(
                          platform.adyenFallbackEstimate
                        ),
                      }}
                    />
                  )}
                </Mutation>
              </Card>
            </div>
          )
        } else {
          return (
            <NonIdealState
              title="No platform to display"
              icon="layers"
              className="bp3-card-non-ideal-state"
              description="There is currently no platform to be displayed"
            />
          )
        }
      }}
    </Query>
  </PageLayout>
)

Platform.propTypes = {
  match: matchType,
  location: locationType,
}

export default Platform
