import { Dialog, Classes } from '@blueprintjs/core'
import { Formik } from 'formik'
import { bool, func, object } from 'prop-types'
import React from 'react'
import { Mutation } from 'react-apollo'

import ADD_PARTNER from './mutations/addPartner'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import GET_PARTNERS from '@components/Partners/queries/getPartners.query'
import PartnerCreateForm from '@components/PartnerForm/PartnerCreateForm.jsx'
import { toggleModal } from '@utils/cacheHelpers'
import { validation } from './validation'

const handleMutation = (id, history) => {
  history.push(`/partners/${id}`)
  toggleModal({
    partnerModal: false,
  })
}

const PartnerModal = ({ modalOpen, modalClose, history }) => {
  return (
    <Dialog isOpen={modalOpen} onClose={modalClose} title="Add Partner">
      <Mutation
        mutation={ADD_PARTNER}
        onError={defaultErrorHandler}
        onCompleted={({ partnerCreate }) => {
          handleMutation(partnerCreate.id, history)
        }}
        refetchQueries={[{ query: GET_PARTNERS }]}
      >
        {addPartner => (
          <div className={Classes.DIALOG_BODY}>
            <Formik
              onSubmit={values => {
                addPartner({
                  variables: {
                    ...values,
                  },
                })
              }}
              initialValues={{
                name: '',
                platformId: '',
              }}
              validationSchema={validation}
            >
              {props => <PartnerCreateForm {...props} />}
            </Formik>
          </div>
        )}
      </Mutation>
    </Dialog>
  )
}

PartnerModal.propTypes = {
  modalOpen: bool,
  modalClose: func,
  handleSubmit: func,
  history: object,
}

export default PartnerModal
