import React from 'react'
import { Mutation } from 'react-apollo'

import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import Query from '@components/Query/Query'
import { UserId } from '@stores/userStore'

import EDIT_MY_DETAILS from '@components/User/Details/mutations/editUserDetails.mutation'
import GET_USER_INFO from '@components/User/Details/queries/getUserInfo.query'
import DetailsForm from './DetailsForm'

const Details = () => {
  const id = UserId()
  return (
    <Query query={GET_USER_INFO} variables={{ id }}>
      {({ user }) => (
        <Mutation
          mutation={EDIT_MY_DETAILS}
          onError={defaultErrorHandler}
          onCompleted={() => successToast('Successfully updated user details')}
          refetchQueries={[{ query: GET_USER_INFO, variables: { id: id } }]}
        >
          {editMyDetails => (
            <DetailsForm
              values={user}
              onSubmit={values => {
                editMyDetails({ variables: { ...values, id } })
              }}
              initialValues={user}
            />
          )}
        </Mutation>
      )}
    </Query>
  )
}

export default Details
