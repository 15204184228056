import { Dialog, Button, Classes } from '@blueprintjs/core'
import { bool, func, string } from 'prop-types'
import React from 'react'
import { Mutation } from 'react-apollo'

import {
  UNSUSPEND_ACCOUNT_HOLDER,
  SUSPEND_ACCOUNT_HOLDER,
} from './queries/suspendAccountHolder.mutation'
import GET_ACCOUNT_MERCHANT from '../queries/GetAccountMerchant'

import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'

const SuspendMerchantAccount = ({
  modalOpen,
  modalClose,
  fleetId,
  accountStatus,
}) => {
  if (accountStatus !== 'Suspended') {
    return (
      <Dialog
        isOpen={modalOpen}
        onClose={modalClose}
        title="Suspend Merchant Account"
      >
        <Mutation
          mutation={SUSPEND_ACCOUNT_HOLDER}
          onError={defaultErrorHandler}
          onCompleted={() => {
            successToast('Successfully suspended merchant account'),
              modalClose()
          }}
          refetchQueries={[
            {
              query: GET_ACCOUNT_MERCHANT,
              variables: { id: fleetId },
            },
          ]}
        >
          {suspendAccount => (
            <div className={Classes.DIALOG_BODY}>
              <h5 className="bp3-heading">Confirmation</h5>
              <p>
                A suspended account will not be able to process any transactions
                or pay out funds. Any payments made to a suspends account holder
                will be directed to the merchant's liable account.
              </p>
              <p>
                <b>Are you sure you want to suspend this merchant account?</b>
              </p>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button
                  className={Classes.POPOVER_DISMISS}
                  text="Cancel"
                  onClick={() => modalClose()}
                />

                <Button
                  className={Classes.INTENT_WARNING}
                  onClick={() => suspendAccount({ variables: { id: fleetId } })}
                  text="Suspend Account"
                />
              </div>
            </div>
          )}
        </Mutation>
      </Dialog>
    )
  } else {
    return (
      <Dialog
        isOpen={modalOpen}
        onClose={modalClose}
        title="Unsuspend Merchant Account"
      >
        <Mutation
          mutation={UNSUSPEND_ACCOUNT_HOLDER}
          onError={defaultErrorHandler}
          onCompleted={() => {
            successToast('Successfully unsuspend merchant account'),
              modalClose()
          }}
          refetchQueries={[
            {
              query: GET_ACCOUNT_MERCHANT,
              variables: { id: fleetId },
            },
          ]}
        >
          {unsuspendAccount => (
            <div className={Classes.DIALOG_BODY}>
              <h5 className="bp3-heading">Confirmation</h5>
              <p>
                Unsuspending this account will allow it to process any
                transactions and pay out funds.
              </p>
              <p>Are you sure you want to unsuspend this merchant account?</p>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button
                  className={Classes.POPOVER_DISMISS}
                  text="Cancel"
                  onClick={() => modalClose()}
                />

                <Button
                  className={Classes.INTENT_WARNING}
                  onClick={() =>
                    unsuspendAccount({ variables: { id: fleetId } })
                  }
                  text="Unsuspend Account"
                />
              </div>
            </div>
          )}
        </Mutation>
      </Dialog>
    )
  }
}

SuspendMerchantAccount.propTypes = {
  modalOpen: bool,
  modalClose: func,
  fleetId: string,
  accountStatus: string,
}
export default SuspendMerchantAccount
