const products = {
  PLATFORM: { name: 'platform' },
  PARTNER: { name: 'partner' },
  PARTNERS: { name: 'partners' },
  FLEETS: { name: 'fleets' },
  FLEET: { name: 'fleet' },
  DRIVERS: { name: 'drivers' },
  DRIVER: { name: 'driver' },
  CUSTOMER: { name: 'customer' },
  JOURNEYS: { name: 'journeys' },
  JOURNEY: { name: 'journey' },
  TRANSACTION: { name: 'transaction' },
  TRANSACTIONS: { name: 'transactions' },
}

export default products
