'use strict'

import * as yup from 'yup'

export const addPartnerStructure = {
  name: yup.string().required('Partner name is required'),
  platformId: yup.string(),
}

export const editPartnerStructure = {
  name: yup.string().required('Partner name is required'),
  serviceCharge: yup
    .number()
    .typeError('Service charge must in pence')
    .positive('Service charge must be positive'),
}
