import React from 'react'
import { func, object } from 'prop-types'
import {
  InputGroup,
  FormGroup,
  Intent,
  Card,
  Classes,
  Button,
} from '@blueprintjs/core'
import { Row, Col } from 'react-simple-flex-grid'

const ContactForm = ({ errors, handleSubmit, handleChange, values }) => (
  <form onSubmit={handleSubmit}>
    <Row gutter={24}>
      <Col xs={12} md={6}>
        <Card>
          <h5 className="bp3-heading">Business Contact Details</h5>
          <FormGroup
            label="Contact Name"
            labelFor="businessContactName"
            helperText={errors.businessContactName || ''}
            intent={Intent.DANGER}
          >
            <InputGroup
              name="businessContactName"
              id="businessContactName"
              type="text"
              onChange={handleChange}
              intent={errors.businesContactName ? Intent.DANGER : Intent.NONE}
              value={values.businessContactName || ''}
            />
          </FormGroup>
          <FormGroup
            label="Contact Phone Number"
            labelFor="businessContactPhone"
            helperText={errors.businessContactPhone || ''}
            intent={Intent.DANGER}
          >
            <InputGroup
              name="businessContactPhone"
              id="businessContactPhone"
              type="text"
              onChange={handleChange}
              intent={errors.businesContactPhone ? Intent.DANGER : Intent.NONE}
              value={values.businessContactPhone || ''}
            />
          </FormGroup>
          <FormGroup
            label="Contact Email Address"
            labelFor="businessContactEmail"
            helperText={errors.businessContactEmail || ''}
            intent={Intent.DANGER}
          >
            <InputGroup
              name="businessContactEmail"
              id="businessContactEmail"
              type="text"
              onChange={handleChange}
              intent={errors.businesContactEmail ? Intent.DANGER : Intent.NONE}
              value={values.businessContactEmail || ''}
            />
          </FormGroup>
        </Card>
      </Col>
      <Col xs={12} md={6}>
        <Card>
          <h5 className="bp3-heading">Technical Contact Details</h5>
          <FormGroup
            label="Contact Name"
            labelFor="technicalContactName"
            helperText={errors.technicalContactName || ''}
            intent={Intent.DANGER}
          >
            <InputGroup
              name="technicalContactName"
              id="technicalContactName"
              type="text"
              onChange={handleChange}
              intent={errors.technicalContactName ? Intent.DANGER : Intent.NONE}
              value={values.technicalContactName || ''}
            />
          </FormGroup>
          <FormGroup
            label="Contact Phone Number"
            labelFor="technicalContactPhone"
            helperText={errors.technicalContactPhone || ''}
            intent={Intent.DANGER}
          >
            <InputGroup
              name="technicalContactPhone"
              id="technicalContactPhone"
              type="text"
              onChange={handleChange}
              intent={
                errors.technicalContactPhone ? Intent.DANGER : Intent.NONE
              }
              value={values.technicalContactPhone || ''}
            />
          </FormGroup>
          <FormGroup
            label="Contact Email Address"
            labelFor="technicalContactEmail"
            helperText={errors.technicalContactEmail || ''}
            intent={Intent.DANGER}
          >
            <InputGroup
              name="technicalContactEmail"
              id="technicalContactEmail"
              type="text"
              onChange={handleChange}
              intent={
                errors.technicalContactEmail ? Intent.DANGER : Intent.NONE
              }
              value={values.technicalContactEmail || ''}
            />
          </FormGroup>
        </Card>
      </Col>
    </Row>

    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
      <Button text="Save" intent={Intent.DEFAULT} type="submit" />
    </div>
  </form>
)

ContactForm.propTypes = {
  handleChange: func,
  handleSubmit: func,
  initialValues: object,
  errors: object,
  values: object,
  setFieldValue: func,
  classes: object,
}

export default ContactForm
