'use strict'

import * as yup from 'yup'

export const addFleetStructure = {
  name: yup.string().required('Name is required'),
  partnerId: yup.string().required('Partner is required'),
  email: yup
    .string()
    .email()
    .required('Fleet Admin email is required'),
}

export const addFleetBasicStructure = {
  name: yup.string().required('Fleet name is required'),
  key: yup.string().required('Fleet key is required'),
  defaultJourneyCharge: yup
    .number()
    .required('Journey change is required')
    .positive(),
  defaultJourneyChargeUplift: yup
    .number()
    .required('Journey change  uplift is required')
    .min(0)
    .integer(),
  paymentGatewayCharge: yup
    .number()
    .required('Payment gateway charge is required')
    .positive(),
  paymentGatewayPercentage: yup
    .number()
    .required('Payment gateway percentage is required')
    .positive()
    .max(100),
  fleetCharge: yup
    .number()
    .min(0)
    .required('Fleet charge is required'),
  icabbiAPIUrl: yup
    .string()
    .url()
    .required('API URL required'),
  overrideDefaultPlatformChargeValue: yup
    .number()
    .required('Value is required')
    .min(0),
  icabbiAPIKey: yup.string().required('API key required'),
  icabbiAPISecret: yup.string().required('API secret required'),
  icabbiAccountId: yup.string().required('Airpay account ID required'),
  useSeparateAccountForPrepay: yup.boolean(),
  icabbiPrepayAccountId: yup.string().when('useSeparateAccountForPrepay', {
    is: true,
    then: yup.string().required('Airpay PrePay account ID required'),
    otherwise: yup.string(),
  }),
}

export const addFleetContactStructure = {
  businessContactName: yup.string().nullable(),
  businessContactPhone: yup.string().nullable(),
  businessContactEmail: yup
    .string()
    .email('Business Contact Email must be a valid email')
    .nullable(),

  technicalContactName: yup.string().nullable(),
  technicalContactPhone: yup.string().nullable(),
  technicalContactEmail: yup
    .string()
    .email('Technical Contact Email must be a valid email')
    .nullable(),
}
