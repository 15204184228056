import { HTMLTable, Tag, Card, NonIdealState, Button } from '@blueprintjs/core'
import map from 'lodash/map'
import { object } from 'prop-types'
import React from 'react'
import { Query as CacheQuery } from 'react-apollo'
import { Link } from 'react-router-dom'

import { permissionsAccess } from '@stores/userStore'
import GET_PARTNERS from './queries/getPartners.query'
import OPEN_MODAL from './queries/openModal.query'
import PageLayout from '@components/PageLayout/PageLayout'
import PartnerModal from '@components/PartnerModal/PartnerModal'
import Query from '@components/Query/Query'

const toggleModal = (client, value) =>
  client.writeData({
    data: {
      partnerModal: value,
    },
  })

const Partners = ({ history }) => (
  <CacheQuery query={OPEN_MODAL}>
    {({ data: { partnerModal }, client }) => (
      <PageLayout
        button={{
          text: 'Add Partner',
          onClick: () => toggleModal(client, true),
        }}
        permissions={permissionsAccess('partnerCreate')}
      >
        <Query query={GET_PARTNERS}>
          {({ partners }) => {
            if (partners.length > 0) {
              return (
                <Card className={'bp3-nopad bp3-scrollable'}>
                  <HTMLTable bordered={false} interactive={true}>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Platform</th>
                        <th>Fleets</th>
                      </tr>
                    </thead>
                    <tbody>
                      {partners.map(({ id, name, platform, fleets, key }) => (
                        <tr key={key}>
                          <td>
                            <Link to={`/partners/${id}`}>{name}</Link>
                          </td>
                          <td>{platform.name}</td>
                          <td>{fleets.length}</td>
                        </tr>
                      ))}
                    </tbody>
                  </HTMLTable>
                </Card>
              )
            } else {
              return (
                <NonIdealState
                  title="No partners to display"
                  icon="person"
                  className="bp3-card-non-ideal-state"
                  description="There are currently no partners, all you partners will be displayed here."
                  action={
                    <Button
                      text="Add Fleet"
                      className="bp3-save-button"
                      onClick={() => toggleModal(client, true)}
                    />
                  }
                />
              )
            }
          }}
        </Query>
        <PartnerModal
          modalOpen={partnerModal}
          modalClose={() => toggleModal(client, false)}
          history={history}
        />
      </PageLayout>
    )}
  </CacheQuery>
)

Partners.propTypes = {
  history: object,
}

export default Partners
