import gql from 'graphql-tag'

const EDIT_FLEET = gql`
  mutation fleetUpdateContactDetails(
    $id: ID!
    $businessContactName: String
    $businessContactPhone: String
    $businessContactEmail: String
    $technicalContactName: String
    $technicalContactPhone: String
    $technicalContactEmail: String
  ) {
    fleetUpdateContactDetails(
      where: { id: $id }
      data: {
        businessContactName: $businessContactName
        businessContactPhone: $businessContactPhone
        businessContactEmail: $businessContactEmail
        technicalContactName: $technicalContactName
        technicalContactPhone: $technicalContactPhone
        technicalContactEmail: $technicalContactEmail
      }
    ) {
      id
      name
    }
  }
`

export default EDIT_FLEET
