import React from 'react'
import { Query as ApolloQuery } from 'react-apollo'
import get from 'lodash/get'
import { logout } from '@stores/userStore'

import { errorToast } from '@utils/toast'
import { Spinner, NonIdealState } from '@blueprintjs/core'
import { bool, string, element, node } from 'prop-types'

const Query = ({
  children,
  showLoader = true,
  loaderTitle = '',
  loaderSubTitle = '',
  loaderIcon = <Spinner size={60} value={null} />,
  ...props
}) => {
  return (
    <ApolloQuery {...props}>
      {({ loading, error, data, refetch }) => {
        if (loading) {
          return showLoader ? (
            <Spinner size="30" value={null} />
          ) : loaderTitle ? (
            <NonIdealState
              icon={loaderIcon}
              title={loaderTitle}
              description={loaderSubTitle}
            />
          ) : null
        }
        if (error) {
          const serverErrors = get(error, 'graphQLErrors', [])

          // fallback for errors that weren't returned by the server
          if (!serverErrors.length) {
            errorToast(error.message)
            return null
          }

          // if any of the errors are because of failed authentication, then log the user out
          if (
            serverErrors.some(
              serverError => serverError.code === 'AUTHENTICATION_ERROR'
            )
          ) {
            logout()
            return null
          }

          // loop through custom server errors displaying error toasy
          for (const serverError of serverErrors) {
            errorToast(serverError.message)
          }
          return null
        }
        return children(data, refetch)
      }}
    </ApolloQuery>
  )
}

Query.propTypes = {
  children: node,
  showLoader: bool,
  loaderTitle: string,
  loaderSubTitle: string,
  loaderIcon: element,
}

export default Query
