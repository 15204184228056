import gql from 'graphql-tag'

const OPEN_DRAWER = gql`
  {
    messageDrawer {
      isOpen
      templateType
      id
    }
  }
`
export default OPEN_DRAWER
