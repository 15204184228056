import React from 'react'
import { Card, NonIdealState, Divider } from '@blueprintjs/core'
import capitalize from 'lodash/capitalize'
import injectSheet from 'react-jss'
import { object } from 'prop-types'
import { matchType } from '@utils/types'

import Query from '@components/Query/Query'
import GET_CUSTOMER_DETAILS from './queries/getCustomerDetails.query'
import { styles } from './Customer.styles'

const Details = ({ match, classes }) => (
  <div>
    <Query
      query={GET_CUSTOMER_DETAILS}
      variables={{ id: match.params.customer }}
    >
      {({ customer }) => {
        if (customer) {
          return (
            <Card>
              <div className={classes.container}>
                <h5 className="bp3-heading">{`${capitalize(
                  customer.name || 'Unnamed'
                )}`}</h5>
                <Divider />
                <p>
                  <span className={classes.label}>Customer Id:</span>{' '}
                  {customer.id}
                </p>
                <p>
                  <span className={classes.label}>Phone Number:</span>{' '}
                  {customer.phone}
                </p>
                <p>
                  <span className={classes.label}>
                    International Phone Number:
                  </span>{' '}
                  {customer.e164Phone}
                </p>
                <p>
                  <span className={classes.label}>Partner:</span>{' '}
                  {customer.partner.name}
                </p>
              </div>
            </Card>
          )
        } else {
          return (
            <NonIdealState
              icon="mugshot"
              title="No Customer with this ID found"
              description="We were unable to find details for this customer. Please try again later."
            />
          )
        }
      }}
    </Query>
  </div>
)

Details.propTypes = {
  match: matchType,
  classes: object,
}

export default injectSheet(styles)(Details)
