import { Formik } from 'formik'
import { func, object } from 'prop-types'
import React from 'react'

import Form from './Form'
import Query from '@components/Query/Query'
import GET_ROLES from './queries/getAllRoles.query'

const UserInviteForm = ({ onSubmit, initialValues }) => (
  <Query query={GET_ROLES}>
    {({ roles }) => (
      <Formik onSubmit={onSubmit} initialValues={initialValues}>
        {props => <Form {...props} roles={roles} />}
      </Formik>
    )}
  </Query>
)

UserInviteForm.propTypes = {
  onSubmit: func.isRequired,
  initialValues: object,
}

export default UserInviteForm
