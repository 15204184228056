import React from 'react'
import { array } from 'prop-types'
import { Classes, NavbarGroup } from '@blueprintjs/core'
import { returnCrumbArray } from '@stores/routerStore'
import PartnerCrumbs from '@components/Header/Breadcrumbs/PartnerCrumbs'
import FleetCrumbs from '@components/Header/Breadcrumbs/FleetCrumbs'
import DriverCrumbs from '@components/Header/Breadcrumbs/DriverCrumbs'
import CustomerCrumbs from '@components/Header/Breadcrumbs/CustomerCrumbs'
import Crumb from '@components/Header/Breadcrumbs/Crumb'
import { locationType, productType } from '@utils/types'
import JourneyCrumbs from '@components/Header/Breadcrumbs/JourneyCrumbs'

const DEFAULT_BREADCRUMB = 'Airpay'

const shouldTruncate = () => {
  return returnCrumbArray().length >= 3
}

const DefaultCrumbs = ({ crumbs }) =>
  crumbs.map(crumb => <Crumb key={crumb} text={crumb} link={`/${crumb}`} />)

const DynamicCrumbs = ({ crumbs, product = {} }) => {
  let Crumbs
  switch (product.name) {
    case 'partner':
      Crumbs = PartnerCrumbs
      break
    case 'fleet':
      Crumbs = FleetCrumbs
      break
    case 'customer':
      Crumbs = CustomerCrumbs
      break
    case 'driver':
      Crumbs = DriverCrumbs
      break
    case 'journey':
      Crumbs = JourneyCrumbs
      break
    default:
      Crumbs = DefaultCrumbs
  }

  return <Crumbs crumbs={crumbs} />
}

DynamicCrumbs.propTypes = {
  crumbs: array,
  product: productType,
}

const Breadcrumbs = ({ location: { product } }) => (
  <NavbarGroup>
    <ul className={Classes.BREADCRUMBS}>
      {/* Default Breadcrumb is always present */}
      <Crumb collapsed={shouldTruncate()} text={DEFAULT_BREADCRUMB} link="/" />

      <DynamicCrumbs
        crumbs={returnCrumbArray(product.crumbNo)}
        product={product}
      />
    </ul>
  </NavbarGroup>
)

Breadcrumbs.propTypes = {
  location: locationType,
}

Breadcrumbs.defaultProps = {
  location: {
    product: {},
  },
}

export default Breadcrumbs
