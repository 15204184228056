import gql from 'graphql-tag'

const CREATE_WEBHOOKS = gql`
  mutation webhookCreate(
    $fleetId: String!
    $url: String!
    $name: String!
    $event: String!
    $template: String!
  ) {
    webhookCreate(
      fleetId: $fleetId
      url: $url
      name: $name
      event: $event
      template: $template
    ) {
      id
    }
  }
`

export default CREATE_WEBHOOKS
