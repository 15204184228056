import gql from 'graphql-tag'

const ADD_PARTNER = gql`
  mutation partnerCreate($name: String!, $platformId: String!) {
    partnerCreate(name: $name, platformId: $platformId) {
      id
      key
      name
      platform {
        id
        name
      }
    }
  }
`

export default ADD_PARTNER
