import { Intent } from '@blueprintjs/core'

export const DEFAULT_RECORDS = 50

export const JourneyStatus = {
  BOOKED: Intent.PRIMARY,
  ENROUTE: Intent.PRIMARY,
  COMPLETED: Intent.SUCCESS,
  TRAVELLING: Intent.WARNING,
  EXPIRED: Intent.DANGER,
  CANCELLED: Intent.WARNING,
}

export const GatewayStatus = {
  COMPLETED: Intent.SUCCESS,
  SUCCEEDED: Intent.SUCCESS,
  PENDING: Intent.WARNING,
  ERROR: Intent.DANGER,
  FAILED: Intent.DANGER,
  REFUNDED: Intent.WARNING,
}
