import gql from 'graphql-tag'

const GET_PLATFORM_IDS = gql`
  query {
    platforms {
      id
      name
    }
  }
`

export default GET_PLATFORM_IDS
