import { Button, Classes, Dialog } from '@blueprintjs/core'
import { bool, func, string } from 'prop-types'
import React from 'react'
import { Mutation } from 'react-apollo'

import CLOSE_ACCOUNT_HOLDER from './queries/closeAccountMerchant.mutation'
import GET_ACCOUNT_MERCHANT from '../queries/GetAccountMerchant'

import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'

const CloseMerchantAccount = ({ modalOpen, modalClose, fleetId }) => {
  return (
    <Dialog
      isOpen={modalOpen}
      onClose={modalClose}
      title="Close Merchant Account"
    >
      <Mutation
        mutation={CLOSE_ACCOUNT_HOLDER}
        onError={defaultErrorHandler}
        onCompleted={() => {
          successToast('Successfully closed merchant account'), modalClose()
        }}
        refetchQueries={[
          {
            query: GET_ACCOUNT_MERCHANT,
            variables: { id: fleetId },
          },
        ]}
      >
        {closeAccount => (
          <div className={Classes.DIALOG_BODY}>
            <h5 className="bp3-heading">Confirmation</h5>
            <p>
              A closed account will not process transactions or pay out funds,
              and it may not be reopened. any payments made to a closed account
              will be redirected to the merchant's liable account.
              <br />
              This action can not be undone.
            </p>
            <p>
              <b>Are you sure you want to close this merchant account?</b>
            </p>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                className={Classes.POPOVER_DISMISS}
                text="Cancel"
                onClick={() => modalClose()}
              />

              <Button
                className={Classes.INTENT_DANGER}
                onClick={() => closeAccount({ variables: { id: fleetId } })}
                text="Close"
              />
            </div>
          </div>
        )}
      </Mutation>
    </Dialog>
  )
}

CloseMerchantAccount.propTypes = {
  modalOpen: bool,
  modalClose: func,
  fleetId: string,
}

export default CloseMerchantAccount
