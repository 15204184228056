import React from 'react'
import NumberFormat from 'react-number-format'
import injectSheet from 'react-jss'
import cx from 'classnames'
import { number, func, object, bool } from 'prop-types'
import { styles } from './PercentageInput.styles'

const PercentageInput = ({ value, handleChange, fill, classes, disabled }) => (
  <NumberFormat
    suffix="%"
    value={value}
    onValueChange={values => handleChange(values)}
    className={cx('bp3-input', { [classes.fill]: fill })}
    allowNegative={false}
    allowEmptyFormatting={true}
    isAllowed={values => {
      if (values.floatValue > 100) {
        return false
      } else if (values.floatValue < 0) {
        return false
      } else {
        return true
      }
    }}
    disabled={disabled}
    decimalScale={2}
  />
)

PercentageInput.propTypes = {
  value: number,
  handleChange: func,
  fill: bool,
  classes: object,
  disabled: bool,
}

export default injectSheet(styles)(PercentageInput)
