import gql from 'graphql-tag'

const REQUEST_PASSWORD_RESET = gql`
  mutation requestResetPassword($email: String!) {
    requestResetPassword(email: $email) {
      message
    }
  }
`

export default REQUEST_PASSWORD_RESET
