import {
  FormGroup,
  TextArea,
  Classes,
  Button,
  InputGroup,
  Intent,
} from '@blueprintjs/core'
import map from 'lodash/map'
import { func, object } from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'
import * as Sqrl from 'squirrelly'

import { permissionsAccess } from '@stores/userStore'

const styles = theme => ({
  example: {
    color: theme.colors.disabledText,
  },
})

const MessageDrawer = ({
  values,
  handleChange,
  handleSubmit,
  errors,
  variables,
  classes,
}) => {
  const editableField = () => !permissionsAccess('messageTemplateCreate')
  const validation =
    values.text === '' ? 'undefined' : Sqrl.Render(values.text, variables)

  const countUndefineds = str => (str.match(/undefined/g) || []).length
  const failedValidation =
    countUndefineds(validation) > countUndefineds(values.text)
  const availableVariables = Object.values(variables)

  return (
    values.type && (
      <form onSubmit={handleSubmit}>
        <div className={Classes.DRAWER_BODY}>
          <div className={Classes.DIALOG_BODY}>
            <FormGroup
              label="Type"
              labelFor="type"
              labelInfo="(required)"
              helperText={errors.type || ''}
              intent={Intent.DANGER}
            >
              <InputGroup
                id="type"
                value={values.type}
                name="type"
                type="text"
                onChange={handleChange}
                disabled={true}
                intent={errors.type ? Intent.DANGER : Intent.NONE}
              />
            </FormGroup>
            <p>Available variables:</p>
            <ul>
              {map(availableVariables, variable => (
                <li key={variable}>{variable}</li>
              ))}
            </ul>
            <FormGroup
              label="Message"
              labelFor="Message"
              labelInfo="(required)"
              helperText={failedValidation ? 'Argument is invalid.' : ''}
              intent={Intent.DANGER}
              fill={true}
            >
              <p
                className={classes.example}
              >{`Example: Hello {{customerName}}. Thank you for using {{fleetName}}.`}</p>
              <TextArea
                id="text"
                fill={true}
                style={{ height: '300px' }}
                value={values.text}
                onChange={handleChange}
                disabled={editableField()}
                intent={failedValidation ? Intent.DANGER : Intent.NONE}
              />
            </FormGroup>
          </div>
        </div>
        <div className={Classes.DRAWER_FOOTER}>
          {!editableField() && (
            <Button
              text="Save"
              intent={Intent.DEFAULT}
              type="submit"
              disabled={failedValidation}
            />
          )}
        </div>
      </form>
    )
  )
}

MessageDrawer.propTypes = {
  values: object,
  errors: object,
  handleChange: func,
  handleSubmit: func,
  variables: object,
}

export default injectSheet(styles)(MessageDrawer)
