import gql from 'graphql-tag'

const EDIT_PARTNER = gql`
  mutation partnerUpdate($id: ID!, $name: String, $serviceCharge: Int) {
    partnerUpdate(
      where: { id: $id }
      data: { name: $name, serviceCharge: $serviceCharge }
    ) {
      id
      name
      serviceCharge
    }
  }
`

export default EDIT_PARTNER
