import { Formik } from 'formik'
import { func, object } from 'prop-types'
import React from 'react'

import MessageDrawer from './MessageDrawer'
import GET_MESSAGE_TEMPLATE from './queries/getMessageTemplate.query'
import GET_TEMPLATE_VARIABLES from './queries/getTemplateVariables.query'

import Query from '@components/Query/Query'

const MessageTemplateForm = ({ onSubmit, initialValues }) => {
  if (initialValues.messageId) {
    return (
      <Query
        query={GET_MESSAGE_TEMPLATE}
        variables={{ id: initialValues.messageId }}
      >
        {({ messageTemplate }) => {
          return (
            <Query query={GET_TEMPLATE_VARIABLES}>
              {({ messageTemplateVariables }) => {
                const variables = messageTemplateVariables.reduce(
                  (variablesAccumulator, variableName) => {
                    variablesAccumulator[variableName] = variableName
                    return variablesAccumulator
                  },
                  {}
                )

                return (
                  <Formik onSubmit={onSubmit} initialValues={messageTemplate}>
                    {props => (
                      <MessageDrawer {...props} variables={variables} />
                    )}
                  </Formik>
                )
              }}
            </Query>
          )
        }}
      </Query>
    )
  }
  return (
    <Query query={GET_TEMPLATE_VARIABLES}>
      {({ messageTemplateVariables }) => {
        const variables = messageTemplateVariables.reduce(
          (variablesAccumulator, variableName) => {
            variablesAccumulator[variableName] = variableName
            return variablesAccumulator
          },
          {}
        )

        return (
          <Formik
            onSubmit={onSubmit}
            initialValues={{
              type: initialValues.messageDrawer.templateType,
              text: '',
              fleetId: initialValues.fleetId,
            }}
          >
            {props => <MessageDrawer {...props} variables={variables} />}
          </Formik>
        )
      }}
    </Query>
  )
}

MessageTemplateForm.propTypes = {
  onSubmit: func.isRequired,
  initialValues: object,
}

export default MessageTemplateForm
