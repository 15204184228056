import {
  Intent,
  FormGroup,
  InputGroup,
  Button,
  Classes,
} from '@blueprintjs/core'
import { func, object } from 'prop-types'
import React from 'react'

import PlatformSelect from '@components/PlatformSelect/PlatformSelect.jsx'
import { selectionUpdate } from '@utils/helpers'

const PartnerCreateForm = ({
  handleChange,
  values,
  handleSubmit,
  setFieldValue,
  errors,
}) => (
  <form onSubmit={handleSubmit}>
    <FormGroup
      label="Name"
      labelFor="name"
      intent={Intent.DANGER}
      helperText={errors.name || ''}
    >
      <InputGroup
        name="name"
        id="name"
        type="text"
        onChange={handleChange}
        value={values.name}
        intent={errors.name ? Intent.DANGER : Intent.NONE}
      />
    </FormGroup>

    <FormGroup
      label="Platform"
      labelFor="platformId"
      intent={Intent.DANGER}
      helperText={errors.platformId || ''}
    >
      <PlatformSelect
        values={values}
        platformId={values.platformId}
        intent={errors.platformId ? Intent.DANGER : Intent.NONE}
        onChange={event => {
          const selection = selectionUpdate(event)
          setFieldValue('platformId', selection.value, false)
        }}
      />
    </FormGroup>
    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
      <Button text="Save" type="submit" />
    </div>
  </form>
)

PartnerCreateForm.propTypes = {
  handleChange: func,
  errors: object,
  values: object,
  setFieldValue: func,
  handleSubmit: func,
}

export default PartnerCreateForm
