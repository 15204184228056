import React from 'react'

import Crumb from '@components/Header/Breadcrumbs/Crumb'
import Query from '@components/Query/Query'
import GET_FLEET_NAME from './queries/getFleetName.query'

const isSecondPart = i => i === 1
const isThirdPart = i => i === 2

const FleetCrumbs = ({ crumbs }) => {
  return crumbs.map((crumb, i) => {
    if (isSecondPart(i)) {
      return (
        <Query key={crumb} query={GET_FLEET_NAME} variables={{ id: crumb }}>
          {({ fleet }) => {
            if (!fleet) {
              return null
            }

            return (
              <Crumb key={crumb} text={fleet.name} link={`/fleets/${crumb}`} />
            )
          }}
        </Query>
      )
    }

    if (isThirdPart(i)) {
      return (
        <Crumb
          key={crumb}
          text={crumb}
          link={`/fleets/${crumbs[1]}/${crumb}`}
        />
      )
    }
    return <Crumb key={crumb} text={crumb} />
  })
}

export default FleetCrumbs
