import React, { Component, Fragment } from 'react'
import PageLayout from '@components/PageLayout/PageLayout'
import {
  Button,
  ButtonGroup,
  ControlGroup,
  InputGroup,
  Spinner,
  Tag,
  NonIdealState,
} from '@blueprintjs/core'
import clone from 'lodash/clone'
import debounce from 'lodash/debounce'
import reverse from 'lodash/reverse'

import { Query } from 'react-apollo'
import {
  GET_CUSTOMERS,
  SEARCH_GET_CUSTOMERS,
} from '@components/Customers/queries/getCustomers.query'
import CustomersTable from './CustomersTable'
import PageNumbers from '@components/PageNumbers/PageNumbers'
import { DEFAULT_RECORDS } from '@stores/recordsStore'
import { refreshToast } from '@utils/toast'
import FilterRow from '@components/FilterRow/FilterRow'

class Customers extends Component {
  constructor() {
    super()
    this.state = {
      first: null,
      last: DEFAULT_RECORDS,
      after: null,
      before: null,
      searchValue: '',
      searchCustomer: null,
      currentPage: 1,
      totalRecords: null,
      showRefreshToast: false,
      pageInfo: null,
      totalCount: 0,
    }
  }

  goToNext = (e, nextPage, startCursor) => {
    e.preventDefault()
    nextPage &&
      this.setState(prevState => ({
        first: null,
        last: DEFAULT_RECORDS,
        before: startCursor,
        after: null,
        currentPage: prevState.currentPage + 1,
        showRefreshToast: false,
      }))
  }

  goToPrevious = (e, previousPage, endCursor) => {
    e.preventDefault()
    previousPage &&
      this.setState(prevState => ({
        first: DEFAULT_RECORDS,
        last: null,
        before: null,
        after: endCursor,
        currentPage: prevState.currentPage - 1,
        showRefreshToast: prevState.currentPage - 1 === 1,
      }))
  }

  searchCustomers = e => {
    e.preventDefault()
    const searchValue = e.target.value
    this.setState({ searchValue })

    this.filterQuery(searchValue)
  }

  filterQuery = debounce(searchValue => {
    this.setState({
      searchCustomer: searchValue,
      first: null,
      last: DEFAULT_RECORDS,
      after: null,
      before: null,
      currentPage: 1,
      totalRecords: null,
      showRefreshToast: false,
    })
  }, 300)

  clearSearch = e => {
    e.preventDefault()
    this.setState({
      searchValue: '',
      before: null,
      after: null,
      searchCustomer: null,
      totalRecords: null,
      showRefreshToast: false,
    })
  }

  newRecordsRefresh = refetch => {
    this.setState({ showRefreshToast: false })
    refetch()
  }

  filterBar = () => {
    const { currentPage, totalCount, pageInfo } = this.state
    const totalPages = Math.ceil(totalCount / DEFAULT_RECORDS)
    const hasNextPage = currentPage < totalPages
    const hasPreviousPage = currentPage !== 1
    return (
      <FilterRow>
        <ButtonGroup>
          <ControlGroup>
            <InputGroup
              leftIcon="search"
              id="searchCustomer"
              placeholder="Search Customer..."
              value={this.state.searchValue}
              onChange={this.searchCustomers}
            />
            <Button
              icon="small-cross"
              onClick={e => {
                this.clearSearch(e)
              }}
            />
          </ControlGroup>
        </ButtonGroup>

        <Tag
          disabled={true}
          minimal={true}
          large={true}
          style={{
            marginLeft: 'auto',
          }}
        >
          {totalCount} Customers
        </Tag>

        <ButtonGroup id="navigationGroup">
          <Button
            disabled={!hasPreviousPage || !pageInfo}
            icon="chevron-left"
            onClick={e =>
              this.goToPrevious(e, hasPreviousPage, pageInfo.endCursor)
            }
          >
            Back
          </Button>
          <PageNumbers
            currentPage={currentPage}
            totalPages={totalPages === 0 ? 1 : totalPages}
          />

          <Button
            disabled={!hasNextPage || !pageInfo}
            rightIcon="chevron-right"
            onClick={e => this.goToNext(e, hasNextPage, pageInfo.startCursor)}
          >
            Next
          </Button>
        </ButtonGroup>
      </FilterRow>
    )
  }

  render() {
    let queryToUse = {
      query: GET_CUSTOMERS,
      variables: {
        first: this.state.first,
        last: this.state.last,
        after: this.state.after,
        before: this.state.before,
      },
    }

    this.state.searchCustomer
      ? (queryToUse = {
          query: SEARCH_GET_CUSTOMERS,
          variables: {
            search: this.state.searchCustomer,
          },
        })
      : (queryToUse = {
          query: GET_CUSTOMERS,
          variables: {
            first: this.state.first,
            last: this.state.last,
            after: this.state.after,
            before: this.state.before,
          },
        })
    return (
      <PageLayout>
        <div className="bp3-table-frame">
          {this.filterBar()}
          <Query
            query={queryToUse.query}
            variables={queryToUse.variables}
            fetchPolicy={
              this.state.currentPage === 1 && this.state.showRefreshToast
                ? 'network-only'
                : 'cache-first'
            }
            onCompleted={({ customersConnection, searchCustomers }) => {
              if (customersConnection) {
                if (
                  this.state.totalRecords === null &&
                  this.state.currentPage === 1
                ) {
                  this.setState({
                    totalRecords: customersConnection.totalCount,
                    showRefreshToast: false,
                  })
                }
                this.setState({
                  totalCount: customersConnection.totalCount,
                  pageInfo: customersConnection.pageInfo,
                })
              } else if (searchCustomers) {
                this.setState({
                  totalRecords: searchCustomers.length,
                  totalCount: searchCustomers.length,
                  pageInfo: null,
                })
              }
            }}
          >
            {({ loading, error, data, refetch }) => {
              if (error) {
                return (
                  <NonIdealState
                    icon="mugshot"
                    title="Failed to fetch customers"
                    description="Please try again"
                  />
                )
              }

              if (data.searchCustomers) {
                return (
                  <CustomersTable
                    edges={data.searchCustomers.map(node => ({ node }))}
                  />
                )
              }

              const customersConnection = data.customersConnection
              if (loading) {
                return <Spinner size="30" value={null} />
              } else {
                const edges = reverse(clone(customersConnection.edges))
                if (
                  this.state.showRefreshToast &&
                  this.state.totalRecords !== customersConnection.totalCount
                ) {
                  refreshToast(this.newRecordsRefresh, refetch)
                }

                return (
                  <Fragment>
                    <CustomersTable edges={edges} />
                  </Fragment>
                )
              }
            }}
          </Query>
        </div>
      </PageLayout>
    )
  }
}

export default Customers
