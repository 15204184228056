import gql from 'graphql-tag'

const CANCEL_TRANSACTION = gql`
  mutation cancelTransaction($id: ID!) {
    cancelTransaction(id: $id) {
      id
    }
  }
`

export default CANCEL_TRANSACTION
