import {
  Classes,
  Dialog,
  Button,
  FormGroup,
  HTMLTable,
  Card,
  Callout,
  Intent,
} from '@blueprintjs/core'
import { bool, func, string, number } from 'prop-types'
import React, { useState } from 'react'
import { Mutation } from 'react-apollo'
import { penceToPounds, numberToPence } from '@utils/helpers'
import * as _ from 'lodash'
import { successToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import CurrencyInput from '@components/CurrencyInput/CurrencyInput'
import RECAPTURE_PAYMENT from './queries/recapture.query'
import { useEffect } from 'react'

const RecaptureModal = ({
  modalOpen,
  modalClose,
  fare,
  partnerCharge,
  fleetCharge,
  journeyId,
}) => {
  const [value, setValue] = useState(fare)

  const serviceCharges = partnerCharge + fleetCharge

  useEffect(() => setValue(fare), [fare])

  return (
    <Dialog isOpen={modalOpen} onClose={modalClose} title="Recapture Payment">
      <div className={Classes.DIALOG_BODY}>
        <Mutation
          mutation={RECAPTURE_PAYMENT}
          onError={defaultErrorHandler}
          refetchQueries={[`getJourney`]}
          onCompleted={() => {
            successToast('Recapture Request Sent')
            modalClose()
          }}
        >
          {recapturePayment => (
            <form>
              <Card className={'bp3-nopad'}>
                {!fare ? (
                  <Callout intent={Intent.WARNING}>
                    This journey has no fare set, please enter one
                  </Callout>
                ) : null}

                <HTMLTable bordered>
                  <tr>
                    <td width="200">Final Fare</td>
                    <td>£{penceToPounds(fare)}</td>
                  </tr>
                  <tr>
                    <td width="200">Service Charge</td>
                    <td>£{penceToPounds(serviceCharges) || '0.00'}</td>
                  </tr>
                </HTMLTable>
              </Card>
              <FormGroup
                labelFor="text-input"
                label="Recapture Fare"
                helperText={
                  'Additional fees and charges are calculated at capture.'
                }
              >
                <CurrencyInput
                  name="value"
                  id="value"
                  value={penceToPounds(value || 0)}
                  onChange={e => setValue(numberToPence(e.currentTarget.value))}
                  onBlur={e => setValue(numberToPence(e.currentTarget.value))}
                />
              </FormGroup>
              <br />
              <Button
                text="Recapture"
                icon="refresh"
                onClick={e => {
                  e.preventDefault()
                  recapturePayment({
                    variables: {
                      value,
                      id: journeyId,
                    },
                  })
                }}
                style={{
                  float: 'right',
                }}
              />
            </form>
          )}
        </Mutation>
      </div>
    </Dialog>
  )
}

RecaptureModal.propTypes = {
  fare: number,
  partnerCharge: number,
  fleetCharge: number,
  modalOpen: bool,
  modalClose: func,
  journeyId: string,
}

export default RecaptureModal
