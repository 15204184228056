import { Dialog, Classes } from '@blueprintjs/core'
import { object } from 'prop-types'
import React from 'react'
import { Mutation } from 'react-apollo'

import defaultErrorHandler from '@utils/defaultErrorHandler'
import EDIT_USER from '@components/Users/mutations/editUser.mutation'
import DISABLE_USER from '@components/Users/mutations/disableUser.mutation'
import GET_ALL_USERS from '@components/Users/queries/getAllUsers.query'
import { modalType } from '@utils/types'
import { successToast } from '@utils/toast'
import { toggleModal, setValue } from '@utils/cacheHelpers'
import UserEditForm from '@components/UserEditForm/UserEditForm'

const UpdateUsersModal = ({ modalOpen, modalClose, user }) => (
  <Dialog isOpen={modalOpen} onClose={modalClose} title={'Edit User'}>
    <div className={Classes.DIALOG_BODY}>
      <Mutation
        mutation={DISABLE_USER}
        onError={defaultErrorHandler}
        refetchQueries={[{ query: GET_ALL_USERS }]}
        onCompleted={() => {
          successToast(
            `User successfully ${user.disabled ? 'enabled' : 'disabled'}`
          )
          toggleModal({
            updateUserModal: false,
          })
        }}
      >
        {disableUser => (
          <Mutation
            mutation={EDIT_USER}
            onError={defaultErrorHandler}
            onCompleted={() => {
              successToast('User successfully edited')
              toggleModal({
                updateUserModal: false,
              })
              setValue({
                user: {
                  id: '',
                  firstName: '',
                  lastName: '',
                  email: '',
                  phoneNumber: '',
                  __typename: 'user',
                },
              })
            }}
          >
            {editUser => (
              <UserEditForm
                initialValues={{
                  ...user,
                }}
                onDisable={values => {
                  disableUser({
                    variables: {
                      ...values,
                    },
                  })
                }}
                onSubmit={values => {
                  editUser({
                    variables: {
                      ...values,
                    },
                  })
                }}
              />
            )}
          </Mutation>
        )}
      </Mutation>
    </div>
  </Dialog>
)

UpdateUsersModal.propTypes = {
  ...modalType,
  classes: object,
  user: object,
}

export default UpdateUsersModal
