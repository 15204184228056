import backgroundMap from '@assets/bg-map.png'

const styles = () => ({
  container: {
    display: 'flex',
    backgroundColor: '#010d14',
    backgroundSize: 'cover',
    height: '100%',
    width: '100%',
    backgroundPosition: 'center',
    position: 'relative',
    overflow: 'hidden',
    overflowX: 'hidden',
    zIndex: '0',
    backgroundImage: `url(${backgroundMap})`,
  },
  loginContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
  },
  loginForm: {
    width: 340,
    marginBottom: 20,
    zIndex: '0',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 10,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  airPayContainer: {
    position: 'absolute',
    top: 'calc(50% - 230px)',
    horizontalAlign: 'center',
    alignItems: 'center',
  },
  airPayLogo: {
    transform: 'scale(0.5)',
    paddingBottom: '6px',
  },
  forgotPassword: {
    color: '#FFF',
    '&:hover': {
      color: '#FFF',
    },
  },
})

export default styles
