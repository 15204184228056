import gql from 'graphql-tag'

const GET_DRIVER = gql`
  query getDriver($id: ID!) {
    driver(where: { id: $id }) {
      id
      name
      fleet {
        id
        name
        partner {
          id
          name
        }
      }
      payments {
        id
        isPaid
        transactions {
          id
          journey {
            id
          }
          type
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      journeys {
        id
        customer {
          id
          name
        }
        icabbiTripId
        dispatcherTripCost
        status
        shortId
        createdAt
        updatedAt
      }
      icabbiDriverId
      createdAt
      updatedAt
    }
  }
`

export default GET_DRIVER
