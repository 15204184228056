import gql from 'graphql-tag'

const CREATE_ACCOUNT_HOLDER_INDIVIDUAL = gql`
  mutation fleetCreateAccountHolderIndividual(
    $id: ID!
    $email: String!
    $firstName: String!
    $lastName: String!
    $city: String!
    $country: String!
    $houseNumberOrName: String!
    $postalCode: String!
    $street: String!
    $legalEntity: String!
  ) {
    fleetCreateAccountHolderIndividual(
      where: { id: $id }
      data: {
        accountHolderDetails: {
          email: $email
          individualDetails: {
            name: {
              firstName: $firstName
              lastName: $lastName
              gender: "UNKNOWN"
            }
          }
          address: {
            city: $city
            country: $country
            houseNumberOrName: $houseNumberOrName
            postalCode: $postalCode
            street: $street
          }
        }
        legalEntity: $legalEntity
      }
    )
  }
`

const CREATE_ACCOUNT_HOLDER_BUSINESS = gql`
  mutation fleetCreateAccountHolderBusiness(
    $id: ID!
    $email: String!
    $firstName: String!
    $lastName: String!
    $city: String!
    $country: String!
    $houseNumberOrName: String!
    $postalCode: String!
    $street: String!
    $legalEntity: String!
    $doingBusinessAs: String!
    $legalBusinessName: String!
    $registrationNumber: String!
    $businessEmail: String!
    $shareholdersEmail: String!
    $shareholdersCity: String!
    $shareholdersCountry: String!
    $shareholdersHouseNumberOrName: String!
    $shareholdersPostalCode: String!
    $shareholdersStreet: String!
    $phoneCountryCode: String!
    $phoneNumber: String!
  ) {
    fleetCreateAccountHolderBusiness(
      where: { id: $id }
      data: {
        accountHolderDetails: {
          email: $email
          address: {
            city: $city
            country: $country
            houseNumberOrName: $houseNumberOrName
            postalCode: $postalCode
            street: $street
          }
          businessDetails: {
            doingBusinessAs: $doingBusinessAs
            legalBusinessName: $legalBusinessName
            registrationNumber: $registrationNumber
            email: $businessEmail
            shareholders: {
              name: {
                firstName: $firstName
                lastName: $lastName
                gender: "UNKNOWN"
              }
              email: $shareholdersEmail
              address: {
                city: $shareholdersCity
                country: $shareholdersCountry
                houseNumberOrName: $shareholdersHouseNumberOrName
                postalCode: $shareholdersPostalCode
                street: $shareholdersStreet
              }
              phoneNumber: {
                phoneCountryCode: $phoneCountryCode
                phoneNumber: $phoneNumber
              }
            }
          }
        }
        legalEntity: $legalEntity
        processingTier: 3
      }
    )
  }
`

export { CREATE_ACCOUNT_HOLDER_INDIVIDUAL, CREATE_ACCOUNT_HOLDER_BUSINESS }
