import React, { useState, useCallback } from 'react'

import Query from '@components/Query/Query'
import FAILED_TRANSACTIONS from '@components/FailedTransactions/queries/failedTransactions.query'
import FilterRow from '@components/FilterRow/FilterRow'
import { Tag, ButtonGroup, Button, Card, HTMLTable } from '@blueprintjs/core'
import { DEFAULT_RECORDS } from '@stores/recordsStore'
import { map } from 'lodash'
import PageNumbers from '@components/PageNumbers/PageNumbers'
import FailedTransactionRow from '@components/FailedTransactions/FailedTransactionRow'
import CompleteModal from '@components/Journey/CompleteModal'
import RecaptureModal from '@components/Journey/RecaptureModal'
import RefundModal from '@components/Journey/RefundModal'
import CancelModal from '@components/Journey/CancelModal'
import { authorizedRole } from '@stores/userStore'
import { FleetFilter } from '@components/Toolbar'
import { UrlQueryParamTypes, addUrlProps } from 'react-url-query'
import { string, func } from 'prop-types'

const isSuperAdmin = () => {
  return authorizedRole('SUPERADMIN_ROLE')
}

const urlPropsQueryConfig = {
  fleetId: { type: UrlQueryParamTypes.string },
}

const FailedTransactions = ({ fleetId = null, onChangeFleetId }) => {
  const [paginationArgs, setPaginationArgs] = useState({
    first: DEFAULT_RECORDS,
    last: null,
    before: null,
    after: null,
  })
  const [currentPage, setCurrentPage] = useState(1)
  const [modals, setModals] = useState({
    cancel: { open: false },
    complete: { open: false },
    recapture: { open: false },
    refund: { open: false },
  })

  const updateModal = useCallback(
    (modalName, newState) => setModals({ ...modals, [modalName]: newState }),
    [modals]
  )

  return (
    <div className="bp3-table-frame">
      <Query
        query={FAILED_TRANSACTIONS}
        variables={{ ...paginationArgs, fleetId: fleetId || undefined }}
      >
        {({ failedTransactions }) => {
          const totalPages = Math.ceil(
            failedTransactions.totalCount / DEFAULT_RECORDS
          )

          return (
            <div className="bp3-layout-row">
              <div className="bp3-table-frame">
                <FilterRow>
                  {isSuperAdmin() && (
                    <FleetFilter
                      fleetId={fleetId}
                      onChange={id => {
                        onChangeFleetId(id)
                      }}
                    />
                  )}
                  <Tag
                    minimal={true}
                    large={true}
                    style={{
                      marginLeft: 'auto',
                    }}
                  >
                    {failedTransactions.totalCount} Transactions
                  </Tag>

                  <ButtonGroup id="navigationGroup">
                    <Button
                      disabled={!failedTransactions.pageInfo.hasPreviousPage}
                      icon="chevron-left"
                      onClick={() => {
                        setPaginationArgs({
                          last: DEFAULT_RECORDS,
                          before: failedTransactions.pageInfo.startCursor,
                          after: null,
                          first: null,
                        })
                        setCurrentPage(currentPage - 1)
                      }}
                    >
                      Back
                    </Button>
                    <PageNumbers
                      currentPage={currentPage}
                      totalPages={totalPages === 0 ? 1 : totalPages}
                    />
                    <Button
                      disabled={!failedTransactions.pageInfo.hasNextPage}
                      rightIcon="chevron-right"
                      onClick={() => {
                        setPaginationArgs({
                          last: null,
                          before: null,
                          after: failedTransactions.pageInfo.endCursor,
                          first: DEFAULT_RECORDS,
                        })
                        setCurrentPage(currentPage + 1)
                      }}
                    >
                      Next
                    </Button>
                  </ButtonGroup>
                </FilterRow>

                <div className="bp3-table-container">
                  <Card className="bp3-scrollable bp3-noPad">
                    <HTMLTable bordered={false} interactive={true}>
                      <thead>
                        <tr>
                          <th>Trip</th>
                          <th>Transaction</th>
                          <th>Customer</th>
                          <th>Charge</th>
                          <th>Transaction Status</th>
                          <th>Journey</th>
                          <th>Gateway</th>
                          <th>Processor</th>
                          <th>Capture Attempts</th>
                          <th>Last Attempt</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {map(failedTransactions.edges, ({ node }) => (
                          <FailedTransactionRow
                            updateModal={updateModal}
                            transaction={node}
                          />
                        ))}
                      </tbody>
                    </HTMLTable>
                  </Card>
                </div>
              </div>
            </div>
          )
        }}
      </Query>

      <CancelModal
        modalOpen={modals.cancel.open}
        modalClose={() => updateModal('cancel', { open: false })}
        journeyId={modals.cancel.id}
      />
      <CompleteModal
        modalOpen={modals.complete.open}
        journeyId={modals.complete.id}
        modalClose={() => updateModal('complete', { open: false })}
      />

      <RecaptureModal
        modalOpen={modals.recapture.open}
        modalClose={() => updateModal('recapture', { open: false })}
        journeyId={modals.recapture.id}
        fare={modals.recapture.fare}
        fleetCharge={modals.recapture.fleetCharge}
        partnerCharge={modals.recapture.partnerCharge}
      />
      <RefundModal
        modalOpen={modals.refund.open}
        modalClose={() => updateModal('refund', { open: false })}
        journeyId={modals.refund.id}
      />
    </div>
  )
}

FailedTransactions.propTypes = {
  fleetId: string,
  onChangeFleetId: func,
}

export default addUrlProps({ urlPropsQueryConfig })(FailedTransactions)
