import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import { ApolloLink } from 'apollo-link'
import { withClientState } from 'apollo-link-state'
import gql from 'graphql-tag'
import fetch from 'unfetch'
import merge from 'lodash/merge'

import ls from '@utils/localStorage'
import {
  modals,
  menu,
  login,
  users,
  marketplace,
  transactions,
  discount,
  orders,
} from './state'
import config from '@config/config'

const cache = new InMemoryCache()

const unsubscribe = async () => {
  await client.clearStore()
  clearState()
}

const clearState = () => stateLink.writeDefaults()

const httpLink = createHttpLink({
  uri: `${config.apiUrl}/gql`,
  fetch,
})

const stateLink = withClientState({
  cache,
  ...merge(
    modals,
    menu,
    login,
    users,
    marketplace,
    transactions,
    discount,
    orders
  ),
})

const omitTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    )
  }
  return forward(operation)
})

function omitTypename(key, value) {
  return key === '__typename' ? undefined : value
}

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = ls.get('jwt')
  const partialToken = ls.get('partialJwt')
  if (token || partialToken) {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${token || partialToken}`,
      },
    })
  }

  return forward(operation)
})

const link = ApolloLink.from([
  omitTypenameLink,
  authMiddleware,
  stateLink,
  httpLink,
])

const client = new ApolloClient({
  link,
  cache,
})

if (process.env.NODE_ENV === 'development') {
  client.initQueryManager() // allow devtools
}

export { client, gql, unsubscribe, clearState }
