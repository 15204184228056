import React from 'react'
import injectSheet from 'react-jss'
import { object, array } from 'prop-types'
import map from 'lodash/map'
import { Tabs, Tab } from '@blueprintjs/core'
import { matchType } from '@utils/types'
import { history } from '@stores/routerStore'
import cx from 'classnames'
import last from 'lodash/last'
import PerfectScrollBar from '@components/PerfectScrollBar/PerfectScrollBar'

const styles = ({ colors }) => ({
  container: {
    top: 48,
    zIndex: 2,
    height: 48,
    width: '100%',
    background: colors.tabBarBG,
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
  },
  scrollBar: {
    display: 'flex',
    width: '100%',
  },
  tabs: {
    display: 'flex',
    alignItems: 'center',
    zIndex: 1,
    background: colors.tabBarBG,
    marginLeft: '72px',

    '& .bp3-tab-indicator': {
      bottom: '-9px',
    },
  },
  tab: {
    color: `${colors.tabBarTabText} !important`,
    padding: '0 5px',
    '&:hover': {
      color: colors.tabBarTabHoverText,
    },
  },
  activeTab: {
    color: `${colors.tabBarTabActiveText} !important`,
    '&:not(.activeNavLink):hover': {
      color: `${colors.tabBarTabActiveText} !important`,
    },
  },
})

const activePath = name => history.location.pathname.endsWith(name)

const selectedTabId = () => last(history.location.pathname.split('/'))

const TabBar = ({ classes, tabs, match }) => (
  <div className={classes.container}>
    <PerfectScrollBar
      options={{
        className: classes.scrollBar,
        options: { suppressScrollY: true },
      }}
    >
      <Tabs
        className={classes.tabs}
        onChange={route => history.push(`${match.url}/${route}`)}
        selectedTabId={selectedTabId()}
      >
        {map(tabs, ({ to, name }) => (
          <Tab
            key={to}
            id={to}
            title={name}
            className={cx(classes.tab, {
              [classes.activeTab]: activePath(to),
            })}
          />
        ))}
      </Tabs>
    </PerfectScrollBar>
  </div>
)

TabBar.propTypes = {
  classes: object,
  tabs: array,
  match: matchType,
}

export default injectSheet(styles)(TabBar)
