import gql from 'graphql-tag'

const OPEN_MODAL = gql`
  {
    recaptureModal @client
    refundModal @client
    completeModal @client
    cancelModal @client
  }
`
export default OPEN_MODAL
