import gql from 'graphql-tag'

const GET_FLEET_PAYOUTS = gql`
  query getFleetPayouts($id: ID!) {
    payouts(where: { fleet: { id: $id } }) {
      id
      ref
      amount
      currency
      estimatedArrivalDate
      statusCode
      eventDate
      createdAt
    }
  }
`

export default GET_FLEET_PAYOUTS
