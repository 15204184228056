import gql from 'graphql-tag'

const RESEND_SMS = gql`
  mutation resendSMS($id: ID!) {
    resendSMS(id: $id) {
      id
    }
  }
`

export default RESEND_SMS
