import React from 'react'
import { Dialog, Classes, Button, Intent } from '@blueprintjs/core'
import { Mutation } from 'react-apollo'
import { Fragment } from 'react'
import CANCEL_TRANSACTION from './queries/cancelTransaction.query'
import { successToast } from '../../utils/toast'
import defaultErrorHandler from '../../utils/defaultErrorHandler'
import { bool } from 'prop-types'
import { func } from 'prop-types'
import { string } from 'prop-types'

const CompleteModal = ({ modalOpen, modalClose, journeyId }) => (
  <Dialog isOpen={modalOpen} onClose={modalClose} title="Complete Journey">
    <div className={Classes.DIALOG_BODY}>
      <Mutation
        mutation={CANCEL_TRANSACTION}
        variables={{ id: journeyId }}
        onCompleted={() => {
          successToast('Transaction cancelled')
          modalClose()
        }}
        onError={defaultErrorHandler}
      >
        {completeAndCapture => (
          <Fragment>
            <p>Are you sure you want to cancel this transaction?</p>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={() => modalClose()}>Close</Button>
              <Button
                onClick={() => completeAndCapture()}
                intent={Intent.PRIMARY}
              >
                Cancel Transaction
              </Button>
            </div>
          </Fragment>
        )}
      </Mutation>
    </div>
  </Dialog>
)

CompleteModal.propTypes = {
  modalOpen: bool,
  modalClose: func,
  journeyId: string,
}

export default CompleteModal
