import gql from 'graphql-tag'

const GET_TRANSACTIONS = gql`
  query(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $createdAt_lte: DateTime
    $createdAt_gte: DateTime
    $fleetId: ID
  ) {
    transactionsConnection(
      first: $first
      last: $last
      after: $after
      before: $before
      where: {
        createdAt_gte: $createdAt_gte
        createdAt_lte: $createdAt_lte
        fleetId: $fleetId
      }
    ) {
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          id
          journey {
            id
            shortId
            siteId
            driver {
              id
              name
              fleet {
                id
                name
              }
            }
            customer {
              id
              name
            }
          }
          createdAt
          type
          gatewayStatus
          orderNet
          preauthTotal
          orderGross
          partnerChargeGross
          grandTotal
          vatTotal
          processingFee
          fleetPayout
          fleetChargePayout
          platformPayout
          partnerVATPayout
          partnerPayout
          paymentRef
          journeyIdSnapshot
          tripIdSnapshot
          driverIdSnapshot
          customerIdSnapshot
          fleetNameSnapshot
          actualProcessingFee
          processFeeDifference
          amountRetainedFromTransaction
          divisibleTotal
          partnerPayoutAfterPlatformFee
          refundedTotal
          payoutRef
        }
        cursor
      }
    }
  }
`

const SEARCH_GET_TRANSACTIONS_ID = gql`
  query(
    $searchValue: ID
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    transactionsConnection(
      first: $first
      last: $last
      before: $before
      after: $after
      where: { id: $searchValue }
    ) {
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          id
          journey {
            id
            shortId
            siteId
            driver {
              id
              name
              fleet {
                id
                name
              }
            }
            customer {
              id
              name
            }
          }
          createdAt
          type
          gatewayStatus
          orderNet
          preauthTotal
          orderGross
          partnerChargeGross
          grandTotal
          vatTotal
          processingFee
          fleetPayout
          fleetChargePayout
          platformPayout
          partnerVATPayout
          partnerPayout
          paymentRef
          journeyIdSnapshot
          tripIdSnapshot
          driverIdSnapshot
          customerIdSnapshot
          fleetNameSnapshot
          actualProcessingFee
          processFeeDifference
          amountRetainedFromTransaction
          divisibleTotal
          partnerPayoutAfterPlatformFee
          refundedTotal
          payoutRef
        }
        cursor
      }
    }
  }
`

const SEARCH_GET_TRANSACTIONS_PAYMENT = gql`
  query(
    $searchValue: String
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    transactionsConnection(
      first: $first
      last: $last
      before: $before
      after: $after
      where: { paymentRef: $searchValue }
    ) {
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          id
          journey {
            id
            shortId
            siteId
            driver {
              id
              name
              fleet {
                id
                name
              }
            }
            customer {
              id
              name
            }
          }
          createdAt
          type
          gatewayStatus
          orderNet
          preauthTotal
          orderGross
          partnerChargeGross
          grandTotal
          vatTotal
          processingFee
          fleetPayout
          fleetChargePayout
          platformPayout
          partnerVATPayout
          partnerPayout
          paymentRef
          journeyIdSnapshot
          tripIdSnapshot
          driverIdSnapshot
          customerIdSnapshot
          fleetNameSnapshot
          actualProcessingFee
          processFeeDifference
          amountRetainedFromTransaction
          divisibleTotal
          partnerPayoutAfterPlatformFee
          refundedTotal
          payoutRef
        }
        cursor
      }
    }
  }
`

const SEARCH_GET_TRANSACTIONS_PAYOUT = gql`
  query(
    $searchValue: String
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    transactionsConnection(
      first: $first
      last: $last
      before: $before
      after: $after
      where: { payoutRef: $searchValue }
    ) {
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          id
          journey {
            id
            shortId
            siteId
            driver {
              id
              name
              fleet {
                id
                name
              }
            }
            customer {
              id
              name
            }
          }
          createdAt
          type
          gatewayStatus
          orderNet
          preauthTotal
          orderGross
          partnerChargeGross
          grandTotal
          vatTotal
          processingFee
          fleetPayout
          fleetChargePayout
          platformPayout
          partnerVATPayout
          partnerPayout
          paymentRef
          journeyIdSnapshot
          tripIdSnapshot
          driverIdSnapshot
          customerIdSnapshot
          fleetNameSnapshot
          actualProcessingFee
          processFeeDifference
          amountRetainedFromTransaction
          divisibleTotal
          partnerPayoutAfterPlatformFee
          refundedTotal
          payoutRef
        }
        cursor
      }
    }
  }
`

export {
  GET_TRANSACTIONS,
  SEARCH_GET_TRANSACTIONS_ID,
  SEARCH_GET_TRANSACTIONS_PAYMENT,
  SEARCH_GET_TRANSACTIONS_PAYOUT,
}
