import React from 'react'
import { Switch, Route, Redirect } from 'react-router'
import { object } from 'prop-types'

import Details from '@components/Partner/Details/Details'
import { matchType } from '@utils/types'
import PageLayout from '@components/PageLayout/PageLayout'

const Partner = ({ match, location }) => (
  <PageLayout
    match={match}
    location={location}
    tabs={[{ to: 'details', name: 'Details' }]}
  >
    <section>
      <Switch location={location}>
        <Redirect
          from="/partners/:partner"
          to="/partners/:partner/details"
          exact={true}
        />
        <Route
          path={`${match.path}/details`}
          exact={true}
          render={() => <Details partnerId={match.params.partner} />}
        />
      </Switch>
    </section>
  </PageLayout>
)

Partner.propTypes = {
  match: matchType.isRequired,
  classes: object,
  location: object,
}

export default Partner
