import gql from 'graphql-tag'

const VALIDATE_PHONE = gql`
  mutation validatePhone {
    validatePhone {
      obfuscatedPhoneNumber
    }
  }
`

export default VALIDATE_PHONE
