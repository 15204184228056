const users = {
  defaults: {
    user: {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      phoneNumber: '',
      disabled: '',
      role: {
        title: '',
        id: '',
        __typename: 'role',
      },
      ownsFleets: [],
      __typename: 'user',
    },
  },
}

export default users
