import React from 'react'
import { string, bool, func, object } from 'prop-types'
import { Route } from 'react-router-dom'
import { NoPermissions } from '@components/UI/Permission/Permission'
import { productType } from '@utils/types'

const CustomRoute = ({
  path,
  exact = false,
  component: Component,
  isPermissionAllowed = () => true,
  product,
}) => (
  <Route
    render={({ location, ...props }) =>
      isPermissionAllowed() ? (
        <Component location={{ ...location, product }} {...props} />
      ) : (
        <NoPermissions {...props} />
      )
    }
    path={path}
    exact={exact}
  />
)

CustomRoute.propTypes = {
  path: string,
  exact: bool,
  component: func,
  render: func,
  isPermissionAllowed: func,
  location: object,
  product: productType,
}

export default CustomRoute
