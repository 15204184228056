const selectConfig = () => {
  return {
    apiUrl: process.env.API_URL || 'http://localhost:3011',
    customerUrl: process.env.CUSTOMER_URL || 'http://localhost:8989',
    journeyLogsApiUrl:
      process.env.JOURNEY_LOGS_API_URL ||
      'https://staging-airpay-journey-logs.air-pay.co.uk',
  }
}

const config = selectConfig()

module.exports = config
