import gql from 'graphql-tag'

const GET_ICABBI_TRIP_ID = gql`
  query getiCabbiTripId($id: ID!) {
    journey(where: { id: $id }) {
      id
      icabbiTripId
    }
  }
`

export default GET_ICABBI_TRIP_ID
