import React from 'react'
import { HTMLSelect } from '@blueprintjs/core'
import map from 'lodash/map'
import { string, func, bool } from 'prop-types'

import Query from '@components/Query/Query'
import GET_PARTNER_IDS from './queries/getPartnerIds.query'

const PartnerSelect = ({ partnerId, disabled, onChange }) => (
  <Query query={GET_PARTNER_IDS}>
    {({ partners }) => (
      <div>
        <HTMLSelect
          name="partnerId"
          fill="{true}"
          options={[
            { value: '', label: 'Select partner...' },
            ...map(partners, ({ id, name }) => ({
              value: id,
              label: name,
            })),
          ]}
          value={partnerId}
          disabled={disabled}
          onChange={event => onChange(event)}
        />
      </div>
    )}
  </Query>
)

PartnerSelect.propTypes = {
  partnerId: string,
  disabled: bool,
  onChange: func,
}

export default PartnerSelect
