import {
  Button,
  Card,
  NonIdealState,
  FormGroup,
  Intent,
  Tag,
} from '@blueprintjs/core'
import { string } from 'prop-types'
import { startCase } from 'lodash'
import React, { Fragment } from 'react'
import { Query as CacheQuery, Mutation } from 'react-apollo'
import GET_FLEET_FROM_DB from './queries/getFleetFromDB.query'
import GET_ACCOUNT_MERCHANT from './queries/GetAccountMerchant'
import OPEN_MODAL from './queries/openAccountMerchantModal.query'
import UpdateMerchantAccount from './UpdateMerchantaccount/UpdateMerchantAccount'
import Query from '@components/Query/Query'
import { getVariables } from './UpdateMerchantaccount/UpdateMerchantAccountVariables'
import AccountMerchantModal from './CreateAccountMerchant/AccountMerchantModal'
import {
  UPDATE_MERCHANT_ACCOUNT_BUSINESS,
  UPDATE_MERCHANT_ACCOUNT_INDIVIDUAL,
} from './queries/updateAccountMerchant.mutation'

import { permissionsAccess } from '@stores/userStore'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import OpenHOCButton from '@components/Fleet/MerchantAccount/OpenHOCButton'

const toggleModal = (client, value) =>
  client.writeData({
    data: {
      accountMerchantModal: value,
    },
  })

const kycTagIntentMap = {
  PASSED: Intent.SUCCESS,
  DATA_PROVIDED: Intent.PRIMARY,
  PENDING: Intent.PRIMARY,
  AWAITING_DATA: Intent.WARNING,
  RETRY_LIMIT_REACHED: Intent.WARNING,
  INVALID_DATA: Intent.WARNING,
  FAILED: Intent.WARNING,
}

const KYCTag = ({ status }) => (
  <Tag intent={kycTagIntentMap[status] || Intent.NONE}>
    {startCase((status || '').toLowerCase())}
  </Tag>
)

const MerchantAccount = ({ fleetId }) => {
  return (
    <Query query={GET_FLEET_FROM_DB} variables={{ id: fleetId }}>
      {fleetFromDB => {
        return (
          <CacheQuery query={OPEN_MODAL}>
            {({ data: { accountMerchantModal }, client }) => (
              <div className="bp3-layout-row">
                <Query query={GET_ACCOUNT_MERCHANT} variables={{ id: fleetId }}>
                  {({ fleet }) => {
                    if (fleet.accountMerchantStatus === 'NOT_STARTED') {
                      return (
                        <NonIdealState
                          icon="new-person"
                          title={`No Account Merchant assigned to ${
                            fleet.name
                          }`}
                          description="There is currently no Merchant Account assigned to this fleet"
                          action={
                            permissionsAccess(
                              'fleetCreateAccountHolderIndividual'
                            ) && (
                              <Button
                                text="Add Account Merchant"
                                className="bp3-save-button"
                                onClick={() => toggleModal(client, true)}
                              />
                            )
                          }
                        />
                      )
                    } else if (fleet.accountMerchantStatus === 'REQUEST_SENT') {
                      return (
                        <NonIdealState
                          icon="updated"
                          title={`We're setting up your merchant account now.`}
                          description={`This could take a couple of minutes. Please check back shortly`}
                          action={null}
                        />
                      )
                    }

                    return (
                      <Fragment>
                        <Card className="bp3-layout-col">
                          <Mutation
                            mutation={UPDATE_MERCHANT_ACCOUNT_INDIVIDUAL}
                            onError={defaultErrorHandler}
                            onCompleted={() =>
                              successToast('Successfully edited driver details')
                            }
                            refetchQueries={[
                              {
                                query: GET_ACCOUNT_MERCHANT,
                                variables: { id: fleetId },
                              },
                            ]}
                          >
                            {updateMerchantAccountIndividual => (
                              <Mutation
                                mutation={UPDATE_MERCHANT_ACCOUNT_BUSINESS}
                                onError={defaultErrorHandler}
                                onCompleted={() =>
                                  successToast(
                                    'Successfully saved account details'
                                  )
                                }
                                refetchQueries={[
                                  {
                                    query: GET_ACCOUNT_MERCHANT,
                                    variables: { id: fleetId },
                                  },
                                ]}
                              >
                                {updateMerchantAccountBusiness => (
                                  <UpdateMerchantAccount
                                    onSubmit={values => {
                                      const variables = getVariables(
                                        values,
                                        fleet
                                      )
                                      if (values.legalEntity === 'Business') {
                                        updateMerchantAccountBusiness({
                                          variables: {
                                            ...variables,
                                          },
                                        })
                                      }
                                      if (values.legalEntity === 'Individual') {
                                        updateMerchantAccountIndividual({
                                          variables: {
                                            ...variables,
                                          },
                                        })
                                      }
                                    }}
                                    initialValues={{
                                      ...fleet.accountHolder,
                                      accountMerchantStatus:
                                        fleet.accountMerchantStatus,
                                      fleetId,
                                    }}
                                  />
                                )}
                              </Mutation>
                            )}
                          </Mutation>
                        </Card>
                        <div className="bp3-layout-col-side">
                          <FormGroup helperText="You need to provide additional details to our payment provider to allow them to process payouts.">
                            <OpenHOCButton fleetId={fleet.id} />
                          </FormGroup>

                          <FormGroup label="Bank Account Verification">
                            <KYCTag status={fleet.bankAccountVerification} />
                          </FormGroup>
                          <FormGroup label="Passport Verification">
                            <KYCTag status={fleet.passportVerification} />
                          </FormGroup>
                          <FormGroup label="Identity Verification">
                            <KYCTag status={fleet.identityVerification} />
                          </FormGroup>
                          <FormGroup label="Company Verification">
                            <KYCTag status={fleet.companyVerification} />
                          </FormGroup>
                        </div>
                      </Fragment>
                    )
                  }}
                </Query>

                <AccountMerchantModal
                  modalOpen={accountMerchantModal}
                  modalClose={() => toggleModal(client, false)}
                  fleetId={fleetId}
                />
              </div>
            )}
          </CacheQuery>
        )
      }}
    </Query>
  )
}

MerchantAccount.propTypes = {
  fleetId: string.isRequired,
}

export default MerchantAccount
