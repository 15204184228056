import {
  Classes,
  Button,
  Intent,
  FormGroup,
  InputGroup,
  NumericInput,
} from '@blueprintjs/core'
import React, { Component } from 'react'
import { func, object } from 'prop-types'

import { permissionsAccess } from '@stores/userStore'
class Form extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  editableField = () => {
    return !permissionsAccess('driverUpdate')
  }
  render() {
    const {
      handleChange,
      handleSubmit,
      errors,
      values,
      setFieldValue,
    } = this.props
    return (
      <form onSubmit={handleSubmit}>
        <FormGroup
          label="Driver Name"
          labelInfo="(required)"
          labelFor="name"
          helperText={errors.name || ''}
          intent={Intent.DANGER}
        >
          <InputGroup
            name="name"
            id="name"
            large={true}
            type="text"
            value={values.name}
            onChange={handleChange}
            intent={errors.name ? Intent.DANGER : Intent.NONE}
            placeholder={values.name}
            disabled={this.editableField()}
            fill={false}
          />
        </FormGroup>
        <FormGroup
          label="Partner"
          labelInfo="(required)"
          labelFor="partner"
          style={{ marginBottom: 5 }}
          helperText={errors.cuisines || ''}
          intent={Intent.DANGER}
        >
          <InputGroup
            name="partner"
            id="partner"
            type="text"
            onChange={handleChange}
            value={values.fleet.partner.name}
            disabled
          />
        </FormGroup>

        <FormGroup
          label="Driver ID"
          labelFor="id"
          style={{ marginBottom: 5 }}
          helperText={errors.cuisines || ''}
          intent={Intent.DANGER}
        >
          <InputGroup
            name="id"
            id="id"
            type="text"
            intent={errors.name ? Intent.DANGER : Intent.NONE}
            value={values.id}
            disabled
          />
        </FormGroup>
        <FormGroup
          label="Icabbi ID"
          labelFor="icabbiDriverId"
          style={{ marginBottom: 10 }}
          helperText={errors.icabbiDriverId || ''}
          intent={Intent.DANGER}
        >
          <NumericInput
            name="icabbiDriverId"
            id="icabbiDriverId"
            disabled={this.editableField()}
            min="0"
            value={values.icabbiDriverId}
            fill={true}
            onValueChange={value => {
              setFieldValue('icabbiDriverId', value)
            }}
            intent={errors.icabbiDriverId ? Intent.DANGER : Intent.NONE}
          />
        </FormGroup>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {!this.editableField() && (
            <Button text="Save" intent={Intent.DEFAULT} type="submit" />
          )}
        </div>
      </form>
    )
  }
}

Form.propTypes = {
  handleChange: func,
  handleSubmit: func,
  initialValues: object,
  errors: object,
  values: object,
  setFieldValue: func,
}

export default Form
