'use string'

import * as yup from 'yup'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const addAccountMerchantStructure = {
  email: yup
    .string()
    .required('Account holder email is required')
    .email('Must be in email format, example@mail.com'),
  houseNumberOrName: yup.string().required('House number or name is required'),
  street: yup.string().required('Street name is required'),
  city: yup.string().required('City name is required'),
  postalCode: yup.string().required('Post code is required'),
  country: yup.string().required('Country is required'),
  legalEntity: yup.string().required('Legal entity is required'),
  firstName: yup.string().required('First name is required'),
  accepttcs: yup
    .boolean()
    .required('Please accept these terms and conditions before proceeding'),
  lastName: yup
    .string()
    .required('Last name is required')
    .max(20, 'Last name cannot be longer then 20 characters'),
  businessEmail: yup
    .string()
    .required('Account holder email is required')
    .email('Must be in email format, example@mail.com'),
  legalBusinessName: yup.string().required('Legal business name is required'),
  doingBusinessAs: yup.string().required('Business name is required'),
  registrationNumber: yup
    .string()
    .required('Business registration number is required'),
  shareholdersEmail: yup
    .string()
    .required('Shareholder email is required')
    .email('Must be in email format, example@mail.com'),
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Phone number is required'),
  shareholdersHouseNumberOrName: yup
    .string()
    .required('House number or name is required'),
  shareholdersStreet: yup.string().required('Street name is required'),
  shareholdersCity: yup.string().required('City name is required'),
  shareholdersPostalCode: yup.string().required('Post code is required'),
  shareholdersCountry: yup.string().required('Country is required'),
}

export const updateAccountMerchantStructure = {
  accountHolderDetails: yup.object().shape({
    email: yup
      .string()
      .required('Account holder email is required')
      .email('Must be in email format, example@mail.com'),
    phoneNumber: yup.object().shape({
      phoneNumber: yup
        .string()
        .matches(phoneRegExp, 'Phone number is not valid'),
    }),
    address: yup.object().shape({
      houseNumberOrName: yup
        .string()
        .required('House number or name is required'),
      street: yup.string().required('Street name is required'),
      postalCode: yup.string().required('Post code is required'),
      city: yup.string().required('City is required'),
      country: yup.string().required('Country is required'),
    }),
    businessDetails: yup.object().shape({
      registrationNumber: yup.string().required('Company Number is required'),
      doingBusinessAs: yup.string().required('Business name is required'),
      legalBusinessName: yup
        .string()
        .required('Legal Business Name is required'),

      shareholders: yup.array().of(
        yup.object().shape({
          address: yup.object().shape({
            houseNumberOrName: yup
              .string()
              .required('House number or name is required'),
            street: yup.string().required('Street name is required'),
            postalCode: yup.string().required('Post code is required'),
            city: yup.string().required('City is required'),
            country: yup.string().required('Country is required'),
          }),
          email: yup
            .string()
            .required('Business email is required')
            .email('Must be in email format, example@mail.com'),
          name: yup.object().shape({
            firstName: yup.string().required('First name is required'),
            lastName: yup.string().required('Last name is required'),
          }),
        })
      ),
    }),
  }),
  legalEntity: yup.string().required('Legal entity is required'),
}
