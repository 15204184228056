import gql from 'graphql-tag'

const REINVITE_USER = gql`
  mutation reinviteUser($id: String!) {
    reinviteUser(id: $id) {
      id
      email
    }
  }
`

export default REINVITE_USER
