import { HTMLTable, NonIdealState, Tag } from '@blueprintjs/core'
import { string } from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import Query from '@components/Query/Query'
import { penceToPounds, currencyToSymbol } from '@utils/helpers'
import GET_FLEET_PAYOUTS from './queries/getFleetPayouts.query'
import moment from 'moment'
const Payouts = ({ fleetId }) => {
  return (
    <Query query={GET_FLEET_PAYOUTS} variables={{ id: fleetId }}>
      {({ payouts }) => {
        if (!payouts.length) {
          return (
            <NonIdealState
              icon="timeline-events"
              title="No Payouts"
              description="No payouts have been made for this fleet yet"
            />
          )
        } else {
          return (
            <HTMLTable
              bordered={true}
              condensed={true}
              striped={true}
              style={{
                border: '1px solid #d8d9dc',
              }}
            >
              <thead>
                <tr>
                  <th>Payment Ref</th>
                  <th>Estimated Arrival Date</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Payment Date</th>
                  <th colspan="2">Received</th>
                </tr>
              </thead>
              <tbody>
                {payouts.map(payout => {
                  return (
                    <tr key={payout.id}>
                      <td>{payout.ref}</td>
                      <td>{payout.estimatedArrivalDate}</td>
                      <td>
                        {currencyToSymbol(payout.currency)}
                        {penceToPounds(payout.amount)}
                      </td>
                      <td>
                        <Tag
                          minimal={true}
                          intent={
                            payout.statusCode === 'Success'
                              ? 'success'
                              : 'warning'
                          }
                        >
                          {payout.statusCode.toUpperCase()}
                        </Tag>
                      </td>
                      <td>
                        {moment(payout.eventDate).format('DD/MM/YY [at] HH:mm')}
                      </td>
                      <td>
                        {moment(payout.createdAt).format('DD/MM/YY [at] HH:mm')}
                      </td>
                      <td>
                        <Link
                          to={`/transactions?searchField=payoutRef&searchValue=${
                            payout.ref
                          }`}
                        >
                          Reconcile
                        </Link>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </HTMLTable>
          )
        }
      }}
    </Query>
  )
}

Payouts.propTypes = {
  fleetId: string,
}

export default Payouts
