import React from 'react'
import { func, object } from 'prop-types'
import {
  Classes,
  Button,
  Intent,
  FormGroup,
  InputGroup,
} from '@blueprintjs/core'
import PhoneInput from '@components/PhoneInput/PhoneInput'

const Form = ({
  handleChange,
  setFieldValue,
  handleSubmit,
  errors,
  values,
}) => (
  <form onSubmit={handleSubmit}>
    <FormGroup
      label="Email"
      labelInfo="(required)"
      intent={Intent.DANGER}
      helperText={errors.email}
    >
      <InputGroup
        name="email"
        type="email"
        value={values.email}
        onChange={handleChange}
      />
    </FormGroup>
    <FormGroup
      label="First Name"
      helperText={errors.firstName}
      intent={Intent.DANGER}
    >
      <InputGroup
        name="firstName"
        type="text"
        value={values.firstName}
        onChange={handleChange}
      />
    </FormGroup>

    <FormGroup
      label="Last Name"
      helperText={errors.lastName}
      intent={Intent.DANGER}
    >
      <InputGroup
        name="lastName"
        type="text"
        value={values.lastName}
        onChange={handleChange}
      />
    </FormGroup>
    <FormGroup
      label="Phone Number"
      helperText={errors.phoneNumber}
      intent={Intent.DANGER}
    >
      <PhoneInput
        value={values.phoneNumber}
        inputProps={{ name: 'phoneNumber' }}
        onChange={phone => {
          setFieldValue('phoneNumber', phone)
        }}
      />
    </FormGroup>

    <FormGroup label="Role">
      <InputGroup
        name="role"
        type="string"
        readOnly
        disabled
        value={values.role.name}
      />
    </FormGroup>
    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
      <Button intent={Intent.PRIMARY} text="Save" type="submit" />
    </div>
  </form>
)

Form.propTypes = {
  handleChange: func,
  handleSubmit: func,
  setFieldValue: func,
  errors: object,
  values: object,
  setStatus: func,
  status: object,
}

export default Form
