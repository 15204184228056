import gql from 'graphql-tag'

const GET_JOURNEYS = gql`
  query getJourney($id: ID, $shortId: String) {
    journey(where: { id: $id, shortId: $shortId }) {
      id
      transactions {
        id
        paymentRef
        updatedAt
        createdAt
        status
        adyenStatus
        gatewayStatus
        grandTotal
        refundedTotal
        notes
        refusalReason
      }
      driver {
        id
        name
        fleet {
          id
          fleetCharge
          partner {
            id
            serviceCharge
          }
        }
      }
      customer {
        id
        name
      }

      icabbiTripId
      dispatcherTripCost
      status
      createdAt
      updatedAt
      firstSeenAt
      lastSeenAt
      dispatcherEstimatedTripCost
      shortId
      siteId
      webhookTripCost
      fixedCost
    }
  }
`

export default GET_JOURNEYS
