import React, { Component, Fragment } from 'react'
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query'

import Nav from '@components/Nav/Nav'
import Header from '@components/Header/Header'
import {
  object,
  func,
  string,
  bool,
  oneOfType,
  arrayOf,
  node,
  shape,
} from 'prop-types'
import { matchType, locationType } from '@utils/types'

const urlPropsQueryConfig = {
  viewTransactions: { type: UrlQueryParamTypes.string },
}

class PageLayout extends Component {
  static defaultProps = {
    viewTransactions: null,
  }
  constructor(props) {
    super(props)
  }

  render() {
    const { location, button, permissions, children, tabs, match } = this.props

    return (
      <Fragment>
        <Header
          button={button}
          permissions={permissions}
          location={location}
          tabs={tabs}
          match={match}
        />
        <Nav />
        <main className="container">
          <section className="mainBodyContainer">{children}</section>
        </main>
      </Fragment>
    )
  }
}

PageLayout.propTypes = {
  location: locationType,
  match: matchType,
  button: shape({
    text: string,
    action: func,
  }),
  permissions: bool,
  classes: object,
  tabs: arrayOf(object),
  children: oneOfType([arrayOf(node), node]),
  viewTransactions: string,
  onChangeViewTransactions: func,
}

export default addUrlProps({ urlPropsQueryConfig })(PageLayout)
