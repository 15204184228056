import gql from 'graphql-tag'

const GET_DRIVER_NAME = gql`
  query getDriverName($id: ID!) {
    driver(where: { id: $id }) {
      id
      name
    }
  }
`

export default GET_DRIVER_NAME
