import gql from 'graphql-tag'

const GET_PARTNER = gql`
  query partner($id: ID!) {
    partner(where: { id: $id }) {
      id
      name
      serviceCharge
      platform {
        id
        name
      }
      fleets {
        id
        name
      }
    }
  }
`

export default GET_PARTNER
