import gql from 'graphql-tag'

const GET_DRIVERS = gql`
  query($first: Int, $last: Int, $after: String, $before: String) {
    driversConnection(
      first: $first
      last: $last
      after: $after
      before: $before
      orderBy: name_ASC
    ) {
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          id
          name
          make
          model
          reg
          fleet {
            id
            name
          }
          icabbiDriverId
          updatedAt
        }
        cursor
      }
    }
  }
`

const SEARCH_GET_DRIVERS = gql`
  query(
    $driverId: Int
    $driverName: String
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    driversConnection(
      first: $first
      last: $last
      before: $before
      after: $after
      orderBy: name_ASC
      where: {
        OR: [{ icabbiDriverId: $driverId }, { name_contains: $driverName }]
      }
    ) {
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          id
          name
          make
          model
          reg
          fleet {
            id
            name
          }
          icabbiDriverId
          updatedAt
        }
        cursor
      }
    }
  }
`

export { GET_DRIVERS, SEARCH_GET_DRIVERS }
