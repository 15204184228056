import {
  Button,
  ButtonGroup,
  Card,
  ControlGroup,
  HTMLTable,
  InputGroup,
  NonIdealState,
  Tag,
} from '@blueprintjs/core'
import debounce from 'lodash/debounce'
import map from 'lodash/map'
import { object } from 'prop-types'
import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'

import { DEFAULT_RECORDS } from '@stores/recordsStore'

import {
  GET_CUSTOMERS,
  SEARCH_GET_CUSTOMERS,
} from './queries/getCustomers.query'
import PageNumbers from '@components/PageNumbers/PageNumbers'
import Query from '@components/Query/Query'
import FilterRow from '@components/FilterRow/FilterRow'

class Drivers extends Component {
  constructor() {
    super()
    this.state = {
      first: DEFAULT_RECORDS,
      last: null,
      before: null,
      after: null,
      searchValue: '',
      searchCustomer: null,
      currentPage: 1,
      totalCount: null,
    }
  }

  goToNext = (e, nextPage, endCursor) => {
    e.preventDefault()
    nextPage &&
      this.setState(prevState => ({
        first: DEFAULT_RECORDS,
        last: null,
        before: null,
        after: endCursor,
        currentPage: prevState.currentPage + 1,
      }))
  }

  goToPrevious = (e, previousPage, startCursor) => {
    e.preventDefault()
    previousPage &&
      this.setState(prevState => ({
        first: null,
        last: DEFAULT_RECORDS,
        before: startCursor,
        after: null,
        currentPage: prevState.currentPage - 1,
      }))
  }

  searchCustomer = e => {
    e.preventDefault()
    const searchValue = e.target.value
    this.setState({ searchValue })
    if (searchValue.length >= 2) {
      if (/^[a-zA-Z]+$/.test(searchValue)) {
        this.filterQuery({ name: searchValue, orderBy: 'name_ASC' })
      }
      if (/^\d+$/.test(searchValue)) {
        this.filterQuery({ phone: searchValue, orderBy: 'phone_ASC' })
      }
      if (/(^[+])\w+/.test(searchValue)) {
        this.filterQuery({ e164Phone: searchValue, orderBy: 'e164Phone_ASC' })
      }
    }
  }
  filterQuery = debounce(searchValue => {
    this.setState({
      searchCustomer: searchValue,
      first: null,
      last: DEFAULT_RECORDS,
      after: null,
      before: null,
      currentPage: 1,
      totalCount: null,
    })
  }, 300)

  clearSearch = e => {
    e.preventDefault()
    this.setState({
      searchValue: '',
      first: DEFAULT_RECORDS,
      last: null,
      before: null,
      after: null,
      searchCustomer: null,
      totalCount: null,
    })
  }

  filterBar = () => {
    const { currentPage, totalCount, pageInfo } = this.state
    const totalPages = Math.ceil(totalCount / DEFAULT_RECORDS)
    const hasNextPage = currentPage < totalPages
    const hasPreviousPage = currentPage !== 1
    return (
      <FilterRow>
        <ButtonGroup>
          <ControlGroup>
            <InputGroup
              leftIcon="search"
              id="searchCustomer"
              placeholder="Search Customer..."
              value={this.state.searchValue}
              onChange={this.searchCustomer}
            />
            <Button
              icon="small-cross"
              onClick={e => {
                this.clearSearch(e)
              }}
            />
          </ControlGroup>
        </ButtonGroup>

        <Tag
          disabled={true}
          minimal={true}
          large={true}
          style={{ marginLeft: 'auto' }}
        >
          {totalCount} Customers
        </Tag>

        <ButtonGroup id="navigationGroup">
          <Button
            disabled={!hasPreviousPage || !pageInfo}
            icon="chevron-left"
            onClick={e =>
              this.goToPrevious(e, hasPreviousPage, pageInfo.startCursor)
            }
          >
            Back
          </Button>
          <PageNumbers
            currentPage={currentPage}
            totalPages={totalPages === 0 ? 1 : totalPages}
          />
          <Button
            disabled={!hasNextPage || !pageInfo}
            rightIcon="chevron-right"
            onClick={e => this.goToNext(e, hasNextPage, pageInfo.endCursor)}
          >
            Next
          </Button>
        </ButtonGroup>
      </FilterRow>
    )
  }

  render() {
    const { first, last, before, after } = this.state
    const { match } = this.props

    const id = match.params.driver

    let queryToUse = {
      query: GET_CUSTOMERS,
      variables: {
        id,
        first,
        last,
        before,
        after,
      },
    }

    this.state.searchCustomer &&
      (queryToUse = {
        query: SEARCH_GET_CUSTOMERS,
        variables: {
          ...this.state.searchCustomer,
          id,
          first,
          last,
          before,
          after,
        },
      })

    return (
      <div className="bp3-table-frame">
        {this.filterBar()}
        <Query
          query={queryToUse.query}
          variables={queryToUse.variables}
          onCompleted={({ customersConnection }) => {
            this.setState({
              totalCount: customersConnection.totalCount,
              pageInfo: customersConnection.pageInfo,
            })
          }}
        >
          {({ customersConnection }) => {
            if (customersConnection.edges.length > 0) {
              return (
                <div className="bp3-table-container">
                  <Card className="bp3-layout-col bp3-nopad bp3-scrollable">
                    <HTMLTable bordered={false} interactive={true}>
                      <thead>
                        <tr>
                          <th>Customer Name</th>
                          <th>Phone Number</th>
                          <th>International Phone Number</th>
                          <th>Total Journeys</th>
                          <th>Partner</th>
                        </tr>
                      </thead>
                      <tbody>
                        {map(customersConnection.edges, edges => {
                          const customer = edges.node
                          return (
                            <tr key={customer.id}>
                              <td>
                                <Link to={`/customers/${customer.id}`}>
                                  {customer.name || 'Unknown'}
                                </Link>
                              </td>
                              <td>{customer.phone}</td>
                              <td>{customer.e164Phone}</td>
                              <td>{customer.journeysTotalCount}</td>
                              <td>
                                <Link to={`/partner/${customer.partner.id}`}>
                                  {customer.partner.name}
                                </Link>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </HTMLTable>
                  </Card>
                </div>
              )
            } else {
              return (
                <NonIdealState
                  icon="person"
                  title="No customers for this driver"
                  description="There are currently no customers for this driver"
                />
              )
            }
          }}
        </Query>
      </div>
    )
  }
}

Drivers.propTypes = {
  match: object.isRequired,
  location: object,
}

export default Drivers
