import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { object } from 'prop-types'
import PageLayout from '@components/PageLayout/PageLayout'
import { matchType } from '@utils/types'
import Details from './Details/Details'
import Journeys from './Journeys/Journeys'
import Transactions from './Transactions/Transactions'
import Customers from './Customers/Customers'
const Driver = ({ match, location }) => (
  <PageLayout
    match={match}
    location={location}
    tabs={[
      { to: 'details', name: 'Details' },
      { to: 'journeys', name: 'Journeys' },
      { to: 'transactions', name: 'Transactions' },
      { to: 'customers', name: 'Customers' },
    ]}
  >
    <section>
      <Switch>
        <Redirect from="/drivers/:driver" to="/drivers/:driver/details" exact />
        <Route
          path={`${match.path}/details`}
          exact={true}
          component={Details}
        />
        <Route
          path={`${match.path}/journeys`}
          component={Journeys}
          match={match}
          exact={true}
        />
        <Route path={`${match.path}/transactions`} component={Transactions} />
        <Route path={`${match.path}/customers`} component={Customers} />
      </Switch>
    </section>
  </PageLayout>
)

Driver.propTypes = {
  match: matchType.isRequired,
  classes: object,
  location: object,
}

export default Driver
