import React, { useState } from 'react'
import { Query } from 'react-apollo'
import GET_HOC_URL from '@components/Fleet/MerchantAccount/queries/getHocUrl.query'
import { Button, Spinner } from '@blueprintjs/core'
import { string } from 'prop-types'

const OpenHOCButton = ({ fleetId }) => {
  const [skipQuery, setSkipQuery] = useState(true)

  return (
    <Query
      query={GET_HOC_URL}
      variables={{ id: fleetId }}
      skip={skipQuery}
      fetchPolicy="network-only"
    >
      {({ data, loading }) => {
        if (data && data.fleet && data.fleet.hocUrl) {
          window.location.href = data.fleet.hocUrl

          return null
        }

        return (
          <Button
            onClick={() => {
              setSkipQuery(false)
            }}
            icon={loading && <Spinner size={10} />}
          >
            Go to onboarding page
          </Button>
        )
      }}
    </Query>
  )
}

OpenHOCButton.propTypes = {
  fleetId: string.isRequired,
}

export default OpenHOCButton
