import gql from 'graphql-tag'

const CHECK_USER_CAN_REGISTER = gql`
  query checkUserCanRegister {
    checkUserCanRegister {
      email
      canRegister
    }
  }
`

export default CHECK_USER_CAN_REGISTER
