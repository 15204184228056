import React from 'react'
import cx from 'classnames'
import injectSheet from 'react-jss'
import startCase from 'lodash/startCase'
import { object, string, bool } from 'prop-types'
import { Breadcrumb, Classes } from '@blueprintjs/core'

const styles = ({ colors }) => ({
  breadcrumb: {
    color: `${colors.headerTextColor}!important`,
  },
})

const Crumb = ({ classes, collapsed, text, link }) => (
  <li>
    <Breadcrumb
      className={cx(
        collapsed ? Classes.BREADCRUMBS_COLLAPSED : classes.breadcrumb
      )}
      text={!collapsed && startCase(text)}
      href={link ? link : `/${text}`}
    />
  </li>
)

Crumb.propTypes = {
  classes: object,
  collapsed: bool,
  text: string,
  link: string,
}

export default injectSheet(styles)(Crumb)
