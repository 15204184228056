import { Card, NonIdealState } from '@blueprintjs/core'
import { string } from 'prop-types'
import React from 'react'
import { Mutation } from 'react-apollo'

import Query from '@components/Query/Query'
import EDIT_PARTNER from './mutations/editPartner.mutation'
import GET_PARTNER from './quieries/getPartner.query'

import PartnerForm from '@components/PartnerForm/PartnerForm'
import { matchType } from '@utils/types'
import { successToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { numberToPence, penceToPounds } from '@utils/helpers'

const Details = ({ partnerId }) => {
  return (
    <Query query={GET_PARTNER} variables={{ id: partnerId }}>
      {({ partner }) => {
        if (!partner) {
          return (
            <NonIdealState
              icon="person"
              title="No partner detail"
              description="There are currently no partner detail to display."
            />
          )
        }

        return (
          <div>
            <Card className="bp3-layout-col">
              <Mutation
                mutation={EDIT_PARTNER}
                onError={defaultErrorHandler}
                onCompleted={() => {
                  successToast('Successfully edited partner details')
                }}
                refetchQueries={[
                  {
                    query: GET_PARTNER,
                    variables: {
                      id: partnerId,
                    },
                  },
                ]}
              >
                {editDetails => {
                  return (
                    <PartnerForm
                      onSubmit={values => {
                        editDetails({
                          variables: {
                            ...values,
                            serviceCharge: numberToPence(values.serviceCharge),
                          },
                        })
                      }}
                      initialValues={{
                        ...partner,
                        serviceCharge: penceToPounds(partner.serviceCharge),
                      }}
                    />
                  )
                }}
              </Mutation>
            </Card>
          </div>
        )
      }}
    </Query>
  )
}

Details.propTypes = {
  partnerId: string,
  match: matchType,
}

export default Details
