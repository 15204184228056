import React from 'react'
import { MultiSelect } from '@blueprintjs/select'
import { MenuItem } from '@blueprintjs/core'
import injectSheet from 'react-jss'
import { array, func, object } from 'prop-types'

import Query from '@components/Query/Query'
import GET_FLEET_IDS from './queries/getFleetIds.query'

const styles = {
  popover: {
    maxHeight: '300px',
    overflow: 'auto',
  },
}

const FleetSelect = ({ fleetIds, addFleetId, removeFleetId, classes }) => (
  <Query query={GET_FLEET_IDS}>
    {({ fleets }) => (
      <div>
        <MultiSelect
          name="fleetIds"
          fill="true"
          items={fleets}
          itemRenderer={(fleet, { modifiers, handleClick }) => (
            <MenuItem
              active={modifiers.active}
              icon={fleetIds.includes(fleet.id) ? 'tick' : 'blank'}
              key={fleet.id}
              onClick={handleClick}
              text={fleet.name}
              shouldDismissPopover={false}
            />
          )}
          selectedItems={fleets.filter(({ id }) => fleetIds.includes(id))}
          popoverProps={{
            minimal: true,
            popoverClassName: classes.popover,
          }}
          onItemSelect={fleet => {
            if (fleetIds.includes(fleet.id)) {
              removeFleetId(fleet.id)
            } else {
              addFleetId(fleet)
            }
          }}
          tagRenderer={fleet => fleet.name}
          tagInputProps={{
            onRemove: name =>
              removeFleetId(fleets.find(fleet => fleet.name === name).id),
            tagProps: {
              minimal: true,
            },
          }}
        />
      </div>
    )}
  </Query>
)

FleetSelect.propTypes = {
  fleetIds: array,
  addFleetId: func,
  removeFleetId: func,
  classes: object,
}

export default injectSheet(styles)(FleetSelect)
