/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */

export const ADD = "\ue63e";
export const ADD_COLUMN_LEFT = "\ue6f9";
export const ADD_COLUMN_RIGHT = "\ue6fa";
export const ADD_ROW_BOTTOM = "\ue6f8";
export const ADD_ROW_TOP = "\ue6f7";
export const ADD_TO_ARTIFACT = "\ue67c";
export const ADD_TO_FOLDER = "\ue6d2";
export const AIRPLANE = "\ue74b";
export const ALIGN_CENTER = "\ue603";
export const ALIGN_JUSTIFY = "\ue605";
export const ALIGN_LEFT = "\ue602";
export const ALIGN_RIGHT = "\ue604";
export const ALIGNMENT_BOTTOM = "\ue727";
export const ALIGNMENT_HORIZONTAL_CENTER = "\ue726";
export const ALIGNMENT_LEFT = "\ue722";
export const ALIGNMENT_RIGHT = "\ue724";
export const ALIGNMENT_TOP = "\ue725";
export const ALIGNMENT_VERTICAL_CENTER = "\ue723";
export const ANNOTATION = "\ue6f0";
export const APPLICATION = "\ue735";
export const APPLICATIONS = "\ue621";
export const ARROW_BOTTOM_LEFT = "\u2199";
export const ARROW_BOTTOM_RIGHT = "\u2198";
export const ARROW_DOWN = "\u2193 ";
export const ARROW_LEFT = "\u2190";
export const ARROW_RIGHT = "\u2192";
export const ARROW_TOP_LEFT = "\u2196";
export const ARROW_TOP_RIGHT = "\u2197";
export const ARROW_UP = "\u2191 ";
export const ARROWS_HORIZONTAL = "\u2194 ";
export const ARROWS_VERTICAL = "\u2195 ";
export const ASTERISK = "\u002a";
export const AUTOMATIC_UPDATES = "\ue65f";
export const BADGE = "\ue6e3";
export const BAN_CIRCLE = "\ue69d";
export const BANK_ACCOUNT = "\ue76f";
export const BARCODE = "\ue676";
export const BLANK = "\ue900";
export const BLOCKED_PERSON = "\ue768";
export const BOLD = "\ue606";
export const BOOK = "\ue6b8";
export const BOOKMARK = "\ue61a";
export const BOX = "\ue6bf";
export const BRIEFCASE = "\ue674";
export const BUILD = "\ue72d";
export const CALCULATOR = "\ue70b";
export const CALENDAR = "\ue62b";
export const CAMERA = "\ue69e";
export const CARET_DOWN = "\u2304";
export const CARET_LEFT = "\u2329";
export const CARET_RIGHT = "\u232A";
export const CARET_UP = "\u2303";
export const CELL_TOWER = "\ue770";
export const CHANGES = "\ue623";
export const CHART = "\ue67e";
export const CHAT = "\ue689";
export const CHEVRON_BACKWARD = "\ue6df";
export const CHEVRON_DOWN = "\ue697";
export const CHEVRON_FORWARD = "\ue6e0";
export const CHEVRON_LEFT = "\ue694";
export const CHEVRON_RIGHT = "\ue695";
export const CHEVRON_UP = "\ue696";
export const CIRCLE = "\ue66a";
export const CIRCLE_ARROW_DOWN = "\ue68e";
export const CIRCLE_ARROW_LEFT = "\ue68c";
export const CIRCLE_ARROW_RIGHT = "\ue68b";
export const CIRCLE_ARROW_UP = "\ue68d";
export const CITATION = "\ue61b";
export const CLEAN = "\ue7c5";
export const CLIPBOARD = "\ue61d";
export const CLOUD = "\u2601";
export const CLOUD_DOWNLOAD = "\ue690";
export const CLOUD_UPLOAD = "\ue691";
export const CODE = "\ue661";
export const CODE_BLOCK = "\ue6c5";
export const COG = "\ue645";
export const COLLAPSE_ALL = "\ue763";
export const COLUMN_LAYOUT = "\ue6da";
export const COMMENT = "\ue68a";
export const COMPARISON = "\ue637";
export const COMPASS = "\ue79c";
export const COMPRESSED = "\ue6c0";
export const CONFIRM = "\ue639";
export const CONSOLE = "\ue79b";
export const CONTRAST = "\ue6cb";
export const CONTROL = "\ue67f";
export const CREDIT_CARD = "\ue649";
export const CROSS = "\u2717";
export const CROWN = "\ue7b4";
export const CUBE = "\ue7c8";
export const CUBE_ADD = "\ue7c9";
export const CUBE_REMOVE = "\ue7d0";
export const CURVED_RANGE_CHART = "\ue71b";
export const CUT = "\ue6ef";
export const DASHBOARD = "\ue751";
export const DATABASE = "\ue683";
export const DELETE = "\ue644";
export const DELTA = "\u0394";
export const DERIVE_COLUMN = "\ue739";
export const DESKTOP = "\ue6af";
export const DIAGRAM_TREE = "\ue7b3";
export const DIRECTION_LEFT = "\ue681";
export const DIRECTION_RIGHT = "\ue682";
export const DISABLE = "\ue600";
export const DOCUMENT = "\ue630";
export const DOCUMENT_OPEN = "\ue71e";
export const DOCUMENT_SHARE = "\ue71f";
export const DOLLAR = "\u0024";
export const DOT = "\u2022";
export const DOUBLE_CARET_HORIZONTAL = "\ue6c7";
export const DOUBLE_CARET_VERTICAL = "\ue6c6";
export const DOUBLE_CHEVRON_DOWN = "\ue703";
export const DOUBLE_CHEVRON_LEFT = "\ue6ff";
export const DOUBLE_CHEVRON_RIGHT = "\ue701";
export const DOUBLE_CHEVRON_UP = "\ue702";
export const DOUGHNUT_CHART = "\ue6ce";
export const DOWNLOAD = "\ue62f";
export const DRAG_HANDLE_HORIZONTAL = "\ue716";
export const DRAG_HANDLE_VERTICAL = "\ue715";
export const DRAW = "\ue66b";
export const DRIVE_TIME = "\ue615";
export const DUPLICATE = "\ue69c";
export const EDIT = "\u270E";
export const EJECT = "\u23cf";
export const ENDORSED = "\ue75f";
export const ENVELOPE = "\u2709";
export const EQUALS = "\ue7d9";
export const ERASER = "\ue773";
export const ERROR = "\ue648";
export const EURO = "\u20ac";
export const EXCHANGE = "\ue636";
export const EXCLUDE_ROW = "\ue6ea";
export const EXPAND_ALL = "\ue764";
export const EXPORT = "\ue633";
export const EYE_OFF = "\ue6cc";
export const EYE_ON = "\ue75a";
export const EYE_OPEN = "\ue66f";
export const FAST_BACKWARD = "\ue6a8";
export const FAST_FORWARD = "\ue6ac";
export const FEED = "\ue656";
export const FEED_SUBSCRIBED = "\ue78f";
export const FILM = "\ue6a1";
export const FILTER = "\ue638";
export const FILTER_KEEP = "\ue78c";
export const FILTER_LIST = "\ue6ee";
export const FILTER_OPEN = "\ue7d7";
export const FILTER_REMOVE = "\ue78d";
export const FLAG = "\u2691";
export const FLAME = "\ue7a9";
export const FLASH = "\ue6b3";
export const FLOPPY_DISK = "\ue6b7";
export const FLOW_BRANCH = "\ue7c1";
export const FLOW_END = "\ue7c4";
export const FLOW_LINEAR = "\ue7c0";
export const FLOW_REVIEW = "\ue7c2";
export const FLOW_REVIEW_BRANCH = "\ue7c3";
export const FLOWS = "\ue659";
export const FOLDER_CLOSE = "\ue652";
export const FOLDER_NEW = "\ue7b0";
export const FOLDER_OPEN = "\ue651";
export const FOLDER_SHARED = "\ue653";
export const FOLDER_SHARED_OPEN = "\ue670";
export const FOLLOWER = "\ue760";
export const FOLLOWING = "\ue761";
export const FONT = "\ue6b4";
export const FORK = "\ue63a";
export const FORM = "\ue795";
export const FULL_CIRCLE = "\ue685";
export const FULL_STACKED_CHART = "\ue75e";
export const FULLSCREEN = "\ue699";
export const FUNCTION = "\ue6e5";
export const GANTT_CHART = "\ue6f4";
export const GEOLOCATION = "\ue640";
export const GEOSEARCH = "\ue613";
export const GIT_BRANCH = "\ue72a";
export const GIT_COMMIT = "\ue72b";
export const GIT_MERGE = "\ue729";
export const GIT_NEW_BRANCH = "\ue749";
export const GIT_PULL = "\ue728";
export const GIT_PUSH = "\ue72c";
export const GIT_REPO = "\ue748";
export const GLASS = "\ue6b1";
export const GLOBE = "\ue666";
export const GLOBE_NETWORK = "\ue7b5";
export const GRAPH = "\ue673";
export const GRAPH_REMOVE = "\ue609";
export const GREATER_THAN = "\ue7e1";
export const GREATER_THAN_OR_EQUAL_TO = "\ue7e2";
export const GRID = "\ue6d0";
export const GRID_VIEW = "\ue6e4";
export const GROUP_OBJECTS = "\ue60a";
export const GROUPED_BAR_CHART = "\ue75d";
export const HAND = "\ue6de";
export const HAND_DOWN = "\ue6bb";
export const HAND_LEFT = "\ue6bc";
export const HAND_RIGHT = "\ue6b9";
export const HAND_UP = "\ue6ba";
export const HEADER = "\ue6b5";
export const HEADER_ONE = "\ue793";
export const HEADER_TWO = "\ue794";
export const HEADSET = "\ue6dc";
export const HEART = "\u2665";
export const HEART_BROKEN = "\ue7a2";
export const HEAT_GRID = "\ue6f3";
export const HEATMAP = "\ue614";
export const HELP = "\u003F";
export const HELPER_MANAGEMENT = "\ue66d";
export const HIGHLIGHT = "\ue6ed";
export const HISTORY = "\ue64a";
export const HOME = "\u2302";
export const HORIZONTAL_BAR_CHART = "\ue70c";
export const HORIZONTAL_BAR_CHART_ASC = "\ue75c";
export const HORIZONTAL_BAR_CHART_DESC = "\ue71d";
export const HORIZONTAL_DISTRIBUTION = "\ue720";
export const ID_NUMBER = "\ue771";
export const IMAGE_ROTATE_LEFT = "\ue73a";
export const IMAGE_ROTATE_RIGHT = "\ue73b";
export const IMPORT = "\ue632";
export const INBOX = "\ue629";
export const INBOX_FILTERED = "\ue7d1";
export const INBOX_GEO = "\ue7d2";
export const INBOX_SEARCH = "\ue7d3";
export const INBOX_UPDATE = "\ue7d4";
export const INFO_SIGN = "\u2139";
export const INHERITANCE = "\ue7d5";
export const INNER_JOIN = "\ue7a3";
export const INSERT = "\ue66c";
export const INTERSECTION = "\ue765";
export const IP_ADDRESS = "\ue772";
export const ISSUE = "\ue774";
export const ISSUE_CLOSED = "\ue776";
export const ISSUE_NEW = "\ue775";
export const ITALIC = "\ue607";
export const JOIN_TABLE = "\ue738";
export const KEY = "\ue78e";
export const KEY_BACKSPACE = "\ue707";
export const KEY_COMMAND = "\ue705";
export const KEY_CONTROL = "\ue704";
export const KEY_DELETE = "\ue708";
export const KEY_ENTER = "\ue70a";
export const KEY_ESCAPE = "\ue709";
export const KEY_OPTION = "\ue742";
export const KEY_SHIFT = "\ue706";
export const KEY_TAB = "\ue757";
export const KNOWN_VEHICLE = "\ue73c";
export const LABEL = "\ue665";
export const LAYER = "\ue6cf";
export const LAYERS = "\ue618";
export const LAYOUT = "\ue60c";
export const LAYOUT_AUTO = "\ue60d";
export const LAYOUT_BALLOON = "\ue6d3";
export const LAYOUT_CIRCLE = "\ue60e";
export const LAYOUT_GRID = "\ue610";
export const LAYOUT_GROUP_BY = "\ue611";
export const LAYOUT_HIERARCHY = "\ue60f";
export const LAYOUT_LINEAR = "\ue6c3";
export const LAYOUT_SKEW_GRID = "\ue612";
export const LAYOUT_SORTED_CLUSTERS = "\ue6d4";
export const LEFT_JOIN = "\ue7a4";
export const LESS_THAN = "\ue7e3";
export const LESS_THAN_OR_EQUAL_TO = "\ue7e4";
export const LIFESAVER = "\ue7c7";
export const LIGHTBULB = "\ue6b0";
export const LINK = "\ue62d";
export const LIST = "\u2630";
export const LIST_COLUMNS = "\ue7b9";
export const LIST_DETAIL_VIEW = "\ue743";
export const LOCATE = "\ue619";
export const LOCK = "\ue625";
export const LOG_IN = "\ue69a";
export const LOG_OUT = "\ue64c";
export const MANUAL = "\ue6f6";
export const MANUALLY_ENTERED_DATA = "\ue74a";
export const MAP = "\ue662";
export const MAP_CREATE = "\ue741";
export const MAP_MARKER = "\ue67d";
export const MAXIMIZE = "\ue635";
export const MEDIA = "\ue62c";
export const MENU = "\ue762";
export const MENU_CLOSED = "\ue655";
export const MENU_OPEN = "\ue654";
export const MERGE_COLUMNS = "\ue74f";
export const MERGE_LINKS = "\ue60b";
export const MINIMIZE = "\ue634";
export const MINUS = "\u2212";
export const MOBILE_PHONE = "\ue717";
export const MOBILE_VIDEO = "\ue69f";
export const MOON = "\ue754";
export const MORE = "\ue62a";
export const MOUNTAIN = "\ue7b1";
export const MOVE = "\ue693";
export const MUGSHOT = "\ue6db";
export const MULTI_SELECT = "\ue680";
export const MUSIC = "\ue6a6";
export const NEW_GRID_ITEM = "\ue747";
export const NEW_LINK = "\ue65c";
export const NEW_OBJECT = "\ue65d";
export const NEW_PERSON = "\ue6e9";
export const NEW_PRESCRIPTION = "\ue78b";
export const NEW_TEXT_BOX = "\ue65b";
export const NINJA = "\ue675";
export const NOT_EQUAL_TO = "\ue7e0";
export const NOTIFICATIONS = "\ue624";
export const NOTIFICATIONS_UPDATED = "\ue7b8";
export const NUMBERED_LIST = "\ue746";
export const NUMERICAL = "\ue756";
export const OFFICE = "\ue69b";
export const OFFLINE = "\ue67a";
export const OIL_FIELD = "\ue73f";
export const ONE_COLUMN = "\ue658";
export const OUTDATED = "\ue7a8";
export const PAGE_LAYOUT = "\ue660";
export const PANEL_STATS = "\ue777";
export const PANEL_TABLE = "\ue778";
export const PAPERCLIP = "\ue664";
export const PARAGRAPH = "\ue76c";
export const PATH = "\ue753";
export const PATH_SEARCH = "\ue65e";
export const PAUSE = "\ue6a9";
export const PEOPLE = "\ue63d";
export const PERCENTAGE = "\ue76a";
export const PERSON = "\ue63c";
export const PHONE = "\u260e";
export const PIE_CHART = "\ue684";
export const PIN = "\ue646";
export const PIVOT = "\ue6f1";
export const PIVOT_TABLE = "\ue6eb";
export const PLAY = "\ue6ab";
export const PLUS = "\u002b";
export const POLYGON_FILTER = "\ue6d1";
export const POWER = "\ue6d9";
export const PREDICTIVE_ANALYSIS = "\ue617";
export const PRESCRIPTION = "\ue78a";
export const PRESENTATION = "\ue687";
export const PRINT = "\u2399";
export const PROJECTS = "\ue622";
export const PROPERTIES = "\ue631";
export const PROPERTY = "\ue65a";
export const PUBLISH_FUNCTION = "\ue752";
export const PULSE = "\ue6e8";
export const RANDOM = "\ue698";
export const RECORD = "\ue6ae";
export const REDO = "\ue6c4";
export const REFRESH = "\ue643";
export const REGRESSION_CHART = "\ue758";
export const REMOVE = "\ue63f";
export const REMOVE_COLUMN = "\ue755";
export const REMOVE_COLUMN_LEFT = "\ue6fd";
export const REMOVE_COLUMN_RIGHT = "\ue6fe";
export const REMOVE_ROW_BOTTOM = "\ue6fc";
export const REMOVE_ROW_TOP = "\ue6fb";
export const REPEAT = "\ue692";
export const RESET = "\ue7d6";
export const RESOLVE = "\ue672";
export const RIG = "\ue740";
export const RIGHT_JOIN = "\ue7a5";
export const RING = "\ue6f2";
export const ROTATE_DOCUMENT = "\ue6e1";
export const ROTATE_PAGE = "\ue6e2";
export const SATELLITE = "\ue76b";
export const SAVED = "\ue6b6";
export const SCATTER_PLOT = "\ue73e";
export const SEARCH = "\ue64b";
export const SEARCH_AROUND = "\ue608";
export const SEARCH_TEMPLATE = "\ue628";
export const SEARCH_TEXT = "\ue663";
export const SEGMENTED_CONTROL = "\ue6ec";
export const SELECT = "\ue616";
export const SELECTION = "\u29bf";
export const SEND_TO = "\ue66e";
export const SEND_TO_GRAPH = "\ue736";
export const SEND_TO_MAP = "\ue737";
export const SERIES_ADD = "\ue796";
export const SERIES_CONFIGURATION = "\ue79a";
export const SERIES_DERIVED = "\ue799";
export const SERIES_FILTERED = "\ue798";
export const SERIES_SEARCH = "\ue797";
export const SETTINGS = "\ue6a2";
export const SHARE = "\ue62e";
export const SHIELD = "\ue7b2";
export const SHOP = "\ue6c2";
export const SHOPPING_CART = "\ue6c1";
export const SIM_CARD = "\ue718";
export const SLASH = "\ue769";
export const SMALL_CROSS = "\ue6d7";
export const SMALL_MINUS = "\ue70e";
export const SMALL_PLUS = "\ue70d";
export const SMALL_TICK = "\ue6d8";
export const SNOWFLAKE = "\ue7b6";
export const SOCIAL_MEDIA = "\ue671";
export const SORT = "\ue64f";
export const SORT_ALPHABETICAL = "\ue64d";
export const SORT_ALPHABETICAL_DESC = "\ue6c8";
export const SORT_ASC = "\ue6d5";
export const SORT_DESC = "\ue6d6";
export const SORT_NUMERICAL = "\ue64e";
export const SORT_NUMERICAL_DESC = "\ue6c9";
export const SPLIT_COLUMNS = "\ue750";
export const SQUARE = "\ue686";
export const STACKED_CHART = "\ue6e7";
export const STAR = "\u2605";
export const STAR_EMPTY = "\u2606";
export const STEP_BACKWARD = "\ue6a7";
export const STEP_CHART = "\ue70f";
export const STEP_FORWARD = "\ue6ad";
export const STOP = "\ue6aa";
export const STRIKETHROUGH = "\ue7a6";
export const STYLE = "\ue601";
export const SWAP_HORIZONTAL = "\ue745";
export const SWAP_VERTICAL = "\ue744";
export const SYMBOL_CIRCLE = "\ue72e";
export const SYMBOL_CROSS = "\ue731";
export const SYMBOL_DIAMOND = "\ue730";
export const SYMBOL_SQUARE = "\ue72f";
export const SYMBOL_TRIANGLE_DOWN = "\ue733";
export const SYMBOL_TRIANGLE_UP = "\ue732";
export const TAG = "\ue61c";
export const TAKE_ACTION = "\ue6ca";
export const TAXI = "\ue79e";
export const TEXT_HIGHLIGHT = "\ue6dd";
export const TH = "\ue667";
export const TH_DERIVED = "\ue669";
export const TH_DISCONNECT = "\ue7d8";
export const TH_FILTERED = "\ue7c6";
export const TH_LIST = "\ue668";
export const THUMBS_DOWN = "\ue6be";
export const THUMBS_UP = "\ue6bd";
export const TICK = "\u2713";
export const TICK_CIRCLE = "\ue779";
export const TIME = "\u23F2";
export const TIMELINE_AREA_CHART = "\ue6cd";
export const TIMELINE_BAR_CHART = "\ue620";
export const TIMELINE_EVENTS = "\ue61e";
export const TIMELINE_LINE_CHART = "\ue61f";
export const TINT = "\ue6b2";
export const TORCH = "\ue677";
export const TRAIN = "\ue79f";
export const TRANSLATE = "\ue759";
export const TRASH = "\ue63b";
export const TREE = "\ue7b7";
export const TRENDING_DOWN = "\ue71a";
export const TRENDING_UP = "\ue719";
export const TWO_COLUMNS = "\ue657";
export const UNDERLINE = "\u2381";
export const UNDO = "\u238c";
export const UNGROUP_OBJECTS = "\ue688";
export const UNKNOWN_VEHICLE = "\ue73d";
export const UNLOCK = "\ue626";
export const UNPIN = "\ue650";
export const UNRESOLVE = "\ue679";
export const UPDATED = "\ue7a7";
export const UPLOAD = "\ue68f";
export const USER = "\ue627";
export const VARIABLE = "\ue6f5";
export const VERTICAL_BAR_CHART_ASC = "\ue75b";
export const VERTICAL_BAR_CHART_DESC = "\ue71c";
export const VERTICAL_DISTRIBUTION = "\ue721";
export const VIDEO = "\ue6a0";
export const VOLUME_DOWN = "\ue6a4";
export const VOLUME_OFF = "\ue6a3";
export const VOLUME_UP = "\ue6a5";
export const WALK = "\ue79d";
export const WARNING_SIGN = "\ue647";
export const WATERFALL_CHART = "\ue6e6";
export const WIDGET = "\ue678";
export const WIDGET_BUTTON = "\ue790";
export const WIDGET_FOOTER = "\ue792";
export const WIDGET_HEADER = "\ue791";
export const WRENCH = "\ue734";
export const ZOOM_IN = "\ue641";
export const ZOOM_OUT = "\ue642";
export const ZOOM_TO_FIT = "\ue67b";
