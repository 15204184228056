import { Toaster, Position } from '@blueprintjs/core'

const errorToaster = Toaster.create({
  position: Position.TOP,
})

const successToaster = Toaster.create({
  position: Position.BOTTOM_RIGHT,
})

export const toast = toast =>
  toast.intent === 'success'
    ? successToaster.show({ ...toast, timeout: 5000 })
    : errorToaster.show({ ...toast, timeout: 10000 })

export const successToast = message => {
  toast({
    message,
    intent: 'success',
  })
}

export const errorToast = error => {
  toast({
    message: error,
    intent: 'danger',
  })
}

export const warningToast = warning => {
  toast({
    message: warning,
    intent: 'warning',
  })
}

export const refreshToast = (newRecordsRefresh, refetch) => {
  toast({
    message: `New records have been added. Refresh to view`,
    intent: 'primary',
    action: {
      onClick: () => newRecordsRefresh(refetch),
      text: 'Refresh',
    },
  })
}

export default toast
