const menu = {
  defaults: {
    itemMenuOpen: false,
    menuId: '',
    itemDetailsOpen: false,
    itemId: '',
    optionId: '',
    paneNumber: 0,
    subMenuId: '',
    parentMenuId: '',
    itemQueryId: '',
    editedMenu: '',
  },
}

export default menu
