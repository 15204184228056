import React from 'react'
import { object } from 'prop-types'
import { Mutation } from 'react-apollo'
import { Dialog, Classes } from '@blueprintjs/core'
import { successToast } from '@utils/toast'
import { modalType } from '@utils/types'
import { toggleModal } from '@utils/cacheHelpers'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import UserInviteForm from '@components/UserInviteForm/UserInviteForm'

import INVITE_USER from '@components/Users/mutations/inviteUser.mutation'
import GET_ALL_USERS from '@components/Users/queries/getAllUsers.query'

const UsersModal = ({ modalOpen, modalClose }) => (
  <Dialog isOpen={modalOpen} onClose={modalClose} title={'Invite user'}>
    <div className={Classes.DIALOG_BODY}>
      <Mutation
        mutation={INVITE_USER}
        onError={defaultErrorHandler}
        refetchQueries={[{ query: GET_ALL_USERS }]}
        onCompleted={() => {
          successToast('User successfully added')
          toggleModal({
            inviteUserModal: false,
          })
        }}
      >
        {inviteUser => (
          <UserInviteForm
            onSubmit={variables => inviteUser({ variables })}
            initialValues={{ email: '', roleId: '', ownsFleets: [] }}
          />
        )}
      </Mutation>
    </div>
  </Dialog>
)

UsersModal.propTypes = {
  ...modalType,
  classes: object,
  user: object,
}

export default UsersModal
