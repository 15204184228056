import {
  Button,
  ButtonGroup,
  Card,
  ControlGroup,
  Tag,
  NonIdealState,
  Spinner,
} from '@blueprintjs/core'
import React, { useState, Fragment, useCallback, useEffect } from 'react'
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query'
import FilterRow from '@components/FilterRow/FilterRow'
import { permissionsAccess } from '@stores/userStore'
import GET_ALL_USERS from '@components/Users/queries/getAllUsers.query'
import OPEN_UPDATE_USER_MODAL from '@components/Users/mutations/openUpdateUserModal.mutation'
import OPEN_MODAL from '@components/Users/mutations/openUserModal.mutation'
import PageLayout from '@components/PageLayout/PageLayout'
import { toggleModal, setValue } from '@utils/cacheHelpers'
import UserModal from './InviteUser.modal'
import UpdateUserModal from './EditUser.modal'
import { Query } from 'react-apollo'
import PageNumbers from '@components/PageNumbers/PageNumbers'
import { Search } from '@components/Toolbar/Search'
import ReinviteUserModal from './ReinviteUser.modal'
import UsersTable from '@components/Users/UsersTable'
import debounce from 'lodash/debounce'
import { string, func } from 'prop-types'

const urlPropsQueryConfig = {
  userSearch: { type: UrlQueryParamTypes.string },
}

const PAGE_SIZE = 40

const Users = ({ userSearch, onChangeUserSearch }) => {
  const [paginationVars, setPaginationVars] = useState({
    before: null,
    after: null,
    first: PAGE_SIZE,
    last: null,
  })

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [userSearchDebounced, setUserSearchDebounced] = useState(null)
  const hasNextPage = currentPage < totalPages
  const hasPreviousPage = currentPage !== 1

  const debouncedSetSearch = useCallback(
    debounce(search => {
      console.log('dep')
      setUserSearchDebounced(search)
    }, 300),
    []
  )

  useEffect(() => debouncedSetSearch(userSearch), [userSearch])

  return (
    <PageLayout
      button={{
        text: 'Invite User',
        onClick: () =>
          toggleModal({
            inviteUserModal: true,
          }),
      }}
      permissions={
        permissionsAccess('inviteUser') || permissionsAccess('userCreate')
      }
    >
      <Query
        query={GET_ALL_USERS}
        variables={{ ...paginationVars, userSearch: userSearchDebounced }}
      >
        {({ data, loading, error }) => {
          const { pageInfo = {}, edges = [], totalCount = 0 } =
            data.usersConnection || {}
          if (!totalPages) {
            setTotalPages(Math.ceil(totalCount / PAGE_SIZE))
          }

          return (
            <div className="bp3-table-frame">
              <div className="bp3-table-container">
                <Card className="bp3-nopad bp3-scrollable">
                  <FilterRow>
                    <ButtonGroup>
                      <ControlGroup>
                        <Search
                          placeholder="User Search..."
                          value={userSearch}
                          onChange={e => onChangeUserSearch(e.value)}
                          onReset={() => onChangeUserSearch()}
                        />
                      </ControlGroup>
                    </ButtonGroup>

                    <Tag
                      minimal={true}
                      large={true}
                      style={{ marginLeft: 'auto' }}
                    >
                      {totalCount} Users
                    </Tag>

                    <ButtonGroup id="navigationGroup">
                      <Button
                        disabled={!hasPreviousPage}
                        icon="chevron-left"
                        onClick={() => {
                          setPaginationVars({
                            last: PAGE_SIZE,
                            before: pageInfo.startCursor,
                            first: null,
                            after: null,
                          })

                          setCurrentPage(currentPage - 1)
                        }}
                      >
                        Back
                      </Button>
                      <PageNumbers
                        currentPage={currentPage}
                        totalPages={totalPages === 0 ? 1 : totalPages}
                      />
                      <Button
                        disabled={!hasNextPage}
                        rightIcon="chevron-right"
                        onClick={() => {
                          setPaginationVars({
                            first: PAGE_SIZE,
                            after: pageInfo.endCursor,
                            last: null,
                            before: null,
                          })
                          setCurrentPage(currentPage + 1)
                        }}
                      >
                        Next
                      </Button>
                    </ButtonGroup>
                  </FilterRow>

                  {data &&
                  data.usersConnection &&
                  data.usersConnection.edges.length ? (
                    <UsersTable edges={edges} />
                  ) : (
                    <div style={{ paddingTop: '30px' }}>
                      {loading ? (
                        <NonIdealState
                          icon={<Spinner size={60} value={null} />}
                          title="Loading Users"
                          description="Please wait..."
                        />
                      ) : error ? (
                        <NonIdealState
                          icon="error"
                          title="Server Error"
                          description="Please try again."
                        />
                      ) : (
                        <NonIdealState
                          icon="search"
                          title="No users found with your current search query"
                          description="Please try again."
                        />
                      )}
                    </div>
                  )}
                </Card>
              </div>
            </div>
          )
        }}
      </Query>
      <Query query={OPEN_MODAL}>
        {({ data: { reinviteUserModal, inviteUserModal, user } }) => (
          <Fragment>
            <UserModal
              modalOpen={inviteUserModal}
              modalClose={() => {
                toggleModal({
                  inviteUserModal: false,
                })
                setValue({
                  user: {
                    id: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    __typename: 'user',
                  },
                })
              }}
              user={user}
            />
            <ReinviteUserModal
              modalOpen={reinviteUserModal}
              modalClose={() => {
                toggleModal({
                  reinviteUserModal: false,
                })
                setValue({
                  user: {
                    id: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    __typename: 'user',
                  },
                })
              }}
              id={user && user.id}
            />
          </Fragment>
        )}
      </Query>

      <Query query={OPEN_UPDATE_USER_MODAL}>
        {({ data: { updateUserModal, user } }) => {
          return (
            <UpdateUserModal
              modalOpen={updateUserModal}
              modalClose={() => {
                toggleModal({
                  updateUserModal: false,
                })
                setValue({
                  user: {
                    id: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    phoneNumber: '',
                  },
                })
              }}
              user={{
                ...user,
                phoneNumber: user.phoneNumber || '',
                ownsFleets: user.ownsFleets.map(({ id }) => id),
                roleId: user.role.id,
              }}
            />
          )
        }}
      </Query>
    </PageLayout>
  )
}

Users.propTypes = {
  userSearch: string,
  onChangeUserSearch: func,
}

export default addUrlProps({ urlPropsQueryConfig })(Users)
