import gql from 'graphql-tag'

const COMPLETE_AND_CANCEL = gql`
  mutation completeAndCancel($id: ID!) {
    completeAndCancel(id: $id) {
      id
    }
  }
`

export default COMPLETE_AND_CANCEL
