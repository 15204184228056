import gql from 'graphql-tag'

const RESET_PASSWORD = gql`
  mutation resetPassword(
    $email: String!
    $token: String!
    $newPassword: String!
  ) {
    resetPassword(email: $email, token: $token, newPassword: $newPassword) {
      message
    }
  }
`

export default RESET_PASSWORD
