import React from 'react'
import { FormGroup, InputGroup, Button, Checkbox } from '@blueprintjs/core'
import { setValue } from '@utils/cacheHelpers'
import { func, object, bool } from 'prop-types'

const LoginForm = ({
  handleChange,
  values,
  data,
  isSubmitting,
  classes,
  mutation,
}) => (
  <form
    onSubmit={e => {
      e.preventDefault()
      if (!isSubmitting) {
        mutation({
          variables: { ...values },
        })
      }
    }}
  >
    <FormGroup>
      <InputGroup
        placeholder={'Email'}
        value={values.email}
        onChange={handleChange}
        type="email"
        name="email"
        large="true"
      />
    </FormGroup>
    <FormGroup>
      <InputGroup
        placeholder={'Password'}
        value={values.password}
        onChange={handleChange}
        type="password"
        name="password"
        large="true"
      />
    </FormGroup>
    <div className={classes.buttonContainer}>
      <Checkbox
        label="Remember Me"
        name="rememberMe"
        onChange={event =>
          setValue({
            rememberMe: event.target.checked,
          })
        }
        checked={data.rememberMe}
      />
      <FormGroup>
        <Button type="submit" disabled={isSubmitting} text="Login" />
      </FormGroup>
    </div>
  </form>
)

LoginForm.propTypes = {
  handleChange: func,
  values: object,
  data: object,
  isSubmitting: bool,
  classes: object,
  mutation: func,
}

export default LoginForm
