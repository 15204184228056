import * as yup from 'yup'

const validation = yup.object().shape({
  adyenPercentageCharge: yup
    .number()
    .required()
    .typeError('Adyen percentage charge must be a number'),
  adyenGatewayCharge: yup
    .number()
    .required()
    .typeError('Adyen gateway charge must be a number'),
  adyenFallbackEstimate: yup
    .number()
    .required()
    .typeError('Adyen fallback estimate must be a number'),
})

export default validation
