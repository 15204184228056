import gql from 'graphql-tag'

const EDIT_MESSAGE_TEMPLATE = gql`
  mutation messageTemplateUpdate($id: ID!, $text: String!) {
    messageTemplateUpdate(where: { id: $id }, data: { text: $text }) {
      id
      key
      type
      text
      createdAt
      updatedAt
    }
  }
`

export default EDIT_MESSAGE_TEMPLATE
