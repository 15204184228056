import React from 'react'
import { object, func } from 'prop-types'
import injectSheet from 'react-jss'
import ReactSVG from 'react-svg'
import { Formik } from 'formik'
import { Mutation } from 'react-apollo'
import { Card } from '@blueprintjs/core'
import get from 'lodash/get'
import * as yup from 'yup'

import ls from '@utils/localStorage'
import { errorToast } from '@utils/toast'
import twoFactorIcon from '@assets/icons/twoFactor.svg'
import airpayLogo from '@assets/airpay-logo-white.png'

import TwoFactorForm from './TwoFactorForm'
import styles from './TwoFactorAuth.styles'
import TWO_FACTOR_AUTH from './mutations/twoFactorAuthentication.mutation'

const handleResponse = async ({ token, user }, history) => {
  if (token) {
    ls.remove('partialJwt')
    ls.remove('obfuscatedPhoneNumber')

    ls.set('jwt', token)
    ls.set('role', user.role.key)
    ls.set('id', user.id)
    ls.set('permissionCategories', user.role.permissionCategories)

    history.push('/dashboard')
  }
}

const validationSchema = yup.object().shape({
  code: yup.string().required('Two Factor Code is required'),
})

const TwoFactorAuthentication = ({ classes, history }) => {
  const {
    container,
    twoFactorContainer,
    twoFactorForm,
    icon,
    airPayLogo,
    airPayContainer,
  } = classes
  return (
    <div className={container}>
      <div className={twoFactorContainer}>
        <div className={airPayContainer}>
          <img src={airpayLogo} alt="Airpay" className={airPayLogo} />
        </div>
        <Card elevation="2" className={twoFactorForm}>
          <ReactSVG src={twoFactorIcon} className={icon} />
          <p>
            Enter the 6 digit code sent to {ls.get('obfuscatedPhoneNumber')}
          </p>
          <Mutation
            mutation={TWO_FACTOR_AUTH}
            onError={error => {
              const serverErrors = get(error, 'graphQLErrors', [])

              // fallback for errors that weren't returned by the server
              if (!serverErrors.length) {
                return errorToast(error.message)
              }

              // loop through custom server errors displaying error toast
              for (const serverError of serverErrors) {
                errorToast(serverError.message)
              }
            }}
            onCompleted={async ({ twoFactorAuthentication }) => {
              await handleResponse(twoFactorAuthentication, history)
            }}
          >
            {twoFactorUser => (
              <Formik
                onSubmit={variables => {
                  twoFactorUser({ variables })
                }}
                initialValues={{ code: '' }}
                validationSchema={validationSchema}
              >
                {props => <TwoFactorForm {...props} classes={classes} />}
              </Formik>
            )}
          </Mutation>
        </Card>
      </div>
    </div>
  )
}

TwoFactorAuthentication.propTypes = {
  classes: object,
  history: object,
  setSubmitting: func,
}

export default injectSheet(styles)(TwoFactorAuthentication)
