import gql from 'graphql-tag'

const FAILED_TRANSACTIONS = gql`
  query failedTransactions(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $fleetId: ID
  ) {
    failedTransactions(
      first: $first
      last: $last
      after: $after
      before: $before
      where: { fleetId: $fleetId }
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        cursor
        node {
          id
          grandTotal
          adyenStatus
          notes
          captureAttempts
          lastCaptureAttempt
          status
          gatewayStatus
          journey {
            id
            icabbiTripId
            status
            createdAt
            dispatcherTripCost
            webhookTripCost
            fixedCost
            driver {
              id
              fleet {
                id
                fleetCharge
                partner {
                  id
                  serviceCharge
                }
              }
            }
            customer {
              id
              name
            }
          }
        }
      }
    }
  }
`

export default FAILED_TRANSACTIONS
