import React, { Fragment } from 'react'
import injectSheet from 'react-jss'
import { object } from 'prop-types'
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router'
import {
  Popover,
  Menu,
  Position,
  MenuItem,
  Navbar,
  MenuDivider,
  Tag,
} from '@blueprintjs/core'
import { Query as CacheQuery } from 'react-apollo'
import Fetch from 'react-fetch-component'

import OPEN_MODAL from './mutations/openModal.mutation'

import { logout, permissionsAccess } from '@stores/userStore'
import { toggleModal } from '@utils/cacheHelpers'

import NavIcon from '@components/NavIcon/NavIcon'
import userIcon from '@assets/icons/user.svg'
import dashboardIcon from '@assets/icons/dashboard.svg'
import settingsIcon from '@assets/icons/settings.svg'
import driversIcon from '@assets/icons/driver.svg'
import fleetsIcon from '@assets/icons/fleet.svg'
import partnersIcon from '@assets/icons/partners.svg'
import platformIcon from '@assets/icons/platform.svg'
import journeyIcon from '@assets/icons/journey.svg'
import customersIcon from '@assets/icons/customers.svg'
import reportsIcon from '@assets/icons/reports.svg'
import supportIcon from '@assets/icons/support.svg'

import UserModal from '@components/User/User.modal'

const styles = theme => ({
  navContainer: {
    display: 'flex',
    position: 'fixed',
    left: 0,
    top: 96,
    height: 'calc(100% - 96px)',
    zIndex: 1,
  },
  slimContainer: {
    width: 48,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: theme.colors.secondaryBg,
    paddingTop: 12,
    paddingBottom: 12,
  },
  slimContainerTop: {
    display: 'flex',
    flexDirection: 'column',
  },
  slimContainerBottom: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  navLink: {
    color: theme.colors.primaryNavColor,
    '&:hover': {
      color: theme.colors.primaryNavHoverColor,
    },
  },
  activeNavLink: {
    color: theme.colors.primaryNavActiveColor,
    '&:not(.activeNavLink):hover': {
      color: theme.colors.primaryNavActiveColor,
    },
  },
})

export const Nav = ({ classes, history }) => {
  const {
    navContainer,
    slimContainer,
    slimContainerTop,
    slimContainerBottom,
    navLink,
    activeNavLink,
  } = classes

  return (
    <nav className={navContainer}>
      <Navbar className={slimContainer}>
        <div className={slimContainerTop}>
          <NavLink
            to="/"
            className={navLink}
            activeClassName={activeNavLink}
            exact={true}
          >
            <NavIcon
              linkUrl="/"
              imagePath={dashboardIcon}
              tooltipContent="Dashboard"
            />
          </NavLink>
          {permissionsAccess('platforms') && (
            <NavLink
              to="/platform"
              className={navLink}
              activeClassName={activeNavLink}
            >
              <NavIcon
                imagePath={platformIcon}
                tooltipContent="Platform"
                linkUrl="/platform"
              />
            </NavLink>
          )}
          {permissionsAccess('partners') && (
            <NavLink
              to="/partners"
              className={navLink}
              activeClassName={activeNavLink}
            >
              <NavIcon
                imagePath={partnersIcon}
                tooltipContent="Partners"
                linkUrl="/partners"
              />
            </NavLink>
          )}

          <NavLink
            to="/fleets"
            className={navLink}
            activeClassName={activeNavLink}
          >
            <NavIcon
              imagePath={fleetsIcon}
              tooltipContent="Fleets"
              linkUrl="/fleets"
            />
          </NavLink>
          <NavLink
            to="/drivers"
            className={navLink}
            activeClassName={activeNavLink}
          >
            <NavIcon
              imagePath={driversIcon}
              tooltipContent="Drivers"
              linkUrl="/drivers"
            />
          </NavLink>
          <NavLink
            to="/journeys"
            className={navLink}
            activeClassName={activeNavLink}
          >
            <NavIcon
              imagePath={journeyIcon}
              tooltipContent="Journeys"
              linkUrl="/journeys"
            />
          </NavLink>
          {permissionsAccess('partners') && (
            <NavLink
              to="/customers"
              className={navLink}
              activeClassName={activeNavLink}
            >
              <NavIcon
                imagePath={customersIcon}
                tooltipContent="Customers"
                linkUrl="/customers"
              />
            </NavLink>
          )}
          <NavLink
            to="/transactions"
            className={navLink}
            activeClassName={activeNavLink}
          >
            <NavIcon
              imagePath={reportsIcon}
              tooltipContent="Transactions"
              linkUrl="/transactions"
            />
          </NavLink>
          {permissionsAccess('users') && (
            <NavLink
              to="/users"
              className={navLink}
              activeClassName={activeNavLink}
            >
              <NavIcon
                imagePath={settingsIcon}
                tooltipContent="Settings"
                linkUrl="/users"
              />
            </NavLink>
          )}
        </div>
        <div className={slimContainerBottom}>
          <Popover
            content={
              <Menu>
                <MenuItem
                  text="Contact Support"
                  onClick={() => Beacon('open')}
                />
                {/* <li>
                  <a
                    className={'bp3-menu-item bp3-popover-dismiss'}
                    href="#"
                    data-beacon-article-modal="5e32d0ff04286364bc94a2d5"
                  >
                    Release Notes
                  </a>
                </li> */}
                <MenuDivider />
                <MenuItem
                  text="Status"
                  label={
                    <Fetch
                      url={
                        'https://1jlxmksj2p8b.statuspage.io/api/v2/status.json'
                      }
                    >
                      {({ loading, data }) =>
                        !loading && (
                          <Fragment>
                            {data.status.indicator == 'none' && (
                              <Tag intent={'success'}>OK</Tag>
                            )}
                            {data.status.indicator == 'minor' && (
                              <Tag intent={'warning'}>MINOR</Tag>
                            )}
                            {data.status.indicator == 'major' && (
                              <Tag intent={'danger'}>INCIDENT</Tag>
                            )}
                            {data.status.indicator == 'critical' && (
                              <Tag intent={'danger'}>CRITICAL</Tag>
                            )}
                          </Fragment>
                        )
                      }
                    </Fetch>
                  }
                  onClick={() =>
                    window.open('http://status.air-pay.co.uk', '_blank')
                  }
                />
              </Menu>
            }
            position={Position.RIGHT}
          >
            <NavIcon
              className={'supportIcon'}
              imagePath={supportIcon}
              tooltipContent="Support"
            />
          </Popover>
          <Popover
            content={
              <Menu>
                <MenuItem
                  text="My Profile"
                  onClick={() =>
                    toggleModal({
                      profileModal: true,
                    })
                  }
                />
                <MenuItem text="Logout" onClick={logout} />
              </Menu>
            }
            position={Position.RIGHT}
          >
            <NavIcon
              pathNameToMatch="profile"
              imagePath={userIcon}
              tooltipContent="Profile"
            />
          </Popover>
        </div>
      </Navbar>
      <CacheQuery query={OPEN_MODAL}>
        {({ data: { profileModal } }) => (
          <UserModal
            modalOpen={profileModal}
            modalClose={() =>
              toggleModal({
                profileModal: false,
              })
            }
          />
        )}
      </CacheQuery>
    </nav>
  )
}

Nav.propTypes = {
  children: object,
  classes: object,
  location: object,
  history: object,
}

export default injectSheet(styles)(withRouter(Nav))
