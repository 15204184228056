import {
  Card,
  HTMLTable,
  Button,
  ButtonGroup,
  InputGroup,
  HTMLSelect,
  Tag,
} from '@blueprintjs/core'
import { string } from 'prop-types'
import React, { Fragment } from 'react'
import { Mutation } from 'react-apollo'
import Query from '@components/Query/Query'
import { upperCase, find, isEmpty, get } from 'lodash'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast, errorToast } from '@utils/toast'
import GET_WEBHOOK_CONFIG from './queries/getWebhookConfig.query'
import GET_FLEET_KEY from './queries/getFleetKey.query'
import CREATE_WEBHOOKS from './mutations/createWebhooks.mutation'
import DELETE_WEBHOOK from './mutations/deleteWebhook.mutation'
import UPDATE_WEBHOOK from './mutations/updateWebhook.mutation'
import config from '@config/config'
import { client } from '@services/client'

const eventOpts = [
  {
    label: 'Driver Enroute',
    value: 'booking:driver_enroute',
    url: `${config.apiUrl}/api/webhooks/icabbi/driver-enroute/`,
    name: 'Airpay Driver Enroute',
    disabled: false,
    template: "#json"
  },
  {
    label: 'Passenger Onboard',
    value: 'booking:madecontact',
    url: `${config.apiUrl}/api/webhooks/icabbi/passenger-onboard/`,
    name: 'Airpay Passenger Onboard',
    disabled: false,
    template: "#json"
  },
  {
    label: 'Journey Complete',
    value: 'booking:completed',
    url: `${config.apiUrl}/api/webhooks/icabbi/journey-complete/`,
    name: 'Airpay Journey Complete',
    disabled: false,
    template: "#json"
  },
  {
    label: 'Cancelled (No Show)',
    value: 'booking:noshow',
    url: `${config.apiUrl}/api/webhooks/icabbi/cancelled/`,
    name: 'Airpay Cancelled (No Show)',
    disabled: false,
    template: "#json"
  },
  {
    label: 'Cancelled (By Dispatch)',
    value: 'booking:dispatch_cancelled',
    url: `${config.apiUrl}/api/webhooks/icabbi/cancelled/`,
    name: 'Airpay Cancelled (Dispatch)',
    disabled: false,
    template: "#json"
  },
  {
    label: 'Booking Created (Prepay)',
    value: 'request:save',
    url: `${config.apiUrl}/api/webhooks/icabbi/journey-created/`,
    name: 'Airpay Booking Created',
    disabled: false,
    template: "#json"
  },
  {
    label: 'Driver GPS Events',
    value: 'driver:position:update',
    url: `${config.apiUrl}/api/webhooks/icabbi/driver-gps/`,
    name: 'Airpay Driver GPS',
    disabled: false,
    template: `{"driver_id":"#driver_id","vehicle_id":"#vehicle_id","speed":"#speed","dctc_meter":"#dctc_meter","trip_id":"#trip_id","lat":"#lat","lng":"#lng","vehicle_reg":"#vehicle_reg","accuracy":"#accuracy","heading":"#heading"}`
  },
]

const styles = {
  urlHeader: {
    width: '30%',
  },
  actionButtonWidth: {
    width: '120px',
  },
  createRow: {
    borderBottom: '1px dashed #ccc',
    height: '11px',
  },
  emptyRow: { height: '11px' },
}

class Webhooks extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      event: '',
      url: '',
      template: "",
      disableCreate: true,
      showCreateRow: false,
      fleetKey: '',
    }
    this.setValue = this.setValue.bind(this)
    this.postValues = this.postValues.bind(this)
    this.updateState = this.updateState.bind(this)
  }

  updateState({ webhooks }) {
    // ADD 'disabled' to already selected events
    eventOpts.map(events => {
      events.disabled = !isEmpty(
        find(webhooks, hook => hook.event === events.value)
      )
    })
    const anyLeft = find(eventOpts, opts => !opts.disabled)
    let { url, name, template } = this.state
    if (anyLeft) {
      let chosenOpt = find(eventOpts, events => events.value === anyLeft.value)
      url = `${chosenOpt.url}${this.state.fleetKey}`
      name = `${chosenOpt.name}`
      template = `${chosenOpt.template}`
    }
    this.setState({
      event: anyLeft ? anyLeft.value : null,
      url,
      name,
      template,
      showCreateRow: !isEmpty(anyLeft),
      disableCreate: isEmpty(name) || isEmpty(url),
    })
  }

  componentDidMount() {
    const { fleetId } = this.props
    // get role and permission data now we are authenticated
    client
      .query({
        query: GET_FLEET_KEY,
        variables: { id: fleetId },
      })
      .then(res => {
        this.setState({
          fleetKey: get(res, 'data.fleet.key', ''),
        })
      })
      .catch(() => {
        errorToast('Failed to get fleet key')
      })
  }

  setValue(e) {
    const { name, value } = e.target
    let { url, name: hookName, template } = this.state
    if (name === 'event') {
      let chosenOpt = find(eventOpts, events => events.value === value)
      url = `${chosenOpt.url}${this.state.fleetKey}`
      hookName = `${chosenOpt.name}`
      template = `${chosenOpt.template}`
    }
    this.setState(
      {
        ...this.state,
        template: template,
        url,
        name: hookName,
        [name]: value,
      },
      () => {
        const { name, url } = this.state
        this.setState({
          disableCreate: isEmpty(name) || isEmpty(url),
        })
      }
    )
  }

  postValues(webhookCreate) {
    const { name, event, url, template } = this.state
    const { fleetId } = this.props

    webhookCreate({
      variables: {
        fleetId,
        url,
        event,
        name,
        template
      },
    })
  }
  render() {
    const { fleetId } = this.props
    const { name, event, url, disableCreate, showCreateRow } = this.state

    return (
      <Query
        query={GET_WEBHOOK_CONFIG}
        variables={{ id: fleetId }}
        fetchPolicy={'network-only'}
        onCompleted={webhooks => this.updateState(webhooks)}
      >
        {({ webhooks }) => {

          return (
            <Card className="bp3-scrollable bp3-noPad">
              <HTMLTable bordered={false} interactive={true}>
                <thead>
                  <tr>
                    <th>Trigger Event</th>
                    <th>Hook Name</th>
                    <th style={styles.urlHeader}>URL</th>
                    {webhooks.length > 0 ? <th>Status</th> : <th />}
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {webhooks.map(webhook => {
                    const notActive = webhook.status === 'NOT_ACTIVE'
                    const intent = notActive ? 'none' : 'success'
                    const postStatus = notActive ? 'ACTIVE' : 'NOT_ACTIVE'
                    const buttonTxt = notActive ? 'Activate' : 'Deactivate'
                    const buttonIcon = notActive ? 'tick-circle' : 'ban-circle'
                    const thisTemplate = find(eventOpts, (hook) => {
                        return hook.value === webhook.event
                    })
                    return (
                      <tr key={webhook.id}>
                        <td>{webhook.event}</td>
                        <td>{webhook.name}</td>
                        <td>{webhook.url}</td>
                        <td>
                          <Tag minimal={true} intent={intent}>
                            {upperCase(webhook.status)}
                          </Tag>
                        </td>
                        <td>
                          <ButtonGroup fill={true}>
                            <Mutation
                              mutation={UPDATE_WEBHOOK}
                              onError={defaultErrorHandler}
                              refetchQueries={[`getWebhookConfig`]}
                              onCompleted={() => {
                                successToast('Updated Webhook')
                              }}
                            >
                              {(webhookUpdate, { loading }) => {
                                return (
                                  <Button
                                    icon={buttonIcon}
                                    text={buttonTxt}
                                    loading={loading}
                                    style={styles.actionButtonWidth}
                                    onClick={() => {
                                      webhookUpdate({
                                        variables: {
                                          fleetId,
                                          hookId: webhook.hookId,
                                          id: webhook.id,
                                          status: postStatus,
                                          template: thisTemplate.template
                                        },
                                      })
                                    }}
                                  />
                                )
                              }}
                            </Mutation>
                            <Mutation
                              mutation={DELETE_WEBHOOK}
                              onError={defaultErrorHandler}
                              refetchQueries={[`getWebhookConfig`]}
                              onCompleted={() => {
                                successToast('Webhook Deleted')
                              }}
                            >
                              {(webhookDelete, { loading }) => {
                                return (
                                  <Button
                                    icon="remove"
                                    text="Delete"
                                    loading={loading}
                                    onClick={() => {
                                      if (confirm('Delete this hook?')) {
                                        webhookDelete({
                                          variables: {
                                            fleetId,
                                            hookId: webhook.hookId,
                                            id: webhook.id,
                                          },
                                        })
                                      }
                                    }}
                                  />
                                )
                              }}
                            </Mutation>
                          </ButtonGroup>
                        </td>
                      </tr>
                    )
                  })}

                  {showCreateRow && (
                    <Fragment>
                      {webhooks.length > 0 && (
                        <tr>
                          <td style={styles.createRow} colSpan="6" />
                        </tr>
                      )}
                      <tr>
                        <td style={styles.emptyRow} colSpan="6" />
                      </tr>
                      <tr>
                        <td>
                          <HTMLSelect
                            onChange={this.setValue}
                            placeholder="Event Trigger"
                            value={event}
                            fill={true}
                            name="event"
                            options={eventOpts}
                          />
                        </td>
                        <td>
                          <InputGroup
                            onChange={this.setValue}
                            placeholder="Name"
                            value={name}
                            name="name"
                          />
                        </td>

                        <td colSpan="2">
                          <InputGroup
                            id="url"
                            name="url"
                            onChange={this.setValue}
                            placeholder="URL"
                            value={url}
                          />
                        </td>

                        <td>
                          <Mutation
                            mutation={CREATE_WEBHOOKS}
                            onError={defaultErrorHandler}
                            refetchQueries={[`getWebhookConfig`]}
                            onCompleted={() => {
                              successToast('Webhook Added')
                            }}
                          >
                            {(webhookCreate, { loading }) => (
                              <Button
                                fill={true}
                                icon="add"
                                text="Create Hook"
                                loading={loading}
                                className="bp3-intent-success"
                                disabled={disableCreate}
                                onClick={() => this.postValues(webhookCreate)}
                              />
                            )}
                          </Mutation>
                        </td>
                      </tr>
                    </Fragment>
                  )}
                </tbody>
              </HTMLTable>
            </Card>
          )
        }}
      </Query>
    )
  }
}

Webhooks.propTypes = {
  fleetId: string.isRequired,
}

export default Webhooks
