import {
  Tag,
  Icon,
  Popover,
  PopoverInteractionKind,
  Position,
  H6,
} from '@blueprintjs/core'
import React from 'react'
import { upperCase } from 'lodash'

const getKycStatusTag = (status, pos, name, description) => {
  let intent = 'none'
  let icon = 'none'
  let color = '#CDD6DD'

  let style =
    pos === 'right'
      ? {
          float: 'right',
        }
      : {}
  switch (status) {
    case 'PASSED':
      intent = 'success'
      icon = 'tick-circle'
      color = '#5BB70D'
      break
    case 'DATA_PROVIDED':
    case 'PENDING':
      intent = 'primary'
      icon = 'upload'
      color = '#CDD6DD'
      break
    case 'AWAITING_DATA':
      intent = 'warning'
      icon = 'help'
      color = '#FFAB00'
      break
    case 'RETRY_LIMIT_REACHED':
    case 'INVALID_DATA':
    case 'FAILED':
      intent = 'danger'
      icon = 'ban-circle'
      color = '#D52020'
      break
    case 'UNCHECKED':
    default:
      intent = 'none'
      icon = 'circle'
      color = '#CDD6DD'
  }

  return status ? (
    <Popover
      interactionKind={PopoverInteractionKind.HOVER}
      popoverClassName="bp3-popover-content-sizing"
      position={Position.RIGHT}
    >
      <Icon icon={icon} intent={intent} color={color} />
      <div>
        <H6>{name}</H6>
        <Tag minimal={true} intent={intent} style={style}>
          {upperCase(status)}
        </Tag>
      </div>
    </Popover>
  ) : (
    <Icon icon="circle" color={color} />
  )
}

export default getKycStatusTag
