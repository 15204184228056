import React from 'react'

export const NoPermissions = () => (
  <div
    style={{
      display: 'flex',
      height: '100%',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <h1>You do not have the correct permissions to view this page.</h1>
  </div>
)
