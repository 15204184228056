import React from 'react'

import Crumb from '@components/Header/Breadcrumbs/Crumb'
import Query from '@components/Query/Query'
import GET_DRIVER_NAME from './queries/getDriverName.query'

const isSecondPart = i => i === 1

const DriverCrumbs = ({ crumbs }) => {
  return crumbs.map((crumb, i) => {
    if (isSecondPart(i)) {
      return (
        <Query key={crumb} query={GET_DRIVER_NAME} variables={{ id: crumb }}>
          {({ driver }) => {
            if (!driver) {
              return null
            }

            return (
              <Crumb
                key={crumb}
                text={driver.name}
                link={`/drivers/${crumb}`}
              />
            )
          }}
        </Query>
      )
    } else {
      return <Crumb key={crumb} text={crumb} />
    }
  })
}

export default DriverCrumbs
