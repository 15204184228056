import gql from 'graphql-tag'

const GET_MESSAGE_TEMPLATES = gql`
  query messageTemplates($id: ID!) {
    messageTemplates(where: { fleet: { id: $id } }) {
      id
      key
      type
      fleet {
        id
        name
      }
      text
      createdAt
      updatedAt
    }
  }
`

export default GET_MESSAGE_TEMPLATES
