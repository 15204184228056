import { NonIdealState } from '@blueprintjs/core'
import { string } from 'prop-types'
import React from 'react'
import { Mutation } from 'react-apollo'

import defaultErrorHandler from '@utils/defaultErrorHandler'
import DetailsForm from './Form/DetailsForm'
import EDIT_FLEET from './mutations/editFleet.mutation'
import GET_FLEET from './queries/getFleetDetails.query'
import { history } from '@stores/routerStore'
import { authorizedRole } from '@stores/userStore'
import { successToast } from '@utils/toast'
import Query from '@components/Query/Query'
import { numberToPence, penceToPounds } from '@utils/helpers'

const handleMutation = id => {
  history.push(`/fleets/${id}/details`)
}

const Details = ({ fleetId }) => {
  const isSuperAdmin = () => {
    return authorizedRole('SUPERADMIN_ROLE')
  }
  return (
    <Query query={GET_FLEET} variables={{ id: fleetId }}>
      {({ fleet }) => {
        if (!fleet) {
          return (
            <NonIdealState
              icon="unknown-vehicle"
              title="No fleet with this ID"
              description="There is currently no fleet with this ID"
            />
          )
        }

        return (
          <Mutation
            mutation={EDIT_FLEET}
            onError={defaultErrorHandler}
            onCompleted={({ fleetUpdateBasicDetails }) => {
              handleMutation(fleetUpdateBasicDetails.id)
              successToast('Successfully edited fleet details')
            }}
            refetchQueries={[
              {
                query: GET_FLEET,
                variables: {
                  id: fleetId,
                },
              },
            ]}
          >
            {editDetails => {
              const initialValues = {
                ...fleet,
                paymentGatewayCharge: penceToPounds(fleet.paymentGatewayCharge),
                fleetCharge: penceToPounds(fleet.fleetCharge),
                defaultJourneyCharge: penceToPounds(fleet.defaultJourneyCharge),
                overrideDefaultPlatformChargeValue: penceToPounds(
                  fleet.overrideDefaultPlatformChargeValue
                ),
                maxFarePerJourney: penceToPounds(fleet.maxFarePerJourney),
                autoRefundStandardNoShowCaptureCustomValue: penceToPounds(
                  fleet.autoRefundStandardNoShowCaptureCustomValue || 0
                ),
                autoRefundPrepayNoShowCaptureCustomValue: penceToPounds(
                  fleet.autoRefundPrepayNoShowCaptureCustomValue || 0
                ),
                autoRefundStandardCancelledCaptureCustomValue: penceToPounds(
                  fleet.autoRefundStandardCancelledCaptureCustomValue || 0
                ),
                autoRefundPrepayCancelledCaptureCustomValue: penceToPounds(
                  fleet.autoRefundPrepayCancelledCaptureCustomValue || 0
                ),
              }

              initialValues.name = fleet.name
              return (
                <DetailsForm
                  onSubmit={values => {
                    const variables = {
                      ...values,
                      paymentGatewayCharge: numberToPence(
                        values.paymentGatewayCharge
                      ),
                      fleetCharge: numberToPence(values.fleetCharge),
                      defaultJourneyCharge: numberToPence(
                        values.defaultJourneyCharge
                      ),
                      overrideDefaultPlatformChargeValue: numberToPence(
                        values.overrideDefaultPlatformChargeValue
                      ),
                      maxFarePerJourney: numberToPence(
                        values.maxFarePerJourney
                      ),
                      autoRefundStandardNoShowCaptureCustomValue: numberToPence(
                        values.autoRefundStandardNoShowCaptureCustomValue
                      ),
                      autoRefundPrepayNoShowCaptureCustomValue: numberToPence(
                        values.autoRefundPrepayNoShowCaptureCustomValue
                      ),
                      autoRefundStandardCancelledCaptureCustomValue: numberToPence(
                        values.autoRefundStandardCancelledCaptureCustomValue
                      ),
                      autoRefundPrepayCancelledCaptureCustomValue: numberToPence(
                        values.autoRefundPrepayCancelledCaptureCustomValue
                      ),
                    }
                    if (!isSuperAdmin()) {
                      delete variables.paymentGatewayCharge
                      delete variables.paymentGatewayPercentage
                    }
                    editDetails({
                      variables,
                    })
                  }}
                  initialValues={initialValues}
                />
              )
            }}
          </Mutation>
        )
      }}
    </Query>
  )
}

Details.propTypes = {
  fleetId: string.isRequired,
}

export default Details
