import gql from 'graphql-tag'

const UPDATE_WEBHOOK = gql`
  mutation webhookUpdate(
    $fleetId: String!
    $hookId: Int!
    $id: ID!
    $status: String!
    $template: String!
  ) {
    webhookUpdate(
      hookId: $hookId
      fleetId: $fleetId
      id: $id
      status: $status
      template: $template
    ) {
      id
    }
  }
`

export default UPDATE_WEBHOOK
