import {
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  NonIdealState,
  Popover,
  Position,
  Spinner,
  Tag,
  Alert,
  ProgressBar,
} from '@blueprintjs/core'
import moment from 'moment'
import { isNil, clone, debounce, reverse, startCase, filter, get } from 'lodash'
import { func, string } from 'prop-types'
import React, { Component, Fragment } from 'react'
import { Query } from 'react-apollo'
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query'
import { authorizedRole } from '@stores/userStore'
import { client } from '@services/client'
import { penceToPounds } from '@utils/helpers'
import { successToast } from '@utils/toast'

import {
  GET_TRANSACTIONS,
  SEARCH_GET_TRANSACTIONS_ID,
  SEARCH_GET_TRANSACTIONS_PAYOUT,
  SEARCH_GET_TRANSACTIONS_PAYMENT,
} from './queries/getTransactions.query'

import { DateSelect, Search, FleetFilter } from '@components/Toolbar'
import PageNumbers from '@components/PageNumbers/PageNumbers'
import TransactionsTable from './TransactionTable'
import FilterRow from '@components/FilterRow/FilterRow'

const DEFAULT_RECORDS = 100

const isSuperAdmin = () => {
  return authorizedRole('SUPERADMIN_ROLE')
}

const urlPropsQueryConfig = {
  beforeDate: { type: UrlQueryParamTypes.string },
  afterDate: { type: UrlQueryParamTypes.string },
  searchValue: { type: UrlQueryParamTypes.string },
  searchField: { type: UrlQueryParamTypes.string },
  fleetId: { type: UrlQueryParamTypes.string },
}

class AllTransactions extends Component {
  static defaultProps = {
    beforeDate: moment().endOf('day'),
    afterDate: moment().startOf('day'),
    searchValue: null,
    searchField: 'id',
    fleetId: null,
  }

  constructor(props) {
    super(props)
    this.state = {
      first: null,
      last: DEFAULT_RECORDS,
      after: null,
      before: null,
      currentPage: 1,
      pageInfo: null,
      totalCount: 0,
      downloading: false,
      downloadingProgress: 0,
      columns: {
        transactionId: { visible: true, path: 'id' },
        createdAt: { visible: true, path: 'createdAt', date: true },
        gatewayStatus: { visible: true, path: 'gatewayStatus' },
        type: { visible: true, path: 'type' },
        journeyId: { visible: true, path: 'journey.id' },
        siteId: { visible: true, path: 'journey.siteId' },
        tripId: { visible: true, path: 'tripIdSnapshot' },
        driver: { visible: true, path: 'journey.driver.name' },
        fleet: { visible: true, path: 'journey.driver.fleet.name' },
        paymentRef: { visible: true, path: 'paymentRef' },
        payoutRef: { visible: true, path: 'payoutRef' },
        driverId: { visible: false, path: 'driverIdSnapshot' },
        customerId: { visible: false, path: 'customerIdSnapshot' },
        fleetName: { visible: false, path: 'fleetNameSnapshot' },
        customerPreauth: {
          visible: true,
          path: 'preauthTotal',
          currency: true,
        },
        customerCharge: { visible: true, path: 'grandTotal', currency: true },
        cardProcessingFee: {
          visible: true,
          path: 'processingFee',
          currency: true,
        },
        airpayFee: {
          visible: true,
          path: 'partnerChargeGross',
          currency: true,
        },
        actualProcessingFee: {
          visible: true,
          path: 'actualProcessingFee',
          currency: true,
        },
        airpayProcessingMargin: {
          visible: false,
          path: 'processFeeDifference',
          currency: true,
        },
        amountRetainedFromTransaction: {
          visible: false,
          path: 'amountRetainedFromTransaction',
          currency: true,
        },
        divisibleTotal: {
          visible: false,
          path: 'divisibleTotal',
          currency: true,
        },
        reservedFunds: {
          visible: false,
          path: 'partnerPayout',
          currency: true,
        },
        fleetCharge: {
          visible: true,
          path: 'fleetChargePayout',
          currency: true,
        },
        fleetPayout: { visible: true, path: 'fleetPayout', currency: true },
        platformPayoutGross: {
          visible: false,
          path: 'platformPayout',
          currency: true,
        },
        airpayPayoutGross: {
          visible: false,
          path: 'partnerPayoutAfterPlatformFee',
          currency: true,
        },
        refundedTotal: {
          visible: true,
          path: 'refundedTotal',
          currency: true,
        },
      },
    }
  }

  goToNext = (e, nextPage, startCursor) => {
    e.preventDefault()
    nextPage &&
      this.setState(prevState => ({
        first: null,
        last: DEFAULT_RECORDS,
        before: startCursor,
        after: null,
        currentPage: prevState.currentPage + 1,
      }))
  }

  goToPrevious = (e, previousPage, endCursor) => {
    e.preventDefault()
    previousPage &&
      this.setState(prevState => ({
        first: DEFAULT_RECORDS,
        last: null,
        before: null,
        after: endCursor,
        currentPage: prevState.currentPage - 1,
      }))
  }

  searchTransaction = e => {
    this.props.onChangeSearchValue(e.value)
    this.props.onChangeSearchField(e.field)
    if (e.value && e.value.length >= 3) {
      this.filterQuery(e.value)
    }
  }

  filterQuery = debounce(searchId => {
    this.setState({
      before: null,
      after: null,
      first: null,
      last: DEFAULT_RECORDS,
      currentPage: 1,
    })
  }, 300)

  clearSearch = e => {
    e.preventDefault()
    this.props.onChangeSearchValue(null)
    this.props.onChangeSearchField(null)
    this.setState({
      before: null,
      after: null,
      last: DEFAULT_RECORDS,
      currentPage: 1,
    })
  }

  newRecordsRefresh = refetch => {
    this.setState({ showRefetchToast: false })
    refetch()
  }

  handleColumnVisibility = e => {
    this.setState({
      columns: {
        ...this.state.columns,
        [e.currentTarget.id]: {
          ...this.state.columns[e.currentTarget.id],
          visible: !this.state.columns[e.currentTarget.id].visible,
        },
      },
    })
  }

  downloadCSV = async e => {
    e.preventDefault()
    this.setState({ downloading: true })

    const afterDate = moment(this.props.afterDate, 'YYYYMMDDTHHmm')
    const beforeDate = moment(this.props.beforeDate, 'YYYYMMDDTHHmm')
    const totalPages = Math.ceil(this.state.totalCount / DEFAULT_RECORDS)

    let csvData = [
      // header
      filter(
        Object.keys(this.state.columns).map(column => {
          return this.state.columns[column].visible ? startCase(column) : false
        })
      ).join(','),
    ]

    let startCursor = null

    for (let i = 0; i <= totalPages - 1; i++) {
      const progress = ((i / totalPages) * 100) / 100
      this.setState({ downloadingProgress: progress })
      await client
        .query(
          this.queryToUse({
            first: null,
            last: DEFAULT_RECORDS,
            before: startCursor,
            after: null,
          })
        )
        .then(data => {
          startCursor = data.data.transactionsConnection.pageInfo.startCursor
          const edges = reverse(clone(data.data.transactionsConnection.edges))
          edges.map(record => {
            csvData.push(
              filter(
                Object.keys(this.state.columns).map(column => {
                  const col = this.state.columns[column]
                  const val = get(record.node, col.path, null)
                  if (!col.visible) return false
                  if (col.date)
                    return moment(record.node[col.path]).format(
                      'DD/MM/YYYY HH:mm:ss'
                    )
                  if (col.currency) return penceToPounds(val)
                  return !isNil(val) ? `"${val}"` : `""`
                })
              ).join(',')
            )
          })
        })
    }

    const blob = new Blob([csvData.join('\n')], { type: 'octet/stream' })
    const url = window.URL.createObjectURL(blob)

    //Add temp Link to page and start download.
    let a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = url
    a.download = `transactions-${afterDate.format(
      'DDMMMYYYY'
    )}-to-${beforeDate.format('DDMMMYYYY')}.csv`
    a.click()

    window.URL.revokeObjectURL(url)

    this.setState({ downloadingProgress: 0 })
    this.setState({ downloading: false })

    successToast('Download Complete')
  }

  queryToUse = (paging = {}) => {
    const beforeDate = moment(this.props.beforeDate, 'YYYYMMDDTHHmm').endOf(
      'minute'
    )
    const afterDate = moment(this.props.afterDate, 'YYYYMMDDTHHmm').startOf(
      'minute'
    )
    let queryToUse = {
      query: GET_TRANSACTIONS,
      variables: {
        createdAt_gte: afterDate,
        createdAt_lte: beforeDate,
        fleetId: this.props.fleetId || undefined,
        ...paging,
      },
    }
    if (this.props.searchValue) {
      queryToUse = {
        query:
          this.props.searchField === 'id'
            ? SEARCH_GET_TRANSACTIONS_ID
            : this.props.searchField === 'payoutRef'
            ? SEARCH_GET_TRANSACTIONS_PAYOUT
            : SEARCH_GET_TRANSACTIONS_PAYMENT,
        variables: {
          searchValue: this.props.searchValue,
          ...paging,
        },
      }
    }

    return queryToUse
  }

  filterBar = () => {
    const { currentPage, totalCount, pageInfo } = this.state
    const totalPages = Math.ceil(totalCount / DEFAULT_RECORDS)
    const hasNextPage = currentPage < totalPages
    const hasPreviousPage = currentPage !== 1
    const beforeDate = moment(this.props.beforeDate, 'YYYYMMDDTHHmm').endOf(
      'minute'
    )
    const afterDate = moment(this.props.afterDate, 'YYYYMMDDTHHmm').startOf(
      'minute'
    )

    return (
      <FilterRow>
        <ButtonGroup>
          <DateSelect
            disabled={this.props.searchValue !== null}
            defaultNmbRecords={DEFAULT_RECORDS}
            afterDate={afterDate}
            beforeDate={beforeDate}
            onDateChange={({ beforeDate, afterDate }) => {
              this.props.onChangeBeforeDate(beforeDate.format('YYYYMMDDTHHmm'))
              this.props.onChangeAfterDate(afterDate.format('YYYYMMDDTHHmm'))
              this.props.onChangeSearchValue(null)
              this.props.onChangeSearchField(null)
            }}
            updateAfterDate={date =>
              this.setState({
                dateAfter: date,
                currentPage: 1,
              })
            }
            updateBeforeDate={date =>
              this.setState({
                dateBefore: date,
                currentPage: 1,
              })
            }
          />
          <Divider />
          <Search
            options={[
              { label: 'Transaction ID', value: 'id' },
              { label: 'Payment Ref', value: 'paymentRef' },
              { label: 'Payout Ref', value: 'payoutRef' },
            ]}
            placeholder="Search..."
            fieldValue={this.props.searchField}
            value={this.props.searchValue}
            onChange={this.searchTransaction}
            onReset={e => {
              this.clearSearch(e)
            }}
          />
          {isSuperAdmin() && (
            <Fragment>
              <Divider />
              <Popover position={Position.BOTTOM_LEFT} boundary="window">
                <Button
                  text="Columns"
                  icon="list-columns"
                  rightIcon="double-caret-vertical"
                />
                <div className="bp3-popover-height">
                  {Object.keys(this.state.columns)
                    .sort()
                    .map(column => (
                      <Checkbox
                        id={column}
                        key={column}
                        label={`${startCase(column)}`}
                        checked={this.state.columns[column].visible}
                        alignIndicator="right"
                        onChange={this.handleColumnVisibility}
                      />
                    ))}
                </div>
              </Popover>
              <FleetFilter
                fleetId={this.props.fleetId}
                onChange={id => {
                  this.props.onChangeFleetId(id)
                }}
              />
            </Fragment>
          )}
        </ButtonGroup>

        <Button
          disabled={!totalCount > 0}
          loading={this.state.downloading}
          icon={'cloud-download'}
          onClick={this.downloadCSV}
          style={{
            marginLeft: 'auto',
          }}
        />

        <Tag disabled={true} minimal={true} large={true}>
          {totalCount} Transactions
        </Tag>

        <ButtonGroup id="navigationGroup">
          <Button
            disabled={!hasPreviousPage || !pageInfo}
            icon="chevron-left"
            onClick={e =>
              this.goToPrevious(e, hasPreviousPage, pageInfo.endCursor)
            }
          >
            Back
          </Button>
          <PageNumbers
            currentPage={currentPage}
            totalPages={totalPages === 0 ? 1 : totalPages}
          />
          <Button
            disabled={!hasNextPage || !pageInfo}
            rightIcon="chevron-right"
            onClick={e => this.goToNext(e, hasNextPage, pageInfo.startCursor)}
          >
            Next
          </Button>
        </ButtonGroup>
      </FilterRow>
    )
  }

  render() {
    const queryToUse = this.queryToUse({
      first: this.state.first,
      last: this.state.last,
      before: this.state.before,
      after: this.state.after,
    })

    return (
      <Fragment>
        <div className="bp3-table-frame">
          {this.filterBar()}
          <Query
            query={queryToUse.query}
            variables={queryToUse.variables}
            fetchPolicy="cache-first"
            onCompleted={({ transactionsConnection }) => {
              this.setState({
                totalCount: transactionsConnection.totalCount,
                pageInfo: transactionsConnection.pageInfo,
              })
            }}
          >
            {({ loading, error = null, data }) => {
              if (error) {
                return (
                  <NonIdealState
                    icon="error"
                    title="Transactions Error"
                    description="Please try again."
                  />
                )
              }

              if (loading) {
                return (
                  <NonIdealState
                    icon={<Spinner size={60} value={null} />}
                    title="Loading Transactions"
                    description="Please wait..."
                  />
                )
              } else {
                const transactionsConnection = data.transactionsConnection

                if (transactionsConnection && transactionsConnection.edges) {
                  const edges = reverse(clone(transactionsConnection.edges))
                  return (
                    <Fragment>
                      <TransactionsTable
                        edges={edges}
                        visibleColumns={this.state.columns}
                      />
                    </Fragment>
                  )
                }

                return (
                  <NonIdealState
                    icon="error"
                    title="No Matching Transactions"
                    description="Modify filters and try again "
                  />
                )
              }
            }}
          </Query>
        </div>
        <Alert
          isOpen={this.state.downloading}
          icon={'cloud-download'}
          canEscapeKeyCancel={false}
          canOutsideClickCancel={false}
          confirmButtonText="Cancel"
          onConfirm={() => {
            location.reload()
          }}
        >
          <p>Downloading transactions, please wait...</p>
          <ProgressBar
            intent={'primary'}
            value={this.state.downloadingProgress}
          />
        </Alert>
      </Fragment>
    )
  }
}

AllTransactions.propTypes = {
  beforeDate: string,
  afterDate: string,
  searchValue: string,
  searchField: string,
  fleetId: string,
  onChangeBeforeDate: func,
  onChangeAfterDate: func,
  onChangeSearchValue: func,
  onChangeSearchField: func,
  onChangeFleetId: func,
}

export default addUrlProps({ urlPropsQueryConfig })(AllTransactions)
