import gql from 'graphql-tag'

const GET_TEMPlATE_TYPES = gql`
  query enumTypes($name: String!) {
    __type(name: $name) {
      enumValues {
        name
      }
    }
  }
`

export default GET_TEMPlATE_TYPES
