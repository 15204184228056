import React from 'react'
import Form from './Form'
import { Formik } from 'formik'
import { func, shape, string, array, number } from 'prop-types'
import { validation } from './validation'

const DetailsForm = ({ onSubmit, initialValues }) => (
  <Formik
    onSubmit={onSubmit}
    initialValues={initialValues}
    initialStatus={{ previewImageValue: null }}
    validationSchema={validation}
  >
    {props => <Form {...props} />}
  </Formik>
)

DetailsForm.propTypes = {
  onSubmit: func.isRequired,
  initialValues: shape({
    id: string.idRequired,
    name: string.isRequired,
    fleet: shape({
      id: string.isRequired,
      name: string.isRequired,
      partner: shape({
        id: string.isRequired,
        name: string.isRequired,
      }),
    }),
    journeys: array,
    icabbiDriverId: number.isRequired,
    payments: array,
    createdAt: string.isRequired,
    updatedAt: string.isRequired,
  }),
}

export default DetailsForm
