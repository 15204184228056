import gql from 'graphql-tag'

const EDIT_USER = gql`
  mutation userUpdate(
    $firstName: String
    $lastName: String
    $id: ID!
    $roleId: String
    $phoneNumber: String
    $ownsFleets: [String!]!
  ) {
    userUpdate(
      where: { id: $id }
      data: {
        firstName: $firstName
        lastName: $lastName
        roleId: $roleId
        ownsFleetIds: $ownsFleets
        phoneNumber: $phoneNumber
      }
    ) {
      id
      firstName
      lastName
      phoneNumber
      disabled
      role {
        id
        name
      }
      ownsFleets {
        id
      }
    }
  }
`

export default EDIT_USER
