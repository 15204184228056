import gql from 'graphql-tag'

const ADD_MESSAGE_TEMPLATE = gql`
  mutation messageTemplateCreate(
    $fleetId: String!
    $text: String!
    $type: FleetTemplateType!
  ) {
    messageTemplateCreate(fleetId: $fleetId, text: $text, type: $type) {
      id
      key
      type
      fleet {
        id
        name
      }
      text
      createdAt
      updatedAt
    }
  }
`

export default ADD_MESSAGE_TEMPLATE
