import { Formik } from 'formik'
import { func, object } from 'prop-types'
import React from 'react'

import Form from './Form'
import { validation } from './validation'
import Query from '@components/Query/Query'
import GET_ROLES from './queries/getAllRoles.query'

const UserForm = ({ onSubmit, initialValues, onDisable }) => (
  <Query query={GET_ROLES}>
    {({ roles }) => (
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validation}
      >
        {props => <Form {...props} roles={roles} onDisable={onDisable} />}
      </Formik>
    )}
  </Query>
)

UserForm.propTypes = {
  onSubmit: func.isRequired,
  initialValues: object,
  onDisable: func,
}

export default UserForm
