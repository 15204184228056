import find from 'lodash/find'
import { getNames, getCode } from 'country-list'
import map from 'lodash/map'
import replace from 'lodash/replace'
import shortid from 'shortid'

export const selectionUpdate = data => {
  // data.currentTarget which blueprint uses does not give access to label value. This loops through options and finds the one matching the value.

  const options = data.currentTarget.childNodes
  const selection = find(
    options,
    option => option.value === data.currentTarget.value
  )

  return { label: selection.label, value: data.currentTarget.value }
}

export const currencyToSymbol = currency => {
  var currency_symbols = {
    USD: '$', // US Dollar
    EUR: '€', // Euro
    GBP: '£', // British Pound Sterling
  }
  return currency_symbols[currency] || currency
}
export const countryList = () => {
  const countries = getNames()
  return countries.map(country => {
    return { value: getCode(country), label: country }
  })
}

export const penceToPounds = pence => (Number(pence) / 100).toFixed(2)

export const numberToPence = num => {
  return Number(num.toString().replace('.', ''))
}

export const poundsToPence = pounds => Math.round(parseFloat(pounds) * 100)

export const checkForBlankInterval = (
  intervalArray,
  setFieldError,
  push,
  BLANK_INTERVAL,
  fieldName
) => {
  const lastTimeInterval = intervalArray[intervalArray.length - 1]

  const isIncompleteInterval = interval =>
    !interval.start.time ||
    !interval.start.day ||
    !interval.end.time ||
    !interval.end.day

  if (isIncompleteInterval(lastTimeInterval)) {
    setFieldError(`${fieldName}`, 'Please enter a time interval')
  } else {
    push({ ...BLANK_INTERVAL, key: shortid.generate() })
  }
}

export const removeUnderscores = str => replace(str, /_/g, ' ')

export const setKeys = arr =>
  map(arr, obj => {
    obj.key = shortid.generate()
    return obj
  })

export const divideAndRound = num => {
  return (num / 100).toFixed(0)
}
