import React from 'react'
import injectSheet from 'react-jss'

import { number, object } from 'prop-types'

const styles = theme => ({
  pageNumbersContainer: {
    minWidth: '90px',
    marginRight: '-1px',
    backgroundColor: theme.colors.disabledBackground,
    padding: '5px 10px',
  },
  pageNumbers: {
    color: theme.colors.disabledText,
    margin: '1px 0px 0px 0px',
    textAlign: 'center',
  },
})

const PageNumbers = ({ currentPage, totalPages, classes }) => {
  return (
    <div className={classes.pageNumbersContainer}>
      <p className={classes.pageNumbers}>{`${currentPage} of ${totalPages}`}</p>
    </div>
  )
}

PageNumbers.propTypes = {
  currentPage: number,
  totalPages: number,
  theme: object,
  classes: object,
}

export default injectSheet(styles)(PageNumbers)
