import React from 'react'
import {
  FormGroup,
  InputGroup,
  Classes,
  Button,
  Intent,
} from '@blueprintjs/core'
import find from 'lodash/find'

import { func, object, array } from 'prop-types'
import { selectionUpdate } from '@utils/helpers'
import RoleSelect from '@components/RoleSelect/RoleSelect'
import FleetSelect from '@components/FleetSelect/FleetSelect'

const Form = ({
  values,
  handleChange,
  handleSubmit,
  errors,
  setFieldValue,
  roles,
  isValid,
}) => {
  const fleetAdminRole = find(roles, { key: 'FLEET_ADMIN_ROLE' })
  const fleetUserRole = find(roles, { key: 'FLEET_USER_ROLE' })
  const journeyResenderRole = find(roles, { key: 'JOURNEY_ADMIN' })

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup
        label="Email"
        helperText={errors.email || ''}
        intent={Intent.DANGER}
      >
        <InputGroup
          value={values.email}
          name="email"
          type="email"
          onChange={handleChange}
          intent={errors.email ? Intent.DANGER : Intent.NONE}
        />
      </FormGroup>
      <FormGroup
        label="Role"
        labelFor="roleId"
        intent={Intent.Danger}
        helperText={errors.roleId || ''}
      >
        <RoleSelect
          values={values}
          roleId={values.roleId}
          onChange={event => {
            const selection = selectionUpdate(event)
            setFieldValue('roleId', selection.value, false)
          }}
        />
      </FormGroup>
      {[fleetAdminRole.id, fleetUserRole.id, journeyResenderRole.id].includes(
        values.roleId
      ) && (
        <FormGroup label="Fleets" labelFor="fleetIds" intent={Intent.Danger}>
          <FleetSelect
            fleetIds={values.ownsFleets}
            addFleetId={fleet => {
              setFieldValue(
                'ownsFleets',
                [...values.ownsFleets, fleet.id],
                false
              )
            }}
            removeFleetId={idToRemove => {
              setFieldValue(
                'ownsFleets',
                values.ownsFleets.filter(id => id !== idToRemove),
                false
              )
            }}
          />
        </FormGroup>
      )}

      <div className={Classes.DIALOG_FOOTER_ACTIONS}>
        <Button text="Save" intent={Intent.DEFAULT} type="submit" />
      </div>
    </form>
  )
}

Form.propTypes = {
  values: object,
  handleChange: func,
  setFieldValue: func,
  handleSubmit: func,
  errors: object,
  roles: array,
}
export default Form
