import gql from 'graphql-tag'

const GET_FLEET_FROM_DB = gql`
  query getFleetbyId($id: ID!) {
    fleet(where: { id: $id }) {
      id
      bankAccountVerification
      passportVerification
      identityVerification
      companyVerification
      updatedAt
    }
  }
`
export default GET_FLEET_FROM_DB
