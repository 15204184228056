import gql from 'graphql-tag'

const DELETE_WEBHOOK = gql`
  mutation webhookDelete($fleetId: String!, $hookId: Int!, $id: ID!) {
    webhookDelete(hookId: $hookId, fleetId: $fleetId, id: $id) {
      id
    }
  }
`

export default DELETE_WEBHOOK
