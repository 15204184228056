import gql from 'graphql-tag'

const ADD_FLEET = gql`
  mutation fleetUserCreate(
    $name: String!
    $partnerId: String!
    $email: String!
    $roleId: String!
  ) {
    fleetUserCreate(
      name: $name
      partnerId: $partnerId
      email: $email
      roleId: $roleId
    ) {
      id
      key
      name
    }
  }
`

const GET_ROLE_ID = gql`
  query role {
    role(where: { key: "FLEET_ADMIN_ROLE" }) {
      id
    }
  }
`

export { ADD_FLEET, GET_ROLE_ID }
